import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'bulk_benefits_set_updates',
  '/bulk_benefits_set_updates',
  'updates'
)
const actions = generator.generate()

export default actions
