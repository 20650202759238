import React from 'react'
import Component from '../common/Component'
import BenefitsSetBulkEditsComponent from '../BenefitsSet/BenefitsSetBulkEditsComponent'
import { benefitLabels, metadatumFieldsDiffSet } from './lifeFields'

const defaultFields = [
  'id',
  'vericred_id',
  'name',
  'audience',
  'issuer_name',
  'voluntary',
  'effective_date',
  'expiration_date',
  'privileged_data',
  'insured_benefits',
  'guaranteed_issue_bands',
  'convertible_after_termination',
  'disabled_premium_waiver',
  'premium_increase',
  'accelerated_life_benefit',
  'benefit_reduction_schedules',
  'accidental_death_dismemberment_included',
  'accidental_death_dismemberment_covered_members'
]

export default class LifeBenefitsSetBulkEditsComponent extends Component {
  render() {
    return (
      <BenefitsSetBulkEditsComponent
        benefitLabels={benefitLabels}
        metadatumFields={metadatumFieldsDiffSet}
        defaultFields={defaultFields}
        benefitsSetUrl="/life_benefits"
        productLine="life"
        {...this.props}
      />
    )
  }
}
