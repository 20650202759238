import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/IssuerSources'
import connectIndex from '../../lib/connectIndex'

export class IssuerSourceIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['name', 'issuer_id']
  }

  static columns = [
    {
      key: 'name',
      label: 'Name',
      filterable: false,
      sortable: true
    },
    {
      key: 'active_from',
      label: 'Active From',
      sortable: true
    },
    {
      key: 'active_until',
      label: 'Active Until',
      sortable: true
    },
    {
      key: 'source',
      label: 'Source',
      filterable: true,
      sortable: false
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/issuer_sources"
          name="Issuer Source"
          pagination={this.props.pagination}
          columns={IssuerSourceIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name or Issuer ID"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'source',
    config: 'issuer_sources.sources'
  }
]

export default connectIndex(IssuerSourceIndex, 'issuerSources', actions, filters)
