import client from '../../client'
import parseError from '../../lib/parseError'
import {
  REQUEST_PLANS,
  RECEIVE_PLANS,
  RECEIVE_PLANS_ERROR
} from './consts'

function requestPlans() {
  return { type: REQUEST_PLANS }
}

function receivePlans(plans, familyId) {
  return { type: RECEIVE_PLANS, plans }
}

function receivePlansError(error) {
  const { generalError, resourceErrors } = parseError(error)

  return { type: RECEIVE_PLANS_ERROR, generalError, resourceErrors }
}

export default function getPlanSearches(productLine, zipCode, fipsCode, market, enrollmentDate) {
  const fields = {
    product_line: productLine,
    zip_code: zipCode,
    fips_code: fipsCode,
    market,
    enrollment_date: enrollmentDate
  }
  return (dispatch) => {
    dispatch(requestPlans())
    client.post('/plans/searches', { search: fields })
      .then((response) => response.data)
      .then((json) => dispatch(receivePlans(json)))
      .catch((error) => dispatch(receivePlansError(error)))
  }
}
