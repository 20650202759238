import { toPairs, isUndefined, isNil, has, omit, cloneDeep, get } from 'lodash'

import benefitGroups from './medAdvBenefitGroups.json'

const fields = {
  'Plan Info': {
    metadatum: {
      name: {
        label: 'Name',
        never_hide: true
      },
      audience: {
        label: 'Audience',
        type: 'select',
        never_hide: true,
        options: []
      },
      plan_type: {
        label: 'Plan Type',
        type: 'select',
        never_hide: true,
        options: (values, configuration) => {
          if (!has(configuration, 'audience_plan_types')) {
            return []
          }

          if (has(configuration.audience_plan_types, values.audience)) {
            return configuration.audience_plan_types[values.audience]
          }

          return []
        },
        disabled: (values) => isNil(values.audience)
      },
      issuer_id: {
        label: 'Issuer',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'issuersIndex.main',
        reset: 'issuersIndex.reset',
        recordPath: 'issuers',
        existingRecordPath: 'issuer',
        recordLabel: ['issuer_id', 'name', 'state_code'],
        searchType: 'search_term',
        filterOptions: false,
        display: (value) => {
          return get(value, 'issuer.name', '') || ''
        }
      },
      source: {
        label: 'Data Source',
        type: 'select',
        never_hide: true,
        options: [

        ]
      },
      plan_year: {
        label: 'Plan Year',
        never_hide: true
      },
      effective_date: {
        label: 'Effective Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      expiration_date: {
        label: 'Expiration Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      service_area_id: {
        label: 'Service Area',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'serviceAreasIndex.main',
        reset: 'serviceAreasIndex.reset',
        recordPath: 'service_areas',
        existingRecordPath: 'service_area',
        recordLabel: ['external_key'],
        searchType: 'search_term',
        filterOptions: false,
        filterFields: [
          { data_key: 'audience', filter_key: 'audience', required: true },
          { data_key: 'plan_year', filter_key: 'plan_year', required: true },
          { data_key: 'carrier_id', filter_key: 'issuer_id', required: true }
        ],
        display: (value) => {
          return get(value, 'service_area.external_key', '') || get(value, 'service_area_external_key', '')
        },
        alternateKey: 'service_area_external_key',
        bulkEditKey: 'service_area_external_key'
      },
      customer_service_phone_member: {
        label: 'Customer Service Phone Number - Member',
        never_hide: true
      },
      customer_service_phone_non_member: {
        label: 'Customer Service Phone Number - NonMember',
        never_hide: true
      },
      stand_alone_part_d: {
        label: 'Stand Alone Part D',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      star_rating_drug_plan: {
        label: 'Star Rating - Drug Plan',
        never_hide: true
      },
      star_rating_health_plan: {
        label: 'Star Rating - Health Plan',
        never_hide: true
      },
      star_rating_overall: {
        label: 'Star Rating - Overall',
        never_hide: true
      },
      premium_health: {
        label: 'Premium - Health',
        never_hide: true
      },
      premium_drug: {
        label: 'Premium - Drug',
        never_hide: true
      },
      part_b_premium_reduction: {
        label: 'Part B Premium Reduction',
        never_hide: true
      },
      supplemental_options: {
        label: 'Supplemental Options',
        never_hide: true,
        disabled: true,
        display: (value) => {
          if (Array.isArray(value.supplemental_options)) {
            return JSON.stringify(value.supplemental_options, null, 2).replace(/"/g, '')
          }

          return value.supplemental_options || ''
        }
      }
    },
    plan_external_ids: {
      label: 'External IDs',
      foreign_key: 'plan_external_id_id',
      options: () => {
        return {
          medicare_plan_id: 'Medicare Plan ID', issuer_internal_id: 'Issuer-Internal ID'
        }
      },
      never_hide: true,
      type: 'plan_external_ids',
      hidden: () => {
        return false
      },
      display: (value) => {
        if (isUndefined(value)) {
          return ''
        }

        let typeLabel
        switch (value.external_id_type) {
        case 'issuer_internal_id':
          typeLabel = 'Issuer-Internal ID'
          break
        case 'medicare_plan_id':
          typeLabel = 'Medicare Plan ID'
          break
        default:
          typeLabel = ''
          break
        }

        return `${typeLabel}: ${value.external_id}`
      }
    }
  },
  Benefits: {
    benefits: {
      name: 'benefits',
      foreign_key: 'benefit_id',
      never_hide: true,
      type: 'tiered_field',
      label: 'Benefits',
      fields: benefitGroups
    }
  }
}

export const fieldPairs = toPairs(fields)
export const benefitLabels = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  // eslint-disable-next-line no-param-reassign
  fieldset.fields.forEach((f) => { memo[f.name] = f.label })
  return memo
}, {})

export const benefitNames = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  fieldset.fields.forEach((f) => { memo.push(f.name) })
  return memo
}, [])

export const benefitGroupNames = fields.Benefits.benefits.fields.map((b) => b.name)
export const infoFields = omit(fields['Plan Info'], ['plan_external_ids'])

export const externalIdField = fields['Plan Info'].plan_external_ids

const metadatumFieldsDiffSetTemp = cloneDeep(infoFields.metadatum)

metadatumFieldsDiffSetTemp.issuer_name = {
  label: 'Issuer Name',
  never_hide: true
}

metadatumFieldsDiffSetTemp.issuer_identifier = {
  label: 'Issuer Id',
  never_hide: true
}

delete metadatumFieldsDiffSetTemp.issuer_id

export const metadatumFields = infoFields.metadatum
export const metadatumFieldsDiffSet = {
  medicare_plan_id: { label: 'Medicare Plan ID' },
  ...metadatumFieldsDiffSetTemp
}

export default fields
