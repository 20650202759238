import React from 'react'
import PropTypes from 'prop-types'
import Component from './Component'

export default class PanelComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.element
    ]).isRequired
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}
