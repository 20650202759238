import React from 'react'
import actions from '../../actions/IssuerDisclaimers'
import Component from '../common/Component'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import CreateForm, { defaultPropTypes, connectCreateForm } from '../common/CreateForm'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'

export class IssuerDisclaimerCreate extends Component {
  static propTypes = defaultPropTypes

  render() {
    const { configuration } = this.props

    return (
      <CreateForm {...this.props}>
        <IssuerRemoteOptionsSelectField
          name="issuer_id"
          label="Issuer"
          errorKey="issuer"
        />
        <LabeledTextField
          name="active_from"
          label="Active From"
          type="date"
        />
        <LabeledTextField
          name="active_until"
          label="Active Until"
          type="date"
        />
        <SelectField
          name="line_of_coverage"
          label="Line of Coverage"
          options={configuration.line_of_coverage}
        />
        <SelectField
          name="market"
          label="Market"
          options={configuration.market}
        />
        <LabeledTextField name="disclaimer" label="Disclaimer" type="textarea" />
      </CreateForm>
    )
  }
}

export default connectCreateForm(IssuerDisclaimerCreate, 'Issuer Disclaimer', 'issuer_disclaimers', actions)
