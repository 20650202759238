import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isNil, upperFirst } from 'lodash'
import actions from '../../actions/Users'

export class UserShow extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    attributes: PropTypes.object,
    match: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    configuration: PropTypes.object
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
  }

  get id() {
    return this.props.match.params.id
  }

  get issuerRows() {
    const issuerLabels = this.props.attributes.user_issuers.map((u) => u.issuer_label)
    const rows = []

    rows.push(
      <tr key="issuers-0">
        <td><strong>Issuers</strong></td>
        <td>{issuerLabels[0]}</td>
      </tr>
    )

    return rows.concat(issuerLabels.slice(1).map((label, index) => (
      <tr key={`issuers-${index + 1}`}>
        <td />
        <td>{label}</td>
      </tr>
    )))
  }

  render() {
    const { attributes } = this.props

    if (isNil(attributes)) {
      return null
    }

    return (
      <div className="content">
        <h2>User Info</h2>
        <table className="pure-table">
          <tbody>
            <tr>
              <td><strong>Username</strong></td>
              <td>{attributes.username}</td>
            </tr>
            <tr>
              <td><strong>Email</strong></td>
              <td>{attributes.email}</td>
            </tr>
            <tr>
              <td><strong>Carrier</strong></td>
              <td>{attributes.carrier_name}</td>
            </tr>
            <tr>
              <td><strong>User Type</strong></td>
              <td>{upperFirst(attributes.user_type)}</td>
            </tr>
            {this.issuerRows}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.users.show

  const configuration = state.login.session.configuration.users

  return {
    id: slice.id,
    attributes: slice.attributes,
    configuration
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  show: actions.show.main,
  reset: actions.show.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UserShow)
