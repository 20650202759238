import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isNil } from 'lodash'
import Component from '../common/Component'
import actions from '../../actions/Issuers'
import carrierActions from '../../actions/Carriers'
import carrierBrandActions from '../../actions/CarrierBrands'
import { createFlashMessage } from '../../actions/FlashMessages'
import IssuerForm from './IssuerForm'
import EntitlementsProvider from '../common/EntitlementsProvider'
import alert from '../../alert'
import confirm from '../../confirm'

export class IssuerEdit extends Component {
  static propTypes = {
    match: PropTypes.object,
    configuration: PropTypes.object,
    entitlements: PropTypes.object,
    states: PropTypes.array,
    update: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    destroyResourceErrors: PropTypes.object,
    attributes: PropTypes.object,
    updated: PropTypes.bool.isRequired,
    destroy: PropTypes.func.isRequired,
    destroyReset: PropTypes.func.isRequired,
    deleted: PropTypes.bool.isRequired,
    createFlashMessage: PropTypes.func.isRequired,
    indexCarriers: PropTypes.func.isRequired,
    resetCarriers: PropTypes.func.isRequired,
    carriers: PropTypes.array,
    carriersRequestInProgress: PropTypes.bool.isRequired,
    indexCarrierBrands: PropTypes.func.isRequired,
    resetCarrierBrands: PropTypes.func.isRequired,
    carrierBrands: PropTypes.array,
    carrierBrandsRequestInProgress: PropTypes.bool.isRequired
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
    this.props.destroyReset()
    this.props.resetCarriers()
    this.props.resetCarrierBrands()
  }

  get id() {
    return this.props.match.params.id
  }

  get destroyError() {
    return get(this.props.destroyResourceErrors, 'base')
  }

  handleSubmit = (values) => {
    this.props
      .update(this.id, values)
      .then(({ id }) => {
        this.props.createFlashMessage('Updated issuer', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  handleDelete = () => {
    const confirmed = confirm('Are you sure you want to delete this issuer?')

    if (confirmed) {
      this.props
        .destroy(this.id)
        .then(() => {
          this.props.createFlashMessage(`Deleted issuer ‘${this.props.attributes.name}’`, { tag: 'index' })
        })
    }
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      entitlements,
      states,
      requestInProgress,
      attributes,
      updated,
      deleted
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (!isNil(this.destroyError)) {
      alert(this.destroyError)
    }

    if (isNil(attributes)) {
      return null
    }

    if (updated) {
      return this.redirect('/issuers')
    }

    if (deleted) {
      return this.redirect('/issuers')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    const carriers = this.props.carriers
    let carrierBrands = this.props.carrierBrands

    if (isNil(carrierBrands) && !this.props.carrierBrandsRequestInProgress) {
      carrierBrands = [this.props.attributes.carrier_brand]
      if (!isNil(this.props.attributes.alternate_carrier_brand)) {
        carrierBrands.push(this.props.attributes.alternate_carrier_brand)
      }
    }

    return (
      <div className="content">
        <header className="form-header">
          <div>
            <div className="form-header-title">
              <h2>Edit Issuer</h2>
            </div>
            <nav>
              <button
                type="button"
                className="button destructive small"
                title="Delete"
                onClick={this.handleDelete}
              >
                Delete
              </button>
            </nav>
          </div>
        </header>
        <div>
          <EntitlementsProvider entitlements={entitlements}>
            <IssuerForm
              handleSubmit={this.handleSubmit}
              errors={errors}
              configuration={configuration}
              states={states}
              requestInProgress={requestInProgress}
              submitLabel="Update Issuer"
              initialValues={attributes}
              resetCarriers={this.props.resetCarriers}
              carriers={carriers}
              carriersRequestInProgress={this.props.carriersRequestInProgress}
              indexCarriers={this.props.indexCarriers}
              resetCarrierBrands={this.props.resetCarrierBrands}
              carrierBrands={carrierBrands}
              carrierBrandsRequestInProgress={this.props.carrierBrandsRequestInProgress}
              indexCarrierBrands={this.props.indexCarrierBrands}
            />
          </EntitlementsProvider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.issuers.update
  const showSlice = state.issuers.show
  const destroySlice = state.issuers.destroy
  const carriersSlice = state.carriers.index
  const carrierBrandsSlice = state.carrierBrands.index
  const configuration = state.login.session.configuration.issuers
  const entitlements = state.login.session.configuration.entitlements.issuers.update
  const states = state.login.session.configuration.states

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,
    deleted: destroySlice.deleted,
    destroyResourceErrors: destroySlice.resourceErrors,
    carriers: carriersSlice.records,
    carriersRequestInProgress: carriersSlice.requestInProgress,
    carrierBrands: carrierBrandsSlice.records,
    carrieBrandsRequestInProgress: carrierBrandsSlice.requestInProgress,
    states,
    configuration,
    entitlements
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  update: actions.update.main,
  show: actions.show.main,
  destroy: actions.destroy.main,
  reset: actions.update.reset,
  showReset: actions.show.reset,
  destroyReset: actions.destroy.reset,
  indexCarriers: carrierActions.index.main,
  resetCarriers: carrierActions.index.reset,
  indexCarrierBrands: carrierBrandActions.index.main,
  resetCarrierBrands: carrierBrandActions.index.reset,

  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(IssuerEdit)
