import React from 'react'
import PropTypes from 'prop-types'
import { isNil, map, get, isEmpty, cloneDeep, some } from 'lodash'
import Component from '../common/Component'
import UserContext from './BenefitsSetUserContext'
import FieldDefinition from './BenefitsSetFieldDefinition'
import HeaderRow from './BenefitsSetHeaderRow'
import InfoRow from './BenefitsSetInfoRow'

export default class BenefitsSetMetadatumComponent extends Component {
  static propTypes = {
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleReviewChange: PropTypes.func.isRequired,
    handleHistory: PropTypes.func,
    fieldDefinition: PropTypes.object.isRequired,
    totalColumns: PropTypes.number.isRequired,
    metadatum: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    rejections: PropTypes.array,
    errors: PropTypes.object,
    reviews: PropTypes.object,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    remoteOptionsSelectStates: PropTypes.object,
    remoteOptionsSelectActions: PropTypes.object,
    showHistory: PropTypes.bool
  }

  static renderedProps = [
    'name',
    'label',
    'reviews',
    'metadatum',
    'rejections',
    'totalColumns',
    'fieldDefinition',
    'userContext',
    'errors',
    'remoteOptionsSelectStates',
    'remoteOptionsSelectActions'
  ]

  get infoFieldDefinitions() {
    return map(this.props.fieldDefinition, (field, name) => new FieldDefinition(
      name,
      field,
      this.infoValueForName(name),
      this.errorForName(name),
      this.props.metadatum,
      this.props.configuration,
      this.props.userContext,
      this.props.remoteOptionsSelectStates,
      this.props.remoteOptionsSelectActions
    ))
  }

  get metadatum() {
    if (this.props.userContext.isEditable) {
      return this.props.metadatum
    }

    return this.props.fieldDefinition.value
  }

  reviewForName = (name) => get(this.props.reviews, name)

  infoValueForName = (name) => this.props.metadatum[name]

  errorForName = (name) => get(this.props.errors, `metadatum.${name}`)

  rejectionsForName = (name) => this.props.rejections.filter((r) => !isNil(get(r.review, name))).map((r) => ({
    review_id: r.review_id,
    created_at: new Date(r.created_at),
    review: r.review[name]
  }))

  handleChange = (name, value) => {
    const metadatum = cloneDeep(this.metadatum)
    if (name === 'audience') {
      delete metadatum.plan_type
    }
    if (name === 'state_id') {
      delete metadatum.skilled_nursing_facility_365
    }
    if (isNil(value) || value === '') {
      delete metadatum[name]
    } else {
      metadatum[name] = value
    }

    if (isEmpty(metadatum) || !some(metadatum, (item) => !isNil(item))) {
      this.props.handleChange('metadatum', {})
      return
    }

    this.props.handleChange('metadatum', metadatum)
  }

  handleReviewChange = (field, value) => {
    const reviews = cloneDeep(this.props.reviews)

    if (isNil(value)) {
      delete reviews[field]
    } else {
      reviews[field] = value
    }

    if (isEmpty(reviews)) {
      this.props.handleReviewChange('metadatum_review', null)
      return
    }

    this.props.handleReviewChange('metadatum_review', reviews)
  }

  handleHistory = (name) => {
    this.props.handleHistory(name, 'metadata')
  }

  infoRow = (definition, note, rejections, handleChange, handleReviewChange, handleHistory) => (
    <InfoRow
      key={definition.name}
      fieldDefinition={definition}
      handleBlur={this.props.handleBlur}
      handleChange={handleChange}
      handleHistory={handleHistory}
      showHistory={this.props.showHistory}
      handleReviewChange={handleReviewChange}
      note={note}
      rejections={rejections}
      totalColumns={this.props.totalColumns}
      userContext={this.props.userContext}
    />
  )

  headerRow(labels) {
    return (
      <HeaderRow
        key={`header-${labels[0]}`}
        labels={labels}
        totalColumns={this.props.totalColumns}
      />
    )
  }

  render() {
    const rows = [this.headerRow(['Plan Info'])]

    this.infoFieldDefinitions.forEach((definition) => {
      rows.push(
        this.infoRow(
          definition,
          this.reviewForName(definition.name),
          this.rejectionsForName(definition.name),
          this.handleChange,
          this.handleReviewChange,
          this.handleHistory
        )
      )
    })

    return (
      <tbody id="plan-info-rows" key="plan-info-rows">
        {rows}
      </tbody>
    )
  }
}
