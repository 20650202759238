import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import MedicalBenefitsSetDocumentComponent from '../Medical/MedicalBenefitsSetDocumentComponent'

export default class SbcCellRenderer extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  }

  render() {
    return (
      <MedicalBenefitsSetDocumentComponent
        url={this.props.data.sbc_url}
        needsProcessing={this.props.data.needs_sbc_processing}
        documentType="SBC"
        iconSize={20}
      />
    )
  }
}

