import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil, isEmpty, cloneDeep } from 'lodash'
import LabeledTextAreaField from '../../common/LabeledTextAreaField'

export default class FeedbackComponent extends Component {
  static propTypes = {
    handleApproveScenario: PropTypes.func,
    handleRejectScenario: PropTypes.func,
    scenarioActionTaken: PropTypes.string,
    handleChange: PropTypes.func,
    rejectionMessage: PropTypes.string,
    needFamilies: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      isReject: false
    }
  }

  get approveButton() {
    if (!this.state.isReject) {
      const label = 'Approve'
      const classNames = ['pure-button', 'pure-input-1', 'approve']
      return (
        <button
          onClick={this.props.handleApproveScenario}
          className={classNames.join(' ')}
          title={label}
          name={label}
        >
          {label}
        </button>
      )
    }
    return null
  }

  /* eslint-disable react/jsx-no-bind */
  get rejectForm() {
    const label = 'Reject'
    const classNames = ['pure-button', 'pure-input-1', 'reject']
    if (!this.state.isReject) {
      return (
        <button
          onClick={this.setIsReject.bind(this, true)}
          className={classNames.join(' ')}
          title={label}
          name={label}
        >
          {label}
        </button>
      )
    }
    const rejectionMessage = this.props.rejectionMessage
    const rejectDisabled = isNil(rejectionMessage) || isEmpty(rejectionMessage)

    return (
      <div>
        <div>
          <LabeledTextAreaField
            key="message"
            name="message"
            value={rejectionMessage}
            label="Reason"
            cssClasses="pure-u-1 pure-u-md-1-3"
            inputCSSClass="pure-u-23-24"
            handleChange={this.props.handleChange}
          />
        </div>
        <button
          onClick={this.props.handleRejectScenario}
          className={classNames.join(' ')}
          disabled={rejectDisabled}
          title={label}
          name={label}
          type="button"
        >
          {label}
        </button>
        <button
          onClick={this.setIsReject.bind(this, false)}
          className={classNames.join(' ')}
          title="cancel"
          name="cancel"
          type="button"
        >
Cancel
        </button>
      </div>

    )
  }
  /* eslint-enable react/jsx-no-bind */

  setIsReject = (isReject) => {
    const state = cloneDeep(this.state)
    state.isReject = isReject
    this.setState(state)
  }

  approvalStatusComponent = (text, className) => {
    if (!text) {
      return null
    }
    return (
      <div className={className}>
        <span>{text}</span>
      </div>
    )
  }

  render() {
    if (this.props.needFamilies) {
      return null
    }

    return (
      <fieldset>
        <div className="pure-u-1 pure-u-md-1 plans-approval-components" name="plans-approval-components">
          <div>
            {this.approveButton}
            {this.rejectForm}
          </div>
          {this.approvalStatusComponent(this.props.scenarioActionTaken, 'howie_success')}
        </div>
      </fieldset>
    )
  }
}
