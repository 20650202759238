import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import fieldGroups, { infoFields, externalIdField } from './lifeFields'
import BenefitsSetComponent from '../BenefitsSet/BenefitsSetComponent'
import BenefitsSummaryDocument from '../BenefitsSet/BenefitsSummaryDocument'
import RemoveDocumentFromPlan from '../BenefitsSet/RemoveDocumentFromPlan'

export default class LifeBenefitsSetComponent extends Component {
  static propTypes = {
    benefitsSet: PropTypes.object.isRequired,
    show: PropTypes.func,
    showReset: PropTypes.func
  }

  render() {
    return (
      <div>
        <div className="action-nav">
          <BenefitsSummaryDocument
            sbcUrl={this.props.benefitsSet.sbc_url}
            iconSize={30}
          />

          {this.props.benefitsSet.id
            && this.props.benefitsSet.sbc_url
            && (
              <RemoveDocumentFromPlan
                benefitsSetId={this.props.benefitsSet.id}
                planName={this.props.benefitsSet.metadatum.name}
                show={this.props.show}
                showReset={this.props.showReset}
              />
            )}
        </div>
        <BenefitsSetComponent
          fieldGroups={fieldGroups}
          infoFields={infoFields}
          externalIdField={externalIdField}
          benefitNames={null}
          {...this.props}
        />
      </div>
    )
  }
}
