import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import qs from 'qs'
import { cloneDeep, get, isNil, includes, remove, forEach } from 'lodash'
import { IoMdCheckmarkCircleOutline, IoIosCloseCircle, IoMdRemoveCircle } from 'react-icons/io'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import BooleanCellDisplayRenderer from '../common/BooleanCellDisplayRenderer'
import CheckboxField from '../common/CheckboxField'
import actions from '../../actions/PlanProducts'
import issuerPeriodAudienceActions from '../../actions/IssuerPeriodAudiences'
import issuerPeriodAudiencePublishingActions from '../../actions/IssuerPeriodAudiencePublishings'
import connectIndex from '../../lib/connectIndex'
import RejectionModal from './RejectionModal'
import ActionModal from './ActionModal'
import ActionButtonsComponent from './ActionButtonsComponent'
import PlanProductStatus from './PlanProductStatus'
import RestorePlanProductButton from './RestorePlanProductButton'
import RestoreAllPlanProductsButton from './RestoreAllPlanProductsButton'
import GeneratePlanHistoryButton from './GeneratePlanHistoryButton'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'

export class PlanProductIndex extends Component {
  static propTypes = {
    name: PropTypes.string,
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    batchDestroyPlanProduct: PropTypes.func.isRequired,
    batchDestroyResetPlanProduct: PropTypes.func.isRequired,
    deleted: PropTypes.bool,
    updateIssuerPeriodAudience: PropTypes.func.isRequired,
    resetUpdateIssuerPeriodAudience: PropTypes.func.isRequired,
    publishIssuerPeriodAudience: PropTypes.func.isRequired,
    resetPublishIssuerPeriodAudience: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterable: PropTypes.bool,
    filters: PropTypes.object,
    filterActions: PropTypes.object,
    includeSearch: PropTypes.bool,
    configuration: PropTypes.object,
    includeNewRecordLink: PropTypes.bool,
    issuerPeriodAudienceUpdate: PropTypes.object,
    issuerPeriodAudiencePublish: PropTypes.object,
    createFlashMessage: PropTypes.func
  }

  static defaultProps = {
    deleted: false,
    name: 'Plan Products',
    includeNewRecordLink: false,
    filterable: true
  }

  static issuerDisplay = (record, column, config) => {
    const issuer = get(record, column.key, [])
    return `${issuer.issuer_id} | ${issuer.name}`
  }

  static iconForApprovalState = (approvalState) => {
    switch (approvalState) {
    case 'approved':
      return <i title="Approved"><IoMdCheckmarkCircleOutline size={30} className="success" /></i>
    case 'rejected':
      return <i title="Rejected"><IoIosCloseCircle size={30} className="error" /></i>
    default:
      return <i title="Not Reviewed"><IoMdRemoveCircle size={30} /></i>
    }
  }

  static approvalDisplay = (componentPresent, displayInfo, url, approvalState) => {
    if (componentPresent) {
      const icon = PlanProductIndex.iconForApprovalState(approvalState)

      return (
        <div className="approval-state">
          {icon}
          <a target="_blank" rel="noopener noreferrer" href={url}>{displayInfo}</a>
        </div>
      )
    }

    return (
      <div className="approval-state">
        <i title="Not Reviewed"><IoMdRemoveCircle size={30} /></i>
        {displayInfo}
      </div>
    )
  }

  static benefitsSetDisplay = (record, column, config) => {
    const benefitsSet = get(record, column.key, null)
    if (benefitsSet === null || get(record, 'deleted?')) {
      return PlanProductIndex.approvalDisplay(false, 'No Plan Design')
    }

    const url = `medical_benefits/${benefitsSet.id}`
    const approvalState = benefitsSet.approval_state

    return PlanProductIndex.approvalDisplay(true, benefitsSet.name, url, approvalState)
  }

  static sheetRateDisplay = (record, column, config) => {
    const sheetRates = get(record, column.key, null)
    if (sheetRates === null || get(record, 'deleted?')) {
      return PlanProductIndex.approvalDisplay(false, 'No Sheet Rates')
    }

    const url = `sheet_rates/review?ids=${sheetRates.ids}`
    const approvalState = sheetRates.approval_state

    return PlanProductIndex.approvalDisplay(true, sheetRates.hios_id, url, approvalState)
  }

  static serviceAreaDisplay = (record, column, config) => {
    const serviceArea = get(record, 'service_area', null)
    if (serviceArea === null || get(record, 'deleted?')) {
      return PlanProductIndex.approvalDisplay(false, 'No Service Area')
    }

    const url = `howie_service_areas/${serviceArea.id}/reviews/new`
    const approvalState = serviceArea.approval_state

    return PlanProductIndex.approvalDisplay(true, serviceArea.external_key, url, approvalState)
  }

  static statusDisplay = (record, column, config) => {
    return <PlanProductStatus record={record} />
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedRecords: [],
      selectAllRecords: false,
      displayRejectionModal: false,
      displayPublishModal: false,
      displayDeleteModal: false,
      displayDeleteIpaModal: false,
      displayDeletedRecords: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.restoredPlanProduct !== this.state.restoredPlanProduct) {
      this.props.createFlashMessage(
        `Plan product for ${this.state.restoredPlanProduct.hios_id} restored`,
        { id: this.state.restoredPlanProduct.id, tag: 'index' }
      )
    }
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.resetUpdateIssuerPeriodAudience()
    this.props.resetPublishIssuerPeriodAudience()
    this.props.batchDestroyResetPlanProduct()
  }

  get columns() {
    const columns = [
      { key: 'hios_id', label: 'HIOS ID' },
      { key: 'issuer', label: 'Issuer', display: PlanProductIndex.issuerDisplay },
      { key: 'audience', label: 'Audience', filterable: true },
      {
        key: 'calendar_period.display_quarter',
        label: 'Quarter',
        sortable: false,
        filterable: true,
        remoteKey: 'quarter'
      },
      {
        key: 'calendar_period.year',
        label: 'Year',
        filterable: true,
        sortable: false,
        remoteKey: 'year'
      },
      {
        key: 'benefits_set',
        label: 'Plan Design',
        filterable: false,
        sortable: false,
        display: PlanProductIndex.benefitsSetDisplay
      },
      {
        key: 'sheet_rates',
        label: 'Rates',
        filterable: false,
        sortable: false,
        display: PlanProductIndex.sheetRateDisplay
      },
      {
        key: 'service_area',
        label: 'Service Area',
        filterable: false,
        sortable: false,
        display: PlanProductIndex.serviceAreaDisplay
      },
      {
        key: 'publishable?',
        label: 'Publishable?',
        filterable: false,
        sortable: false,
        display: BooleanCellDisplayRenderer
      },
      {
        key: 'status',
        label: 'Status',
        filterable: false,
        sortable: false,
        display: PlanProductIndex.statusDisplay
      }
    ]

    if (this.deleteMode) {
      columns.unshift(
        {
          key: 'selected',
          label: this.selectedCheckboxAll,
          display: this.selectedCheckboxDisplay,
          handleChange: this.checkHandleChange
        }
      )
    }

    if (this.state.displayDeletedRecords) {
      columns.unshift(
        {
          key: 'restore',
          label: '',
          display: this.restoreDeletedButton
        }
      )
    }

    return columns
  }

  get queryStrings() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  get issuerPeriodAudience() {
    return get(this.queryStrings, 'issuer_period_audience', null)
  }

  get deleteMode() {
    return get(this.queryStrings, 'delete_mode', false) === 'true'
  }

  get deleteIpaMode() {
    return get(this.queryStrings, 'delete_ipa_mode', false) === 'true'
  }

  get rejectionModal() {
    if (this.state.displayRejectionModal !== true) {
      return null
    }

    return (
      <RejectionModal
        planCount={this.props.pagination.total}
        handleClose={this.handleModalClose}
        handleRejection={this.handleRejection}
      />
    )
  }

  get publishModal() {
    if (this.state.displayPublishModal !== true) {
      return null
    }
    const primaryConfirm = <p>Please confirm that you would like to PUBLISH {this.props.pagination.total} plans</p>

    return (
      <ActionModal
        actionType="Publish"
        handleClose={this.handleModalClose}
        handleAction={this.handlePublish}
        primaryConfirmationMessage={primaryConfirm}
      />
    )
  }

  get deleteModal() {
    if (this.state.displayDeleteModal !== true || this.props.deleted === true) {
      return null
    }

    const hiosIds = this.props.records.filter(
      (record) => this.state.selectedRecords.indexOf(record.id.toString()) > -1
    ).map((record) => record.hios_id)

    const hiosIdsConfirmationMessage = (
      <div>
        <p>HIOS IDs:</p>
        {hiosIds.map((hiosId) => (<p key={hiosId}>{hiosId}</p>))}
      </div>
    )

    const primaryConfirm = <p>Please confirm that you would like to DELETE {hiosIds.length} plans</p>

    return (
      <ActionModal
        actionType="Delete"
        handleClose={this.handleModalClose}
        handleAction={this.handleDelete}
        primaryConfirmationMessage={primaryConfirm}
        secondaryConfirmationMessage={hiosIdsConfirmationMessage}
      />
    )
  }

  get deleteIpaModal() {
    if (this.state.displayDeleteIpaModal !== true || this.props.deleted === true) {
      return null
    }

    const primaryConfirm = <p>Please confirm that you would like to DELETE ALL plan products for this IPA</p>

    return (
      <ActionModal
        actionType="Delete"
        handleClose={this.handleModalClose}
        handleAction={this.handleDeleteIpa}
        primaryConfirmationMessage={primaryConfirm}
      />
    )
  }

  get publishActions() {
    return (
      [
        {
          title: 'Publish',
          id: 'publish',
          cssClass: 'publish-button',
          handleOnClick: this.handlePublishButton
        },
        {
          title: 'Reject',
          id: 'reject',
          cssClass: 'reject-button',
          handleOnClick: this.handleRejectButton
        }
      ]
    )
  }

  get deleteActions() {
    return (
      [
        {
          title: 'Delete',
          id: 'delete',
          cssClass: 'delete-button',
          handleOnClick: this.handleDeleteButton
        },
        {
          title: 'Cancel',
          id: 'cancel',
          cssClass: 'reject-button',
          handleOnClick: this.handleDeleteCancelButton
        }
      ]
    )
  }

  get deleteIpaActions() {
    return (
      [
        {
          title: 'Delete IPA',
          id: 'delete_ipa',
          cssClass: 'delete-button',
          handleOnClick: this.handleDeleteIpaButton
        },
        {
          title: 'Cancel',
          id: 'cancel',
          cssClass: 'reject-button',
          handleOnClick: this.handleDeleteCancelButton
        }
      ]
    )
  }

  get deletePlanProductsLink() {
    if (isNil(this.issuerPeriodAudience)) {
      return null
    }

    const query = cloneDeep(this.queryStrings)
    query[encodeURIComponent('delete_mode')] = encodeURIComponent('true')

    return (
      <Link
        key="delete_mode"
        title="Delete Plan Products"
        className="button quiet-button small"
        to={{
          pathname: '/plan_products',
          search: qs.stringify(query)
        }}
      >Delete Plan Products
      </Link>
    )
  }

  get deleteIssuerPeriodAudienceLink() {
    if (isNil(this.issuerPeriodAudience)) {
      return null
    }

    const query = cloneDeep(this.queryStrings)
    query[encodeURIComponent('delete_ipa_mode')] = encodeURIComponent('true')

    return (
      <Link
        key="delete_ipa_mode"
        title="Delete IPA"
        className="button quiet-button small"
        to={{
          pathname: '/plan_products',
          search: qs.stringify(query)
        }}
      >Delete IPA
      </Link>
    )
  }

  get restoreAllLink() {
    if (!this.state.displayDeletedRecords) { return null }

    return (
      <RestoreAllPlanProductsButton records={this.props.records} handleAction={this.handleRestoreAll} />
    )
  }

  get generatePlanHistoryLink() {
    if (isNil(this.issuerPeriodAudience) || isNil(this.props.pagination)) { return null }

    return (
      <GeneratePlanHistoryButton
        issuerPeriodAudience={this.issuerPeriodAudience}
        total={this.props.pagination.total}
      />
    )
  }

  get displayDeletedCheckbox() {
    return (
      <div id="display-deleted">
        <input
          className=""
          title="Display Deleted"
          name="display-deleted"
          type="checkbox"
          checked={this.state.displayDeletedRecords}
          onChange={this.handleDisplayDeletedRecordsCheckBox}
        />
        <label className="inline-label small">Display Deleted</label>
      </div>
    )
  }

  get indexLocation() {
    if (this.state.displayDeletedRecords) {
      const indexLocation = cloneDeep(this.props.location)
      const query = qs.parse(indexLocation.search)
      query.page = 1
      query.only_deleted = true
      indexLocation.search = qs.stringify(query, { encode: false })

      return indexLocation
    }
    return this.props.location
  }

  selectedCheckboxAll = () => {
    return (
      <CheckboxField
        key="select-all-records"
        name="select-all-records"
        title="select"
        handleChange={this.handleSelectAll}
        value={this.state.selectAllRecords}
      />
    )
  }

  handleSelectAll = (_, checked) => {
    forEach(this.props.records, (record) => {
      this.checkHandleChange(record.id.toString(), checked)
    })

    this.setState({ selectAllRecords: checked })
  }

  displayCheckBox = (record) => {
    return includes(this.state.selectedRecords, record.id.toString())
  }

  checkHandleChange = (id, checked) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.selectedRecords)

      if (checked) {
        if (!includes(newState, id)) {
          newState.push(id)
        }
      } else {
        remove(newState, (item) => {
          return item === id
        })
      }
      return { selectedRecords: newState }
    })
  }

  selectedCheckboxDisplay = (record, column) => {
    return (
      <CheckboxField
        key={record.id.toString()}
        name={record.id.toString()}
        title="select"
        handleChange={column.handleChange}
        handleBlur={column.handleBlur}
        errorMessage=""
        value={this.displayCheckBox(record)}
        disabled={record.status === 'deleting'}
      />
    )
  }

  restoreDeletedButton = (record, _column) => {
    return (<RestorePlanProductButton record={record} onRestore={this.handleRestore} />)
  }

  handleRestore = (record) => {
    this.setState({
      displayDeletedRecords: false,
      restoredPlanProduct: record
    })
  }

  handleRestoreAll = (event) => {
    this.setState({ displayDeletedRecords: false })
  }

  handleRejectButton = (event) => {
    event.preventDefault()

    this.setState({ displayRejectionModal: true })
  }

  handleDeleteButton = (event) => {
    event.preventDefault()

    this.setState({ displayDeleteModal: true })
  }

  handleDeleteIpaButton = (event) => {
    event.preventDefault()

    this.setState({ displayDeleteIpaModal: true })
  }

  handleDisplayDeletedRecordsCheckBox = (event) => {
    this.setState({ displayDeletedRecords: event.target.checked })
  }

  handleModalClose = () => {
    this.setState({
      displayRejectionModal: false,
      displayPublishModal: false,
      displayDeleteModal: false,
      displayDeleteIpaModal: false
    })
  }

  handlePublishButton = (event) => {
    event.preventDefault()

    this.setState({ displayPublishModal: true })
  }

  handlePublish = () => {
    const publishValues = { issuer_period_audience_id: this.issuerPeriodAudience }
    const updateValues = { rejection_reason: null, approval_state: 'approved' }
    this.props.publishIssuerPeriodAudience(publishValues)
      .then(() => {
        this.props.updateIssuerPeriodAudience(this.issuerPeriodAudience, updateValues)
      })
  }

  handleDelete = () => {
    const values = { ids: this.state.selectedRecords }
    this.props.batchDestroyPlanProduct(values)
  }

  handleDeleteIpa = () => {
    const values = { ipa_id: this.issuerPeriodAudience }
    this.props.batchDestroyPlanProduct(values)
  }

  handleRejection = (rejectionReason) => {
    const values = { }
    values.rejection_reason = rejectionReason
    values.approval_state = 'rejected'
    this.props
      .updateIssuerPeriodAudience(this.issuerPeriodAudience, values)
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      deleted,
      issuerPeriodAudienceUpdate,
      issuerPeriodAudiencePublish
    } = this.props

    if (issuerPeriodAudienceUpdate.updated) {
      return this.redirect('/issuer_period_audiences')
    }

    if (deleted && this.deleteMode) {
      const queryStrings = this.queryStrings
      delete queryStrings.delete_mode
      return this.redirect(`/plan_products?${qs.stringify(queryStrings)}`)
    }

    if (deleted && this.deleteIpaMode) {
      return this.redirect('/issuer_period_audiences')
    }

    const disablePublishActionButtons = issuerPeriodAudienceUpdate.requestInProgress
      || issuerPeriodAudiencePublish.requestInProgress
      || isNil(this.issuerPeriodAudience)
      || this.state.displayDeletedRecords

    const disableDeleteActionButtons = issuerPeriodAudienceUpdate.requestInProgress
      || issuerPeriodAudiencePublish.requestInProgress
      || this.state.selectedRecords.length === 0
      || this.state.displayDeletedRecords

    const disableDeleteIpaActionButtons = issuerPeriodAudienceUpdate.requestInProgress
      || issuerPeriodAudiencePublish.requestInProgress
      || this.state.displayDeletedRecords

    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.indexLocation}
          path="/plan_products"
          name={this.props.name}
          pagination={this.props.pagination}
          columns={this.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterable={this.props.filterable}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          includeSearch={this.props.includeSearch}
          configuration={this.props.configuration}
          includeNewRecordLink={this.props.includeNewRecordLink}
          additionalQueryParams={['only_deleted']}
          actionLinks={[
            this.restoreAllLink,
            this.deletePlanProductsLink,
            this.deleteIssuerPeriodAudienceLink,
            this.generatePlanHistoryLink,
            this.displayDeletedCheckbox
          ]}
          actions={[]}
        />
        <ActionButtonsComponent
          key="publish_actions"
          disableActionButtons={disablePublishActionButtons}
          hideActionButtons={this.deleteMode || this.deleteIpaMode}
          actions={this.publishActions}
        />
        <ActionButtonsComponent
          key="delete_actions"
          disableActionButtons={disableDeleteActionButtons}
          hideActionButtons={!this.deleteMode || this.deleteIpaMode}
          actions={this.deleteActions}
        />
        <ActionButtonsComponent
          key="delete_ipa_actions"
          disableActionButtons={disableDeleteIpaActionButtons}
          hideActionButtons={!this.deleteIpaMode}
          actions={this.deleteIpaActions}
        />
        {this.publishModal}
        {this.deleteModal}
        {this.deleteIpaModal}
        {this.rejectionModal}
      </div>
    )
  }
}

const filters = [
  {
    key: 'audience',
    config: 'plan_products.audience'
  },
  {
    key: 'calendar_period.year',
    config: 'plan_products.year'
  },
  {
    key: 'calendar_period.display_quarter',
    config: 'plan_products.quarter'
  }
]

export default connectIndex(PlanProductIndex,
  'planProducts',
  actions,
  filters,
  {},
  [
    { action: issuerPeriodAudienceActions.update.main, key: 'updateIssuerPeriodAudience' },
    { action: issuerPeriodAudienceActions.update.reset, key: 'resetUpdateIssuerPeriodAudience' },
    { action: actions.batchdestroy.main, key: 'batchDestroyPlanProduct' },
    { action: actions.batchdestroy.reset, key: 'batchDestroyResetPlanProduct' },
    { action: issuerPeriodAudiencePublishingActions.create.main, key: 'publishIssuerPeriodAudience' },
    { action: issuerPeriodAudiencePublishingActions.create.reset, key: 'resetPublishIssuerPeriodAudience' },
    { action: createFlashMessage, key: 'createFlashMessage' }
  ],
  [
    { key: 'issuerPeriodAudienceUpdate', reducer: 'issuerPeriodAudiences', action: 'update' },
    { key: 'batchDestroyPlanProduct', reducer: 'planProducts', action: 'batchdestroy' },
    { key: 'issuerPeriodAudiencePublish', reducer: 'issuerPeriodAudiencePublishings', action: 'create' }
  ],
  null,
  [{ action: 'batchdestroy', prop: 'deleted' }])
