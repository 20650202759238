import React from 'react'
import PropTypes from 'prop-types'
import { upperFirst, reject } from 'lodash'
import Component from '../../common/Component'

export class PlanIdentifiersComponent extends Component {
  static propTypes = {
    identifiers: PropTypes.array.isRequired
  }

  get identifierComponents() {
    const identifiers = reject(this.props.identifiers, { type: 'document_id' })

    return identifiers.map((identifier) => this.identifierComponent(identifier))
  }

  identifierComponent = (identifier) => (
    <tr key={`${identifier.type}-${identifier.value}`}>
      <td>{this.typeNameForType(identifier.type)}</td>
      <td>{identifier.value}</td>
    </tr>
  )

  typeNameForType = (type) => type.split('_').map(upperFirst).join(' ')

  render() {
    return (
      <div className="plan-benefits-info-group">
        <table className="pure-table">
          <thead>
            <tr>
              <td style={{ width: '50%' }}><strong>Type</strong></td>
              <td><strong>Value</strong></td>
            </tr>
          </thead>
          <tbody>
            {this.identifierComponents}
          </tbody>
        </table>

      </div>
    )
  }
}

export default PlanIdentifiersComponent
