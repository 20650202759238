import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IoIosClose } from 'react-icons/io'
import Component from '../common/Component'
import Modal from '../common/Modal'
import CheckboxField from '../common/CheckboxField'
import actions from '../../actions/PlanProducts'

export class RestorePlanProductButton extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateState: PropTypes.object.isRequired,
    onRestore: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      restoreRates: true
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value })
  }

  handleRestoreClick = () => {
    this.props.reset()
    this.setState({ showModal: true })
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }

  handleAction = async () => {
    const response = await this.props.update(this.props.record.id, { restore_rates: this.state.restoreRates })

    if (!response.generalError) {
      this.handleClose()
      this.props.onRestore(this.props.record)
    }
  }

  displayModal = () => {
    if (!this.state.showModal) {
      return null
    }

    const record = this.props.record

    return (
      <Modal
        className="restore-plan-product-modal"
        handleClose={this.handleClose}
      >
        <div>
          <CheckboxField
            key="restoreRates"
            name="restoreRates"
            label="Restore rates?"
            handleChange={this.handleChange}
            value={this.state.restoreRates}
          />
        </div>
        <button
          id="restore-plan-product"
          className="select-button pure-button pure-input-1 pure-button-primary action-ipa"
          type="button"
          onClick={this.handleAction}
        >
          Restore Plan Product for {record.hios_id}
        </button>
        <button
          className="cancel-button"
          type="button"
          onClick={this.handleClose}
        >
          <IoIosClose />
        </button>
        {this.displayModalError()}
      </Modal>
    )
  }

  displayModalError = () => {
    if (!this.props.updateState.generalError) {
      return null
    }

    return (<div className="howie_error">{this.props.updateState.generalError}</div>)
  }

  render() {
    return (
      <div>
        <button type="button" className="button" onClick={this.handleRestoreClick}>
          Restore
        </button>
        {this.displayModal()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { updateState: state.planProducts.update }
}

const mapDispatchToProps = {
  update: actions.update.main,
  reset: actions.update.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(RestorePlanProductButton)
