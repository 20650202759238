import React from 'react'
import PropTypes from 'prop-types'
import { round, isNil } from 'lodash'
import { IoMdCheckmarkCircleOutline, IoIosCloseCircle, IoMdRemoveCircle } from 'react-icons/io'

export default class UploadFileComponent extends React.Component {
  static propTypes = {
    filename: PropTypes.string.isRequired,
    uploaded: PropTypes.bool,
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    errorMessage: PropTypes.string,
    previewUrl: PropTypes.string
  }

  static defaultProps = {
    uploaded: false,
    progress: 0,
    previewUrl: null
  }

  get fileIcon() {
    const { errorMessage, uploaded } = this.props

    if (errorMessage) {
      return <i title="Error"><IoIosCloseCircle size={30} className="error" /></i>
    }

    if (uploaded) {
      return <i title="Uploaded"><IoMdCheckmarkCircleOutline size={30} className="success" /></i>
    }
    return <i title="Not Uploaded"><IoMdRemoveCircle size={30} /></i>
  }

  get previewImage() {
    const { previewUrl } = this.props

    if (isNil(previewUrl)) {
      return null
    }

    return (
      <div className="file-image">
        <a href={previewUrl} target="_blank" rel="noopener noreferrer">
          <img src={previewUrl} alt="" />
        </a>
      </div>

    )
  }

  render() {
    const { errorMessage, filename, progress } = this.props

    return (
      <div className="uploader">
        {this.previewImage}
        <div className="file-name">
          {filename}
        </div>
        <div className="file-progress">
          {!errorMessage && <progress value={progress || 0} max="100" />}
          {errorMessage && <div className="error">{errorMessage}</div>}
        </div>
        <div className="file-icon">
          <span>
            {round(progress, 2)}% | {' '}
          </span>
          {this.fileIcon}
        </div>
      </div>
    )
  }
}
