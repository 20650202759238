import React from 'react'
import PropTypes from 'prop-types'
import Component from './Component'

class SubmitButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    className: 'pure-button pure-input-1 pure-button-primary',
    disabled: false
  }

  render() {
    const { className, title, loading, disabled } = this.props

    const text = this.props.text || title

    return (
      <button
        className={className}
        title={title}
        type="submit"
        disabled={loading || disabled}
      >
        {text}
      </button>
    )
  }
}

export default SubmitButton
