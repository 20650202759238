import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/Issuers'
import connectIndex from '../../lib/connectIndex'

export class IssuerIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['name', 'carrier_brand_name', 'issuer_id', 'state']
  }

  static columns = [
    { key: 'name', label: 'Name' },
    { key: 'carrier_brand_name', label: 'Carrier Brand' },
    { key: 'issuer_id', label: 'Issuer ID' },
    { key: 'licensed_state_code', label: 'State' }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/issuers"
          name="Issuer"
          pagination={this.props.pagination}
          columns={IssuerIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name or Issuer ID"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = []

export default connectIndex(IssuerIndex, 'issuers', actions, filters)
