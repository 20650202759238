import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isNil, get, upperFirst } from 'lodash'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import calculateFileMD5 from '../../lib/calculateFileMD5'
import actions from '../../actions/Blobs'
import UploadFileComponent from './UploadFileComponent'

// // TODO 154218500: Remove skipping of PropType validation for Dropzone children
// delete Dropzone.propTypes.children
export class FileUploaderComponent extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    blobId: PropTypes.number,
    directUploadUrl: PropTypes.string,
    createBlob: PropTypes.func.isRequired,
    resetBlob: PropTypes.func.isRequired,
    dropzoneClassName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    dropzoneStyle: PropTypes.object,
    acceptedMimeTypes: PropTypes.string,
    previewUrl: PropTypes.string,
    filename: PropTypes.string,
    errorMessage: PropTypes.string
  }

  static defaultProps = {
    dropzoneClassName: 'react-dropzone-s3-uploader'
  }

  constructor(props) {
    super(props)

    this.state = { droppedFile: null, progress: 0 }
  }

  componentWillUnmount() {
    this.props.resetBlob()
  }

  get previewUrl() {
    const { previewUrl, blobId } = this.props

    if (!isNil(this.state.droppedFile)) {
      if (this.state.uploaded === true) {
        return previewUrl
      }

      return null
    }

    if (isNil(previewUrl) || isNil(blobId)) {
      return null
    }

    return previewUrl
  }

  get filename() {
    return get(this.state.droppedFile, 'file.name', this.props.filename)
  }

  get uploaded() {
    if (!isNil(this.state.droppedFile)) {
      return this.state.uploaded
    }

    return true
  }

  get progress() {
    if (!isNil(this.state.droppedFile)) {
      return this.state.progress
    }

    return 100.0
  }

  get fileUploadErrorMessage() {
    return get(this.state.droppedFile, 'errorMessage')
  }

  get generalError() {
    const { errorMessage } = this.props

    if (isNil(errorMessage)) {
      return null
    }

    return (
      <div className="error">{upperFirst(errorMessage)}</div>
    )
  }

  get dropzonePlaceholder() {
    return (
      <div className="drag-and-drop-water-mark" id="drag-and-drop-water-mark">
        <span>Drag and Drop or Click To Upload File</span>
      </div>
    )
  }

  get dropzoneContent() {
    let content

    if (isNil(this.state.droppedFile) && isNil(this.filename)) {
      content = this.dropzonePlaceholder
    } else {
      content = this.droppedFileContent
    }

    return (
      <div>
        {content}
        {this.generalError}
      </div>
    )
  }

  get droppedFileContent() {
    return (
      <UploadFileComponent
        filename={this.filename}
        uploaded={this.uploaded}
        progress={this.progress}
        errorMessage={this.fileUploadErrorMessage}
        previewUrl={this.previewUrl}
      />
    )
  }

  uploadToS3 = () => {
    const { directUploadUrl } = this.props
    const { droppedFile } = this.state
    const { file, params } = droppedFile

    if (isNil(directUploadUrl)) {
      return null
    }

    const options = {
      headers: {
        'content-type': params.content_type,
        'content-md5': params.checksum
      },
      onUploadProgress: this.handleUploadProgress
    }

    this.setState({ progress: 0, uploaded: false })

    return axios.put(directUploadUrl, file, options)
      .then(this.handleFinish)
      .catch(this.handleUploadError)
  }

  generateBlobParams = async (file) => {
    const checksum = await calculateFileMD5(file)

    const params = {
      filename: file.name,
      byte_size: file.size,
      content_type: file.type,
      checksum
    }

    const droppedFile = {
      file,
      params
    }

    this.setState({ droppedFile })

    return params
  }

  uploadFile = async (file) => {
    const params = await this.generateBlobParams(file)

    this.props.createBlob(params)
      .then(this.uploadToS3)
  }

  handleUploadProgress = (progressEvent) => {
    const progress = (progressEvent.loaded / progressEvent.total) * 100.0
    this.setState({ progress, uploaded: false })
  }

  handleUploadError = (e) => {
    const { file, params } = this.state.droppedFile

    const droppedFile = {
      file,
      params,
      errorMessage: e.message
    }

    this.setState({ droppedFile })
  }

  handleFinish = () => {
    this.setState({ progress: 100, uploaded: true })
    this.props.handleChange(this.props.name, this.props.blobId)
  }

  handleDrop = (files) => {
    const file = files[0]

    if (isNil(file)) {
      this.setState({ droppedFile: null })
      return
    }

    this.setState({ uploaded: false })

    this.uploadFile(file)
  }

  render() {
    return (
      <div>
        <label htmlFor={this.props.name}>
          <div>{this.props.label}</div>
        </label>
        <Dropzone
          onDrop={this.handleDrop}
          className={this.props.dropzoneClassName}
          style={this.props.dropzoneStyle}
          inputProps={{ id: this.props.name, name: this.props.name }}
          accept={this.props.acceptedMimeTypes}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="react-dropzone-s3-uploader" {...getRootProps()}>
              <input {...getInputProps()} id={this.props.name} />
              {this.dropzoneContent}
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const slice = state.blobs.create

  return {
    blobId: slice.id || ownProps.value,
    directUploadUrl: get(slice.responseAttributes, 'direct_upload_url'),
    previewUrl: get(slice.responseAttributes, 'public_url', ownProps.previewUrl),
    created: slice.created
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createBlob: actions.create.main,
  resetBlob: actions.create.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FileUploaderComponent)
