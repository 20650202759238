import { isNil, includes, cloneDeep } from 'lodash'

const notCoveredFields = [
  'individual_medical_deductible',
  'individual_drug_deductible',
  'family_medical_deductible',
  'family_drug_deductible',
  'individual_medical_moop',
  'individual_drug_moop',
  'family_medical_moop',
  'family_drug_moop',
  'plan_coinsurance',
  'primary_care_physician',
  'specialist',
  'preventative_care',
  'diagnostic_test',
  'imaging',
  'generic_drugs',
  'non_preferred_brand_drugs',
  'preferred_brand_drugs',
  'specialty_drugs',
  'outpatient_physician',
  'urgent_care',
  'inpatient_facility',
  'inpatient_physician',
  'inpatient_mental_health',
  'outpatient_mental_health',
  'inpatient_substance',
  'outpatient_substance',
  'inpatient_birth',
  'inpatient_birth_physician',
  'home_health_care',
  'rehabilitation_services',
  'habilitation_services',
  'skilled_nursing',
  'durable_medical_equipment',
  'hospice_service',
  'child_eye_exam',
  'child_eyewear',
  'child_dental',
  'lab_test',
  'prenatal_care',
  'postnatal_care',
  'imaging_center',
  'imaging_physician',
  'outpatient_hospital',
  'outpatient_ambulatory_care_center'
]

function tierValuePrepopulated(benefit, tierOption, value) {
  let tier = benefit.tiers.find((t) => t.option === tierOption)

  if (isNil(tier) || isNil(tier.value) || tier.value === '') {
    tier = {
      option: tierOption,
      value
    }
  }

  return tier
}

function assignTieredFields(originalDataValue, fieldNames, value) {
  let dataValue = originalDataValue

  if (isNil(dataValue)) {
    dataValue = []
  }

  const newBenefits = cloneDeep(dataValue)

  fieldNames.forEach((name) => {
    const existingIndex = newBenefits.findIndex((b) => b.name === name)
    let benefit

    if (existingIndex !== -1) {
      benefit = newBenefits[existingIndex]
    } else {
      benefit = {
        name,
        limit: '',
        tiers: []
      }
    }

    const outOfNetworkTier = tierValuePrepopulated(benefit, 'Out of Network', value)

    const existingTierIndex = benefit.tiers.findIndex((b) => b.option === 'Out of Network')

    if (existingTierIndex !== -1) {
      benefit.tiers[existingTierIndex] = outOfNetworkTier
    } else {
      benefit.tiers.push(outOfNetworkTier)
    }

    if (existingIndex !== -1) {
      newBenefits[existingIndex] = benefit
    } else {
      newBenefits.push(benefit)
    }
  })

  return newBenefits
}

// TODO #150482491: This is broken for now since prepopulation does not work with default values
function assignGatedValue(originalState) {
  const state = originalState

  if (state.metadatum.gated !== 'Unknown' && !isNil(state.metadatum.gated)) {
    return state
  }

  if (includes(['HMO', 'POS'], state.metadatum.plan_type)) {
    state.metadatum.gated = 'Yes'
  }

  if (includes(['EPO', 'PPO'], state.metadatum.plan_type)) {
    state.metadatum.gated = 'No'
  }

  return state
}

function assignBenefitValues(originalState) {
  const state = originalState

  if (!includes(['HMO', 'EPO'], state.metadatum.plan_type)) {
    return state
  }

  if (isNil(state.benefits)) {
    state.benefits = []
  }

  state.benefits = assignTieredFields(state.benefits, notCoveredFields, 'Not Covered')

  return state
}

export default (state) => {
  if (isNil(state.metadatum.plan_type)) {
    return state
  }

  let newState = cloneDeep(state)

  newState = assignGatedValue(newState)
  newState = assignBenefitValues(newState)

  return newState
}
