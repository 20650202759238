import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, upperFirst } from 'lodash'

export default class UploadField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    if (!this.props.handleChange) { return }

    this.props.handleChange(event.target.name, event.target.value, event.target.files[0])
  }

  render() {
    const {
      name,
      errorMessage,
      label,
      disabled,
      id
    } = this.props

    const value = this.props.value || ''
    const classNames = ['field']

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    return (
      <div className={classNames.join(' ')}>
        <label htmlFor={id} style={{ marginBottom: '20px' }}>{label}</label>

        <input
          className="pure-input-1"
          id={id}
          name={name}
          type="file"
          onChange={this.handleChange}
          value={value}
          disabled={disabled}
        />
        {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
      </div>
    )
  }
}
