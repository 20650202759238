import createSession from './createSession'

import { RECEIVE_OAUTH_SESSION_ERROR } from './consts'

function receiveOAuthSessionError(error) {
  return { type: RECEIVE_OAUTH_SESSION_ERROR, error }
}

export default function createOAuthSession(token, redirectPath) {
  return createSession({ token }, receiveOAuthSessionError, redirectPath)
}
