import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import MedicareAdvantageBenefitsSetComponent from './MedicareAdvantageBenefitsSetComponent'
import { benefitNames, metadatumFields } from './medAdvFields'
import BenefitsSetForm from '../BenefitsSet/BenefitsSetForm'

export default class MedicareAdvantageBenefitsSetForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object
  }

  get supplementalOptionsString() {
    return metadatumFields.supplemental_options.display(
      { supplemental_options: this.props.initialValues.metadatum.supplemental_options }
    )
  }

  render() {
    this.props.initialValues.metadatum.supplemental_options = this.supplementalOptionsString

    return (
      <BenefitsSetForm
        benefitNames={benefitNames}
        benefitsSetComponent={MedicareAdvantageBenefitsSetComponent}
        productLine="Medicare Advantage"
        {...this.props}
      />
    )
  }
}
