import client from '../../client'
import receiveCensusError from './receiveCensusError'
import { REQUEST_CENSUS } from './consts'

function requestCensus() {
  return { type: REQUEST_CENSUS }
}

export default function getCensus(id, receiveAction) {
  return (dispatch) => {
    dispatch(requestCensus())
    client.get(`/censuses/${id}`)
      .then((response) => response.data)
      .then((json) => dispatch(receiveAction(json)))
      .catch((error) => dispatch(receiveCensusError(error.response.data.error)))
  }
}
