import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { isNil, forEach, findKey } from 'lodash'
import Component from '../common/Component'
import { createCopy, resetCopy } from '../../actions/BenefitsSetsCopies'
import BenefitsSetCopyForm from './BenefitsSetCopyForm'
import alert from '../../alert'

export class BenefitsSetCopy extends Component {
  static propTypes = {
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    indexBenefitsSets: PropTypes.func.isRequired,
    resetBenefitsSets: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    requestInProgress: PropTypes.bool,
    requestComplete: PropTypes.bool,
    resourceErrors: PropTypes.array,
    created: PropTypes.bool.isRequired,
    benefitsSetExportPath: PropTypes.string.isRequired,
    productLine: PropTypes.string,
    benefitsSets: PropTypes.array,
    benefitsSetsRequestInProgress: PropTypes.bool,
    metadataFields: PropTypes.object.isRequired,
    benefitsFields: PropTypes.array.isRequired,
    benefits_set_export_id: PropTypes.number
  }

  static defaultProps = {
    requestInProgress: false,
    benefitsSetsRequestInProgress: false,
    productLine: 'Medical',
    resourceErrors: []
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values) => {
    const formData = new FormData()
    formData.append('copy[file]', values.binary_file)
    formData.append('copy[metadata_exclusions]', this.translateMetadataExclusions(values.metadata_exclusions))
    formData.append('copy[benefits_exclusions]', this.translateBenefitsExclusions(values.benefits_exclusions))
    formData.delete('copy[binary_file]')

    forEach(Object.keys(values), (key) => {
      if (!['file', 'metadata_exclusions', 'benefits_exclusions', 'binary_file'].includes(key)) {
        formData.append(`copy[${key}]`, values[key])
      }
    })

    this.props.create(formData)
  }

  translateMetadataExclusions(exclusions) {
    if (exclusions.length === 0) { return exclusions }

    const metadataExclusions = []

    forEach(exclusions, (attribute) => {
      const key = findKey(this.props.metadataFields, (field) => { return field.label === attribute })
      metadataExclusions.push(key)
    })

    return metadataExclusions
  }

  translateBenefitsExclusions(exclusions) {
    if (exclusions.length === 0) { return exclusions }

    let benefitsExclusions = []

    forEach(exclusions, (category) => {
      const fields = this.props.benefitsFields.filter((benefit) => { return benefit.name === category })[0].fields
      benefitsExclusions = [...benefitsExclusions, ...fields.map((field) => field.name)]
    })

    return benefitsExclusions
  }

  redirect() {
    const path = this.props.benefitsSetExportPath
    const id = this.props.benefits_set_export_id

    return <Redirect push to={`/${path}/${id}`} />
  }

  render() {
    const {
      created,
      generalError,
      resourceErrors,
      productLine
    } = this.props

    if (!isNil(generalError)) { alert(generalError) }

    const errors = isNil(resourceErrors) ? [] : resourceErrors

    if (created) { return this.redirect() }

    return (
      <div className="content">
        <h2>Copy {productLine} Benefits Set</h2>
        <div>
          <BenefitsSetCopyForm
            handleSubmit={this.handleSubmit}
            errors={errors}
            {...this.props}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.benefitsSetsCopies

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    requestComplete: slice.requestComplete,
    created: slice.created,
    cancelRequest: slice.cancelToken,
    benefits_set_export_id: slice.benefits_set_export_id
  }
}

const mapDispatchToProps = (dispatch) => ({
  create: bindActionCreators(createCopy, dispatch),
  reset: bindActionCreators(resetCopy, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetCopy)
