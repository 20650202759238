import React from 'react'
import PropTypes from 'prop-types'
import { isNil, isBoolean } from 'lodash'
import Component from '../common/Component'
import ValueField from './BenefitsSetValueField'
import UserContext from './BenefitsSetUserContext'
import FieldDefinition from './BenefitsSetFieldDefinition'

export default class InfoRow extends Component {
  static propTypes = {
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleReviewChange: PropTypes.func.isRequired,
    handleHistory: PropTypes.func,
    totalColumns: PropTypes.number.isRequired,
    note: PropTypes.string,
    rejections: PropTypes.array,
    fieldDefinition: PropTypes.instanceOf(FieldDefinition).isRequired,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    showHistory: PropTypes.bool
  }

  static renderedProps = [
    'totalColumns',
    'note',
    'rejections',
    'fieldDefinition',
    'userContext'
  ]

  get displayValue() {
    const value = this.props.fieldDefinition.value

    if (isNil(value)) {
      return null
    }

    if (isBoolean(value)) {
      return (value === true) ? 'Yes' : 'No'
    }

    return value.toString()
  }

  get cell() {
    return (
      <ValueField
        colSpan={this.props.totalColumns - 1}
        name={this.props.fieldDefinition.name}
        text={this.displayValue}
        note={this.props.note}
        rejections={this.props.rejections}
        handleChange={this.props.handleChange}
        handleBlur={this.props.handleBlur}
        handleReviewChange={this.props.handleReviewChange}
        handleHistory={this.props.handleHistory}
        showHistory={this.props.showHistory}
        fieldDefinition={this.props.fieldDefinition}
        userContext={this.props.userContext}
      />
    )
  }

  render() {
    return (
      <tr>
        <td>{this.props.fieldDefinition.label}</td>
        {this.cell}
      </tr>
    )
  }
}

