import client from '../../client'

import { RECEIVE_CONFIGS, RECEIVE_CONFIGS_ERROR } from './consts'

function receiveConfigs(googleClientId) {
  return { type: RECEIVE_CONFIGS, googleClientId }
}

function receiveConfigsError(error) {
  return { type: RECEIVE_CONFIGS_ERROR, error }
}

export default function loadConfigs() {
  return (dispatch) => {
    client.get('/configs')
      .then((response) => response.data)
      .then((json) => dispatch(receiveConfigs(json.google_client_id)))
      .catch((error) => dispatch(receiveConfigsError(error.response.data.error)))
  }
}
