import React from 'react'
import PropTypes from 'prop-types'
import TextAreaField from './TextAreaField'
import Component from './Component'

export default class LabeledTextAreaField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    fieldName: PropTypes.string,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    cols: PropTypes.number,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    cssClasses: PropTypes.string,
    inputCssClass: PropTypes.string
  }

  static renderedProps = [
    'label',
    'name',
    'type',
    'errorMessage',
    'value',
    'placeholder',
    'fieldName',
    'disabled',
    'rows',
    'cols'
  ]

  render() {
    const {
      label, name, handleChange, handleBlur,
      errorMessage, value, placeholder, cssClasses, inputCssClass,
      fieldName, disabled, rows, cols
    } = this.props

    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div id={name} className={cssClasses}>
        <label>
          <div>{label}</div>
          <TextAreaField
            name={name}
            title={name}
            fieldName={fieldName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorMessage={errorMessage}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            inputCssClass={inputCssClass}
            rows={rows}
            cols={cols}
          />
        </label>
      </div>
    )
    /* eslint-enable jsx-a11y/label-has-for */
  }
}
