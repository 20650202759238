import client from '../../client'

import {
  RESET_PASSWORD_TOKEN_VALID,
  RESET_PASSWORD_TOKEN_EXPIRED
} from './consts'

export default function validateToken(token) {
  return (dispatch) => {
    client.get(`/passwords/validate?token=${token}`)
      .then((response) => {
        dispatch({ type: RESET_PASSWORD_TOKEN_VALID })
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_TOKEN_EXPIRED, errors: error.response.data.errors })
      })
  }
}
