import React from 'react'
import PropTypes from 'prop-types'
import { isString } from 'lodash'
import Component from './Component'

export default class DateTimeComponent extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  }

  static renderedProps = [
    'value'
  ]

  get formattedValue() {
    const value = this.props.value

    let date

    if (isString(value)) {
      date = new Date(value)
    } else {
      date = value
    }

    const format = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }

    return date.toLocaleString(undefined, format)
  }

  render() {
    return (
      <time dateTime={this.props.value}>{this.formattedValue}</time>
    )
  }
}
