import React from 'react'
import PropTypes from 'prop-types'
import { isNil, startCase } from 'lodash'
import { connect } from 'react-redux'
import Component from '../common/Component'
import DocumentSelectField from '../common/DocumentSelectField'
import SubmitButton from '../common/SubmitButton'
import actions from '../../actions/VericredImports'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'

export class VericredDocumentImport extends Component {
  static propTypes = {
    create: PropTypes.func,
    reset: PropTypes.func,
    createState: PropTypes.object,
    createFlashMessage: PropTypes.func.isRequired,
    resourceErrors: PropTypes.array,
    importType: PropTypes.string
  }

  static defaultProps = {
    resourceErrors: []
  }

  constructor(props) {
    super(props)

    this.state = {
      values: this.defaultValues,
      resetCounter: 0
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  get defaultValues() {
    return {
      import_type: this.props.importType
    }
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newValues = { ...prevState.values, [field]: value }
      return { values: newValues }
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const response = await this.props.create(this.state.values)
    if (isNil(response.resourceErrors)) {
      this.props.createFlashMessage(
        `Import file created in: ${response.responseAttributes.save_path}`,
        { tag: 'general' }
      )
      this.resetState()
    }
  }

  showErrors = () => {
    const errors = this.props.resourceErrors.map((error, index) => (
      <div className="howie_error" key={`error-${index}`}>{error}</div>
    ))

    return errors
  }

  submitDisabled() {
    return isNil(this.state.values.document_id)
  }

  resetState() {
    this.setState((prevState) => {
      return { values: this.defaultValues, resetCounter: prevState.resetCounter + 1 }
    })
  }

  render() {
    return (
      <div className="content">
        <h2>Import {startCase(this.props.importType)}</h2>
        <div>
          <form key={this.state.resetCounter} className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            {this.props.resourceErrors.length > 0 && this.showErrors()}
            <fieldset>
              <DocumentSelectField
                name="document_id"
                filterFields={{ document_source_contents: ['Service Areas', 'Rates'] }}
                handleChange={this.handleChange}
                value={this.state.values.document_id}
              />
            </fieldset>
            <fieldset>
              <SubmitButton
                title="Submit"
                text="Submit"
                loading={this.props.createState.requestInProgress}
                disabled={this.submitDisabled()}
              />
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { createState: state.vericredImports.create,
    resourceErrors: state.vericredImports.create.resourceErrors }
}

const mapDispatchToProps = {
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(VericredDocumentImport)

