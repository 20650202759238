import React from 'react'
import Component from '../common/Component'
import DisabilityBenefitsSetComponent from './DisabilityBenefitsSetComponent'
import { benefitNames } from './disabilityFields'
import BenefitsSetForm from '../BenefitsSet/BenefitsSetForm'

export default class DisabilityBenefitsSetForm extends Component {
  render() {
    return (
      <BenefitsSetForm
        benefitNames={benefitNames}
        benefitsSetComponent={DisabilityBenefitsSetComponent}
        productLine="Disability"
        {...this.props}
      />
    )
  }
}
