import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { map, isEmpty, keys, startCase, reduce, union, isNil } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AgGridReact } from 'ag-grid-react'
import pluralize from 'pluralize'
import qs from 'qs'
import SourceDocumentCellRenderer from './SourceDocumentCellRenderer'
import actions from '../../actions/SheetRatesReviews'
import alert from '../../alert'

export class SheetRatesReview extends Component {
  static propTypes = {
    index: PropTypes.func.isRequired,
    indexError: PropTypes.string,
    indexReset: PropTypes.func.isRequired,
    indexInProgress: PropTypes.bool,
    review: PropTypes.func.isRequired,
    reviewReset: PropTypes.func.isRequired,
    reviewErrors: PropTypes.any,
    reviewCompleted: PropTypes.bool,
    sheetRates: PropTypes.array,
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.assignQueryParams()

    this.state = {
      numApproved: 0,
      numRejected: 0
    }
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.assignQueryParams()
      this.reset()
      this.fetchRecords()
    }
  }

  componentWillUnmount() {
    this.reset()
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi

    this.autoSizeAll()
    this.updateCounts()
    this.selectRejectedRows()
  }

  onRowSelected = (params) => {
    this.updateCounts()
  }

  get needsSheetRate() {
    return isEmpty(this.props.sheetRates)
  }

  get rowData() {
    return this.needsSheetRate ? [] : this.recordMapper(this.props.sheetRates)
  }

  get priceColumns() {
    return this.needsSheetRate ? [] : reduce(this.props.sheetRates, (columns, sheetRate) => {
      // eslint-disable-next-line no-param-reassign
      columns[sheetRate.type] = union(columns[sheetRate.type], keys(sheetRate.prices))
      return columns
    }, {})
  }

  get reviewErrorsMessage() {
    return reduce(this.props.reviewErrors, (message, error, field) => {
      return `${message ? `${message}\n` : ''} ${startCase(field)} ${error}`
    }, '')
  }

  get columnDefs() {
    const defaultColumns = [
      {
        field: 'reject',
        headerName: '',
        checkboxSelection: true,
        suppressToolPanel: true,
        pinned: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true
      },
      {
        field: 'rejection_reason',
        headerName: 'Reason',
        editable: true,
        pinned: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true
      },
      {
        headerName: 'Info',
        children: [
          { field: 'hios_id', headerName: 'HIOS ID' },
          { field: 'type', headerName: 'Type' },
          { field: 'rating_area', headerName: 'Rating Area' },
          { field: 'document',
            headerName: 'Source Document',
            cellRendererFramework: SourceDocumentCellRenderer },
          { field: 'effective_date', headerName: 'Effective Date' },
          { field: 'expiration_date', headerName: 'Expiration Date' }
        ]
      }
    ]

    return defaultColumns.concat(map(this.priceColumns, (columns, header) => {
      return {
        headerName: header,
        children: map(columns, (column) => {
          return {
            field: column,
            headerName: startCase(column)
          }
        })
      }
    }))
  }

  handleSubmit = () => {
    const reviews = []

    this.gridApi.forEachNode((rowNode) => {
      reviews.push({
        id: rowNode.data.id,
        reject: rowNode.isSelected(),
        rejection_reason: rowNode.data.rejection_reason
      })
    })

    this.props.review(reviews)
  }

  selectRejectedRows = () => {
    this.gridApi.forEachNode((rowNode) => {
      if (rowNode.data.approval_state === 'rejected') {
        rowNode.setSelected(true)
      }
    })
  }

  updateCounts = () => {
    let numApproved = 0
    let numRejected = 0

    this.gridApi.forEachNode((rowNode) => {
      rowNode.isSelected() ? numRejected += 1 : numApproved += 1
    })

    this.setState({ numApproved, numRejected })
  }

  reset = () => {
    this.props.indexReset()
    this.props.reviewReset()
  }

  assignQueryParams = () => {
    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  fetchRecords = () => {
    this.props.index({ ids: this.queryParams.ids })
  }

  autoSizeAll = () => {
    const allColumnIds = []

    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId)
    })

    this.gridColumnApi.autoSizeColumns(allColumnIds)
  }

  recordMapper = (records) => {
    return map(records, (record) => {
      return {

        ...record,
        ...record.prices
      }
    })
  }

  rowNodeId = (data) => {
    return data.id
  }

  renderGrid() {
    if (this.needsSheetRate) {
      return this.props.indexInProgress ? 'Fetching records...' : 'No sheet rates found.'
    }

    const statusText = `${pluralize('Approval', this.state.numApproved, true)} and \
      ${pluralize('Rejection', this.state.numRejected, true)}`

    return (
      <div>
        <div className="ag-theme-balham data-grid">
          <AgGridReact
            columnDefs={this.columnDefs}
            rowData={this.rowData}
            onGridReady={this.onGridReady}
            rowSelection="multiple"
            onRowSelected={this.onRowSelected}
            getRowNodeId={this.rowNodeId}
            stopEditingWhenGridLosesFocus
            suppressRowClickSelection
            animateRows
            enableFilter
            enableSorting
            suppressAggFuncInHeader
            enableColResize
            enableRangeSelection
            toolPanelSuppressRowGroups
            toolPanelSuppressValues
            toolPanelSuppressPivots
            toolPanelSuppressPivotMode
          />
        </div>
        <fieldset>
          <button
            className="pure-button pure-input-1 pure-button-primary"
            title="Submit"
            id="Submit"
            onClick={this.handleSubmit}
          >
Submit
          </button>
          {statusText}
        </fieldset>
      </div>
    )
  }

  render() {
    if (this.props.reviewCompleted) {
      return <Redirect push to="/sheet_rates" />
    }

    if (!isNil(this.props.reviewErrors)) {
      alert(this.reviewErrorsMessage)
      this.props.reviewReset()
    }

    return (
      <div className="content">
        <h2>Review Rates</h2>
        {this.props.indexError}
        {this.renderGrid()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const indexSlice = state.sheetRatesReviews.index
  const createSlice = state.sheetRatesReviews.create

  return {
    sheetRates: indexSlice.records,
    indexError: indexSlice.generalError,
    indexInProgress: indexSlice.requestInProgress,
    reviewErrors: createSlice.resourceErrors,
    reviewCompleted: createSlice.created
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  index: actions.index.main,
  indexReset: actions.index.reset,
  review: actions.create.main,
  reviewReset: actions.create.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SheetRatesReview)
