import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toPairs, isObject, join } from 'lodash'

export class PlanBenefitsComponent extends Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    fieldGroups: PropTypes.object.isRequired
  }

  get fields() {
    return this.props.fieldGroups.Benefits.benefits.fields
  }

  get benefitFields() {
    const fieldNames = {}

    this.fields.forEach(
      (fieldGroup) => fieldGroup.fields.forEach(
        (field) => { fieldNames[field.vericred_name || field.name] = field.label }
      )
    )
    return fieldNames
  }

  planBenefits = (plan) => {
    return plan.benefits
  }

  planBenefitComponent = (plan) => {
    const benefits = this.planBenefits(plan)

    return toPairs(this.benefitFields).map((field) => {
      const fieldName = field[0]
      const benefitName = field[1]

      return (
        <tr key={`${plan.id}${field}`}>
          <td name="benefit-name">{benefitName}</td>
          <td name="benefit-value">{this.sanitizeValue(benefits[fieldName])}</td>
        </tr>
      )
    })
  }

  sanitizeValue = (value) => {
    return isObject(value) ? this.stringifyObject(value) : value
  }

  stringifyObject = (value) => {
    const pairs = []
    Object.keys(value).forEach((key) => { pairs.push(`${key}: ${value[key]}`) })

    return join(pairs, ' / ')
  }

  render() {
    const { plan } = this.props
    const planBenefits = this.planBenefitComponent(plan)
    return (
      <div className="plan-benefits-info-group" id={`${plan.id}-benefits`}>
        <table className="pure-table" name={`${plan.name}-benefits-table`}>
          <thead>
            <tr>
              <td style={{ width: '50%' }}>Benefit</td>
              <td>Value</td>
            </tr>
          </thead>
          <tbody>
            {planBenefits}
          </tbody>
        </table>
      </div>
    )
  }
}

export default PlanBenefitsComponent
