import React from 'react'
import { connect } from 'react-redux'
import Component from '../common/Component'
import { benefitLabels, metadatumFieldsDiffSet } from './lifeFields'
import publishingActions from '../../actions/BenefitsSetsExportPublishings'
import BenefitsSetExportShow from '../BenefitsSet/BenefitsSetExportShow'

export class LifeBenefitsSetExportShow extends Component {
  static defaultFields = [
    'id',
    'vericred_id',
    'approval_state',
    'publish_state',
    'name',
    'audience',
    'plan_type',
    'stand_alone',
    'voluntary',
    'ortho',
    'source',
    'issuer_identifier',
    'issuer_name'
  ]

  render() {
    return (
      <BenefitsSetExportShow
        productLine="Life"
        benefitLabels={benefitLabels}
        metadatumFieldsDiffSet={metadatumFieldsDiffSet}
        defaultFields={LifeBenefitsSetExportShow.defaultFields}
        benefitsSetUrl="/life_benefits"
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    exportPublish: state.benefitsSetsExportPublishings.create
  }
}

const mapDispatchToProps = {
  createExportPublish: publishingActions.create.main,
  resetCreateExportPublish: publishingActions.create.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(LifeBenefitsSetExportShow)
