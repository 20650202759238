import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, get, isEqual } from 'lodash'
import { IoIosClose } from 'react-icons/io'
import RemoteOptionsSelectField from './RemoteOptionsSelectField'
import SelectField from './SelectField'
import Component from './Component'
import Modal from './Modal'
import LabeledTextField from './LabeledTextField'
import SubmitButton from './SubmitButton'

export default class IndexFilterModal extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]).isRequired,
    filterValues: PropTypes.object,
    filterActions: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    handleSelected: PropTypes.func.isRequired,
    remoteKey: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.initialValue,
      initialValue: cloneDeep(props.initialValue),
      close: false
    }
  }

  get selectField() {
    switch (get(this.props.filterValues, 'type')) {
    case 'dateField':
      return this.dateField
    case 'standardSelectField':
      return this.standardSelectField
    default:
      return this.remoteSelectField
    }
  }

  get dateField() {
    const {
      remoteKey
    } = this.props

    return (
      <LabeledTextField
        label="Enter date to filter"
        name={remoteKey}
        key={remoteKey}
        handleChange={this.handleFilterChange}
        value={this.state.value}
        type="date"
      />
    )
  }

  get standardSelectField() {
    const {
      filterValues,
      remoteKey
    } = this.props

    return (
      <SelectField
        label="Enter text to filter"
        key={remoteKey}
        name={remoteKey}
        handleChange={this.handleFilterChange}
        value={this.state.value}
        options={filterValues.records}
        autoFocus
        multi
      />
    )
  }

  get remoteSelectField() {
    const {
      filterValues,
      filterActions,
      remoteKey
    } = this.props

    return (
      <RemoteOptionsSelectField
        label="Enter text to filter"
        placeholder="Type at least 2 characters to filter"
        key={remoteKey}
        name={remoteKey}
        handleChange={this.handleFilterChange}
        records={filterValues.records}
        requestInProgress={filterValues.requestInProgress}
        reset={filterActions.reset}
        recordsRequest={filterActions.records}
        value={this.state.value}
        autoFocus
        multi
      />
    )
  }

  handleFilterChange = (name, value) => {
    this.setState({ value })
  }

  handleSelected = (event) => {
    this.setState({ close: true })

    this.props.handleSelected(this.props.remoteKey, this.state.value)
  }

  handleCancelButton = (event) => {
    this.setState({ close: true })
    this.props.handleClose()
  }

  render() {
    if (this.state.close) {
      return null
    }

    return (
      <Modal
        handleClose={this.props.handleClose}
        className="filter-modal"
      >
        <form className="pure-form pure-form-stacked" onSubmit={this.handleSelected}>
          <h2>Filter By {this.props.label}</h2>
          <fieldset>
            {this.selectField}
          </fieldset>
          <fieldset>
            <SubmitButton
              title="Filter"
              text="Filter"
              loading={isEqual(this.state.value, this.state.initialValue)}
            />
          </fieldset>
        </form>
        <button
          className="cancel-button"
          type="button"
          onClick={this.handleCancelButton}
        >
          <IoIosClose />
        </button>
      </Modal>
    )
  }
}
