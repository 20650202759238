import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { Link } from 'react-router-dom'
import { isNil, cloneDeep } from 'lodash'
import pluralize from 'pluralize'
import PaginationComponent from '@traverse-data/pagination/react/Pagination'
import Component from './Component'

export default class Pagination extends Component {
  static propTypes = {
    queryParams: PropTypes.object,
    path: PropTypes.string.isRequired,
    pagination: PropTypes.object
  }

  static defaultProps = {
    pagination: {
      currentPage: 1,
      perPage: 25,
      count: 1
    }
  }

  get paginationInfo() {
    const { pagination } = this.props

    if (isNil(pagination)) {
      return null
    }

    const {
      pages,
      total,
      currentRange
    } = pagination

    if (isNil(currentRange) || isNil(total) || total <= 0) {
      return null
    }

    const textParts = ['Displaying']

    if (pages > 1) {
      textParts.push(`${currentRange[0]} – ${currentRange[1]} of`)
    }

    textParts.push(`${total} ${pluralize('Record', total)}`)

    return (
      <div className="pagination-info">
        {textParts.join(' ')}
      </div>
    )
  }

  get pagination() {
    const { pagination } = this.props

    if (isNil(pagination)) {
      return null
    }

    if (isNil(pagination.total) || isNil(pagination.currentPage) || pagination.total <= 0) {
      return null
    }

    return (
      <PaginationComponent
        page={pagination.currentPage}
        perPage={pagination.perPage}
        count={pagination.total}
        context={1}
        previous={(page) => this.pageLink(page, '‹', 'prev')}
        next={(page) => this.pageLink(page, '›', 'next')}
        item={(page, active) => this.pageLink(page, page, `page-${page}`, active)}
        spacer={(key) => <div key={key}><span className="separator">&hellip;</span></div>}
        wrapper={(props) => <div className="pagination">{props.children}</div>}
      />
    )
  }

  pageLink = (page, text, name, active = false) => {
    const query = cloneDeep(this.props.queryParams)

    const search = Object.assign(query, { page })

    let content

    if (active) {
      content = <span className="active">{page}</span>
    } else {
      content = (
        <Link
          className={name}
          title={name}
          to={{ pathname: this.props.path, search: qs.stringify(search) }}
        >
          {text}
        </Link>
      )
    }

    return (
      <div key={`pagination-${name}`} className={name}>
        {content}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.children}
        {this.paginationInfo}
        {this.pagination}
      </div>
    )
  }
}
