import { get } from 'lodash'
import PropTypes from 'prop-types'
import Component from './Component'

export default class EntitlementProvider extends Component {
  static propTypes = {
    entitlements: PropTypes.object
  }

  static childContextTypes = {
    attributeAccessible: PropTypes.func,
    attributeInaccessible: PropTypes.func
  }

  getChildContext() {
    return {
      attributeAccessible: this.attributeAccessible,
      attributeInaccessible: this.attributeInaccessible
    }
  }

  get permittedAttributes() {
    return get(this.props.entitlements, 'permitted_attributes', [])
  }

  attributeAccessible = (attribute) => {
    return this.permittedAttributes.includes(attribute)
  }

  attributeInaccessible = (attribute) => {
    return !this.attributeAccessible(attribute)
  }

  render() {
    return this.props.children
  }
}
