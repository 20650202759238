import React from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, range, filter, includes } from 'lodash'
import Component from '../common/Component'
import RemoteOptionsSelectField from '../common/RemoteOptionsSelectField'
import SelectField from '../common/SelectField'
import CheckboxField from '../common/CheckboxField'
import UploadField from '../common/UploadField'
import SubmitButton from '../common/SubmitButton'

import '../../css/FlexBox.scss'

export default class BenefitsSetCopyForm extends Component {
  static propTypes = {
    indexBenefitsSets: PropTypes.func.isRequired,
    resetBenefitsSets: PropTypes.func.isRequired,
    benefitsSets: PropTypes.array,
    benefitsSetsRequestInProgress: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    metadataFields: PropTypes.object.isRequired,
    benefitsFields: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    const defaultValues = {
      base_benefits_set: null,
      year: null,
      file: null,
      binary_file: null,
      include_metadata: false,
      metadata_exclusions: [],
      include_benefits: false,
      benefits_exclusions: []
    }

    this.state = { values: defaultValues }
  }

  get metadata() {
    const keys = Object.keys(this.props.metadataFields)
    const exposedKeys = filter(keys, ((key) => { return !includes(this.blackListedMetadata, key) }))

    return exposedKeys.map((key) => this.props.metadataFields[key].label)
  }

  get benefits() {
    return this.props.benefitsFields.map((benefit) => benefit.name)
  }

  get years() {
    const year = new Date().getFullYear()

    return range(year - 5, year + 10).map(String)
  }

  get blackListedMetadata() {
    return [
      'name',
      'display_name',
      'plan_year',
      'skilled_nursing_facility_365',
      'abortion_rider',
      'infertility_rider',
      'age29_rider',
      'dp_rider',
      'fp_rider'
    ]
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.handleSubmit(this.state.values)
  }

  handleChange = (field, value, binaryFile) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)
      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        if (field === 'file') { newState.binary_file = binaryFile }
        newState[field] = value
      }

      return { values: newState }
    })
  }

  metadataExclusionsDisabled = () => {
    return !this.state.values.include_metadata
  }

  benefitsExclusionsDisabled = () => {
    return !this.state.values.include_benefits
  }

  submitDisabled = () => {
    return isNil(this.state.values.base_benefits_set) || isNil(this.state.values.file) || isNil(this.state.values.year)
  }

  showErrors = () => {
    const errors = this.props.errors.map((error, index) => (
      <div className="howie_error" key={`error-${index}`}>{error}</div>
    ))

    return errors
  }

  render() {
    const metadataExclusionsDisabled = this.metadataExclusionsDisabled()
    const benefitsExclusionsDisabled = this.benefitsExclusionsDisabled()
    const submitDisabled = this.submitDisabled()

    return (
      <form
        className="benefits-set-form pure-form pure-form-stacked"
        onSubmit={this.handleSubmit}
      >
        {this.props.errors.length > 0 && this.showErrors()}

        <fieldset className="flex-box">
          <div className="flex-box-item">
            <RemoteOptionsSelectField
              placeholder="Enter base plan HIOS ID..."
              key="base_benefits_set"
              name="base_benefits_set"
              label="Base Benefits Set"
              recordLabel={['name', 'hios_ids', 'plan_year']}
              handleChange={this.handleChange}
              records={this.props.benefitsSets}
              requestInProgress={this.props.benefitsSetsRequestInProgress}
              reset={this.props.resetBenefitsSets}
              recordsRequest={this.props.indexBenefitsSets}
              value={this.state.values.base_benefits_set}
            />
          </div>

          <div className="flex-box-item">
            <SelectField
              key="year"
              name="year"
              label="Year"
              options={this.years}
              handleChange={this.handleChange}
              value={this.state.values.year}
              disabled={false}
            />
          </div>
        </fieldset>

        <div className="buffer-bottom">
          <UploadField
            key="file"
            name="file"
            label="List of HIOS IDs to Copy To (CSV File)"
            handleChange={this.handleChange}
            value={this.state.values.file}
            disabled={false}
          />
        </div>

        <fieldset>
          <h5 className="underline">Plan Metadata</h5>

          <div className="flex-box align-items-center">
            <div className="flex-box-item-fixed">
              <CheckboxField
                inputCssClass=""
                key="include_metadata"
                name="include_metadata"
                label="Include Plan Metadata"
                handleChange={this.handleChange}
                value={this.state.values.include_metadata}
              />
            </div>

            <div className="flex-box-item">
              <SelectField
                key="metadata_exclusions"
                name="metadata_exclusions"
                label="Exclude from Metadata"
                placeholder="Type to select metadata..."
                options={this.metadata}
                handleChange={this.handleChange}
                value={this.state.values.metadata_exclusions}
                disabled={metadataExclusionsDisabled}
                multi
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <h5 className="underline">Plan Benefits</h5>

          <div className="flex-box align-items-center buffer-bottom">
            <div className="flex-box-item-fixed">
              <CheckboxField
                inputCssClass=""
                key="include_benefits"
                name="include_benefits"
                label="Include Plan Benefits"
                handleChange={this.handleChange}
                value={this.state.values.include_benefits}
              />
            </div>

            <div className="flex-box-item">
              <SelectField
                key="benefits_exclusions"
                name="benefits_exclusions"
                label="Exclude from Benefits"
                placeholder="Type to select benefits..."
                options={this.benefits}
                handleChange={this.handleChange}
                value={this.state.values.benefits_exclusions}
                disabled={benefitsExclusionsDisabled}
                multi
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <SubmitButton
            title="Submit Benefits Set"
            text="Submit"
            disabled={submitDisabled}
            loading={submitDisabled}
          />
        </fieldset>
      </form>
    )
  }
}
