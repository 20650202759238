import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MenuButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired
  }

  render() {
    const { title, handleClick } = this.props

    return (
      <button type="button" className="menu-link quiet-button" title={title} onClick={handleClick}>{title}</button>
    )
  }
}

export default MenuButton
