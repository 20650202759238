import { map, range } from 'lodash'

export default class PlanYearOptionsHelper {
  static planYearOptions(fromYear, toYear) {
    const twoYearsFromNow = toYear

    return map(range(twoYearsFromNow - fromYear + 1), (index) => {
      const value = fromYear + index
      return { text: value, value }
    })
  }

  static planYearOptionsFromCurrent(years) {
    const currentYear = new Date().getFullYear()

    return this.planYearOptions(currentYear, currentYear + years)
  }
}
