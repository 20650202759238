// Core
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { camelCase } from 'lodash'

// Helpers
import { createFlashMessage } from '../../actions/FlashMessages'

// Components
import Component from './Component'
import Form from './Form'

export default class CreateForm extends Component {
  static propTypes = {
    modelName: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
    createAction: PropTypes.func.isRequired,
    resetCreateAction: PropTypes.func.isRequired,
    resourceActionStatuses: PropTypes.object.isRequired,
    attributes: PropTypes.object,
    createFlashMessage: PropTypes.func,
    disableSubmit: PropTypes.func
  }

  static defaultProps = {
    attributes: {},
    createFlashMessage,
    disableSubmit: () => { return false }
  }

  componentWillUnmount() {
    this.props.resetCreateAction()
  }

  get created() {
    return !!(this.props.resourceActionStatuses.create.created)
  }

  get submitLabel() {
    return `Create ${this.props.modelName}`
  }

  handleSubmit = (data) => {
    return this.props.createAction(data)
      .then(() => {
        if (this.created) {
          this.props.createFlashMessage(`Created ${this.props.modelName}`, { tag: 'index' })
        }
      })
  }

  render() {
    if (this.created) return (<Redirect push to={this.props.redirectTo} />)

    return (
      <div className="content">
        <header className="form-header">
          <div className="form-header-title">
            <h2>Create {this.props.modelName}</h2>
          </div>
        </header>
        <Form
          actionStatus={this.props.resourceActionStatuses.create}
          handleSubmit={this.handleSubmit}
          attributes={this.props.attributes}
          submitLabel={this.submitLabel}
          disableSubmit={this.props.disableSubmit}
        >

          {this.props.children}
        </Form>
      </div>
    )
  }
}

export const defaultPropTypes = {
  configuration: PropTypes.object.isRequired,
  redirectTo: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  createAction: PropTypes.func.isRequired,
  resetCreateAction: PropTypes.func.isRequired,
  resourceActionStatuses: PropTypes.object.isRequired
}

export function connectCreateForm(klass, model, plural, actions) {
  const mapStateToProps = (state) => {
    return {
      resourceActionStatuses: state[camelCase(plural)],
      redirectTo: `/${plural}`,
      modelName: model,
      configuration: state.login.session.configuration[plural] || {}
    }
  }

  const mapDispatchToProps = {
    createAction: actions.create.main,
    resetCreateAction: actions.create.reset,

    createFlashMessage
  }

  return connect(mapStateToProps, mapDispatchToProps)(klass)
}
