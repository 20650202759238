import { combineReducers } from 'redux'
import ActionTypes from '../ActionTypes'
import GroupGenerator from '../GroupGenerator'

import CreateReducerGenerator from './CreateReducerGenerator'
import ShowReducerGenerator from './ShowReducerGenerator'
import IndexReducerGenerator from './IndexReducerGenerator'
import UpdateReducerGenerator from './UpdateReducerGenerator'
import DestroyReducerGenerator from './DestroyReducerGenerator'
import BatchDestroyReducerGenerator from './BatchDestroyReducerGenerator'

export default class ReducerGenerator extends GroupGenerator {
  static generatorClasses = {
    [ActionTypes.CREATE]: CreateReducerGenerator,
    [ActionTypes.SHOW]: ShowReducerGenerator,
    [ActionTypes.INDEX]: IndexReducerGenerator,
    [ActionTypes.UPDATE]: UpdateReducerGenerator,
    [ActionTypes.DESTROY]: DestroyReducerGenerator,
    [ActionTypes.BATCHDESTROY]: BatchDestroyReducerGenerator
  }

  generate = () => {
    const reducers = {}

    for (const method of this.methods) {
      reducers[this.nameForMethod(method)] = this.generators[method].generateReducer()
    }

    return combineReducers(reducers)
  }
}
