import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, upperFirst, isString, isNil } from 'lodash'
import Select from 'react-select'
import SearchTooltip from './SearchTooltip'

export default class SelectField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleInputChange: PropTypes.func,
    filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    name: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool
    ]),
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    noResultsText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    autoFocus: PropTypes.bool,
    multi: PropTypes.bool,
    fieldName: PropTypes.string,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    noResultsText: 'No results found',
    autoFocus: false,
    multi: false,
    handleChange: () => {},
    searchColumns: []
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = {}
  }

  handleChange(name, event) {
    const fieldName = this.props.fieldName || name

    if (isEmpty(event)) {
      this.props.handleChange(fieldName, null)
    } else if (this.props.multi) {
      const values = event.map((v) => {
        return v.value
      })
      this.props.handleChange(fieldName, values, event)
    } else {
      this.props.handleChange(fieldName, event.value, event)
    }
  }

  handleBlur(name) {
    if (!isNil(this.props.handleBlur)) {
      this.props.handleBlur(name)
    }
  }

  handleInputChange(value) {
    if (!isNil(this.props.handleInputChange)) {
      this.props.handleInputChange(value)
    }
  }

  render() {
    const {
      name,
      label,
      options,
      errorMessage,
      value,
      disabled,
      placeholder,
      title,
      noResultsText,
      autoFocus,
      filterOption,
      multi
    } = this.props

    const selectOptions = options.map((option) => {
      let text
      let optionValue
      let disabledValue = false

      if (isString(option)) {
        text = option
        optionValue = option
      } else {
        text = option.text
        optionValue = option.value
        disabledValue = option.disabled
      }

      return { value: optionValue, label: text, disabled: disabledValue }
    })

    const classNames = ['field']

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    const clearable = isNil(this.props.clearable) ? true : this.props.clearable
    const isLoading = isNil(this.props.isLoading) ? false : this.props.isLoading

    /* eslint-disable jsx-a11y/label-has-for, react/jsx-no-bind */
    return (
      <div id={name} className={classNames.join(' ')}>
        <label>
          {label && <div>{label}</div>}
          <div className="select-tooltip">
            <Select
              className="select-tooltip-select"
              name={name}
              title={title || label}
              value={value}
              placeholder={placeholder}
              onChange={this.handleChange.bind(this, name)}
              onBlur={this.handleBlur.bind(this, name)}
              onInputChange={this.handleInputChange}
              options={selectOptions}
              disabled={disabled}
              clearable={clearable}
              backspaceRemoves={clearable}
              isLoading={isLoading}
              noResultsText={noResultsText}
              inputProps={{ autoComplete: 'none', autoCorrect: 'none' }}
              autoFocus={autoFocus}
              multi={multi}
              filterOption={filterOption}
            />
            {this.props.searchColumns.length > 0
            && <SearchTooltip className="select-tooltip-tip" columns={this.props.searchColumns} />}
            {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
          </div>
        </label>
      </div>
    )
    /* eslint-enable jsx-a11y/label-has-for, react/jsx-no-bind */
  }
}
