import React from 'react'
import Component from '../common/Component'
import BenefitsSetBulkEditsComponent from '../BenefitsSet/BenefitsSetBulkEditsComponent'
import { benefitLabels, metadatumFieldsDiffSet } from './dentalFields'

const defaultFields = [
  'id',
  'vericred_id',
  'name',
  'audience',
  'plan_type',
  'issuer_name',
  'stand_alone',
  'voluntary',
  'ortho',
  'effective_date',
  'expiration_date'
]

export default class DentalBenefitsSetBulkEditsComponent extends Component {
  render() {
    return (
      <BenefitsSetBulkEditsComponent
        benefitLabels={benefitLabels}
        metadatumFields={metadatumFieldsDiffSet}
        defaultFields={defaultFields}
        benefitsSetUrl="/dental_benefits"
        productLine="dental"
        {...this.props}
      />
    )
  }
}
