import IndexComponent from '../common/IndexComponent'
import indexActions from '../../actions/MedicalBenefitsSets'
import { BenefitsSetIndex, connectedBenefitsSetIndex } from '../BenefitsSet/BenefitsSetIndex'
import approvalStateDisplay from '../BenefitsSet/approvalStateDisplay'
import BooleanCellDisplayRenderer from '../common/BooleanCellDisplayRenderer'
import BenefitsSetsHelper from '../BenefitsSet/Helpers/BenefitsSetsHelper'
import publishStateDisplayWithModal from '../BenefitsSet/publishStateDisplayWithModal'

export const columnsList = [
  { key: 'name', label: 'Name', display: BenefitsSetIndex.showLinkDisplay('/medical_benefits') },
  { key: 'carrier_id', label: 'Carrier ID' },
  { key: 'vericred_id', label: 'Vericred ID', sortable: false },
  { key: 'hios_ids', label: 'HIOS IDs', sortable: false },
  {
    key: 'plan_year',
    label: 'Year',
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'audience',
    label: 'Audience',
    filterable: true,
    optionsEnum: true
  },
  {
    key: 'state_id',
    label: 'State',
    display: BenefitsSetIndex.stateDisplay,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  { key: 'updated_at', label: 'Last Updated At', display: IndexComponent.dateTimeDisplay },
  { key: 'last_updated_by_username', label: 'Last Updated By' },
  {
    key: 'approval_state',
    label: 'Status',
    display: approvalStateDisplay,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'needs_sbc_processing',
    label: 'Needs SBC Processing',
    filterable: true,
    display: BooleanCellDisplayRenderer,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'key_benefits_complete',
    label: 'Key Benefits Complete',
    sortable: false,
    filterable: true,
    recordValue: 'value',
    recordLabel: 'text',
    display: BooleanCellDisplayRenderer
  },
  {
    key: 'publish_state',
    label: 'Publish Status',
    display: publishStateDisplayWithModal,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  }
]

export const filters = [
  {
    key: 'audience',
    config: 'benefits.audience'
  },
  {
    key: 'state_id',
    config: 'states'
  },
  {
    key: 'approval_state',
    options: [
      { value: 'new', text: 'New' },
      { value: 'approved', text: 'Approved' },
      { value: 'rejected', text: 'Rejected' },
      { value: 'corrected', text: 'Corrected' },
      { value: 'amended', text: 'Amended' }
    ]
  },
  {
    key: 'publish_state',
    options: [
      { value: 'needs_publishing', text: 'Needs Publishing' },
      { value: 'failed_to_publish', text: 'Failed to publish' },
      { value: 'published', text: 'Published' },
      { value: 'unpublished', text: 'Unpublished' },
      { value: 'publishing', text: 'Publishing' }
    ]
  },
  {
    key: 'key_benefits_complete',
    options: [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' }
    ]
  },
  {
    key: 'needs_sbc_processing',
    options: [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' }
    ]
  },
  {
    key: 'plan_year',
    options: BenefitsSetsHelper.planYearOptions(2015, (new Date()).getFullYear() + 1)
  }
]

export default connectedBenefitsSetIndex(
  'medicalBenefitsSets',
  indexActions,
  filters,
  {
    name: 'Medical Benefits Set',
    columnsList,
    benefitsSetPath: '/medical_benefits',
    includeBulkEditLink: true,
    includeDiffSetLink: true,
    includeBulkPublishLink: true,
    includeSelectAll: true,
    productLine: 'medical',
    searchColumns: ['name', 'vericred_id', 'hios_id', 'carrier_id', 'document_id']
  }
)
