import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IoIosClose } from 'react-icons/io'
import Component from '../common/Component'
import actions from '../../actions/BulkPlanProductRestores'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'
import CheckboxField from '../common/CheckboxField'
import Modal from '../common/Modal'

export class RestoreAllPlanProductsButton extends Component {
  static propTypes = {
    records: PropTypes.array.isRequired,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleAction: PropTypes.func.isRequired,
    createFlashMessage: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      restoreRates: true,
      generalError: null
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleClick = (event) => {
    this.props.reset()
    this.setState({ modal: true, generalError: null })
  }

  handleClose = (event) => {
    this.setState({ modal: false })
  }

  handleCheckbox = (key, value) => {
    this.setState({ [key]: value })
  }

  handleRestoreAll = async () => {
    const ids = this.props.records.map((record) => { return record.id })
    const response = await this.props.create({ plan_product_ids: ids, restore_rates: this.state.restoreRates })

    if (!response.generalError) {
      this.setState({ modal: false })
      this.props.handleAction()
      this.props.createFlashMessage(
        `${this.props.records.length} Plan Products have been restored`,
        { tag: 'general' }
      )
    } else {
      this.setState({ generalError: response.generalError })
    }
  }

  displayError = () => {
    return <div className="howie_error">{this.state.generalError}</div>
  }

  renderModal = () => {
    const restoreIds = this.props.records.map((record) => <li key={record.id.toString()}>{record.hios_id}</li>)

    return (
      <Modal
        className="restore-plan-product-modal"
        handleClose={this.handleClose}
      >
        <div>
          {this.state.generalError && this.displayError()}

          <p><strong>Please confirm that you would like to restore the following Plan Products:</strong></p>

          <ul className="buffer-top buffer-bottom">{restoreIds}</ul>

          <CheckboxField
            key="restoreRates"
            name="restoreRates"
            label="Restore rates?"
            handleChange={this.handleCheckbox}
            value={this.state.restoreRates}
          />
        </div>

        <button
          id="restore-plan-product"
          className="select-button pure-button pure-input-1 pure-button-primary action-ipa"
          type="button"
          onClick={this.handleRestoreAll}
        >
          Restore plan products
        </button>

        <button
          className="cancel-button"
          type="button"
          onClick={this.handleClose}
        >
          <IoIosClose />
        </button>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <button
          key="restore-all"
          title="Restore all plans"
          className="button quiet-button small"
          onClick={this.handleClick}
        >
          Restore Plan Products ({this.props.records.length})
        </button>

        {this.state.modal && this.renderModal()}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}, dispatch)

export default connect(null, mapDispatchToProps)(RestoreAllPlanProductsButton)
