import { isEmpty, omit, keys } from 'lodash'

export default class UserContext {
  constructor(states = {}) {
    const additionalKeys = keys(omit(states, ['editable', 'viewable', 'reviewable']))

    if (!isEmpty(additionalKeys)) {
      throw new Error(`Invalid states passed to UserContext: ${additionalKeys.join(', ')}`)
    }

    this.editable = states.editable || false
    this.viewable = states.viewable || false
    this.reviewable = states.reviewable || false
  }

  get isEditable() {
    return this.editable
  }

  get isReviewable() {
    return this.reviewable
  }

  get isViewable() {
    return this.viewable
  }
}
