import React from 'react'
import { connect } from 'react-redux'
import Component from '../common/Component'
import { benefitLabels, metadatumFieldsDiffSet } from './medAdvFields'
import publishingActions from '../../actions/BenefitsSetsExportPublishings'
import BenefitsSetExportShow from '../BenefitsSet/BenefitsSetExportShow'

export class MedicareAdvantageBenefitsSetExportShow extends Component {
  static defaultFields = [
    'id',
    'vericred_id',
    'approval_state',
    'publish_state',
    'name',
    'audience',
    'plan_type',
    'source',
    'issuer_identifier',
    'issuer_name',
    'medicare_plan_id'
  ]

  render() {
    return (
      <BenefitsSetExportShow
        productLine="Medicare Advantage"
        benefitLabels={benefitLabels}
        metadatumFieldsDiffSet={metadatumFieldsDiffSet}
        defaultFields={MedicareAdvantageBenefitsSetExportShow.defaultFields}
        benefitsSetUrl="/med_adv_benefits"
        alwaysShowFields={['medicare_plan_id']}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    exportPublish: state.benefitsSetsExportPublishings.create
  }
}

const mapDispatchToProps = {
  createExportPublish: publishingActions.create.main,
  resetCreateExportPublish: publishingActions.create.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicareAdvantageBenefitsSetExportShow)
