import {
  isNil,
  isEmpty,
  cloneDeep,
  find,
  findIndex,
  merge,
  words,
  upperFirst,
  without
} from 'lodash'

class BenefitListPrepopulator {
  constructor(benefitIds, options = {}) {
    this.benefitIds = benefitIds
    this.ignoreLimit = options.ignoreLimit || false
    this.condition = options.condition || ((state) => true)
  }

  newBenefit = (benefitId) => ({
    name: benefitId,
    value: benefitId,
    limit: ''
  })

  includesBenefit = (benefitId) => this.benefitIds.includes(benefitId)

  prepopulate = (benefitId, fieldId, state) => {
    if (isNil(state.benefits)) {
      return state
    }

    let newState = cloneDeep(state)

    if (fieldId === 'limit' && this.ignoreLimit) {
      return state
    }

    if (!this.condition(state)) {
      return state
    }

    const tierName = this.fieldNameFromId(fieldId)

    const fromBenefit = find(newState.benefits, ['name', benefitId])

    if (isNil(fromBenefit)) {
      return state
    }

    newState = this.assignBenefitValues(fromBenefit, tierName, newState)

    return newState
  }

  fieldNameFromId(fieldId) {
    return words(fieldId).map(upperFirst).join(' ')
  }

  destinationIdsForBenefitId = (benefitId) => without(this.benefitIds, [benefitId])

  assignBenefitValues = (fromBenefit, tierName, state) => {
    const destinationBenefitIds = this.destinationIdsForBenefitId(fromBenefit)

    let newState = cloneDeep(state)

    destinationBenefitIds.forEach((destinationBenefitId) => {
      newState = merge(newState, this.assignBenefitValue(fromBenefit, tierName, destinationBenefitId, state))
    })

    return newState
  }

  assignBenefitValue = (fromBenefit, tierName, destinationBenefitId, originalState) => {
    const state = originalState

    const index = findIndex(state.benefits, ['name', destinationBenefitId])
    let toBenefit = (index !== -1) ? state.benefits[index] : this.newBenefit(destinationBenefitId)

    if (tierName === 'Limit') {
      if (isEmpty(toBenefit.limit)) {
        toBenefit.limit = fromBenefit.limit
      }
    } else {
      toBenefit = this.assignTierValue(fromBenefit, toBenefit, tierName)
    }

    if (index !== -1) {
      state.benefits[index] = toBenefit
    } else {
      state.benefits.push(toBenefit)
    }

    return state
  }

  assignTierValue = (fromBenefit, originalToBenefit, tierName) => {
    const toBenefit = originalToBenefit

    const newTiersAttributes = toBenefit.tiers || []

    const fromTier = find(fromBenefit.tiers, ['option', tierName])
    const index = findIndex(newTiersAttributes, ['option', tierName])

    if (isNil(fromTier) || isEmpty(fromTier.value)) {
      return toBenefit
    }

    if (index === -1) {
      newTiersAttributes.push({ option: tierName, value: fromTier.value })
    } else {
      const newTier = newTiersAttributes[index]

      if (isEmpty(newTier.value)) {
        newTier.value = fromTier.value
        newTiersAttributes[index] = newTier
      }
    }

    toBenefit.tiers = newTiersAttributes

    return toBenefit
  }
}

export default BenefitListPrepopulator
