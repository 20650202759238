import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { IoIosDocument } from 'react-icons/io'
import Tree from 'react-ui-tree'
import 'react-ui-tree/dist/react-ui-tree.css'
import cx from 'classnames'
import moment from 'moment'
import { get, startCase } from 'lodash'

import Component from '../common/Component'
import AccordionItem from '../common/AccordionItem'

import serviceAreaActions from '../../actions/HowieServiceAreas'
import reviewActions from '../../actions/HowieServiceAreaReviews'

import CreateReviewForm from '../common/CreateReviewForm'
import LabeledTextAreaField from '../common/LabeledTextAreaField'

export class HowieServiceAreaReview extends Component {
  static propTypes = {
    modelName: PropTypes.string.isRequired,
    createAction: PropTypes.func.isRequired,
    resetCreateAction: PropTypes.func.isRequired,
    showServiceAreaAction: PropTypes.func.isRequired,
    resetShowServiceAreaAction: PropTypes.func.isRequired,
    resourceActionStatuses: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    serviceArea: PropTypes.object,
    match: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      tree: {},
      active: false
    }
  }

  componentDidMount() {
    const { showServiceAreaAction } = this.props
    showServiceAreaAction(this.id)
  }

  componentWillUnmount() {
    const { resetShowServiceAreaAction } = this.props
    resetShowServiceAreaAction()
  }

  onClickNode = (node) => {
    this.setState({
      active: node
    })
  }

  get defaultAttributes() {
    return { howie_service_area_id: this.id, rejection_reason: '' }
  }

  get id() {
    return this.props.match.params.id
  }

  get shouldRender() {
    return this.props.serviceArea
  }

  get documentUrl() {
    return this.props.serviceArea.document.public_url
  }

  get documentLink() {
    if (this.documentUrl) {
      return (
        <a href={this.documentUrl} target="_sbc">
          <IoIosDocument size={30} />
          Document
        </a>
      )
    }
    return 'No Document Attached'
  }

  get treeComponents() {
    return (
      this.props.serviceArea.zip_counties.map((tree) => (
        <Tree
          key={tree.module}
          tree={tree}
          paddingLeft={20}
          onChange={this.handleChange}
          renderNode={this.renderNode}
          isNodeCollapsed
        />
      ))
    )
  }

  get returnPath() {
    return get(this.props.location, 'state.return_path', '/howie_service_areas/')
  }

  get reviews() {
    const newReviews = this.props.serviceArea.howie_service_area_reviews

    return (
      newReviews.sort((a, b) => (a.created_at > b.created_at ? -1 : 1)).map((review) => (
        <tr name={`review - ${review.id}`} key={`${review.id}`}>
          <td name="review-type">{startCase(review.review_type)}</td>
          <td name="reviewer">{review.reviewer}</td>
          <td name="rejection-reason">{review.rejection_reason}</td>
          <td name="review-date">{this.formattedDateValue(review.created_at)}</td>
        </tr>
      ))
    )
  }

  formattedDateValue = (value) => {
    return moment(value).utc().format('YYYY-MM-DD')
  }

  handleChange = (tree) => {
    this.setState((prevState) => {
      return { tree: prevState.tree }
    })
  }

  renderNode = (node) => {
    return (
      <span
        className={cx('node', {
          'is-active': node === this.state.active
        })}
        role="presentation"
        onClick={() => this.onClickNode.bind(null, node)}
        onKeyDown={() => this.onClickNode.bind(null, node)}
      >
        {node.module}
      </span>
    )
  }

  render() {
    const { serviceArea } = this.props

    if (!this.shouldRender) return null

    return (
      <div className="content">
        <h1>Service Area</h1>
        <div>
          {this.documentLink}
        </div>
        <h5>Metadata</h5>
        <table className="pure-table service_area_info">
          <tbody>
            <tr>
              <td><strong>External ID</strong></td>
              <td name="external_key">{serviceArea.external_key}</td>
            </tr>
            <tr>
              <td><strong>Name</strong></td>
              <td name="name">{serviceArea.name}</td>
            </tr>
            <tr>
              <td><strong>Audience</strong></td>
              <td name="audience">{serviceArea.audience}</td>
            </tr>
            <tr>
              <td><strong>Year</strong></td>
              <td name="year">{serviceArea.year}</td>
            </tr>
            <tr>
              <td><strong>Line of Coverage</strong></td>
              <td name="line_of_coverage">{serviceArea.line_of_coverage}</td>
            </tr>
            <tr>
              <td><strong>Number of Counties</strong></td>
              <td name="fips_code_count">{serviceArea.fips_code_count}</td>
            </tr>
            <tr>
              <td><strong>Number of Zip Codes</strong></td>
              <td name="zip_code_count">{serviceArea.zip_code_count}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>Zip Counties</h5>
        <div className="tree">
          {this.treeComponents}
        </div>
        <br />
        <CreateReviewForm {...this.props} redirectTo={this.returnPath} attributes={this.defaultAttributes}>
          <AccordionItem
            title={['Review History']}
            titleClass={['pure-u-1']}
            name="review_table"
          >
            <table className="pure-table">
              <thead>
                <tr>
                  <th name="review-type">Review Type</th>
                  <th name="reviewer">Reviewer</th>
                  <th name="rejection-reason">Rejection Reason</th>
                  <th name="review-date">Review Date</th>
                </tr>
              </thead>
              <tbody>
                {this.reviews}
              </tbody>
            </table>
          </AccordionItem>
          <LabeledTextAreaField
            name="rejection_reason"
            label="Rejection Note"
            placeholder="Enter a rejection reason here"
          />
        </CreateReviewForm>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const resourceActionStatuses = state.howieServiceAreaReviews
  const showSlice = state.howieServiceAreas.show
  const configuration = state.login.session.configuration.howie_service_areas

  return {
    serviceArea: showSlice.attributes,
    modelName: 'Service Area Review',
    configuration,
    resourceActionStatuses
  }
}

const mapDispatchToProps = {
  createAction: reviewActions.create.main,
  resetCreateAction: reviewActions.create.reset,
  showServiceAreaAction: serviceAreaActions.show.main,
  resetShowServiceAreaAction: serviceAreaActions.show.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(HowieServiceAreaReview)
