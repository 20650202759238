import React from 'react'
import PropTypes from 'prop-types'
import { keys, isNil, isEmpty, compact, get, cloneDeep, last } from 'lodash'
import Component from '../common/Component'
import BenefitsSetExternalIdComponent from './BenefitsSetExternalIdComponent'
import UserContext from './BenefitsSetUserContext'
import FieldDefinition from './BenefitsSetFieldDefinition'
import HeaderRow from './BenefitsSetHeaderRow'

export default class BenefitsSetExternalIdsComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    reviews: PropTypes.array,
    rejections: PropTypes.array,
    location: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleReviewChange: PropTypes.func.isRequired,
    totalColumns: PropTypes.number.isRequired,
    fieldDefinition: PropTypes.instanceOf(FieldDefinition).isRequired,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    benefitsSet: PropTypes.object.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ])
  }

  static renderedProps = [
    'name',
    'label',
    'reviews',
    'rejections',
    'totalColumns',
    'fieldDefinition',
    'userContext',
    'errors'
  ]

  get externalIds() {
    if (this.props.userContext.isEditable) {
      return this.editableExternalIds
    }

    return this.props.fieldDefinition.value
  }

  get editableExternalIds() {
    const ids = cloneDeep(this.props.fieldDefinition.value)

    if (ids.length === 0 || !isEmpty(get(last(ids), 'external_id'))) {
      ids.push({ external_id: '', external_id_type: this.defaultExternalIdType })
    }

    return ids
  }

  get defaultExternalIdType() {
    return keys(this.props.fieldDefinition.options)[0]
  }

  get rows() {
    const name = this.props.name

    return this.externalIds.map((externalId, index) => (
      <BenefitsSetExternalIdComponent
        key={`${name}-${index}`}
        name={`${name}-${index}`}
        index={index}
        errors={get(this.props.errors, index)}
        review={this.reviewForExternalId(externalId)}
        rejections={this.rejectionsForExternalId(externalId)}
        handleChange={this.handleChange}
        handleBlur={this.props.handleBlur}
        handleReviewChange={this.handleReviewChange}
        totalColumns={this.props.totalColumns}
        fieldDefinition={this.props.fieldDefinition.copyWithValue(externalId, this.props.benefitsSet)}
        userContext={this.props.userContext}
        benefitsSetId={this.props.benefitsSet.id}
        location={this.props.location}
      />
    ))
  }

  get errorRow() {
    const errorMessage = get(this.props.errors, 'base')

    if (isNil(errorMessage)) {
      return null
    }

    return (
      <HeaderRow
        key="header-error"
        labels={[errorMessage]}
        totalColumns={this.props.totalColumns}
        className="error-row"
      />
    )
  }

  handleChange = (id, index, value) => {
    const externalIds = cloneDeep(this.externalIds)

    if (isNil(value)) {
      delete externalIds[index]
    } else {
      externalIds[index] = value
    }

    if (isEmpty(compact(externalIds))) {
      this.props.handleChange('plan_external_ids', null)
      return
    }

    this.props.handleChange('plan_external_ids', compact(externalIds))
  }

  handleReviewChange = (id, index, value) => {
    const reviews = cloneDeep(this.props.reviews) || []
    const externalId = this.externalIds[index]

    if (isNil(value)) {
      reviews[index] = null
    } else {
      reviews[index] = {
        note: value,
        plan_external_id_id: externalId.id
      }
    }

    if (isEmpty(compact(reviews))) {
      this.props.handleReviewChange('plan_external_id_reviews', null)
      return
    }

    this.props.handleReviewChange('plan_external_id_reviews', reviews)
  }

  reviewForExternalId = (externalId) => {
    const reviews = this.props.reviews || []
    return reviews.find((r) => get(r, 'plan_external_id_id') === externalId.id)
  }

  rejectionsForExternalId = (externalId) => {
    const rejections = this.props.rejections || []

    return compact(rejections.map((r) => {
      const review = r.review.find((externalIdReview) => (
        externalIdReview.plan_external_id_id === externalId.id
      ))

      if (isNil(review)) {
        return null
      }

      return {
        review_id: r.review_id,
        created_at: r.created_at,
        review: review.note
      }
    }))
  }

  render() {
    const label = this.props.label

    if (this.props.fieldDefinition.hidden) {
      return null
    }

    return (
      <tbody id="external-id-rows">
        <HeaderRow
          key={`header-${label}`}
          labels={[label]}
          totalColumns={this.props.totalColumns}
        />
        {this.errorRow}
        {this.rows}
      </tbody>
    )
  }
}

