import ReducerGenerator from '../../lib/ReducerGenerator'

const generator = new ReducerGenerator(
  'bulk_plan_product_restores',
  '/bulk_plan_product_restores',
  'restores'
)
const reducer = generator.generate()

export default reducer
