import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/MedicalBenefitsSets'
import { createFlashMessage } from '../../actions/FlashMessages'
import MedicalBenefitsSetForm from './MedicalBenefitsSetForm'
import BenefitsSetCreate from '../BenefitsSet/BenefitsSetCreate'
import howieServiceAreaActions from '../../actions/HowieServiceAreas'

const defaultValues = {
  document: null,
  metadatum: {
    gated: null,
    dp_rider: null,
    fp_rider: null,
    age29_rider: null,
    infertility_rider: null,
    abortion_rider: null,
    telemedicine: null,
    adult_dental: null,
    chiropractic_services: null,
    plan_calendar: null,
    skilled_nursing_facility_365: null,
    actively_marketed: null,
    hsa_eligible: null,
    effective_date: null,
    expiration_date: null
  },
  plan_external_ids: [],
  benefits: []
}

const mapStateToProps = (state) => {
  const slice = state.medicalBenefitsSets.create
  const configuration = state.login.session.configuration.benefits
  const remoteOptionsSelectStates = {
    howie_service_areas: state.howieServiceAreas.index,
    medical_benefits: state.medicalBenefitsSets.index
  }

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    requestComplete: slice.requestComplete,
    id: slice.id,
    created: slice.created,
    configuration,
    remoteOptionsSelectStates,
    benefitsSetForm: MedicalBenefitsSetForm,
    defaultValues,
    productLine: 'Medical',
    benefitsSetPath: 'medical_benefits'
  }
}

const mapDispatchToProps = (dispatch) => ({
  create: bindActionCreators(actions.create.main, dispatch),
  reset: bindActionCreators(actions.create.reset, dispatch),

  remoteOptionsSelectActions: {
    howieServiceAreasIndex: {
      main: bindActionCreators(howieServiceAreaActions.index.main, dispatch),
      reset: bindActionCreators(howieServiceAreaActions.index.reset, dispatch)
    },
    medicalBenefitsSetsIndex: {
      main: bindActionCreators(actions.index.main, dispatch),
      reset: bindActionCreators(actions.index.reset, dispatch)
    }
  },

  createFlashMessage
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetCreate)
