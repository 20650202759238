import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Set } from 'immutable'
import { isEmpty, upperFirst } from 'lodash'
import CheckboxField from './CheckboxField'

export default class CheckboxFieldSet extends Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    value: PropTypes.array,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
  }

  handleChange = (name, checked) => {
    let value = new Set(this.props.value)

    if (checked) {
      value = value.add(name)
    } else if (value.contains(name)) {
      value = value.remove(name)
    }

    this.props.handleChange(this.props.name, value.toArray())
  }

  handleBlur = (name) => {
    if (!this.props.handleBlur) {
      return
    }

    this.props.handleBlur(name)
  }

  render() {
    const {
      name, label, options, value, disabled, errorMessage
    } = this.props

    const classNames = ['field']

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    const valueSet = new Set(value)

    const checkboxComponents = options.map((option) => (
      <CheckboxField
        inpuCssClass="pure-checkbox"
        name={option}
        label={option}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        value={(valueSet.has(option))}
        disabled={disabled}
      />
    ))

    return (
      <fieldset id={name} className={classNames.join(' ')}>
        <legend>{label}</legend>
        { checkboxComponents }
        {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
      </fieldset>
    )
  }
}
