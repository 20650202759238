import React from 'react'
import PropTypes from 'prop-types'
import {
  first, cloneDeep, isArray, isEmpty, isFunction,
  isNil, has, map, omit, reduce, range, set, unset,
  isUndefined, startCase, compact, merge,
  filter, includes, trim, forEach, size, values
} from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import { Prompt, Redirect } from 'react-router-dom'
import Component from '../common/Component'
import DiffCellRenderer from './DiffCellRenderer'
import SbcCellRenderer from './SbcCellRenderer'
import SobCellRenderer from './SobCellRenderer'
import BulkEditsErrorsComponent from './BulkEditsErrorsComponent'
import GridSelectOptionsHelper from './Helpers/GridSelectOptionsHelper'
import DocumentSelectField from '../common/DocumentSelectField'
import benefitValueFormatter from './Helpers/benefitValueFormatter'
import '../../css/AgGrid.scss'
import '../../css/BulkEdits.scss'

export default class BulkEditsComponent extends Component {
  static propTypes = {
    records: PropTypes.array.isRequired,
    defaultFields: PropTypes.array.isRequired,
    metadataFields: PropTypes.object.isRequired,
    benefitFields: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    createBulkUpdate: PropTypes.func.isRequired,
    bulkUpdate: PropTypes.object,
    higherPrioritySourceValidation: PropTypes.func.isRequired,
    resetHigherPrioritySourceValidation: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    includeSbcCheckbox: PropTypes.bool,
    productLine: PropTypes.string,
    hideId: PropTypes.bool
  }

  constructor(props) {
    super(props)

    const records = this.shallowRecords(props.records)
    const changes = props.records.reduce((memo, value) => {
      return Object.assign(memo, { [value.id]: {} })
    }, {})

    const initialRecords = records.reduce((memo, value) => {
      return Object.assign(memo, { [value.id]: value })
    }, {})

    this.state = {
      savePromise: new Promise((resolve, reject) => { resolve() }),
      records: cloneDeep(records),
      initialRecords,
      changes
    }
  }

  componentWillMount = () => {
    this.saveListener = document.addEventListener('keydown', (e) => {
      if (e.keyCode === 83 && (e.metaKey || e.ctrlKey)) {
        this.handleSave(e)
        e.preventDefault()
      }
      return true
    })
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.saveListener)
    this.props.resetHigherPrioritySourceValidation()
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi

    this.autoSizeAll()
  }

  get allBenefitFields() {
    let fields = [
      { key: 'in_network', label: 'In Network' }
    ]

    fields = fields.concat(
      range(2, Math.max(2, this.maxTiers + 1))
        .map((v) => ({ key: `in_network_tier_${v}`, label: `In Network Tier ${v}` }))
    )

    fields.push({ key: 'out_of_network', label: 'Out Of Network' })
    fields.push({ key: 'limit', label: 'Limit' })

    return fields
  }

  get filteredRecords() {
    return filter(this.state.records, (record) => {
      if (this.state.withSbc) { return !isEmpty(record.sbc_url) }
      if (this.state.withSob) { return !isEmpty(record.sob_url) }

      return true
    })
  }

  get maxTiers() {
    if (isNil(this.props.records)) {
      return 0
    }

    const rowTiers = this.props.records.map((row) => {
      const benefitTiers = Object.values(row.benefits).map((benefit) => {
        const firstNilTier = range(2, 11).find((i) => isNil(benefit[`in_network_tier_${i}`]))

        if (isNil(firstNilTier) || firstNilTier === 2) {
          return 0
        }

        return firstNilTier - 1
      })

      return Math.max(...benefitTiers)
    })

    return Math.max(...rowTiers)
  }

  get rowCount() {
    return this.props.records.length
  }

  get metadatumColumns() {
    return map(this.props.metadataFields, (field, key) => {
      let editor
      let filterType
      let editorParams = {}
      let valueFormatter
      let valueParser
      const options = cloneDeep(field.options)

      switch (field.type) {
      case 'select':
        editor = 'agRichSelectCellEditor'

        if (isArray(options) && !isEmpty(options) && !has(first(options), 'text')) {
          options.push(null)
          editorParams = { values: options }
        } else if (isFunction(field.options)) {
          editorParams = (params) => {
            return { values: field.options(params.data, this.props.configuration).concat(null) }
          }
        } else {
          // eslint-disable-next-line no-shadow
          let values = this.props.configuration[key] || []

          if (!values.length && has(first(options), 'text')) {
            values = options
          }

          if (has(first(values), 'text')) {
            const optionsHelper = new GridSelectOptionsHelper(values)
            values = optionsHelper.values
            valueFormatter = (params) => {
              return optionsHelper.valueToText(params.value)
            }
            valueParser = (params) => {
              return optionsHelper.textToValue(params.value)
            }
          }

          editorParams = { values }
        }

        break
      case 'checkbox':
        editor = 'agRichSelectCellEditor'
        editorParams = {
          values: [true, false],
          cellRenderer: (params) => {
            return params.value ? 'true' : 'false'
          }
        }
        break
      default:
        filterType = 'agTextColumnFilter'
        editor = 'agTextCellEditor'
        valueParser = (params) => {
          return field.valueParser
            ? field.valueParser(params.newValue)
            : trim(params.newValue)
        }
      }

      return {
        field: field.bulkEditKey || key,
        headerName: field.label,
        hide: !this.props.defaultFields.includes(key),
        editable: !field.display_only,
        cellRendererFramework: DiffCellRenderer,
        cellClass: 'ag-grid-cell-diff',
        cellEditor: editor,
        cellType: 'metadatum',
        filter: filterType,
        cellEditorParams: editorParams,
        valueFormatter,
        valueParser
      }
    })
  }

  get benefitColumns() {
    return map(this.props.benefitFields, (value, key) => {
      return {
        headerName: value,
        children: this.allBenefitFields.map((field) => ({
          field: `${key}_${field.key}`,
          headerName: field.label,
          hide: !this.props.defaultFields.includes(`${key}_${field.key}`),
          editable: true,
          filter: 'agTextColumnFilter',
          cellRendererFramework: DiffCellRenderer,
          cellClass: 'ag-grid-cell-diff',
          cellType: 'benefits',
          valueParser: (params) => {
            return benefitValueFormatter(params.newValue)
          }
        }))
      }
    })
  }

  get documentLookup() {
    if (this.state.withSbc || this.state.withSob) return ''
    return (
      <DocumentSelectField
        key="document"
        name="document"
        handleChange={this.handleChange}
        value={this.state.document}
        filterFields={this.remoteFilterQueryParams}
      />
    )
  }

  get sbcCheckbox() {
    if (!this.props.includeSbcCheckbox || this.state.withSob) { return null }

    return (
      <div>
        <input id="with_sbc" type="checkbox" onClick={this.filterWithSBC} />
        <label htmlFor="with_sbc">Build From SBCs</label>
      </div>
    )
  }

  get sobCheckbox() {
    if (!this.props.includeSbcCheckbox || this.state.withSbc) { return null }

    return (
      <div>
        <input id="with_sob" type="checkbox" onClick={this.filterWithSOB} />
        <label htmlFor="with_sob">Build From SOBs</label>
      </div>
    )
  }

  get columns() {
    return [
      {
        field: 'id',
        headerName: 'ID',
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        lockPosition: true,
        pinned: true,
        lockVisible: !this.props.hideId,
        hide: this.props.hideId
      },
      {
        field: 'vericred_id',
        headerName: 'Vericred ID',
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        lockPosition: true,
        pinned: true,
        lockVisible: !this.props.hideId,
        hide: this.props.hideId
      },
      {
        field: 'sbc_url',
        cellRendererFramework: SbcCellRenderer,
        headerName: 'SBC URL',
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        lockPosition: true,
        lockVisible: true,
        pinned: true
      },
      {
        field: 'sob_url',
        cellRendererFramework: SobCellRenderer,
        headerName: 'SOB URL',
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        lockPosition: true,
        lockVisible: true,
        pinned: true
      },
      {
        headerName: 'Info',
        children: [
          {
            field: 'hios_ids',
            filter: 'agTextColumnFilter',
            headerName: 'HIOS IDs',
            hide: !this.props.defaultFields.includes('hios_ids')
          }

        ].concat(this.metadatumColumns)
      }
    ].concat(this.benefitColumns)
  }

  get rowData() {
    if (isNil(this.props.records)) {
      return []
    }

    return this.props.records.map(this.rowMapper)
  }

  get changes() {
    return this.state.records.map((record, index) => {
      return this.changesForRow(record, this.state.initialRecords[index])
    })
  }

  get updateValues() {
    let changes = cloneDeep(this.state.changes)
    const validIds = map(this.filteredRecords, (record) => { return record.id })

    changes = Object.keys(changes).map((id) => {
      const benefitsAttributes = this.updateBenefitValues(changes[id].benefits)
      const metadatumAttributes = omit(changes[id], ['benefits'])

      if (!includes(validIds, parseInt(id, 10)) || (isEmpty(benefitsAttributes) && isEmpty(metadatumAttributes))) {
        return null
      }

      return {
        benefits_set_id: parseInt(id, 10),
        benefits_attributes: benefitsAttributes,
        metadatum_attributes: metadatumAttributes,
        document_id: this.state.document
      }
    })

    return compact(changes)
  }

  get savingDisabled() {
    const NoRecordChanges = values(this.state.changes).every((idChange) => isEmpty(idChange))

    return this.noDocuments || NoRecordChanges
  }

  get noDocuments() {
    const NoDocument = isNil(this.state.document)
    const NoSbc = !this.state.withSbc
    const NoSob = !this.state.withSob

    return NoDocument && NoSbc && NoSob
  }

  get remoteFilterQueryParams() {
    return {
      product_line: startCase(this.props.productLine),
      document_source_contents: ['Benefits', 'Metadata']
    }
  }

  get hasPendingChanges() {
    return this.updateValues.length > 0
  }

  get savingMsg() {
    if (this.noDocumens) {
      return 'Cannot save yet - select a Document, SBC or SOB'
    } if (this.props.bulkUpdate.requestInProgress) {
      return 'Saving...'
    } if (this.props.bulkUpdate.resourceErrors) {
      return 'Error Saving'
    } if (this.hasPendingChanges) {
      return 'Unsaved Changes'
    }
    return 'Saved'
  }

  get savingMsgClass() {
    if (this.props.bulkUpdate.resourceErrors || this.hasPendingChanges) {
      return 'alert'
    }
    return 'success'
  }

  get shouldPreventBack() {
    return this.hasPendingChanges || this.props.bulkUpdate.requestInProgress
  }

  get pendingUpdatesMessage() {
    return `Are you sure?  Leaving will lose changes for ${size(this.updateValues)} unsaved benefits set(s).`
  }

  translateTiersAttributes = (benefit) => {
    const tiers = Object.keys(benefit).filter((key) => {
      return key !== 'limit'
    })

    return tiers.map((tier) => {
      return {
        option: startCase(tier).replace('Of', 'of'),
        value: benefit[tier]
      }
    })
  }

  translateLimit = (benefit) => {
    const limit = benefit.limit

    if (isUndefined(limit)) {
      return null
    }

    return limit
  }

  updateBenefitValues = (benefits) => {
    if (isUndefined(benefits)) {
      return []
    }

    return Object.keys(benefits).map((benefitName) => {
      return {
        name: benefitName,
        value: benefitName,
        limit: this.translateLimit(benefits[benefitName]),
        tiers_attributes: this.translateTiersAttributes(benefits[benefitName])
      }
    })
  }

  changesForRow = (row, initialRecord) => {
    return reduce(row, (memo, value, key) => {
      if (value !== initialRecord[key]) {
        Object.assign(memo, { [key]: value })
      }

      return memo
    }, {})
  }

  shallowRecords = (records) => {
    return records.map(this.shallowRecord)
  }

  shallowRecord = (record) => {
    const newRecord = {

      ...record,
      ...this.explodedBenefitFieldsForRow(record),
      hios_ids: this.hiosIDsForRow(record)
    }

    delete newRecord.benefits
    delete newRecord.external_ids

    return newRecord
  }

  initiateColumnBulkEdit = (column) => {
  }

  mainMenuItems = (params) => {
    const items = cloneDeep(params.defaultItems)

    if (params.column.colDef.editable) {
      items.push({
        name: 'Bulk Edit Selected',
        action: this.initiateColumnBulkEdit.bind(params.column)
      })
    }

    return items
  }

  rowMapper = (row) => {
    return {

      ...row,
      ...this.explodedBenefitFieldsForRow(row),
      hios_ids: this.hiosIDsForRow(row)
    }
  }

  hiosIDsForRow = (row) => {
    if (!row.external_ids) { return '' }
    return row.external_ids.reduce((memo, value) => {
      if (value.type === 'hios_id') {
        memo.push(value.external_id)
      }

      return memo
    }, []).join(', ')
  }

  explodedBenefitFieldsForRow = (row) => {
    return reduce(row.benefits, (memo, value, key) => {
      return Object.assign(memo, {
        [`${key}_limit`]: value.limit,
        [`${key}_in_network`]: value.in_network,
        [`${key}_in_network_tier_2`]: value.in_network_tier_2,
        [`${key}_in_network_tier_3`]: value.in_network_tier_3,
        [`${key}_in_network_tier_4`]: value.in_network_tier_4,
        [`${key}_in_network_tier_5`]: value.in_network_tier_5,
        [`${key}_in_network_tier_6`]: value.in_network_tier_6,
        [`${key}_in_network_tier_7`]: value.in_network_tier_7,
        [`${key}_in_network_tier_8`]: value.in_network_tier_8,
        [`${key}_in_network_tier_9`]: value.in_network_tier_9,
        [`${key}_in_network_tier_10`]: value.in_network_tier_10,
        [`${key}_out_of_network`]: value.out_of_network
      })
    }, {})
  }

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit()
  }

  autoSizeAll = () => {
    const allColumnIds = []

    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId)
    })

    this.gridColumnApi.autoSizeColumns(allColumnIds)
  }

  clearEmptyBenefits = (change) => {
    const newBenefits = reduce(change.benefits, (memo, value, key) => {
      if (!isEmpty(value)) {
        Object.assign(memo, { [key]: value })
      }

      return memo
    }, {})

    return { ...change, benefits: newBenefits }
  }

  assignChangedValue = (id, fieldName, changedValue) => {
    this.setState((prevState) => {
      const changes = cloneDeep(prevState.changes)
      const change = changes[id]
      const initialRecord = prevState.initialRecords[id]
      let objectFieldName = fieldName

      if (fieldName.includes('_limit') || fieldName.includes('_in_network') || fieldName.includes('_out_of_network')) {
        objectFieldName = `benefits.${fieldName}`
        objectFieldName = objectFieldName.replace('_limit', '.limit')
        objectFieldName = objectFieldName.replace('_in_network', '.in_network')
        objectFieldName = objectFieldName.replace('_out_of_network', '.out_of_network')
      }

      let value = changedValue

      if (fieldName === 'plan_years' && !isArray(changedValue) && !isEmpty(changedValue)) {
        value = changedValue.split(',').map(trim).map(Number)
      }

      if (changedValue !== initialRecord[fieldName]) {
        set(change, objectFieldName, value)
      } else {
        unset(change, objectFieldName)
      }

      const clearedChange = this.clearEmptyBenefits(change)

      if (isEmpty(clearedChange.benefits)) {
        delete clearedChange.benefits
      }

      changes[id] = clearedChange

      return { changes }
    })
  }

  cellValueChanged = (params) => {
    this.gridColumnApi.autoSizeColumn(params.column.colId)
    this.assignChangedValue(params.node.id, params.colDef.field, params.newValue)
    if (this.state.document) { this.validateCell(params) }
  }

  validateCell = (params) => {
    this.props.higherPrioritySourceValidation({
      benefits_set_id: params.node.id,
      field: params.colDef.field,
      field_type: params.colDef.cellType,
      document_id: this.state.document
    }).then((response) => {
      if (!response.responseAttributes.valid) {
        const errorStyles = { borderColor: 'black', borderWidth: '3px', lineHeight: '1.9' }
        /* eslint-disable no-param-reassign */
        params.colDef.cellStyle = (colParams) => { return errorStyles }
        /* eslint-enable no-param-reassign */
        this.gridApi.refreshCells({ force: true, rowNodes: [params.node] })
      }
    })
  }

  rowNodeId = (data) => {
    return data.id
  }

  handleSave = (event) => {
    this.makeUpdate((params) => {
      this.resetInitialRecords(params)
    })
  }

  handleSaveAndReturn = (event) => {
    this.makeUpdate((params) => {
      this.setState((prevState) => {
        return merge(prevState, { shouldRedirect: true })
      })
    })
  }

  makeUpdate = (callback) => {
    this.setState((prevState) => {
      const newSavePromise = prevState.savePromise.then(() => {
        const params = { changes: this.updateValues }
        if (this.state.withSbc) { params.with_sbc = true }
        if (this.state.withSob) { params.with_sob = true }

        return this.props.createBulkUpdate(params).then((response) => {
          if (response && !response.resourceErrors) {
            callback(params)
          }
        })
      })
      return merge(prevState, { savePromise: newSavePromise })
    })
  }

  handleChange = (name, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState)
      newState[name] = value
      return newState
    })
  }

  filterWithSBC = () => {
    this.setState((prevState) => {
      return merge(prevState, { withSbc: !prevState.withSbc })
    })
  }

  filterWithSOB = () => {
    this.setState((prevState) => {
      return merge(prevState, { withSob: !prevState.withSob })
    })
  }

  resetInitialRecords = (params) => {
    this.setState((prevState) => {
      const newInitialRecords = prevState.initialRecords
      const changes = prevState.changes

      params.changes.forEach((change) => {
        forEach(change.metadatum_attributes, (value, key) => {
          newInitialRecords[change.benefits_set_id][key] = value
          delete changes[change.benefits_set_id][key]
        })

        forEach(change.benefits_attributes, (benefit) => {
          if (benefit.limit) {
            newInitialRecords[change.benefits_set_id][`${benefit.name}_limit`] = benefit.limit
            delete changes[change.benefits_set_id].benefits[benefit.name].limit
          }
          forEach(benefit.tiers_attributes, (tier) => {
            const tierName = tier.option.toLowerCase().replace(/\s+/g, '_')
            newInitialRecords[change.benefits_set_id][`${benefit.name}_${tierName}`] = tier.value
            delete changes[change.benefits_set_id].benefits[benefit.name][tierName]
          })
        })
        forEach(changes[change.benefits_set_id].benefits, (value, key) => {
          if (isEmpty(value)) {
            delete changes[change.benefits_set_id].benefits[key]
          }
        })

        if (isEmpty(changes[change.benefits_set_id].benefits)) {
          delete changes[change.benefits_set_id].benefits
        }
      })
      return merge(prevState, { changes, initialRecords: newInitialRecords })
    })
  }

  render() {
    if (this.state.shouldRedirect) {
      return <Redirect push to={this.props.redirectUrl} />
    }

    return (
      <div>
        {this.documentLookup}
        {this.sbcCheckbox}
        {this.sobCheckbox}
        <div className="flash-message-contains">
          <p className={this.savingMsgClass}>{this.savingMsg}</p>
        </div>
        <div className="ag-theme-balham data-grid">
          <AgGridReact
            columnDefs={this.columns}
            rowData={this.filteredRecords}
            onGridReady={this.onGridReady}
            onCellValueChanged={this.cellValueChanged}
            onCellEditingStopped={this.cellEditingStopped}
            getMainMenuItems={this.mainMenuItems}
            getRowNodeId={this.rowNodeId}
            initialData={this.state.initialRecords}
            animateRows
            enableSorting
            enableFilter
            suppressAggFuncInHeader
            enableColResize
            enableRangeSelection
            deltaRowDataMode
            toolPanelSuppressRowGroups
            toolPanelSuppressValues
            toolPanelSuppressPivots
            toolPanelSuppressPivotMode
          />
        </div>
        <BulkEditsErrorsComponent
          bulkUpdate={this.props.bulkUpdate}
          explodedBenefitFieldsForRow={this.explodedBenefitFieldsForRow}
        />
        <fieldset>
          <button
            className="pure-button pure-input-1 pure-button-primary"
            title="Save and Continue"
            id="save_and_continue"
            onClick={this.handleSave}
            disabled={this.savingDisabled}
          >
            Save and Continue
          </button>

          <button
            className="pure-button pure-input-1 pure-button-primary"
            title="Save and Back"
            id="save_and_back"
            onClick={this.handleSaveAndReturn}
            disabled={this.savingDisabled}
          >
            Save and Back
          </button>
        </fieldset>
        <Prompt
          when={this.shouldPreventBack}
          message={(location) => (
            this.pendingUpdatesMessage
          )}
        />
      </div>
    )
  }
}
