import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { forEach, groupBy } from 'lodash'
import MenuSection from './MenuSection'

export default class MenuSubSection extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        label: PropTypes.string
      }).isRequired
    )
  }

  constructor(props) {
    super(props)

    this.state = { isActive: false }
  }

  mainMenus = () => {
    const subMenus = []

    forEach(this.groupRoutesByLabels(), (routes, label) => {
      subMenus.push(this.subMenuSection(label, routes))
    })

    return subMenus
  }

  subMenuSection = (title, routes) => {
    return (
      <MenuSection
        key={`menu-section-${title}`}
        title={title}
        routes={routes}
        active={this.state.isActive}
        hasChildren
      />
    )
  }

  groupRoutesByLabels = () => {
    return groupBy(this.props.routes, 'label')
  }

  render() {
    return (
      <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
        <span className="pure-menu-link menu-link">{this.props.title}</span>
        <ul className="pure-menu-children">
          {this.mainMenus()}
        </ul>
      </li>
    )
  }
}
