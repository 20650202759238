import { isNil } from 'lodash'
import clearCensuses from './clearCensuses'
import { SET_AUDIENCE, CLEAR_AUDIENCE } from './consts'

function setAudience(audience) {
  return { type: SET_AUDIENCE, audience }
}

function clearAudience(audience) {
  return { type: CLEAR_AUDIENCE }
}

export default function setAudienceCreator(audience) {
  return (dispatch) => {
    if (isNil(audience)) {
      dispatch(clearCensuses())
      dispatch(clearAudience())
    } else {
      dispatch(setAudience(audience))
    }
  }
}
