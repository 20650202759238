import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { sortBy, map, flatten, uniq, startCase, isUndefined } from 'lodash'

export class PlanRateComponent extends Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    quoteRates: PropTypes.array
  }

  get quoteRate() {
    return this.props.quoteRates.find((rate) => rate.plan_id === this.props.plan.id)
  }

  get premiumBands() {
    const productLine = this.props.plan.product_line

    return this.quoteRate.premiums[productLine].premium_bands
  }

  get ratings() {
    const ratings = this.premiumBands.map((rating) => Object.assign(this.ratingOrder, rating))

    return sortBy(ratings, ['minimum_age'], ['asc'])
  }

  get childRate() {
    return this.quoteRate.premiums.life.child_rate
  }

  get accidentalDeathDismemberment() {
    return this.quoteRate.premiums.accidental_death_dismemberment.employee
  }

  get isLife() {
    return this.props.plan.product_line === 'life'
  }

  get lifeRatingOrder() {
    return {
      minimum_age: null,
      maximum_age: null,
      employee: null,
      employee_tobacco: null,
      spouse: null
    }
  }

  get disabilityRatingOrder() {
    return {
      minimum_age: null,
      maximum_age: null,
      employee: null
    }
  }

  get ratingOrder() {
    switch (this.props.plan.product_line) {
    case 'life':
      return this.lifeRatingOrder
    case 'disability':
      return this.disabilityRatingOrder
    default:
      return null
    }
  }

  get ratingHeader() {
    switch (this.props.plan.product_line) {
    case 'life':
      return 'Rates are monthly per $1,000 of benefit coverage'
    case 'disability':
      return 'STD rates are monthly per $10 of benefit coverage, LTD rates are monthly per $100 of benefit coverage'
    default:
      return null
    }
  }

  ratingColumns = () => {
    const columns = uniq(flatten(this.ratings.map((rating) => Object.keys(rating))))

    return columns.map((column) => <th key={`rating_col_${column}`}>{startCase(column)}</th>)
  }

  ratingRows = (rating) => {
    return map(rating, (value, key) => { return <td key={`${key}_${rating.id}`}>{value}</td> })
  }

  renderRatings = () => {
    const rows = this.ratings.map((rating, index) => <tr key={`rate_row_${index}`}>{this.ratingRows(rating)}</tr>)
    const classNames = []

    if (this.isLife) { classNames.push('buffer-bottom') }

    return (
      <div className={classNames.join(' ')}>
        <p>{this.ratingHeader}</p>
        <p><strong>Premium Bands</strong></p>
        <table className="pure-table" key="premium-bands-table">
          <thead>
            {this.ratingColumns()}
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }

  renderChildRates = () => {
    return (
      <div className="buffer-bottom">
        <p><strong>Child Rates</strong></p>
        <table className="pure-table" key="child-rates-table">
          <thead>
            <tr>
              <th style={{ width: '50%' }}>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>child_rate</td>
              <td>{this.childRate}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderAccidentalDeathDismemberments = () => {
    return (
      <div>
        <p><strong>Accidental Death and Dismemberment Rates</strong></p>
        <table className="pure-table" key="accidental-death-table">
          <thead>
            <tr>
              <th style={{ width: '50%' }}>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>accidental_death_dismemberment</td>
              <td>{this.accidentalDeathDismemberment}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { plan } = this.props

    if (isUndefined(this.quoteRate)) { return null }

    return (
      <div key={`${plan.id}-rates`} id={`${plan.id}-rates`} className="plan-rates-group">
        {this.renderRatings()}
        {this.isLife && this.renderChildRates()}
        {this.isLife && this.renderAccidentalDeathDismemberments()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    quoteRatesCreated: state.quoteRates.created,
    quoteRates: state.quoteRates.create.responseAttributes
  }
}

export default connect(mapStateToProps, null)(PlanRateComponent)
