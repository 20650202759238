import {
  REQUEST_SESSION,
  RESET_SESSION,
  REQUEST_CREATE_SESSION,
  RECEIVE_SESSION,
  RECEIVE_CREDENTIALS_SESSION_ERROR,
  RECEIVE_OAUTH_SESSION_ERROR,
  RECEIVE_SESSION_ERROR
} from '../../actions/Login/consts'

const defaultState = {
  nonce: undefined,
  configuration: undefined,
  user: undefined,
  oauthError: undefined,
  credentialsError: undefined,
  generalError: undefined
}

export default function sessionReducer(state = defaultState, action) {
  switch (action.type) {
  case REQUEST_SESSION:
    return ({ ...state,
      configuration: undefined,
      user: undefined,
      credentialsError: undefined,
      oauthError: undefined,
      generalError: undefined })
  case RESET_SESSION:
    return { ...state,
      nonce: undefined,
      configuration: undefined,
      user: undefined,
      credentialsError: undefined,
      oauthError: undefined,
      generalError: undefined }
  case REQUEST_CREATE_SESSION:
    return { ...state,
      nonce: undefined,
      configuration: undefined,
      user: undefined,
      credentialsError: undefined,
      oauthError: undefined,
      generalError: undefined }
  case RECEIVE_SESSION:
    return { ...state,
      nonce: action.nonce,
      configuration: action.configuration,
      user: action.user,
      credentialsError: undefined,
      oauthError: undefined,
      generalError: undefined }
  case RECEIVE_CREDENTIALS_SESSION_ERROR:
    return { ...state, credentialsError: action.error }
  case RECEIVE_OAUTH_SESSION_ERROR:
    return { ...state, oauthError: action.error }
  case RECEIVE_SESSION_ERROR:
    return { ...state, generalError: action.error }
  default:
    return { ...state }
  }
}
