export const REQUEST_UPDATE_CENSUS = 'CARRIER_VERIFICATION_REQUEST_UPDATE_CENSUS'
export const REQUEST_CENSUS = 'CARRIER_VERIFICATION_REQUEST_CENSUS'
export const REQUEST_CREATE_CENSUS = 'CARRIER_VERIFICATION_REQUEST_CREATE_CENSUS'
export const RECEIVE_CENSUS = 'CARRIER_VERIFICATION_RECEIVE_CENSUS'
export const RECEIVE_CENSUS_ERROR = 'CARRIER_VERIFICATION_RECEIVE_CENSUS_ERROR'
export const REQUEST_CENSUSES = 'CARRIER_VERIFICATION_REQUEST_CENSUSES'
export const RECEIVE_CENSUSES = 'CARRIER_VERIFICATION_RECEIVE_CENSUSES'
export const REQUEST_ZIP_COUNTIES = 'CARRIER_VERIFICATION_REQUEST_ZIP_COUNTIES'
export const RECEIVE_ZIP_COUNTIES = 'CARRIER_VERIFICATION_RECEIVE_ZIP_COUNTIES'
export const RECEIVE_ZIP_COUNTIES_ERROR = 'CARRIER_VERIFICATION_RECEIVE_ZIP_COUNTIES_ERROR'
export const CLEAR_CENSUSES = 'CARRIER_VERIFICATION_CLEAR_CENSUSES'
export const SET_AUDIENCE = 'CARRIER_VERIFICATION_SET_AUDIENCE'
export const CLEAR_AUDIENCE = 'CARRIER_VERIFICATION_CLEAR_AUDIENCE'
export const REQUEST_PLANS = 'CARRIER_VERIFICATION_REQUEST_PLANS'
export const RECEIVE_PLANS = 'CARRIER_VERIFICATION_RECEIVE_PLANS'
export const RECEIVE_PLANS_ERROR = 'CARRIER_VERIFICATION_RECEIVE_PLANS_ERROR'
export const REQUEST_CENSUS_PLANS = 'CARRIER_VERIFICATION_REQUEST_CENSUS_PLANS'
export const RECEIVE_CENSUS_PLAN = 'CARRIER_VERIFICATION_RECEIVE_CENSUS_PLAN'
export const RECEIVE_CENSUS_PLANS_ERROR = 'CARRIER_VERIFICATION_RECEIVE_CENSUS_PLANS_ERROR'
export const RECEIVE_SCENARIO_FEEDBACK = 'CARRIER_VERIFICATION_RECEIVE_SCENARIO_FEEDBACK'
export const RECEIVE_SCENARIO_FEEDBACK_ERROR = 'CARRIER_VERIFICATION_RECEIVE_SCENARIO_FEEDBACK_ERROR'
export const REQUEST_CREATE_SCENARIO_FEEDBACK = 'CARRIER_VERIFICATION_REQUEST_CREATE_SCENARIO_FEEDBACK'
export const CLEAR_SCENARIO = 'CARRIER_VERIFICATION_CLEAR_SCENARIO'
export const REQUEST_AUDIENCE_CENSUSES = 'CARRIER_VERIFICATION_REQUEST_AUDIENCE_CENSUSES'
export const RECEIVE_AUDIENCE_CENSUSES = 'CARRIER_VERIFICATION_RECEIVE_AUDIENCE_CENSUSES'
