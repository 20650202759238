import {
  CREATE_COPY,
  RECEIVE_COPY,
  RECEIVE_ERROR,
  RESET_REQUEST
} from '../../actions/BenefitsSetsCopies/consts'

const initialState = {
  requestInProgress: false,
  requestComplete: false,
  created: false,
  generalError: null,
  resourceErrors: [],
  attributes: null,
  responseAttributes: null,
  benefits_set_export_id: null,
  responseHeaders: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case CREATE_COPY:
    return {
      ...state,
      requestInProgress: true,
      requestComplete: false,
      created: false,
      attributes: action.attributes
    }
  case RECEIVE_COPY:
    return {
      ...state,
      nonce: action.nonce,
      requestInProgress: false,
      requestComplete: true,
      created: true,
      benefits_set_export_id: action.responseAttributes.benefits_set_export_id,
      responseAttributes: action.responseAttributes,
      responseHeaders: action.responseHeaders
    }
  case RECEIVE_ERROR:
    return {
      ...state,
      requestInProgress: false,
      requestComplete: true,
      created: false,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors
    }
  case RESET_REQUEST:
    return { ...initialState }
  default:
    return { ...state }
  }
}
