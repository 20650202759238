import React from 'react'
import Component from '../common/Component'
import CreateForm, { defaultPropTypes, connectCreateForm } from '../common/CreateForm'
import LabeledTextField from '../common/LabeledTextField'
import actions from '../../actions/CarrierBrands'

export class CarrierBrandCreate extends Component {
  static propTypes = defaultPropTypes

  render() {
    return (
      <CreateForm {...this.props}>
        <LabeledTextField
          key="name"
          name="name"
          label="Name"
        />
      </CreateForm>
    )
  }
}

export default connectCreateForm(CarrierBrandCreate, 'Carrier Brand', 'carrier_brands', actions)
