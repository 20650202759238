import React from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, castArray, camelCase, map } from 'lodash'

import { connect } from 'react-redux'

import Component from './Component'
import RemoteOptionsSelectField from './RemoteOptionsSelectField'

export default class ConnectedRemoteOptionsSelectField extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    indexAction: PropTypes.func.isRequired,
    indexActionStatus: PropTypes.bool.isRequired,
    resetAction: PropTypes.func.isRequired,
    showAction: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    handleChange: PropTypes.func,
    multi: PropTypes.bool,
    placeholder: PropTypes.string,
    recordLabel: PropTypes.array,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    multi: false,
    placeholder: 'Type at least 2 characters to search',
    filterOption: false,
    recordLabel: ['name']
  }

  constructor(props) {
    super(props)
    this.state = { records: [] }

    if (isNil(this.props.value)) {
      this.state.hasLoaded = true
    } else {
      this.setRecordsFromValue()
    }
  }

  setRecordsFromValue() {
    Promise.all(map(castArray(this.props.value), (id) => { return this.props.showAction(id) }))
      .then((collection) => {
        this.setState({
          hasLoaded: true,
          records: map(collection, (data) => { return cloneDeep(data.attributes) })
        })
      })
  }

  render() {
    if (!this.state.hasLoaded) return null
    return (
      <RemoteOptionsSelectField
        {...this.props}
        records={this.state.records}
        recordsRequest={this.props.indexAction}
        requestInProgress={this.props.indexActionStatus}
        reset={this.props.resetAction}
      />
    )
  }
}

export const defaultPropTypes = {
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  indexAction: PropTypes.func.isRequired,
  indexActionStatus: PropTypes.bool.isRequired,
  resetAction: PropTypes.func.isRequired,
  showAction: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ])
}

export function connectRemoteOptionsSelectField(klass, plural, actions) {
  const mapStateToProps = (state) => {
    return {
      indexActionStatus: state[camelCase(plural)].index.requestInProgress,
      record: state[camelCase(plural)].show.attributes,
      records: state[camelCase(plural)].index.records
    }
  }

  const mapDispatchToProps = {
    indexAction: actions.index.main,
    resetAction: actions.index.reset,
    showAction: actions.show.main
  }

  return connect(mapStateToProps, mapDispatchToProps)(klass)
}
