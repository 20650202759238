import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/IssuerDisclaimers'
import connectIndex from '../../lib/connectIndex'

export class IssuerDisclaimerIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['issuer', 'issuer_id']
  }

  static stateDisplay = (record) => (record.issuer.licensed_state_code)

  static columns = [
    {
      key: 'carrier_brand_name',
      label: 'Issuer Carrier Brand Name',
      filterable: false,
      sortable: false
    },
    {
      key: 'issuer.name',
      label: 'Issuer Name',
      filterable: false,
      sortable: false
    },
    {
      key: 'issuer.issuer_id',
      label: 'Issuer ID',
      filterable: false,
      sortable: false
    },
    {
      key: 'licensed_state_id',
      label: 'State',
      filterable: true,
      sortable: false,
      display: IssuerDisclaimerIndex.stateDisplay
    },
    {
      key: 'active_from',
      label: 'Active From',
      filterable: false,
      sortable: true
    },
    {
      key: 'active_until',
      label: 'Active Until',
      filterable: false,
      sortable: true
    },
    {
      key: 'line_of_coverage',
      label: 'Line of Coverage',
      filterable: true,
      sortable: false
    },
    {
      key: 'market',
      label: 'Market',
      filterable: true,
      sortable: false
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/issuer_disclaimers"
          name="Issuer Disclaimer"
          pagination={this.props.pagination}
          columns={IssuerDisclaimerIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Issuer Id or Brand Name"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'licensed_state_id',
    config: 'states'
  },
  {
    key: 'market',
    config: 'issuer_disclaimers.market'
  },
  {
    key: 'line_of_coverage',
    config: 'issuer_disclaimers.line_of_coverage'
  }
]

export default connectIndex(IssuerDisclaimerIndex, 'issuerDisclaimers', actions, filters)
