import React from 'react'

const BooleanCellDisplayRenderer = (record, row) => {
  if (record[row.key]) {
    return (<b>Yes</b>)
  }
  return (<span>No</span>)
}

export default BooleanCellDisplayRenderer
