import { template } from 'lodash'
import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'rates_batch_publishing',
  template('/rates_batches/<%= rates_batch_id %>/publishings')
)

const actions = generator.generate()

export default actions
