import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/MedicareAdvantageBenefitsSets'
import { createFlashMessage } from '../../actions/FlashMessages'
import BenefitsSetEdit from '../BenefitsSet/BenefitsSetEdit'
import MedicareAdvantageBenefitsSetForm from './MedicareAdvantageBenefitsSetForm'
import issuerActions from '../../actions/Issuers'
import serviceAreaActions from '../../actions/ServiceAreas'
import historyActions from '../../actions/BenefitsSetFieldHistories'

const mapStateToProps = (state) => {
  const slice = state.medicareAdvantageBenefitsSets.update
  const showSlice = state.medicareAdvantageBenefitsSets.show
  const remoteOptionsSelectStates = {
    issuers: state.issuers.index,
    service_areas: state.serviceAreas.index
  }
  const configuration = state.login.session.configuration.medicare_advantage_benefits

  const historySlice = state.benefitsSetFieldHistory.index

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,
    requestComplete: slice.requestComplete,
    configuration,
    remoteOptionsSelectStates,

    benefitsSetFieldHistory: historySlice.records,
    benefitsSetForm: MedicareAdvantageBenefitsSetForm,
    benefitsSetUrl: 'med_adv_benefits',
    productLine: 'Medicare Advantage'
  }
}

const mapDispatchToProps = (dispatch) => ({
  update: bindActionCreators(actions.update.main, dispatch),
  show: bindActionCreators(actions.show.main, dispatch),
  reset: bindActionCreators(actions.update.reset, dispatch),
  showReset: bindActionCreators(actions.show.reset, dispatch),
  history: bindActionCreators(historyActions.index.main, dispatch),
  resetHistory: bindActionCreators(historyActions.index.reset, dispatch),

  remoteOptionsSelectActions: {
    serviceAreasIndex: {
      main: bindActionCreators(serviceAreaActions.index.main, dispatch),
      reset: bindActionCreators(serviceAreaActions.index.reset, dispatch)
    },
    issuersIndex: {
      main: bindActionCreators(issuerActions.index.main, dispatch),
      reset: bindActionCreators(issuerActions.index.reset, dispatch)
    }
  },

  createFlashMessage
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetEdit)
