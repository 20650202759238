import React from 'react'
import PropTypes from 'prop-types'
import { IoIosClose } from 'react-icons/io'
import Modal from '../common/Modal'
import Component from '../common/Component'

export default class BulkPublishModal extends Component {
  static propTypes = {
    handleClose: PropTypes.func,
    handlePublish: PropTypes.func,
    disabled: PropTypes.bool
  }

  render() {
    return (
      <Modal
        className="bulk-publish-modal"
        handleClose={this.props.handleClose}
      >
        {this.props.children}
        <button
          className="select-button pure-button pure-input-1 pure-button-primary"
          type="button"
          disabled={this.props.disabled}
          onClick={this.props.handlePublish}
        >
Publish
        </button>
        <button
          className="cancel-button"
          type="button"
          onClick={this.props.handleClose}
        >
          <IoIosClose />
        </button>
      </Modal>
    )
  }
}
