import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isNil } from 'lodash'
import { createFlashMessage } from '../../actions/FlashMessages'
import Component from '../common/Component'
import actions from '../../actions/SmokeChecks'
import alert from '../../alert'

export class SmokeCheckRun extends Component {
  static propTypes = {
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.string,
    createFlashMessage: PropTypes.func.isRequired,
    ipaId: PropTypes.number
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleClick = () => {
    this.props
      .create({ issuer_period_audience_id: this.props.ipaId })
      .then(({ id }) => {
        this.props.createFlashMessage('Smoke Check is running', { id: parseInt(id, 10), tag: 'general' })
      })
  }

  render() {
    const {
      generalError,
      resourceErrors
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }
    if (!isNil(resourceErrors)) {
      alert(resourceErrors)
    }

    return (
      <button
        type="button"
        className="link-button"
        title="Run"
        onClick={this.handleClick}
      >
          Run
      </button>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.smokeChecks.create

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    id: slice.id,
    created: slice.created
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SmokeCheckRun)
