import React from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, every, keys, clone } from 'lodash'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Component from '../common/Component'
import SubmitButton from '../common/SubmitButton'
import actions from '../../actions/AncillaryRateFactorSets'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'
import RateFactorDocumentCreate from './RateFactorDocumentCreate'
import LabeledTextField from '../common/LabeledTextField'

export class AncillaryRateFactorSetEdit extends Component {
  static propTypes = {
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    createFlashMessage: PropTypes.func.isRequired,
    updated: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resourceErrors: PropTypes.array,
    rateFactorSet: PropTypes.object,
    showRateFactorSetAction: PropTypes.func,
    requestInProgress: PropTypes.bool
  }

  static defaultProps = {
    resourceErrors: []
  }

  constructor(props) {
    super(props)
    this.state = { values: { rate_factors: {}, composite: false } }
  }

  componentDidMount() {
    const { showRateFactorSetAction } = this.props
    showRateFactorSetAction(this.id)
  }

  componentDidUpdate(prevProps) {
    const { rateFactorSet } = this.props

    if (rateFactorSet !== prevProps.rateFactorSet) {
      this.initializeRateFactors()
    }
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
  }

  get id() {
    return this.props.match.params.id
  }

  get shouldRender() {
    return this.props.rateFactorSet
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newValues = clone(prevState.values)

      newValues[field] = value
      return { values: newValues }
    })
  }

  handleRateFactorChange = (rateFactorKey, value) => {
    const { rateFactorSet } = this.props

    this.setState((prevState) => {
      const newValues = cloneDeep(prevState.values)
      newValues.name = rateFactorSet.name
      newValues.formula = rateFactorSet.formula
      newValues.composite = rateFactorSet.composite
      newValues.rate_factors[rateFactorKey] = value

      return { values: newValues }
    })
  }

  handleSubmit = async (values) => {
    values.preventDefault()

    const response = await this.props.update(this.id, this.createParams())

    if (isNil(response.resourceErrors)) {
      this.props.createFlashMessage('Created Rate Factor Documents', { tag: 'general' })
    }
  }

  showErrors = () => {
    const errors = this.props.resourceErrors.map((error, index) => (
      <div className="howie_error" key={`error-${index}`}>{error}</div>
    ))

    return errors
  }

  createParams() {
    const params = clone(this.state.values)
    params.rate_factors = keys(params.rate_factors).map((key) => (
      {
        formula_key: key,
        blob_id: params.rate_factors[key].blob_id
      }
    )).filter((el) => (el.blob_id))

    return params
  }

  submitDisabled() {
    return this.incompleteRateFactor()
  }

  initializeRateFactors() {
    const { rateFactorSet } = this.props
    const rateFactorFormulaKeys = rateFactorSet.formula.match(/[A-Za-z0-9_]+/g) || []

    this.setState((prevState) => {
      const newValues = cloneDeep(prevState.values)
      const rateFactors = this.state.values.rate_factors

      newValues.rate_factors = {}

      rateFactorFormulaKeys.forEach((formulaKey) => {
        newValues.rate_factors[formulaKey] = rateFactors[formulaKey] || {}
      })

      return { values: newValues }
    })
  }

  rateFactors() {
    return (
      Object.keys(this.state.values.rate_factors).map((key) => (
        <RateFactorDocumentCreate
          key={key}
          name={key}
          value={this.state.values.rate_factors[key]}
          handleChange={this.handleRateFactorChange}
        />
      ))
    )
  }

  incompleteRateFactor() {
    return every(keys(this.state.values.rate_factors), (key) => (
      isNil(this.state.values.rate_factors[key].blob_id)
    ))
  }

  redirect(id) {
    return <Redirect push to={`/ancillary_rate_factor_sets/${id}`} />
  }

  render() {
    const {
      updated,
      rateFactorSet
    } = this.props

    if (!this.shouldRender) return null

    if (updated) {
      return this.redirect(this.id)
    }

    return (
      <div className="content">
        <h2>Add Rate Factors</h2>
        <div>
          <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            {this.props.resourceErrors.length > 0 && this.showErrors()}
            <fieldset>
              <LabeledTextField
                key="name"
                name="name"
                label="Rate Factor Set Name"
                disabled
                value={rateFactorSet.name}
              />
              <LabeledTextField
                key="vericred_id"
                name="vericred_id"
                label="Rate Factor Set ID"
                disabled
                value={rateFactorSet.vericred_id}
              />
              <LabeledTextField
                key="formula"
                name="formula"
                label="Formula"
                disabled
                value={rateFactorSet.formula}
              />
              {this.rateFactors()}
            </fieldset>
            <fieldset>
              <SubmitButton
                title="Submit"
                text="Submit"
                loading={this.props.requestInProgress}
                disabled={this.submitDisabled()}
              />
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.ancillaryRateFactorSets.update
  const showSlice = state.ancillaryRateFactorSets.show
  const configuration = state.login.session.configuration.ancillary_rate_factor_sets
  return { requestInProgress: slice.requestInProgress,
    updated: slice.updated,
    rateFactorSet: showSlice.attributes,
    resourceErrors: slice.resourceErrors,
    configuration }
}

const mapDispatchToProps = {
  update: actions.update.main,
  reset: actions.update.reset,
  showReset: actions.show.reset,
  showRateFactorSetAction: actions.show.main,
  createFlashMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(AncillaryRateFactorSetEdit)
