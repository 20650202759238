import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from '../common/Component'
import benefitsSetsActions from '../../actions/MedicalBenefitsSets'
import BenefitsSetCopy from '../BenefitsSet/BenefitsSetCopy'
import fields from './fields'
import benefitGroups from './benefitGroups.json'

class MedicalBenefitsSetCopy extends Component {
  render() {
    return (
      <BenefitsSetCopy
        productLine="Medical"
        benefitsSetExportPath="medical_benefits_exports"
        metadataFields={fields['Plan Info'].metadatum}
        benefitsFields={benefitGroups}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    benefitsSetsRequestInProgress: state.medicalBenefitsSets.create.requestInProgress,
    benefitsSets: state.medicalBenefitsSets.index.records
  }
}

const mapDispatchToProps = (dispatch) => ({
  indexBenefitsSets: bindActionCreators(benefitsSetsActions.index.main, dispatch),
  resetBenefitsSets: bindActionCreators(benefitsSetsActions.index.reset, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MedicalBenefitsSetCopy)
