import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, get, isNil, findIndex } from 'lodash'
import Component from '../common/Component'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'
import RadioButtonSet from '../common/RadioButtonSet'
import RemoteOptionsSelectField from '../common/RemoteOptionsSelectField'
import SubmitButton from '../common/SubmitButton'
import ConnectedFileUploaderComponent from '../common/ConnectedFileUploaderComponent'

export default class IssuerForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    configuration: PropTypes.object,
    states: PropTypes.array,
    initialValues: PropTypes.object.isRequired,
    requestInProgress: PropTypes.bool,
    submitLabel: PropTypes.string,
    indexCarriers: PropTypes.func.isRequired,
    resetCarriers: PropTypes.func.isRequired,
    carriersRequestInProgress: PropTypes.bool.isRequired,
    carriers: PropTypes.array,
    indexCarrierBrands: PropTypes.func.isRequired,
    resetCarrierBrands: PropTypes.func.isRequired,
    carrierBrandsRequestInProgress: PropTypes.bool.isRequired,
    carrierBrands: PropTypes.array
  }

  static defaultProps = {
    requestInProgress: false,
    configuration: {},
    states: [],
    errors: {},
    submitLabel: 'Create Issuer',
    carriers: [],
    carrierBrands: []
  }

  static contextTypes = {
    attributeInaccessible: PropTypes.func
  }

  constructor(props) {
    super(props)

    const initialValues = cloneDeep(props.initialValues)

    this.state = {
      values: initialValues
    }
  }

  get values() {
    return cloneDeep(this.state.values)
  }

  get submitButton() {
    return (
      <SubmitButton
        title={this.props.submitLabel}
        text={this.props.submitLabel}
        loading={this.props.requestInProgress}
      />
    )
  }

  get alternateCarrierBrandAudiences() {
    return [{ text: 'Individual', value: 'individual' }, { text: 'Small Group', value: 'small_group' }]
  }

  get alternateCarrierBrandAudienceIndex() {
    if (isNil(this.state.values.alternate_carrier_brand_id)) { return -1 }

    return (
      findIndex(
        this.alternateCarrierBrandAudiences, (audience) => {
          return audience.value === this.state.values.alternate_carrier_brand_audience
        }
      )
    )
  }

  get isAudienceDisabled() {
    return (
      isNil(this.state.values.alternate_carrier_brand_id)
        || this.context.attributeInaccessible('alternate_carrier_brand_audience')
    )
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)

      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        newState[field] = value
      }

      if (isNil(newState.alternate_carrier_brand_id)) { newState.alternate_carrier_brand_audience = null }

      return { values: newState }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.handleSubmit(this.values)
  }

  render() {
    const {
      errors,
      configuration,
      states
    } = this.props

    return (
      <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
        <fieldset>
          <LabeledTextField
            key="name"
            name="name"
            label="Name"
            handleChange={this.handleChange}
            errorMessage={errors.name}
            value={this.state.values.name}
            disabled={this.context.attributeInaccessible('name')}
          />
          <RemoteOptionsSelectField
            key="carrier_brand_id"
            name="carrier_brand_id"
            label="Carrier Brand"
            handleChange={this.handleChange}
            errorMessage={errors.carrier_brand_id}
            value={this.state.values.carrier_brand_id}
            records={this.props.carrierBrands}
            reset={this.props.resetCarrierBrands}
            recordsRequest={this.props.indexCarrierBrands}
            requestInProgress={this.props.carrierBrandsRequestInProgress}
            disabled={this.context.attributeInaccessible('carrier_brand_id')}
          />
          <div>
            <RemoteOptionsSelectField
              key="alternate_carrier_brand_id"
              name="alternate_carrier_brand_id"
              label="Alternate Carrier Brand"
              handleChange={this.handleChange}
              errorMessage={errors.alternate_carrier_brand_id}
              value={this.state.values.alternate_carrier_brand_id}
              records={this.props.carrierBrands}
              reset={this.props.resetCarrierBrands}
              recordsRequest={this.props.indexCarrierBrands}
              requestInProgress={this.props.carrierBrandsRequestInProgress}
              disabled={this.context.attributeInaccessible('alternate_carrier_brand_id')}
            />

            <RadioButtonSet
              key="alternate_carrier_brand_audience"
              name="alternate_carrier_brand_audience"
              values={this.alternateCarrierBrandAudiences}
              handleChange={this.handleChange}
              defaultIndex={this.alternateCarrierBrandAudienceIndex}
              errorMessage={errors.alternate_carrier_brand_audience}
              disabled={this.isAudienceDisabled}
              hidden={false}
              styles={{ marginTop: '-10px', marginBottom: '20px' }}
            />
          </div>
          <RemoteOptionsSelectField
            key="carrier_id"
            name="carrier_id"
            label="Carrier"
            handleChange={this.handleChange}
            errorMessage={errors.carrier_id}
            value={this.state.values.carrier_id}
            records={this.props.carriers}
            reset={this.props.resetCarriers}
            recordsRequest={this.props.indexCarriers}
            requestInProgress={this.props.carriersRequestInProgress}
            disabled={this.context.attributeInaccessible('carrier_id')}
          />
          <SelectField
            key="licensed_state_id"
            name="licensed_state_id"
            label="Licensed State"
            options={states}
            handleChange={this.handleChange}
            errorMessage={errors.licensed_state_id}
            value={this.state.values.licensed_state_id}
            disabled={this.context.attributeInaccessible('licensed_state_id')}
          />
          <ConnectedFileUploaderComponent
            key="logo_blob_id"
            name="logo_blob_id"
            label="Logo"
            handleChange={this.handleChange}
            errorMessage={errors.logo_blob_id}
            acceptedMimeTypes="image/png, image/jpeg, image/gif"
            previewUrl={get(this.state.values, 'logo.public_url')}
            filename={get(this.state.values, 'logo.filename')}
            value={get(this.state.values, 'logo_blob_id')}
            disabled={this.context.attributeInaccessible('logo_blob_id')}
          />
          <SelectField
            key="issuer_id_type"
            name="issuer_id_type"
            label="Issuer ID Type"
            options={configuration.issuer_id_type}
            handleChange={this.handleChange}
            errorMessage={errors.issuer_id_type}
            value={this.state.values.issuer_id_type}
            disabled={this.context.attributeInaccessible('issuer_id_type')}
          />
          <LabeledTextField
            key="issuer_id"
            name="issuer_id"
            label="Issuer ID"
            handleChange={this.handleChange}
            errorMessage={errors.issuer_id}
            value={this.state.values.issuer_id}
            disabled={this.context.attributeInaccessible('issuer_id')}
          />
          <LabeledTextField
            key="address_line_1"
            name="address_line_1"
            label="Address Line 1"
            handleChange={this.handleChange}
            errorMessage={errors.address_line_1}
            value={this.state.values.address_line_1}
            disabled={this.context.attributeInaccessible('address_line_1')}
          />
          <LabeledTextField
            key="address_line_2"
            name="address_line_2"
            label="Address Line 2"
            handleChange={this.handleChange}
            errorMessage={errors.address_line_2}
            value={this.state.values.address_line_2}
            disabled={this.context.attributeInaccessible('address_line_2')}
          />
          <LabeledTextField
            key="city"
            name="city"
            label="City"
            handleChange={this.handleChange}
            errorMessage={errors.city}
            value={this.state.values.city}
            disabled={this.context.attributeInaccessible('city')}
          />
          <SelectField
            key="state_id"
            name="state_id"
            label="State"
            options={states}
            handleChange={this.handleChange}
            errorMessage={errors.state_id}
            value={this.state.values.state_id}
            disabled={this.context.attributeInaccessible('state_id')}
          />
          <LabeledTextField
            key="zip_code"
            name="zip_code"
            label="Zip Code"
            handleChange={this.handleChange}
            errorMessage={errors.zip_code}
            value={this.state.values.zip_code}
            disabled={this.context.attributeInaccessible('zip_code')}
          />
        </fieldset>
        <fieldset>
          {this.submitButton}
        </fieldset>
      </form>
    )
  }
}
