import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AgGridReact } from 'ag-grid-react'
import { map, isNil, keys, startCase, get, intersection } from 'lodash'
import publishingActions from '../../actions/RatesBatchPublishings'
import ratesBatchesActions from '../../actions/RatesBatches'
import SimpleDiffCellRenderer from '../common/SimpleDiffCellRenderer'

export class RatesBatchExport extends Component {
  static propTypes = {
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool.isRequired,
    diffSetRequestInProgress: PropTypes.bool.isRequired,
    created: PropTypes.bool.isRequired,
    match: PropTypes.object,
    results: PropTypes.object
  }

  static infoColumns = [
    'row_number',
    'hios_id',
    'rating_area_natural_key',
    'effective_date',
    'expiration_date',
    'age'
  ]

  static diffColumns = [
    'tobacco',
    'price',
    'single',
    'single_and_spouse',
    'single_and_children',
    'family',
    'child_only'
  ]

  componentDidMount() {
    this.props.show(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi

    this.autoSizeAll()
  }

  get resultsReady() {
    if (isNil(this.props.results)) {
      return false
    }

    return get(this.props, 'results.id') === parseInt(this.props.match.params.id, 10)
  }

  get saveButton() {
    if (this.props.created) {
      return null
    }

    return (
      <button
        className="pure-button pure-input-1 pure-button-primary"
        title="Save"
        type="submit"
        disabled={this.props.requestInProgress || this.props.diffSetRequestInProgress}
        onClick={this.handleSave}
      >
Save
      </button>
    )
  }

  get redirect() {
    if (!this.props.created) {
      return null
    }

    return <Redirect push to="/sheet_rates" />
  }

  get draftRatesDiff() {
    return get(this.props, 'results.draft_rates_diff')
  }

  get columnDefs() {
    return map(intersection(RatesBatchExport.infoColumns, keys(this.draftRatesDiff[0])), (fieldName) => {
      return {
        field: fieldName,
        headerName: startCase(fieldName)
      }
    }).concat(map(intersection(RatesBatchExport.diffColumns, keys(this.draftRatesDiff[0])), (fieldName) => {
      return {
        field: fieldName,
        headerName: startCase(fieldName),
        cellRendererFramework: SimpleDiffCellRenderer,
        cellClass: 'ag-grid-cell-diff text-center'
      }
    }))
  }

  get diffGrid() {
    if (!this.resultsReady) {
      return null
    }

    return (
      <div className="ag-theme-balham data-grid">
        <AgGridReact
          columnDefs={this.columnDefs}
          rowData={this.draftRatesDiff}
          onGridReady={this.onGridReady}
          animateRows
          enableFilter
          enableSorting
          suppressAggFuncInHeader
          suppressColumnVirtualisation
          enableColResize
          enableRangeSelection
          toolPanelSuppressRowGroups
          toolPanelSuppressValues
          toolPanelSuppressPivots
          toolPanelSuppressPivotMode
        />
      </div>
    )
  }

  rowNodeId = (data) => {
    return data.row_number
  }

  autoSizeAll = () => {
    const allColumnIds = []

    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId)
    })

    this.gridColumnApi.autoSizeColumns(allColumnIds)
  }

  handleSave = (event) => {
    event.preventDefault()

    this.props.create(null, { rates_batch_id: this.props.match.params.id })
  }

  render() {
    return (
      <div className="content">
        <header className="main-header">
          <div className="header-actions">
            <div className="header-actions">
              {this.saveButton}
              {this.redirect}
            </div>
          </div>
        </header>
        {this.diffGrid}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.ratesBatchPublishings.create
  const showSlice = state.ratesBatches.show

  return {
    diffSetRequestInProgress: showSlice.requestInProgress,
    requestInProgress: slice.requestInProgress,
    created: slice.created,
    results: showSlice.attributes
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  show: ratesBatchesActions.show.main,
  showReset: ratesBatchesActions.show.reset,
  create: publishingActions.create.main,
  reset: publishingActions.create.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RatesBatchExport)
