import { isArray, some, isNil, isEmpty } from 'lodash'

export default function (attributes) {
  return some(requiredFields(attributes), (field) => {
    const value = attributes[field]
    return isNil(value) || (isArray(value) && isEmpty(value))
  })
}

function requiredFields(attributes) {
  const alwaysRequired = [
    'state_id',
    'plan_audiences',
    'source',
    'blob_id',
    'product_line',
    'requires_manual_build',
    'year_quarters'
  ]
  return issuersRequired(attributes) ? alwaysRequired.concat(['issuers']) : alwaysRequired
}

function issuersRequired(attributes) {
  const sourceExceptionsForIssuers = [
    'PUF_FFM',
    'PUF_SBM',
    'URR',
    'Unclear'
  ]
  return !sourceExceptionsForIssuers.includes(attributes.source)
}
