import { get, has, isNil, cloneDeep, range } from 'lodash'

export default class BenefitsInitializer {
  constructor(values, tiersCount, benefitNames) {
    this.values = values || []
    this.tiersCount = tiersCount
    this.benefitNames = benefitNames
  }

  initialize = () => this.benefitNames.map((name) => {
    let benefit = this.values.find((b) => b.name === name)

    if (isNil(benefit)) {
      benefit = {
        name,
        value: name,
        limit: '',
        tiers: []
      }
    }

    return this.assignTiersToBenefit(benefit)
  })

  generateMainTiers = (orderedTiers) => [
    orderedTiers[0] || { option: 'In Network', value: '' },
    orderedTiers[1] || { option: 'Out of Network', value: '' }
  ]

  generateAdditionalTiers = (orderedTiers) => {
    const newTiers = []

    range(2, this.tiersCount).forEach((i) => {
      let tier = orderedTiers[i]

      if (isNil(tier)) {
        tier = { option: `In Network Tier ${i}`, value: '' }
      }

      delete tier._destroy

      newTiers.push(tier)
    })

    return newTiers
  }

  generateDeletingTiers = (orderedTiers) => {
    const newTiers = []

    range(this.tiersCount, orderedTiers.length).forEach((i) => {
      const tier = orderedTiers[i]

      if (!has(tier, 'id')) {
        return
      }

      tier._destroy = true
      newTiers.push(tier)
    })

    return newTiers
  }

  orderTiers = (tiers) => {
    const newTiers = [
      tiers.find((t) => t.option === 'In Network'),
      tiers.find((t) => t.option === 'Out of Network')
    ]

    return newTiers.concat(tiers.filter((t) => t.option !== 'In Network' && t.option !== 'Out of Network'))
  }

  assignTiersToBenefit = (initialBenefit) => {
    const benefit = cloneDeep(initialBenefit)

    const tiers = this.orderTiers(get(benefit, 'tiers', []))

    let newTiers = this.generateMainTiers(tiers)
    newTiers = newTiers.concat(this.generateAdditionalTiers(tiers))
    newTiers = newTiers.concat(this.generateDeletingTiers(tiers))

    benefit.tiers = newTiers

    return benefit
  }
}
