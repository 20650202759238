import MethodReducerGenerator from './MethodReducerGenerator'

export default class CreateReducerGenerator extends MethodReducerGenerator {
  constructor(name, basePath) {
    super('CREATE', name, basePath)
  }

  static initialState = {
    requestInProgress: false,
    requestComplete: false,
    created: false,
    generalError: undefined,
    resourceErrors: undefined,
    attributes: undefined,
    responseAttributes: undefined,
    id: undefined,
    responseHeaders: undefined
  }

  requestHandler(state, action) {
    return { ...state,
      requestInProgress: true,
      requestComplete: false,
      created: false,
      attributes: action.attributes }
  }

  responseHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      requestComplete: true,
      created: true,
      id: action.id,
      responseAttributes: action.responseAttributes,
      responseHeaders: action.responseHeaders }
  }

  errorHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      requestComplete: true,
      created: false,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors }
  }
}
