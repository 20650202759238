import React from 'react'

import actions from '../../actions/IssuerStatuses'
import IssuerStatusIndex from '../IssuerStatuses/IssuerStatusIndex'

import Component from './Component'
import ConnectedRemoteOptionsSelectField,
{ connectRemoteOptionsSelectField, defaultPropTypes }
  from './ConnectedRemoteOptionsSelectField'

export class IssuerStatusRemoteOptionsSelectField extends Component {
  static propTypes = defaultPropTypes

  static defaultProps = {
    label: 'Issuer Status',
    multi: false,
    name: 'issuer_status_id',
    title: 'issuer_status_id',
    searchColumns: IssuerStatusIndex.searchColumns,
    filterOption: (candidate, input) => {
      let status = false
      if (input) {
        candidate.label.split(' - ').forEach((index) => {
          if (input.toLowerCase().includes(index.toLowerCase())) {
            status = true
          }
        })
      }
      return status
    }
  }

  render() {
    return (
      <ConnectedRemoteOptionsSelectField {...this.props} />
    )
  }
}

export default connectRemoteOptionsSelectField(IssuerStatusRemoteOptionsSelectField, 'issuer_statuses', actions)
