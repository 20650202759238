import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/MedicalBenefitsSets'
import { createFlashMessage } from '../../actions/FlashMessages'
import BenefitsSetEdit from '../BenefitsSet/BenefitsSetEdit'
import MedicalBenefitsSetForm from './MedicalBenefitsSetForm'
import howieServiceAreaActions from '../../actions/HowieServiceAreas'
import historyActions from '../../actions/BenefitsSetFieldHistories'

const mapStateToProps = (state) => {
  const slice = state.medicalBenefitsSets.update
  const showSlice = state.medicalBenefitsSets.show

  const historySlice = state.benefitsSetFieldHistory.index
  const configuration = state.login.session.configuration.benefits

  const remoteOptionsSelectStates = {
    howie_service_areas: state.howieServiceAreas.index,
    medical_benefits: state.medicalBenefitsSets.index
  }

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,
    requestComplete: slice.requestComplete,
    configuration,
    remoteOptionsSelectStates,
    benefitsSetFieldHistory: historySlice.records,
    benefitsSetForm: MedicalBenefitsSetForm,
    benefitsSetUrl: 'medical_benefits',
    productLine: 'Medical'
  }
}

const mapDispatchToProps = (dispatch) => ({
  update: bindActionCreators(actions.update.main, dispatch),
  show: bindActionCreators(actions.show.main, dispatch),
  reset: bindActionCreators(actions.update.reset, dispatch),
  showReset: bindActionCreators(actions.show.reset, dispatch),
  history: bindActionCreators(historyActions.index.main, dispatch),
  resetHistory: bindActionCreators(historyActions.index.reset, dispatch),

  remoteOptionsSelectActions: {
    howieServiceAreasIndex: {
      main: bindActionCreators(howieServiceAreaActions.index.main, dispatch),
      reset: bindActionCreators(howieServiceAreaActions.index.reset, dispatch)
    },
    medicalBenefitsSetsIndex: {
      main: bindActionCreators(actions.index.main, dispatch),
      reset: bindActionCreators(actions.index.reset, dispatch)
    }
  },

  createFlashMessage
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetEdit)
