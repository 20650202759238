import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'quote_rate_member_rates',
  '/plans/quote_rate_member_rates',
  'quote_rate_member_rates'
)
const actions = generator.generate()

export default actions
