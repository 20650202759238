import createSession from './createSession'

import { RECEIVE_CREDENTIALS_SESSION_ERROR } from './consts'

function receiveCredentialsSessionError(error) {
  return { type: RECEIVE_CREDENTIALS_SESSION_ERROR, error }
}

export default function createCredentialsSession(username, password, redirectPath) {
  return createSession({ username, password }, receiveCredentialsSessionError, redirectPath)
}
