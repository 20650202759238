import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil, times, get, isString } from 'lodash'
import Component from '../common/Component'
import HeaderRow from './BenefitsSetHeaderRow'
import UserContext from './BenefitsSetUserContext'
import ExternalIdsComponent from './BenefitsSetExternalIdsComponent'
import BenefitsComponent from './BenefitsSetBenefitsComponent'
import FieldDefinition from './BenefitsSetFieldDefinition'
import BenefitsSetMetadatumComponent from './BenefitsSetMetadatumComponent'
import HistoryModal from './HistoryModal'
import { BenefitsSetIndex } from './BenefitsSetIndex'

export default class BenefitsSetComponent extends Component {
  static propTypes = {
    fieldGroups: PropTypes.object,
    infoFields: PropTypes.object,
    externalIdField: PropTypes.object,
    benefitNames: PropTypes.array,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleReviewChange: PropTypes.func,
    showHistory: PropTypes.bool,
    handleTiersCountChange: PropTypes.func,
    errors: PropTypes.object,
    benefitsSet: PropTypes.object.isRequired,
    review: PropTypes.object,
    rejections: PropTypes.array,
    displayValidFields: PropTypes.bool,
    configuration: PropTypes.object.isRequired,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    tiersCount: PropTypes.number.isRequired,
    remoteOptionsSelectStates: PropTypes.object,
    remoteOptionsSelectActions: PropTypes.object,
    benefitsSetFieldHistory: PropTypes.object,
    resetHistory: PropTypes.func,
    history: PropTypes.func
  }

  static renderedProps = [
    'errors',
    'benefitsSet',
    'rejections',
    'displayValidFields',
    'configuration',
    'userContext',
    'tiersCount',
    'review',
    'remoteOptionsSelectStates',
    'remoteOptionsSelectActions',
    'benefitsSetFieldHistory'
  ]

  get planInfoRows() {
    const reviews = get(this.props.review, 'metadatum_review', {})
    return (
      <BenefitsSetMetadatumComponent
        key="metadatum"
        name="metadatum"
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        totalColumns={this.totalColumns}
        handleReviewChange={this.handleReviewChange}
        handleHistory={this.handleMetadatumHistory}
        showHistory={this.props.showHistory}
        metadatum={this.metadatumValues}
        reviews={reviews}
        fieldDefinition={this.props.infoFields.metadatum}
        rejections={this.rejectionsForName('metadatum_review') || []}
        errors={this.props.errors}
        configuration={this.props.configuration}
        userContext={this.props.userContext}
        remoteOptionsSelectStates={this.props.remoteOptionsSelectStates}
        remoteOptionsSelectActions={this.props.remoteOptionsSelectActions}
      />
    )
  }

  get additionalTiersCount() {
    return 2 - this.props.tiersCount
  }

  get totalColumns() {
    return 2 + this.props.tiersCount
  }

  get rejections() {
    return this.props.rejections || []
  }

  get metadatumValues() {
    return this.props.benefitsSet.metadatum || {}
  }

  get externalIdValues() {
    return this.props.benefitsSet.plan_external_ids || []
  }

  get externalIdReviews() {
    return get(this.props.review, 'plan_external_id_reviews', [])
  }

  get externalIdFieldDefinition() {
    return new FieldDefinition(
      'plan_external_ids',
      this.props.externalIdField,
      this.externalIdValues,
      this.errorForName('plan_external_ids'),
      this.props.benefitsSet,
      this.props.configuration,
      this.props.userContext
    )
  }

  get fieldCols() {
    return times(this.totalColumns - 1).map((index) => (
      <col key={`field-column-${index}`} className="field-column" />
    ))
  }

  get colGroup() {
    return (
      <colgroup>
        <col className="label-column" />
        {this.fieldCols}
      </colgroup>
    )
  }

  get benefitRows() {
    if (isEmpty(this.props.benefitNames)) {
      return null
    }

    const reviews = get(this.props.review, 'benefit_reviews', [])
    return (
      <BenefitsComponent
        key="benefits-rows"
        name="benefits"
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleReviewChange={this.handleReviewChange}
        handleTiersCountChange={this.props.handleTiersCountChange}
        handleHistory={this.handleBenefitHistory}
        showHistory={this.props.showHistory}
        benefits={this.infoValueForName('benefits')}
        benefitsSet={this.props.benefitsSet}
        reviews={reviews}
        benefitNames={this.props.benefitNames}
        fieldGroups={this.props.fieldGroups}
        rejections={this.rejectionsForName('benefit_reviews')}
        errors={this.errorForName('benefits')}
        displayValidFields={this.props.displayValidFields}
        tiersCount={this.props.tiersCount}
        configuration={this.props.configuration}
        userContext={this.props.userContext}
        headerKey="header-benefits"
      />
    )
  }

  get externalIdRows() {
    let errors = this.errorForName('plan_external_ids')

    if (isString(errors)) {
      errors = { base: errors }
    }

    return (
      <ExternalIdsComponent
        key="plan-external-id-rows"
        name="plan_external_ids"
        label="External IDs"
        errors={errors}
        reviews={this.externalIdReviews}
        rejections={this.rejectionsForName('plan_external_id_reviews')}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleReviewChange={this.handleReviewChange}
        totalColumns={this.totalColumns}
        userContext={this.props.userContext}
        fieldDefinition={this.externalIdFieldDefinition}
        benefitsSet={this.props.benefitsSet}
      />
    )
  }

  get ancillaryRateFactorRow() {
    if (isNil(this.props.benefitsSet.ancillary_rate_factor_set_id)) {
      return null
    }
    const colSpan = this.totalColumns - 1
    return (
      <tbody id="ancillary-rate-factor-rows">
        <HeaderRow
          key="header-rate-factor-id"
          labels={['Rate Factor Set']}
          totalColumns={this.totalColumns}
        />
        <tr>
          <td />
          <td colSpan={colSpan || 2}>
            { BenefitsSetIndex.showRateFactorSetLinkDisplay(this.props.benefitsSet) }
          </td>
        </tr>
      </tbody>
    )
  }

  get ancillaryServiceAreaRow() {
    if (isNil(this.props.benefitsSet.ancillary_service_area_id)) {
      return null
    }
    const colSpan = this.totalColumns - 1
    return (
      <tbody id="ancillary-service-area-rows">
        <HeaderRow
          key="header-service-area-id"
          labels={['Service Area']}
          totalColumns={this.totalColumns}
        />
        <tr>
          <td />
          <td colSpan={colSpan || 2}>
            { BenefitsSetIndex.showServiceAreaLinkDisplay(this.props.benefitsSet) }
          </td>
        </tr>
      </tbody>
    )
  }

  get rows() {
    return [
      this.planInfoRows,
      this.ancillaryRateFactorRow,
      this.ancillaryServiceAreaRow,
      this.externalIdRows,
      this.benefitRows
    ]
  }

  handleChange = (name, value) => {
    this.props.handleChange(name, value)
  }

  handleBlur = (name) => {
    this.props.handleBlur(name)
  }

  handleReviewChange = (name, value) => {
    this.props.handleReviewChange(name, value)
  }

  handleMetadatumHistory= (name) => {
    this.handleHistory(name, 'metadata')
  }

  handleBenefitHistory= (name, benefitName) => {
    this.handleHistory(name, 'benefits', benefitName)
  }

  errorForName = (name) => get(this.props.errors, name)

  reviewForName = (name) => get(this.props.review, name)

  infoValueForName = (name) => this.props.benefitsSet[name]

  rejectionsForName = (name) => this.rejections.filter((r) => !isNil(get(r, name))).map((r) => ({
    review_id: r.id,
    created_at: new Date(r.created_at),
    review: r[name]
  }))

  externalIdReviewForIndex = (index) => {
    const reviews = get(this.props.review, 'plan_external_id_reviews', [])

    if (isNil(reviews)) {
      return null
    }

    const value = reviews[index]

    if (isNil(value)) {
      return null
    }

    return value.note
  }

  handleHistory = (key, type, benefitName) => {
    this.props.resetHistory()
    this.props.history({ id: this.props.benefitsSet.id, field_type: type, benefit_name: benefitName, field: key })
  }

  handleHistoryModalClose = () => {
    this.props.resetHistory()
  }

  historyModal = () => {
    if (isNil(this.props.benefitsSetFieldHistory)) {
      return null
    }

    return (
      <HistoryModal
        fieldGroups={this.props.fieldGroups}
        history={this.props.benefitsSetFieldHistory}
        handleClose={this.handleHistoryModalClose}
        configuration={this.props.configuration}
        benefitsSet={this.props.benefitsSet}
      />
    )
  }

  headerRow(labels) {
    return (
      <HeaderRow
        key={`header-${labels[0]}`}
        labels={labels}
        totalColumns={this.totalColumns}
      />
    )
  }

  render() {
    return (
      <div>
        <table className="pure-table pure-table-bordered benefits-set-table">
          {this.colGroup}
          {this.rows}
        </table>
        {this.historyModal()}
      </div>
    )
  }
}

