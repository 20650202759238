import MethodActionGenerator from './MethodActionGenerator'
import parsePaginationHeaders from '../parsePaginationHeaders'

export default class IndexActionGenerator extends MethodActionGenerator {
  constructor(name, basePath, parameterName) {
    super('INDEX', name, basePath, parameterName)
  }

  mainActionGenerator = () => {
    return (params, urlParams) => (dispatch) => {
      dispatch({ type: this.types.request, params })

      return this.client.get(this.urlWithParams(urlParams), { params })
        .then((response) => {
          return dispatch({
            type: this.types.response,
            records: response.data,
            pagination: parsePaginationHeaders(response.headers),
            responseHeaders: response.headers
          })
        })
        .catch((error) => dispatch({ type: this.types.error, ...this.parseError(error) }))
    }
  }
}
