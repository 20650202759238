import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
import StorePersistor from './StorePersistor'

let middleware = [thunkMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware.push(createLogger())
}

middleware = composeEnhancers(applyMiddleware(...middleware))

const persistor = new StorePersistor()

const store = createStore(
  rootReducer,
  persistor.initialState(),
  middleware
)

persistor.subscribe(store)

export default store
