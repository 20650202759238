import React from 'react'
import PropTypes from 'prop-types'
import { IoIosClose, IoIosDocument } from 'react-icons/io'
import { isEmpty, isNil, find } from 'lodash'
import Component from '../common/Component'
import DateTimeComponent from '../common/DateTimeComponent'
import Modal from '../common/Modal'
import FieldDefinition from './BenefitsSetFieldDefinition'

export default class HistoryModal extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    benefitsSet: PropTypes.object.isRequired,
    fieldGroups: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      close: false
    }
  }

  get historyRows() {
    if (isEmpty(this.props.history)) {
      return (<span>Nothing to see here</span>)
    }

    return (
      this.props.history.updates.map((item) => {
        const cssClass = `history_record ${item.current === true ? ' current' : ''}`

        return (
          <div className={cssClass} key={`history-${item.updated_at}-${item.document_id}`}>
            <div className="history_record_value">
              {this.displayValue(item)}
            </div>
            {this.documentForHistory(item)}
            <div> <b>By:</b> <span>{item.username}</span> </div>
            <div> <b>When:</b> <span><DateTimeComponent value={item.updated_at} /></span> </div>
          </div>
        )
      })
    )
  }

  displayValue = (item) => {
    const noDisplayFields = ['service_area_external_key', 'plan_ancestor_identifier']
    if (this.props.history.type === 'metadatum' && !noDisplayFields.includes(this.props.history.key)) {
      return this.displayMetadatumValue(item)
    }

    return this.displayBenefitValue(item)
  }

  displayBenefitValue = (item) => {
    return (
      <span>{item.value.toString()}</span>
    )
  }

  findFieldDefinition = (key) => {
    let def = this.props.fieldGroups['Plan Info'].metadatum[key]

    if (isNil(def)) {
      def = find(this.props.fieldGroups['Plan Info'].metadatum, (field) => {
        return field.alternateKey === key
      })
    }

    return def
  }

  displayMetadatumValue = (item) => {
    const fieldDefinition = new FieldDefinition(
      this.props.history.key,
      this.findFieldDefinition(this.props.history.key),
      item.value,
      null,
      this.props.benefitsSet.metadatum,
      this.props.configuration,
      null,
      null,
      null
    )

    return (
      <span>{fieldDefinition.displayValue}</span>
    )
  }

  documentForHistory = (item) => {
    if (isNil(item.document_id)) {
      return <div className="history_record_value"><b>NO SOURCE DOCUMENT</b></div>
    }

    return (
      <div>
        <div>
          <b>From:</b>
          <a href={item.document_public_url} target="_blank" rel="noopener noreferrer">
            <IoIosDocument size={20} />
            {item.document_vericred_id}
          </a>
        </div>
        <div> <b>Source:</b> <span>{item.document_source}</span> </div>
      </div>
    )
  }

  handleCancelButton = (event) => {
    this.setState({ close: true })
    this.props.handleClose()
  }

  render() {
    if (this.state.close) {
      return null
    }

    return (
      <Modal className="history-modal" handleClose={this.handleCancelButton}>
        <h3><b>Benefits Set Field History</b></h3>
        <div>
          {this.historyRows}
          <button
            className="cancel-button"
            type="button"
            onClick={this.handleCancelButton}
          >
            <IoIosClose />
          </button>
        </div>
      </Modal>
    )
  }
}
