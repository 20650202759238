import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { connect } from 'react-redux'
import Component from '../common/Component'
import DocumentSelectField from '../common/DocumentSelectField'
import SubmitButton from '../common/SubmitButton'
import actions from '../../actions/HowieGeographies'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'

export class HowieGeographiesCreate extends Component {
  static propTypes = {
    create: PropTypes.func,
    reset: PropTypes.func,
    createState: PropTypes.object,
    createFlashMessage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      values: {},
      resetCounter: 0
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newValues = { ...prevState.values, [field]: value }

      return { values: newValues }
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const response = await this.props.create(this.state.values)

    if (response.generalError) {
      alert(response.generalError) // eslint-disable-line no-alert
    } else if (response.resourceError) {
      alert(response.resourceError) // eslint-disable-line no-alert
    } else {
      this.props.createFlashMessage('Geographies import file was saved and will be processed soon', { tag: 'general' })
      this.resetState()
    }
  }

  submitDisabled() {
    return isNil(this.state.values.document_id)
  }

  resetState() {
    this.setState((prevState) => {
      return { values: {}, resetCounter: prevState.resetCounter + 1 }
    })
  }

  render() {
    return (
      <div className="content">
        <h2>Import Geographies</h2>
        <div>
          <form key={this.state.resetCounter} className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            <fieldset>
              <DocumentSelectField
                name="document_id"
                filterFields={{ document_source_contents: ['Service Areas', 'Rates'] }}
                handleChange={this.handleChange}
                value={this.state.values.document_id}
              />
            </fieldset>
            <fieldset>
              <SubmitButton
                title="Submit"
                text="Submit"
                loading={this.props.createState.requestInProgress}
                disabled={this.submitDisabled()}
              />
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { createState: state.howieGeographies.create }
}

const mapDispatchToProps = {
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(HowieGeographiesCreate)

