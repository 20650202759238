import React from 'react'
import Component from '../common/Component'
import BenefitsSetBulkEditsComponent from '../BenefitsSet/BenefitsSetBulkEditsComponent'
import { benefitLabels, metadatumFieldsDiffSet } from './disabilityFields'

const defaultFields = [
  'id',
  'vericred_id',
  'name',
  'audience',
  'issuer_name',
  'voluntary',
  'effective_date',
  'expiration_date',
  'privileged_data',
  'benefit',
  'term',
  'waiting_period_accident',
  'waiting_period_illness',
  'disabled_premium_waiver',
  'own_occupation_disability_definition'
]

export default class DisabilityBenefitsSetBulkEditsComponent extends Component {
  render() {
    return (
      <BenefitsSetBulkEditsComponent
        benefitLabels={benefitLabels}
        metadatumFields={metadatumFieldsDiffSet}
        defaultFields={defaultFields}
        benefitsSetUrl="/disability_benefits"
        productLine="disability"
        {...this.props}
      />
    )
  }
}
