import React from 'react'
import PropTypes from 'prop-types'
import { isBoolean } from 'lodash'
import Component from './Component'
import '../../css/Tabs.scss'

export default class TabsComponent extends Component {
  static propTypes = {
    selected: PropTypes.number,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.element
    ]).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      selected: this.props.selected
    }
  }

  get children() {
    return this.props.children.filter((child) => !isBoolean(child))
  }

  handleClick = (index, event) => {
    event.preventDefault()
    this.setState({
      selected: index
    })
  }

  labels = (child, index) => {
    const activeClass = (this.state.selected === index ? 'active' : '')

    /* eslint-disable react/jsx-no-bind, jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    return (
      <li key={index} className={activeClass}>
        <a onClick={this.handleClick.bind(this, index)}>
          {child.props.label}
        </a>
      </li>
    )
    /* eslint-enable react/jsx-no-bind, jsx-a11y/anchor-is-valid */
    /* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  }

  renderTitles = () => (
    <ul className="tabs__labels">
      {this.children.map(this.labels.bind(this))}
    </ul>
  )

  renderContent = () => (
    <div className="tabs__content">
      {this.children[this.state.selected]}
    </div>
  )

  render() {
    return (
      <div className="tabs">
        {this.renderTitles()}
        {this.renderContent()}
      </div>
    )
  }
}
