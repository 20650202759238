import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, upperFirst, isUndefined } from 'lodash'

export default class CheckboxField extends Component {
  static propTypes = {
    label: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    errorMessage: PropTypes.string,
    inputCssClass: PropTypes.string
  }

  get fieldName() {
    return this.props.fieldName || this.props.name
  }

  /* eslint-disable jsx-a11y/label-has-for */
  get fieldLabel() {
    if (!isUndefined(this.props.label)) {
      return (
        <label className="inline-label">
          {this.props.label}
        </label>
      )
    }

    return null
  }
  /* eslint-enable jsx-a11y/label-has-for */

  handleChange = (event) => {
    if (!this.props.handleChange) {
      return
    }

    this.props.handleChange(this.fieldName, event.target.checked)
  }

  handleBlur = (event) => {
    if (!this.props.handleBlur) {
      return
    }

    this.props.handleBlur(this.fieldName)
  }

  render() {
    const {
      name, value, errorMessage, inputCssClass, disabled
    } = this.props

    const classNames = ['field']
    let inputCssClassValue = inputCssClass

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    if (isUndefined(inputCssClassValue)) {
      inputCssClassValue = ''
    }

    return (
      <div id={name} className={classNames.join(' ')}>
        <input
          className={inputCssClassValue}
          title={this.props.title || this.props.label}
          name={name}
          type="checkbox"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          checked={value || false}
          disabled={disabled}
        />
        {this.fieldLabel}
        {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
      </div>
    )
  }
}
