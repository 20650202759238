import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isEmpty, isNil } from 'lodash'
import { clearFlashMessages } from '../../actions/FlashMessages'
import Component from './Component'

export class FlashMessage extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    clearFlashMessages: PropTypes.func.isRequired,
    tag: PropTypes.string.isRequired,
    visible: PropTypes.bool
  }

  static renderedProps = [
    'messages'
  ]

  static defaultProps = {
    visible: false
  }

  componentWillUnmount() {
    this.clearFlashMessages()
  }

  get relevantMessages() {
    const { messages, tag } = this.props

    return messages.filter((m) => get(m, 'data.tag') === tag)
  }

  get visible() {
    const messages = this.relevantMessages.map((message) => { return message.data.visible })

    if (messages.every((val, i, arr) => val === arr[0])) { return messages[0] }

    return this.props.visible
  }

  clearFlashMessages() {
    const ids = this.relevantMessages.map((m) => m.id)
    this.props.clearFlashMessages(ids)
  }

  messagesDisplay(messages) {
    if (!isNil(this.timeoutID)) {
      window.clearTimeout(this.timeoutID)
    }

    if (!this.visible) { this.timeoutID = window.setTimeout(() => this.clearFlashMessages(), 5000) }

    return messages.map((m) => {
      return <p key={`flash-${m.id}`} className={m.data.type}>{m.message}</p>
    })
  }

  render() {
    const messages = this.relevantMessages

    if (isEmpty(messages)) {
      return null
    }

    return (
      <div className="flash-message-container">
        {this.messagesDisplay(messages)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  clearFlashMessages
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage)
