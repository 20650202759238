import React from 'react'
import Component from '../common/Component'
import { benefitLabels, metadatumFieldsDiffSet } from './fields'
import BenefitsSetExportShow from '../BenefitsSet/BenefitsSetExportShow'

export default class MedicalBenefitsSetExportShow extends Component {
  static defaultFields = [
    'id',
    'vericred_id',
    'hios_ids',
    'approval_state',
    'publish_state',
    'name',
    'display_name',
    'level',
    'audience',
    'plan_type',
    'plan_year',
    'effective_date',
    'expiration_date',
    'carrier_id',
    'issuer_name',
    'private_exchange',
    'state_id',
    'covered_ages',
    'drug_formulary_url',
    'source',
    'market',
    'sbc_name',
    'actuarial_value',
    'mail_order_rx',
    'plan_calendar',
    'embedded_deductible',
    'gated',
    'skilled_nursing_facility_365',
    'actively_marketed',
    'hsa_eligible',
    'dp_rider',
    'fp_rider',
    'age29_rider',
    'essential_health_benefits_percentage',
    'service_area_id',
    'cms_quality_ratings_overall',
    'cms_quality_ratings_medical_care',
    'cms_quality_ratings_member_experience',
    'cms_quality_ratings_plan_administration',
    'provider_directory_url'
  ]

  render() {
    return (
      <BenefitsSetExportShow
        productLine="Medical"
        benefitLabels={benefitLabels}
        metadatumFieldsDiffSet={metadatumFieldsDiffSet}
        defaultFields={MedicalBenefitsSetExportShow.defaultFields}
        benefitsSetUrl="/medical_benefits"
        {...this.props}
      />
    )
  }
}
