import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { get, isNil, intersection, uniqBy, upperCase } from 'lodash'
import pluralize from 'pluralize'
import Component from '../common/Component'
import actions from '../../actions/BenefitsSetsExports'
import connectIndex from '../../lib/connectIndex'
import DiffSet from './DiffSet'
import DiffSetActions from './DiffSetActions'
import SelectField from '../common/SelectField'

export class BenefitsSetExportShow extends Component {
  static propTypes = {
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    records: PropTypes.array,
    location: PropTypes.object.isRequired,
    configuration: PropTypes.object,
    createExportPublish: PropTypes.func,
    resetCreateExportPublish: PropTypes.func,
    exportPublish: PropTypes.object,
    benefitsSetsExport: PropTypes.object,
    updateExport: PropTypes.func.isRequired,
    resetUpdateExport: PropTypes.func.isRequired,
    benefitLabels: PropTypes.object,
    metadatumFieldsDiffSet: PropTypes.object,
    defaultFields: PropTypes.array,
    benefitsSetUrl: PropTypes.string,
    productLine: PropTypes.string,
    alwaysShowFields: PropTypes.array,
    indexExport: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.assignQueryParams()
    this.state = { approvedRecordIds: [], unapprovedLayerUserIds: [] }
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.assignQueryParams()
      this.resetProps()
      this.fetchRecords()
    }
  }

  componentWillUnmount() {
    this.resetProps()
  }

  get recordIds() {
    return this.props.records.map((r) => {
      return r.approved_benefits_set.id
    })
  }

  get approvePrompt() {
    const numApproved = intersection(this.recordIds, this.state.approvedRecordIds).length
    const numRejected = this.props.records.length - numApproved

    return `${pluralize('Approval', numApproved, true)} and ${pluralize('Rejection', numRejected, true)}`
  }

  get publishButtonHandler() {
    return this.props.createExportPublish && this.handlePublishButton
  }

  get unapprovedLayerUsers() {
    if (isNil(this.props.records)) {
      return []
    }
    const users = this.props.records.flatMap(
      (record) => record.full_benefits_set.unapproved_layer_users.map((user) => (
        { text: upperCase(user.username), value: user.user_id }))
    )

    return uniqBy(users, 'text').filter((user) => !isNil(user.text)) || []
  }

  resetProps = () => {
    this.props.reset()
    this.props.resetCreateExportPublish && this.props.resetCreateExportPublish()
    this.props.resetUpdateExport()
  }

  fetchRecords = () => {
    this.props.index({ id: this.props.match.params.id })
  }

  handlePublishButton = () => {
    this.props.createExportPublish(null, { id: this.props.match.params.id })
  }

  handleApproveButton = () => {
    this.props.updateExport(
      this.props.match.params.id,
      { approved_benefits_set_ids: this.state.approvedRecordIds, user_ids: this.state.unapprovedLayerUserIds }
    )
  }

  handleApprovedRecords = (approvedRecordIds) => {
    this.setState({ approvedRecordIds })
  }

  handleUserSelection = (_field, value) => {
    this.setState({ unapprovedLayerUserIds: value }, () => {
      this.props.indexExport({
        id: this.props.match.params.id,
        user_ids: this.state.unapprovedLayerUserIds
      })
    })
  }

  assignQueryParams() {
    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    if (isNil(this.props.records) || isNil(this.props.configuration)) {
      return null
    }

    if (this.props.benefitsSetsExport.updated) {
      const returnLocation = get(this.props.location, 'state.from', this.props.benefitsSetUrl)
      return <Redirect push to={returnLocation} />
    }

    const {
      benefitLabels,
      metadatumFieldsDiffSet,
      defaultFields,
      productLine
    } = this.props

    return (
      <div className="content">
        <h2>{`${productLine} Benefits Sets Diff Set`}</h2>
        <SelectField
          key="unapproved-layer-users"
          name="unapproved_layer_users"
          label="Filter Changes by User"
          options={this.unapprovedLayerUsers}
          handleChange={this.handleUserSelection}
          value={this.state.unapprovedLayerUserIds}
          multi
        />
        <DiffSet
          records={this.props.records}
          defaultFields={defaultFields}
          metadataFields={metadatumFieldsDiffSet}
          benefitFields={benefitLabels}
          configuration={this.props.configuration.benefits}
          handleApprovedRecords={this.handleApprovedRecords}
          alwaysShowFields={this.props.alwaysShowFields || []}
        />
        <DiffSetActions
          handlePublish={this.publishButtonHandler}
          handleApprove={this.handleApproveButton}
          handleReject={this.handleRejectButton}
          exportPublish={this.props.exportPublish}
          export={this.props.benefitsSetsExport}
          approvePrompt={this.approvePrompt}
        />
      </div>
    )
  }
}

export default connectIndex(
  BenefitsSetExportShow,
  'benefitsSetsExports',
  actions,
  [],
  {},
  [
    { action: actions.index.main, key: 'indexExport' },
    { action: actions.update.main, key: 'updateExport' },
    { action: actions.update.reset, key: 'resetUpdateExport' }
  ],
  [
    { key: 'benefitsSetsExport', reducer: 'benefitsSetsExports', action: 'update' }
  ]
)
