import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/PrivateExchanges'
import connectIndex from '../../lib/connectIndex'

export class PrivateExchangeIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['name']
  }

  static columns = [
    {
      key: 'name',
      label: 'Name',
      filterable: false,
      sortable: true
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/private_exchanges"
          name="Private Exchange"
          pagination={this.props.pagination}
          columns={PrivateExchangeIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = []
export default connectIndex(PrivateExchangeIndex, 'privateExchanges', actions, filters)
