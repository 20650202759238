export const RECEIVE_CONFIGS = 'RECEIVE_CONFIGS'
export const RECEIVE_CONFIGS_ERROR = 'RECEIVE_CONFIGS_ERROR'
export const REQUEST_SESSION = 'REQUEST_SESSION'
export const REQUEST_CREATE_SESSION = 'REQUEST_CREATE_SESSION'
export const RECEIVE_SESSION = 'RECEIVE_SESSION'
export const RESET_SESSION = 'RESET_SESSION'
export const RECEIVE_CREDENTIALS_SESSION_ERROR = 'RECEIVE_CREDENTIALS_SESSION_ERROR'
export const RECEIVE_OAUTH_SESSION_ERROR = 'RECEIVE_OAUTH_SESSION_ERROR'
export const RECEIVE_SESSION_ERROR = 'RECEIVE_SESSION_ERROR'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_TOKEN_VALID = 'RESET_PASSWORD_TOKEN_VALID'
export const RESET_PASSWORD_TOKEN_EXPIRED = 'RESET_PASSWORD_TOKEN_EXPIRED'
