import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, get } from 'lodash'
import TextField from '../../common/TextField'
import CheckboxField from '../../common/CheckboxField'
import SelectField from '../../common/SelectField'

export class FamilyMemberComponent extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  get isPersisted() {
    return !isNil(this.props.value.id)
  }

  handleChange(field, value) {
    if (!this.isPersisted && field === 'age' && value === '') {
      this.props.handleChange(this.props.index, null)
    } else {
      const member = cloneDeep(this.props.value)
      member[field] = value

      this.props.handleChange(this.props.index, member)
    }
  }

  render() {
    const {
      label, errors, index, disabled
    } = this.props

    const { age, tobacco, gender } = this.props.value

    return (
      <tr>
        <td className="family-member-label">{label}</td>
        <td>
          <TextField
            name={`member-${label.toLowerCase()}-${index}.age`}
            type="number"
            fieldName="age"
            handleChange={this.handleChange}
            errorMessage={errors.age}
            value={get(this.props, 'value.age')}
            disabled={disabled}
            min={0}
            max={250}
          />
        </td>
        <td>
          {age
            && (
              <CheckboxField
                name={`member-${label.toLowerCase()}-${index}.tobacco`}
                fieldName="tobacco"
                handleChange={this.handleChange}
                errorMessage={errors.tobacco}
                value={tobacco || false}
                disabled={disabled}
              />
            )}
        </td>
        <td className="gender">
          {age
            && (
              <SelectField
                name={`member-${label.toLowerCase()}-${index}.gender`}
                fieldName="gender"
                handleChange={this.handleChange}
                errorMessage={errors.gender}
                placeholder=""
                value={gender}
                options={[
                  { value: 'male', text: 'M' },
                  { value: 'female', text: 'F' }
                ]}
                clearable={false}
              />
            )}
        </td>
      </tr>
    )
  }
}

export default FamilyMemberComponent
