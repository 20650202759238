import client from '../../client'
import receiveCensusError from './receiveCensusError'
import { REQUEST_AUDIENCE_CENSUSES, RECEIVE_AUDIENCE_CENSUSES } from './consts'

function requestCensuses() {
  return { type: REQUEST_AUDIENCE_CENSUSES }
}

function receiveCensuses(censuses, audience) {
  return { type: RECEIVE_AUDIENCE_CENSUSES, censuses, audience }
}

export default function getCensuses(audience) {
  return (dispatch) => {
    dispatch(requestCensuses())

    client.get('/censuses', { params: { audience } })
      .then((response) => response.data)
      .then((json) => dispatch(receiveCensuses(json, audience)))
      .catch((error) => dispatch(receiveCensusError(error.response.data.error)))
  }
}
