import MethodActionGenerator from './MethodActionGenerator'

export default class ShowActionGenerator extends MethodActionGenerator {
  constructor(name, basePath, parameterName) {
    super('SHOW', name, basePath, parameterName)
  }

  mainActionGenerator = () => {
    return (id) => (dispatch) => {
      dispatch({ type: this.types.request, id })

      return this.client.get(`${this.basePath}/${id}`)
        .then((response) => {
          return dispatch({
            type: this.types.response,
            attributes: response.data,
            responseHeaders: response.headers
          })
        })
        .catch((error) => dispatch({ type: this.types.error, ...this.parseError(error) }))
    }
  }
}
