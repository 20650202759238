import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'benefits_set_field_source_validation',
  '/benefits_set_field_source_validations',
  'validations'
)

const actions = generator.generate()

export default actions
