import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import MedicalBenefitsSetDocumentComponent from './MedicalBenefitsSetDocumentComponent'
import RemoveDocumentFromPlan from '../BenefitsSet/RemoveDocumentFromPlan'

export default class MedicalBenefitsSetDocuments extends Component {
  static propTypes = {
    benefitsSet: PropTypes.object.isRequired,
    show: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired
  }

  render() {
    return (
      <>
        <div className="action-nav">
          <MedicalBenefitsSetDocumentComponent
            documentType="SBC"
            needsProcessing={this.props.benefitsSet.needs_sbc_processing}
            url={this.props.benefitsSet.sbc_url}
            iconSize={30}
          />

          {this.props.benefitsSet && this.props.benefitsSet.sbc_url && (
            <RemoveDocumentFromPlan
              documentType="SBC"
              benefitsSetId={this.props.benefitsSet.id}
              planName={this.props.benefitsSet.metadatum.name}
              show={this.props.show}
              showReset={this.props.showReset}
            />
          )}
        </div>

        <div className="action-nav">
          <MedicalBenefitsSetDocumentComponent
            documentType="SOB"
            needsProcessing={this.props.benefitsSet.needs_sob_processing}
            url={this.props.benefitsSet.sob_url}
            iconSize={30}
          />

          {this.props.benefitsSet && this.props.benefitsSet.sob_url && (
            <RemoveDocumentFromPlan
              documentType="SOB"
              benefitsSetId={this.props.benefitsSet.id}
              planName={this.props.benefitsSet.metadatum.name}
              show={this.props.show}
              showReset={this.props.showReset}
            />
          )}
        </div>
      </>
    )
  }
}
