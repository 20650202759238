import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { isNil, get, cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../actions/Censuses'
import alert from '../../../alert'
import CensusForm from './CensusForm'

export class CensusEdit extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    update: PropTypes.func,
    reset: PropTypes.func.isRequired,
    indexReset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    id: PropTypes.number.isRequired,
    needsCensus: PropTypes.bool,
    attributes: PropTypes.object,
    updated: PropTypes.bool.isRequired
  }

  componentDidMount() {
    if (this.props.needsCensus) {
      this.props.show(this.props.id)
    }
  }

  componentDidUpdate() {
    if (!this.props.needsCensus && (this.props.attributes.id !== this.props.id || this.props.updated)) {
      this.props.reset()
      this.props.showReset()
      this.props.indexReset()
      this.props.show(this.props.id)
    }
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
  }

  get initialValues() {
    const attributes = cloneDeep(this.props.attributes)

    attributes.families_attributes = get(attributes, 'families', []).map((originalFamily) => {
      const f = originalFamily

      f.members_attributes = f.members

      delete f.members

      return f
    })

    delete attributes.families

    return attributes
  }

  handleSubmit = (values) => {
    this.props.update(this.props.id, values)
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      needsCensus
    } = this.props

    const requestInProgress = this.props.requestInProgress === true

    if (!isNil(generalError)) {
      alert(generalError)
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    if (needsCensus) {
      return null
    }

    return (
      <CensusForm
        onSubmit={this.handleSubmit}
        errors={errors}
        initialValues={this.initialValues}
        submitLabel="Update Census"
        requestInProgress={requestInProgress}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.censuses.update
  const showSlice = state.censuses.show

  return {
    needsCensus: (
      !isNil(showSlice.generalError)
      || isNil(showSlice.attributes)
    ),
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    updated: slice.updated
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  update: actions.update.main,
  show: actions.show.main,
  reset: actions.update.reset,
  showReset: actions.show.reset,
  indexReset: actions.index.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CensusEdit)
