import { find, get, reduce } from 'lodash'

export default class GridSelectOptionsHelper {
  constructor(options) {
    this.options = options
    this.values = []

    this.optionsByValue = reduce(options, (hash, option) => {
      this.values.push(option.value)
      return Object.assign(hash, { [option.value]: option })
    }, {})
  }

  valueToText = (value) => {
    return get(this.optionsByValue, `${value}.text`)
  }

  textToValue = (text) => {
    return get(find(this.options, (option) => {
      return option.text === text
    }), 'value')
  }
}
