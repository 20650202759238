import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'plan_product_histories',
  '/plan_product_histories',
  'histories'
)
const actions = generator.generate()

export default actions
