import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/LifeBenefitsSets'
import { createFlashMessage } from '../../actions/FlashMessages'
import LifeBenefitsSetForm from './LifeBenefitsSetForm'
import BenefitsSetCreate from '../BenefitsSet/BenefitsSetCreate'
import issuerActions from '../../actions/Issuers'
import howieServiceAreaActions from '../../actions/HowieServiceAreas'

const defaultValues = {
  document: null,
  metadatum: {
    stand_alone: null
  },
  plan_external_ids: [],
  benefits: []
}

const mapStateToProps = (state) => {
  const slice = state.lifeBenefitsSets.create
  const configuration = state.login.session.configuration.life_benefits
  const remoteOptionsSelectStates = {
    issuers: state.issuers.index,
    howie_service_areas: state.howieServiceAreas.index
  }

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestComplete: slice.requestComplete,
    id: slice.id,
    created: slice.created,
    configuration,
    remoteOptionsSelectStates,

    benefitsSetForm: LifeBenefitsSetForm,
    defaultValues,
    productLine: 'Life',
    benefitsSetPath: 'life_benefits'
  }
}

const mapDispatchToProps = (dispatch) => ({
  create: bindActionCreators(actions.create.main, dispatch),
  reset: bindActionCreators(actions.create.reset, dispatch),

  remoteOptionsSelectActions: {
    howieServiceAreasIndex: {
      main: bindActionCreators(howieServiceAreaActions.index.main, dispatch),
      reset: bindActionCreators(howieServiceAreaActions.index.reset, dispatch)
    },
    issuersIndex: { main: bindActionCreators(issuerActions.index.main, dispatch),
      reset: bindActionCreators(issuerActions.index.reset, dispatch) }
  },

  createFlashMessage
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetCreate)
