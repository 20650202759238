// Core
import React from 'react'
import PropTypes from 'prop-types'

// Helpers
import { isNil, cloneDeep } from 'lodash'

// Components
import Component from './Component'
import SubmitButton from './SubmitButton'

export default class Form extends Component {
  static propTypes = {
    className: PropTypes.string,
    submitButtonClassName: PropTypes.string,
    submitLabel: PropTypes.string,
    actionStatus: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func,
    attributes: PropTypes.object.isRequired,
    disableSubmit: PropTypes.func
  }

  static defaultProps = {
    submitButtonClassName: 'pure-button pure-input-1 pure-button-primary',
    className: 'pure-form pure-form-stacked',
    submitLabel: 'Save',
    disableSubmit: () => { return false },
    handleChange: () => {}
  }

  constructor(props) {
    super(props)

    this.state = { attributes: props.attributes }
  }

  get children() {
    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, this.childAttributes(child))
    })
  }

  get disableSubmit() {
    return this.props.disableSubmit(this.state.attributes)
  }

  get generalError() {
    if (isNil(this.props.actionStatus.generalError)) return null
    return (
      <div className="flash-message-container">
        <p>{this.props.actionStatus.generalError}</p>
      </div>
    )
  }

  get resourceErrors() {
    return this.props.actionStatus.resourceErrors || {}
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.attributes)

      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        newState[field] = value
      }

      this.props.handleChange(newState)
      return { attributes: newState }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.handleSubmit(this.state.attributes)
  }

  childAttributes(child) {
    return {
      attributes: this.state.attributes,
      value: this.state.attributes[child.props.name],
      handleChange: this.handleChange.bind(this),
      errorMessage: this.resourceErrors[child.props.errorKey || child.props.name]
    }
  }

  render() {
    return (
      <form className={this.props.className} onSubmit={this.handleSubmit}>

        {this.generalError}

        {this.children}

        <SubmitButton
          className={this.props.submitButtonClassName}
          title={this.props.submitLabel}
          text={this.props.submitLabel}
          loading={this.props.actionStatus.requestInProgress}
          disabled={this.disableSubmit}
        />
      </form>
    )
  }
}
