import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cloneDeep, isNil } from 'lodash'
import actions from '../../actions/Users'
import issuerActions from '../../actions/Issuers'
import alert from '../../alert'
import CarrierUserForm from './CarrierUserForm'

export class CarrierUserCreate extends Component {
  static propTypes = {
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    requestInProgress: PropTypes.bool.isRequired,
    issuers: PropTypes.array,
    indexIssuers: PropTypes.func.isRequired,
    resetIssuers: PropTypes.func.isRequired,
    issuersRequestInProgress: PropTypes.bool.isRequired
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values, dispatch) => {
    this.props.create(this.translateValues(values))
  }

  translateValues = (values) => {
    const newValues = cloneDeep(values)

    if (!isNil(newValues.user_issuers)) {
      newValues.user_issuers = newValues.user_issuers.map((v) => ({
        issuer_id: v
      }))
    }

    return newValues
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const { generalError, resourceErrors } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (this.props.created && !isNil(this.props.id)) {
      return this.redirect(`/users/${this.props.id}`)
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <div className="content">
        <h2>New Carrier User</h2>
        <div>
          <CarrierUserForm
            handleSubmit={this.handleSubmit}
            errors={errors}
            requestInProgress={this.props.requestInProgress}
            issuers={this.props.issuers}
            resetIssuers={this.props.resetIssuers}
            issuersRequestInProgress={this.props.issuersRequestInProgress}
            indexIssuers={this.props.indexIssuers}
            initialValues={{
              user_type: 'carrier',
              username: null,
              email: null,
              carrier_id: null,
              user_issuers: [],
              carrier_verification_tool: false,
              quote_reporting: false,
              analytics_reporting: false
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.users.create
  const issuersSlice = state.issuers.index

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created,

    issuers: issuersSlice.records,
    issuersRequestInProgress: issuersSlice.requestInProgress
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,

  indexIssuers: issuerActions.index.main,
  resetIssuers: issuerActions.index.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CarrierUserCreate)
