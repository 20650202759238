import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Tree from 'react-ui-tree'
import 'react-ui-tree/dist/react-ui-tree.css'
import cx from 'classnames'
import Component from '../common/Component'
import externalIdActions from '../../actions/BenefitsSetExternalIds'

export class BenefitsSetExternalIdShow extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    externalId: PropTypes.object,
    match: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      tree: {},
      active: false
    }
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
  }

  onClickNode = (node) => {
    this.setState({
      active: node
    })
  }

  get id() {
    return this.props.match.params.id
  }

  get shouldRender() {
    return this.props.externalId
  }

  get treeComponents() {
    return (
      this.props.externalId.zip_counties.map((tree) => (
        <Tree
          key={tree.module}
          tree={tree}
          paddingLeft={20}
          onChange={this.handleChange}
          renderNode={this.renderNode}
          isNodeCollapsed
        />
      ))
    )
  }

  handleChange = (tree) => {
    this.setState((prevState) => {
      return { tree: prevState.tree }
    })
  }

  renderNode = (node) => {
    return (
      <span
        className={cx('node', {
          'is-active': node === this.state.active
        })}
        role="presentation"
        onClick={() => this.onClickNode.bind(null, node)}
        onKeyDown={() => this.onClickNode.bind(null, node)}
      >
        {node.module}
      </span>
    )
  }

  render() {
    const { externalId } = this.props

    if (!this.shouldRender) return null

    return (
      <div className="content">
        <h1>External ID</h1>

        <h5>Metadata</h5>
        <table className="pure-table zip_county_info">
          <tbody>
            <tr>
              <td><strong>External ID</strong></td>
              <td name="external_id">{externalId.external_id}</td>
            </tr>
            <tr>
              <td><strong>Type</strong></td>
              <td name="type">{externalId.external_id_type}</td>
            </tr>
            <tr>
              <td><strong>Number of Counties</strong></td>
              <td name="fips_code_count">{externalId.fips_code_count}</td>
            </tr>
            <tr>
              <td><strong>Number of Zip Codes</strong></td>
              <td name="zip_code_count">{externalId.zip_code_count}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>Zip Counties</h5>
        <div className="tree">
          {this.treeComponents}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const showSlice = state.BenefitsSetExternalIds.show
  const configuration = state.login.session.configuration

  return {
    externalId: showSlice.attributes,
    configuration
  }
}

const mapDispatchToProps = {
  show: externalIdActions.show.main,
  reset: externalIdActions.show.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetExternalIdShow)
