import client from '../../client'
import clearSession from './clearSession'

import {
  REQUEST_SESSION,
  RECEIVE_SESSION
} from './consts'

function requestSession(redirectPath) {
  return { type: REQUEST_SESSION, redirectPath }
}

function receiveSession(session) {
  return {
    type: RECEIVE_SESSION, nonce: session.nonce, configuration: session.configuration, user: session.user
  }
}

export default function getCurrentSession() {
  return (dispatch) => {
    dispatch(requestSession())

    client.get('/sessions/me')
      .then((response) => response.data)
      .then((json) => { dispatch(receiveSession(json)) })
      .catch((error) => dispatch(clearSession()))
  }
}
