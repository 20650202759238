import client from '../../client'
import parseError from '../../lib/parseError'
import {
  REQUEST_CENSUS_PLANS,
  RECEIVE_CENSUS_PLAN,
  RECEIVE_CENSUS_PLANS_ERROR
} from './consts'

function requestPlans(familyIds) {
  return { type: REQUEST_CENSUS_PLANS, familyIds }
}

function receivePlans(plans, familyId) {
  return { type: RECEIVE_CENSUS_PLAN, plans, familyId }
}

function receivePlansError(error, familyId) {
  const { generalError, resourceErrors } = parseError(error)

  return {
    type: RECEIVE_CENSUS_PLANS_ERROR, generalError, resourceErrors, familyId
  }
}

export default function getCensusPlanSearches(productLine, zipCode, fipsCode, market, enrollmentDate, families) {
  return (dispatch) => {
    const familyIds = families.map((f) => f.id)
    dispatch(requestPlans(familyIds))
    families.forEach((family) => {
      const fields = {
        product_line: productLine,
        zip_code: zipCode,
        fips_code: fipsCode,
        market,
        enrollment_date: enrollmentDate,
        group_name: family.name,
        applicants: family.applicants,
        household_income: family.household_income,
        household_size: family.household_size
      }
      client.post('/plans/searches', { search: fields })
        .then((response) => response.data)
        .then((json) => dispatch(receivePlans(json, family.id)))
        .catch((error) => dispatch(receivePlansError(error, family.id)))
    })
  }
}
