import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isUndefined } from 'lodash'

export default class InputField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleKeyDown: PropTypes.func,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    fieldName: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    errorMessage: PropTypes.string,
    inputCssClass: PropTypes.string,
    step: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    id: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    placeholder: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  get fieldName() {
    return this.props.fieldName || this.props.name
  }

  handleChange(event) {
    if (!this.props.handleChange) {
      return
    }

    this.props.handleChange(this.fieldName, event.target.value)
  }

  handleBlur(event) {
    if (!this.props.handleBlur) {
      return
    }

    this.props.handleBlur(this.fieldName, event.target.value)
  }

  render() {
    const {
      name,
      errorMessage,
      inputCssClass,
      min,
      max,
      disabled,
      step,
      id,
      placeholder,
      handleKeyDown
    } = this.props

    const value = this.props.value || ''
    const type = this.props.type || 'text'

    const classNames = ['field']
    let inputCssClassValue = inputCssClass

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    if (isUndefined(inputCssClassValue)) {
      inputCssClassValue = 'pure-input-1'
    }

    const title = this.props.title || name

    return (
      <input
        className={inputCssClassValue}
        id={id}
        title={title}
        name={name}
        type={type}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyDown={handleKeyDown}
        value={value}
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        placeholder={placeholder}
      />
    )
  }
}
