// Core
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { isNil, cloneDeep, camelCase } from 'lodash'

// Helpers
import confirm from '../../confirm'
import { createFlashMessage } from '../../actions/FlashMessages'

// Components
import Component from './Component'
import Form from './Form'

export default class EditForm extends Component {
  static propTypes = {
    modelName: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
    deleteRedirect: PropTypes.string,
    updateAction: PropTypes.func.isRequired,
    showAction: PropTypes.func.isRequired,
    destroyAction: PropTypes.func.isRequired,
    resetUpdateAction: PropTypes.func.isRequired,
    resetShowAction: PropTypes.func.isRequired,
    resetDestroyAction: PropTypes.func.isRequired,
    resourceActionStatuses: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    createFlashMessage: PropTypes.func,
    transformAttributes: PropTypes.func,
    confirm: PropTypes.func,
    showDelete: PropTypes.bool
  }

  static defaultProps = {
    createFlashMessage,
    confirm,
    showDelete: true,
    transformAttributes: (attrs) => { return attrs }
  }

  componentDidMount = async () => {
    this.props.showAction(this.props.id).then((data) => {
      this.setState({ attributes: this.props.transformAttributes(cloneDeep(data.attributes)) })
    })
  }

  componentWillUnmount = async () => {
    this.props.resetUpdateAction()
    this.props.resetShowAction()
    this.props.resetDestroyAction()
  }

  get shouldRender() {
    return !isNil(this.state) && !isNil(this.state.attributes)
  }

  get updated() {
    return !!(this.props.resourceActionStatuses.update.updated)
  }

  get deleted() {
    return !!(this.props.resourceActionStatuses.destroy.deleted)
  }

  get submitLabel() {
    return `Update ${this.props.modelName}`
  }

  get deleteButton() {
    if (this.props.showDelete) {
      return (
        <nav>
          <button
            type="button"
            className="button destructive small"
            title="Delete"
            onClick={this.handleDelete}
          >
            Delete
          </button>
        </nav>
      )
    }
    return null
  }

  handleSubmit = async (data) => {
    this.props.updateAction(this.props.id, data)
      .then(() => {
        this.props.createFlashMessage(`Updated ${this.props.modelName} ${this.props.id}`, { tag: 'index' })
      })
  }

  handleDelete = async () => {
    if (this.props.confirm(`Are you sure you want to delete this ${this.props.modelName}?`)) {
      this.props.destroyAction(this.props.id)
        .then(() => {
          this.props.createFlashMessage(`Deleted ${this.props.modelName} ${this.props.id}`, { tag: 'index' })
        })
    }
  }

  render() {
    if (!this.shouldRender) return null
    if (this.deleted && this.props.deleteRedirect) return (<Redirect push to={this.props.deleteRedirect} />)
    if (this.updated || this.deleted) return (<Redirect push to={this.props.redirectTo} />)
    return (
      <div className="content">
        <header className="form-header">
          <div className="form-header-title">
            <h2>
              Edit {this.props.modelName}
            </h2>
            {this.deleteButton}
          </div>
        </header>
        <Form
          actionStatus={this.props.resourceActionStatuses.update}
          handleSubmit={this.handleSubmit}
          attributes={this.state.attributes}
          submitLabel={this.submitLabel}
        >
          {this.props.children}
        </Form>
      </div>
    )
  }
}

export const defaultPropTypes = {
  redirectTo: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  updateAction: PropTypes.func.isRequired,
  showAction: PropTypes.func.isRequired,
  destroyAction: PropTypes.func.isRequired,
  resetUpdateAction: PropTypes.func.isRequired,
  resetShowAction: PropTypes.func.isRequired,
  resetDestroyAction: PropTypes.func.isRequired,
  resourceActionStatuses: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
  match: PropTypes.object
}

export function connectEditForm(klass, model, plural, actions) {
  const mapStateToProps = (state) => {
    return {
      resourceActionStatuses: state[camelCase(plural)],
      redirectTo: `/${plural}`,
      modelName: model,
      configuration: state.login.session.configuration[plural] || {}
    }
  }

  const mapDispatchToProps = {
    updateAction: actions.update.main,
    showAction: actions.show.main,
    destroyAction: actions.destroy.main,
    resetUpdateAction: actions.update.reset,
    resetShowAction: actions.show.reset,
    resetDestroyAction: actions.destroy.reset
  }

  return connect(mapStateToProps, mapDispatchToProps)(klass)
}
