import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IoIosDocument } from 'react-icons/io'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/SheetRates'
import connectIndex from '../../lib/connectIndex'
import publishStateDisplay from '../BenefitsSet/publishStateDisplay'
import approvalStateDisplay from '../BenefitsSet/approvalStateDisplay'

export class SheetRateIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterable: PropTypes.bool,
    filters: PropTypes.object,
    filterActions: PropTypes.object,
    includeSearch: PropTypes.bool,
    includeNewRecordLink: PropTypes.bool,
    includeBulkActionLink: PropTypes.bool,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['hios_id', 'vericred_id', 'rating_area', 'document_vericred_id']
  }

  static showLinkDisplay = (record) => {
    return <Link title={record.vericred_id} to={`/sheet_rates/review?ids=${record.id}`}>{record.vericred_id}</Link>
  }

  static DocumentLink = (record) => {
    return (
      <a href={record.document.public_url} target="_blank" rel="noopener noreferrer">
        <IoIosDocument size={20} />
        {record.document.source}
      </a>
    )
  }

  static SourceDocumentDisplay = (record) => {
    return (
      <div className="source-document-table">
        <table className="source-document-table">
          <tbody>
            <tr key={`source-document-table-row-${record.vericred_id}`}>
              <td className="source-document-url"> {SheetRateIndex.DocumentLink(record)} </td>
              <td className="source-document-id">ID: {record.document.vericred_id}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  get columns() {
    return [
      { key: 'vericred_id', label: 'Vericred ID', sortable: false, display: SheetRateIndex.showLinkDisplay },
      { key: 'hios_id', label: 'HIOS ID' },
      { key: 'rating_area', label: 'Rating Area' },
      { key: 'effective_date', label: 'Effective Date', filterable: true },
      { key: 'expiration_date', label: 'Expiration Date', filterable: true },
      { key: 'source_document', label: 'Source Document', display: SheetRateIndex.SourceDocumentDisplay },
      { key: 'updated_at', label: 'Last Updated At', display: IndexComponent.dateTimeDisplay },
      { key: 'approval_state', label: 'Status', display: approvalStateDisplay },
      { key: 'publish_state', label: 'Publish Status', display: publishStateDisplay }
    ]
  }

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/sheet_rates"
          name="Rate"
          pagination={this.props.pagination}
          columns={this.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterable={this.props.filterable}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          includeNewRecordLink={this.props.includeNewRecordLink}
          includeBulkActionLink={this.props.includeBulkActionLink}
          bulkActionName="Review"
          includeSearch={this.props.includeSearch}
          searchPlaceholder="Search by Vericred ID, HIOS ID, Rating Area, or Source Document Vericred ID"
          actions={[]}
          searchColumns={this.props.searchColumns}
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'effective_date',
    dateField: true
  },
  {
    key: 'expiration_date',
    dateField: true
  }
]

export default connectIndex(SheetRateIndex, 'sheetRates', actions, filters)
