import { cloneDeep, has, toUpper } from 'lodash'

export default class MethodActionGenerator {
  constructor(method, name, basePath) {
    this.method = method
    this.name = name
    this.basePath = basePath
    this.typeName = toUpper(name)

    this.types = {
      reset: `${this.typeName}_${this.method}_RESET`,
      request: `${this.typeName}_${this.method}_REQUEST`,
      response: `${this.typeName}_${this.method}_RESPONSE`,
      error: `${this.typeName}_${this.method}_ERROR`
    }
  }

  resetHandler = (state, action) => {
    return cloneDeep(this.constructor.initialState)
  }

  generateReducer = () => {
    const handlers = {
      [this.types.reset]: this.resetHandler,
      [this.types.request]: this.requestHandler,
      [this.types.response]: this.responseHandler,
      [this.types.error]: this.errorHandler
    }

    const initialState = cloneDeep(this.constructor.initialState)

    return function reducer(state = initialState, action) {
      if (has(handlers, action.type)) {
        return handlers[action.type](state, action)
      }

      return state
    }
  }
}
