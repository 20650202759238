import React from 'react'
import { get } from 'lodash'

const SourceContentCellDisplayRenderer = (record, column) => {
  const values = get(record, column.key, [])
  const sourceContentRows = values.map((sourceContent, i) => (
    <tr key={`source_content_index_row-${record.vericred_id}-${sourceContent.source_content}`}>
      <td className="source-content">{sourceContent.source_content}</td>
      <td className="pages">{`Pages: ${sourceContent.pages}`}</td>
      <td className="affected-records">{`Affected Records: ${sourceContent.affected_records}`}</td>
    </tr>
  ))
  return (
    <div className="document-source-content">
      <table className="document-source-content">
        <tbody>
          {sourceContentRows}
        </tbody>
      </table>
    </div>
  )
}

export default SourceContentCellDisplayRenderer
