import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isUndefined, isObject, startCase, toPairs, join, map, includes } from 'lodash'

export class PlanInformationComponent extends Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    fieldGroups: PropTypes.object.isRequired
  }

  get metadataFields() {
    const fieldNames = {}

    toPairs(this.props.fieldGroups['Plan Info'].metadatum).forEach((field) => {
      if (!field[1].display_on_cvt) return

      fieldNames[field[1].vericred_name || field[0]] = field[1].cvt_label || field[1].label
    })

    return Object.assign(this.metadataFieldsOrder, fieldNames)
  }

  get metadataFieldsOrder() {
    if (this.props.plan.product_line === 'life') return this.lifeMetadataOrder

    return {}
  }

  get lifeMetadataOrder() {
    return {
      name: null,
      voluntary: null,
      accidental_death_dismemberment_included: null,
      insured_benefits: null
    }
  }

  planMetadata = (plan) => {
    return plan.metadata
  }

  stringifyObject = (value) => {
    const pairs = []
    Object.keys(value).forEach((key) => { pairs.push(`${startCase(key)}: ${value[key]}`) })

    return join(pairs, ' / ')
  }

  sanitizeValue = (val) => {
    if (isObject(val)) return this.stringifyObject(val)

    const value = isUndefined(val) ? '' : val.toString()

    if (value.match(/^https?:|^\d/)) return (value)
    if (value === 'true') return 'Yes'
    if (value === 'false') return 'No'

    return startCase(value).replace('Both Markets', 'Both')
  }

  sanitizeBenefit = (val) => {
    const defaultOrder = { maximum: null, minimum: null, increment: null }

    return this.stringifyObject(Object.assign(defaultOrder, val))
  }

  planInfoComponent = (plan) => {
    const metadata = this.planMetadata(plan)

    return toPairs(this.metadataFields).map((fieldGroup) => {
      const metadataName = fieldGroup[1]
      const metadataValue = this.sanitizeValue(metadata[fieldGroup[0]])

      if (includes(metadataName, 'Benefits')) return this.addBenefits(metadataName, plan.benefits)

      return (
        <tr key={`${plan.id}${fieldGroup[0]}`}>
          <td name="metadatum-name">{metadataName}</td>
          <td name="metadatum-value">{metadataValue}</td>
        </tr>
      )
    })
  }

  addBenefits = (name, benefits) => {
    const defaultOrder = { employee: null, spouse: null, child: null }

    const rows = map(Object.assign(defaultOrder, benefits), (value, key) => {
      return (
        <tr key={`insured_benefit_${key}`}>
          <td key={`metadata_name_${key}`}>{startCase(`${key} ${name}`)}</td>
          <td key={`metadata_value_${key}`}>{this.sanitizeBenefit(value)}</td>
        </tr>
      )
    })

    return rows
  }

  render() {
    const { plan } = this.props
    const planMetadata = this.planInfoComponent(plan)

    return (
      <div className="plan-metadata-info-group" id={`${plan.id}-info`}>
        <table className="pure-table" name={`${plan.name}-info-table`}>
          <thead>
            <tr>
              <td style={{ width: '50%' }}><strong>Metadata</strong></td>
              <td><strong>Value</strong></td>
            </tr>
          </thead>
          <tbody>
            {planMetadata}
          </tbody>
        </table>
      </div>
    )
  }
}

export default PlanInformationComponent
