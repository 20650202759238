import {
  RECEIVE_CONFIGS,
  RECEIVE_CONFIGS_ERROR
} from '../../actions/Login/consts'

const defaultState = {
  error: undefined,
  googleClientId: undefined
}

export default function GoogleReducer(state = defaultState, action) {
  switch (action.type) {
  case RECEIVE_CONFIGS:
    return { ...state,
      googleClientId: action.googleClientId,
      error: undefined }
  case RECEIVE_CONFIGS_ERROR:
    return { ...state, error: action.error }
  default:
    return { ...state }
  }
}
