import { RESET_SESSION } from './consts'

function resetSession() {
  return { type: RESET_SESSION }
}

export default function clearSession() {
  return (dispatch) => {
    dispatch(resetSession())
  }
}
