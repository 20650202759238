import React from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, mapValues, isString, get, isEmpty, isObject } from 'lodash'
import { Prompt } from 'react-router-dom'
import Component from '../common/Component'
import UserContext from './BenefitsSetUserContext'
import prepopulator from './prepopulator'
import SubmitButton from '../common/SubmitButton'
import totalTiersCountForBenefits from './Helpers/tiersCountForBenefits'
import DocumentSelectField from '../common/DocumentSelectField'
import BenefitsInitializer from './Helpers/BenefitsInitializer'

export default class BenefitsSetForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    rejections: PropTypes.array,
    configuration: PropTypes.object,
    remoteOptionsSelectStates: PropTypes.object,
    remoteOptionsSelectActions: PropTypes.object,
    requestComplete: PropTypes.bool.isRequired,
    showHistory: PropTypes.bool,
    benefitNames: PropTypes.array,
    benefitsSetComponent: PropTypes.func,
    productLine: PropTypes.string,
    benefitsSetFieldHistory: PropTypes.object,
    resetHistory: PropTypes.func,
    history: PropTypes.func,
    show: PropTypes.func,
    showReset: PropTypes.func
  }

  constructor(props) {
    super(props)

    const tiersCount = totalTiersCountForBenefits(get(props.initialValues, 'benefits'))

    const initialValues = cloneDeep(props.initialValues)
    const initializer = new BenefitsInitializer(initialValues.benefits, tiersCount, props.benefitNames)
    initialValues.benefits = initializer.initialize()

    this.state = {
      values: initialValues,
      displayValidFields: undefined,
      changedFields: new Set(),
      tiersCount
    }
  }

  get sanitizedSubmitValues() {
    const values = cloneDeep(this.state.values)

    return this.sanitizeValues(values)
  }

  get shouldPreventBack() {
    if (!this.props.requestComplete) {
      return this.state.changedFields.size > 0
    }

    return false
  }

  get toggleButton() {
    if (isNil(this.state.displayValidFields)) {
      return null
    }

    const title = this.state.displayValidFields ? 'Hide Valid Fields' : 'Display Valid Fields'

    return (
      <button
        className="text-button"
        title={title}
        id="toggle-valid-fields"
        type="button"
        onClick={this.handleToggleDisplayValidFieldsButton}
      >
        {title}
      </button>
    )
  }

  get values() {
    return cloneDeep(this.state.values)
  }

  get remoteFilterQueryParams() {
    return {
      product_line: this.props.productLine,
      document_source_contents: ['Benefits', 'Metadata']
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.submit()
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = prevState.values
      const newChangedFields = prevState.changedFields

      if (isNil(value) || value === '' || (isObject(value) && isEmpty(value))) {
        newState[field] = isObject(value) ? {} : null

        if (newChangedFields.has(field)) {
          newChangedFields.delete(field)
        } else {
          newChangedFields.add(field)
        }
      } else {
        newState[field] = value

        if (this.props.initialValues[field] !== value) {
          newChangedFields.add(field)
        } else {
          newChangedFields.delete(field)
        }
      }

      return { values: newState, changedFields: newChangedFields }
    })
  }

  handleBlur = (field) => {
    this.setState((prevState) => {
      let newState = prevState.values

      newState = this.prepopulate(field, newState)

      return { values: newState }
    })
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (event.metaKey) {
        this.submit()
      }
    }
  }

  handleToggleDisplayValidFieldsButton = (event) => {
    event.preventDefault()

    this.setState((prevState) => ({ displayValidFields: !prevState.displayValidFields }))
  }

  handleTiersCountChange = (totalTiers) => {
    this.setState((prevState) => {
      const values = cloneDeep(prevState.values)

      const initializer = new BenefitsInitializer(values.benefits, totalTiers, this.props.benefitNames)
      values.benefits = initializer.initialize()

      return {
        tiersCount: totalTiers,
        values
      }
    })
  }

  submitDisabled = () => {
    return isNil(this.state.values.document)
  }

  submit() {
    if (isNil(this.state.displayValidFields)) {
      this.setState({ displayValidFields: false })
    }

    this.props.handleSubmit(this.sanitizedSubmitValues)
  }

  prepopulate(field, state) {
    return prepopulator(field, state, this.props.configuration)
  }

  sanitizeValues(values) {
    const newValues = mapValues(values, (v) => {
      if (isString(v)) {
        return v.trim()
      }

      return v
    })

    newValues.plan_external_ids_attributes = get(values, 'plan_external_ids', [])

    newValues.benefits_attributes = this.sanitizeBenefits(get(values, 'benefits', []))

    newValues.metadatum_attributes = get(values, 'metadatum', {})

    delete newValues.plan_external_ids
    delete newValues.benefits
    delete newValues.metadatum

    newValues.plan_external_ids_attributes = this.sanitizeExternalIds(newValues.plan_external_ids_attributes)

    return newValues
  }

  sanitizeBenefits(benefits) {
    return benefits.map((originalBenefit) => {
      const b = cloneDeep(originalBenefit)

      b.tiers_attributes = b.tiers
      delete b.tiers

      if (isString(b.limit)) {
        b.limit = b.limit.trim()
      }

      b.tiers_attributes = b.tiers_attributes.map((originalTier) => {
        const t = cloneDeep(originalTier)

        if (isString(t.value)) {
          t.value = t.value.trim()
        }

        return t
      })

      return b
    })
  }

  sanitizeExternalIds(externalIds) {
    return externalIds.reduce((memo, currentValue) => {
      const value = cloneDeep(currentValue)

      if (value.external_id === '' && !isNil(value.id)) {
        memo.push({
          id: value.id,
          _destroy: true
        })
      } else if (value.external_id !== '') {
        value.external_id = value.external_id.trim()
        memo.push(value)
      }

      return memo
    }, [])
  }

  render() {
    const {
      errors,
      configuration
    } = this.props

    const BenefitsSetComponent = this.props.benefitsSetComponent

    const submitDisabled = this.submitDisabled()

    if (isNil(configuration)) {
      return null
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <form
        className="benefits-set-form pure-form pure-form-stacked"
        onSubmit={this.handleSubmit}
        onKeyDown={this.handleKeyDown}
      >
        <fieldset>
          {this.toggleButton}
        </fieldset>
        <DocumentSelectField
          key="document"
          name="document"
          recordLabel={['vericred_id', 'name', 'state_code', 'source']}
          filterFields={this.remoteFilterQueryParams}
          handleChange={this.handleChange}
          errorMessage={errors.document}
          value={this.state.values.document}
        />
        <fieldset>
          <BenefitsSetComponent
            benefitsSetFieldHistory={this.props.benefitsSetFieldHistory}
            resetHistory={this.props.resetHistory}
            history={this.props.history}
            benefitsSet={this.values}
            configuration={configuration}
            errors={errors}
            handleBlur={this.handleBlur}
            handleChange={this.handleChange}
            handleTiersCountChange={this.handleTiersCountChange}
            showHistory={this.props.showHistory}
            userContext={new UserContext({ editable: true })}
            displayValidFields={this.state.displayValidFields !== false}
            tiersCount={this.state.tiersCount}
            rejections={this.props.rejections}
            remoteOptionsSelectStates={this.props.remoteOptionsSelectStates}
            remoteOptionsSelectActions={this.props.remoteOptionsSelectActions}
            show={this.props.show}
            showReset={this.props.showReset}
          />
        </fieldset>
        <fieldset>
          <SubmitButton
            title="Submit Benefits Set"
            text="Submit"
            loading={submitDisabled}
          />
        </fieldset>
        <Prompt
          when={this.shouldPreventBack}
          message={(location) => (
            'You have unsaved changes, are you sure that you want to navigate away from this page?'
          )}
        />
      </form>
    )
  }
}
