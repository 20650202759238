import React from 'react'

import actions from '../../actions/PrivateExchanges'

import Component from './Component'
import ConnectedRemoteOptionsSelectField,
{ connectRemoteOptionsSelectField, defaultPropTypes }
  from './ConnectedRemoteOptionsSelectField'

export class PrivateExchangeRemoteOptionsSelectField extends Component {
  static propTypes = defaultPropTypes

  static defaultProps = {
    label: 'Private Exchange',
    multi: false,
    title: 'private_exchange_id'
  }

  render() {
    return (
      <ConnectedRemoteOptionsSelectField {...this.props} />
    )
  }
}

export default connectRemoteOptionsSelectField(PrivateExchangeRemoteOptionsSelectField, 'private_exchanges', actions)
