import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { isNil } from 'lodash'
import Component from '../common/Component'
import actions from '../../actions/BulkBenefitsSets'
import updateActions from '../../actions/BulkBenefitsSetUpdates'
import validateActions from '../../actions/BenefitsSetFieldSourceValidations'
import connectIndex from '../../lib/connectIndex'
import BulkEditsComponent from './BulkEditsComponent'

export class BenefitsSetBulkEditsComponent extends Component {
  static propTypes = {
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    location: PropTypes.object.isRequired,
    configuration: PropTypes.object,
    createBulkUpdate: PropTypes.func.isRequired,
    bulkUpdate: PropTypes.object,
    resetCreateBulkUpdate: PropTypes.func.isRequired,
    higherPrioritySourceValidation: PropTypes.func.isRequired,
    resetHigherPrioritySourceValidation: PropTypes.func.isRequired,
    benefitLabels: PropTypes.object.isRequired,
    metadatumFields: PropTypes.object.isRequired,
    defaultFields: PropTypes.array,
    benefitsSetUrl: PropTypes.string,
    productLine: PropTypes.string,
    includeSbcCheckbox: PropTypes.bool,
    hideId: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.assignQueryParams()
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.assignQueryParams()
      this.props.reset()
      this.fetchRecords()
      this.props.resetCreateBulkUpdate()
    }
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.resetCreateBulkUpdate()
  }

  get rowCount() {
    return this.props.records.length
  }

  get rowData() {
    if (isNil(this.props.records)) {
      return []
    }

    return this.props.records.map(this.rowMapper)
  }

  get redirectUrl() {
    return `${this.props.benefitsSetUrl}?${qs.stringify(this.queryParams.query)}`
  }

  get configuration() {
    switch (this.props.productLine) {
    case 'medical':
      return this.props.configuration.benefits
    case 'dental':
      return this.props.configuration.dental_benefits
    case 'vision':
      return this.props.configuration.vision_benefits
    case 'medicare_advantage':
      return this.props.configuration.medicare_advantage_benefits
    default:
      return {}
    }
  }

  fetchRecords = () => {
    this.props.index({ ids: this.queryParams.ids, product_line: this.props.productLine })
  }

  createBulkUpdate = (params) => {
    this.props.resetCreateBulkUpdate()
    return this.props.createBulkUpdate({ ...params, product_line: this.props.productLine })
  }

  assignQueryParams() {
    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    if (isNil(this.props.records) || isNil(this.props.configuration)) {
      return null
    }
    return (
      <div className="content">
        <h2>Bulk Edit Benefits Set</h2>
        <BulkEditsComponent
          records={this.props.records}
          defaultFields={this.props.defaultFields}
          metadataFields={this.props.metadatumFields}
          benefitFields={this.props.benefitLabels}
          configuration={this.configuration}
          createBulkUpdate={this.createBulkUpdate}
          bulkUpdate={this.props.bulkUpdate}
          higherPrioritySourceValidation={this.props.higherPrioritySourceValidation}
          resetHigherPrioritySourceValidation={this.props.resetHigherPrioritySourceValidation}
          redirectUrl={this.redirectUrl}
          includeSbcCheckbox={this.props.includeSbcCheckbox}
          productLine={this.props.productLine}
          hideId={this.props.hideId}
        />
      </div>
    )
  }
}

export default connectIndex(
  BenefitsSetBulkEditsComponent,
  'bulkBenefitsSets',
  actions,
  [],
  {
  },
  [
    { action: updateActions.create.main, key: 'createBulkUpdate' },
    { action: updateActions.create.reset, key: 'resetCreateBulkUpdate' },
    { action: validateActions.create.main, key: 'higherPrioritySourceValidation' },
    { action: validateActions.create.reset, key: 'resetHigherPrioritySourceValidation' }
  ],
  [
    { key: 'bulkUpdate', reducer: 'bulkBenefitsSetUpdates', action: 'create' }
  ]
)
