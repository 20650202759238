import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, startCase } from 'lodash'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/SmokeChecks'
import connectIndex from '../../lib/connectIndex'

export class SmokeCheckIndex extends Component {
  static propTypes = {
    name: PropTypes.string,
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterable: PropTypes.bool,
    filters: PropTypes.object,
    filterActions: PropTypes.object,
    includeSearch: PropTypes.bool,
    configuration: PropTypes.object,
    includeNewRecordLink: PropTypes.bool
  }

  static defaultProps = {
    name: 'Smoke Checks',
    filterable: true,
    includeNewRecordLink: false
  }

  static issuerDisplay = (record, column) => {
    const issuer = get(record.issuer_period_audience, column.key, [])

    return `${issuer.issuer_id} | ${issuer.name}`
  }

  static schedulingStatusDisplay = (record, column) => {
    const schedulingStatus = get(record, column.key, [])
    return `${startCase(schedulingStatus)}`
  }

  static approvalStatusDisplay = (record) => {
    if (record.approval_status_user) {
      return (
        `${startCase(record.approval_status)} by ${record.approval_status_user.username}`
      )
    }

    return `${startCase(record.approval_status)}`
  }

  static showLinkDisplay = (record) => {
    return <Link title="Show" to={`/smoke_checks/${record.id}`}>Show</Link>
  }

  static reRunLinkDisplay = (record) => {
    return <Link title="Re-run" to={`/smoke_checks/${record.id}`}>Re-run</Link>
  }

  static columns = [
    {
      key: 'issuer',
      label: 'Issuer',
      display: SmokeCheckIndex.issuerDisplay,
      sortable: false
    },
    {
      key: 'issuer_period_audience.audience',
      label: 'Audience',
      sortable: false,
      filterable: true,
      remoteKey: 'audience'
    },
    {
      key: 'issuer_period_audience.calendar_period.display_quarter',
      label: 'Quarter',
      sortable: false,
      filterable: true,
      remoteKey: 'quarter'
    },
    {
      key: 'issuer_period_audience.calendar_period.year',
      label: 'Year',
      sortable: false,
      filterable: true,
      remoteKey: 'year'
    },
    { key: 'source', label: 'Source', sortable: false },
    {
      key: 'updated_at',
      label: 'Last Updated At',
      display: IndexComponent.dateTimeDisplay,
      sortable: true
    },
    {
      key: 'scheduling_status',
      label: 'Scheduling Status',
      display: SmokeCheckIndex.schedulingStatusDisplay,
      sortable: false
    },
    {
      key: 'approval_status',
      label: 'Approval Status',
      display: SmokeCheckIndex.approvalStatusDisplay,
      sortable: false
    },
    { key: 'notes', label: 'Notes', sortable: false },
    {
      key: 'smoke_check_show_url',
      label: '',
      display: SmokeCheckIndex.showLinkDisplay,
      sortable: false
    },
    {
      key: 'rerun_smoke_check_url',
      label: '',
      display: SmokeCheckIndex.reRunLinkDisplay,
      sortable: false
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/smoke_checks"
          name={this.props.name}
          pagination={this.props.pagination}
          columns={SmokeCheckIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterable={this.props.filterable}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          includeSearch={this.props.includeSearch}
          configuration={this.props.configuration}
          includeNewRecordLink={this.props.includeNewRecordLink}
          actions={[]}
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'issuer_period_audience.audience',
    config: 'plan_products.audience'
  },
  {
    key: 'issuer_period_audience.calendar_period.year',
    config: 'plan_products.year'
  },
  {
    key: 'issuer_period_audience.calendar_period.display_quarter',
    config: 'plan_products.quarter'
  }
]

export default connectIndex(SmokeCheckIndex, 'smokeChecks', actions, filters)
