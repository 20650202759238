import MethodReducerGenerator from './MethodReducerGenerator'

export default class DestroyReducerGenerator extends MethodReducerGenerator {
  constructor(name, basePath) {
    super('DESTROY', name, basePath)
  }

  static initialState = {
    requestInProgress: false,
    generalError: undefined,
    resourceErrors: undefined,
    attributes: undefined,
    id: undefined,
    deleted: false,
    responseHeaders: undefined
  }

  requestHandler(state, action) {
    return { ...state,
      requestInProgress: true,
      id: action.id }
  }

  responseHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      deleted: true,
      responseHeaders: action.responseHeaders }
  }

  errorHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      deleted: false,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors }
  }
}
