import React from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, some, keys, clone } from 'lodash'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Component from '../common/Component'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import SelectField from '../common/SelectField'
import SubmitButton from '../common/SubmitButton'
import actions from '../../actions/AncillaryRateFactorSets'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'
import CheckboxField from '../common/CheckboxField'
import LabeledTextField from '../common/LabeledTextField'

export class AncillaryRateFactorSetCreate extends Component {
  static propTypes = {
    create: PropTypes.func,
    reset: PropTypes.func,
    createFlashMessage: PropTypes.func.isRequired,
    created: PropTypes.bool.isRequired,
    resourceErrors: PropTypes.array,
    id: PropTypes.number,
    requestInProgress: PropTypes.bool,
    configuration: PropTypes.object
  }

  static defaultProps = {
    resourceErrors: []
  }

  constructor(props) {
    super(props)
    this.state = { values: { rate_factors: {}, composite: false } }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newValues = clone(prevState.values)

      newValues[field] = value

      return { values: newValues }
    })
  }

  handleFormulaChange = (field, value) => {
    const rateFactorFormulaKeys = value.match(/[A-Za-z0-9_]+/g) || []
    this.setState((prevState) => {
      const newValues = cloneDeep(prevState.values)
      const rateFactors = this.state.values.rate_factors

      newValues.rate_factors = {}

      rateFactorFormulaKeys.forEach((formulaKey) => {
        newValues.rate_factors[formulaKey] = rateFactors[formulaKey] || {}
      })

      newValues[field] = value

      return { values: newValues }
    })
  }

  handleSubmit = async (values) => {
    values.preventDefault()

    const response = await this.props.create(this.createParams())

    if (isNil(response.resourceErrors)) {
      this.props.createFlashMessage('Created Ancillary Rate Factor Set', { tag: 'general' })
    }
  }

  showErrors = () => {
    const errors = this.props.resourceErrors.map((error, index) => (
      <div className="howie_error" key={`error-${index}`}>{error}</div>
    ))

    return errors
  }

  createParams() {
    const params = clone(this.state.values)

    params.rate_factors = keys(params.rate_factors).map((key) => (
      {
        formula_key: key
      }
    ))

    return params
  }

  incompleteFields() {
    const requiredFields = [
      'issuer_id',
      'name',
      'composite',
      'formula',
      'line_of_coverage'
    ]

    return some(requiredFields, (field) => (isNil(this.state.values[field])))
  }

  submitDisabled() {
    return this.incompleteFields()
  }

  redirect(path) {
    return <Redirect push to={`${path}/edit`} />
  }

  render() {
    const {
      created,
      id
    } = this.props

    if (created && !isNil(id)) {
      return this.redirect(`/ancillary_rate_factor_sets/${id}`)
    }

    return (
      <div className="content">
        <h2>New Rate Factor Set</h2>
        <div>
          <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            {this.props.resourceErrors.length > 0 && this.showErrors()}
            <fieldset>
              <LabeledTextField
                key="name"
                name="name"
                label="Name"
                handleChange={this.handleChange}
                value={this.state.values.name}
              />
              <CheckboxField
                name="composite"
                label="Composite?"
                handleChange={this.handleChange}
                value={this.state.values.composite}
              />
              <br />
              <IssuerRemoteOptionsSelectField
                handleChange={this.handleChange}
                name="issuer_id"
                value={this.state.values.issuer_id}
              />
              <SelectField
                name="line_of_coverage"
                label="Product Line"
                options={this.props.configuration.line_of_coverage}
                handleChange={this.handleChange}
                value={this.state.values.line_of_coverage}
              />
              <LabeledTextField
                key="formula"
                name="formula"
                label="Formula"
                handleChange={this.handleFormulaChange}
                value={this.state.values.formula}
              />
            </fieldset>
            <fieldset>
              <SubmitButton
                title="Submit"
                text="Submit"
                loading={this.props.requestInProgress}
                disabled={this.submitDisabled()}
              />
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.ancillaryRateFactorSets.create
  const configuration = state.login.session.configuration.ancillary_rate_factor_sets
  return { id: slice.id,
    created: slice.created,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    configuration }
}

const mapDispatchToProps = {
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}
export default connect(mapStateToProps, mapDispatchToProps)(AncillaryRateFactorSetCreate)
