import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import Component from '../common/Component'
import CheckboxField from '../common/CheckboxField'
import TextAreaField from '../common/TextAreaField'

export default class ReviewValueField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    note: PropTypes.string,
    handleChange: PropTypes.func.isRequired
  }

  static renderedProps = [
    'name',
    'note'
  ]

  get review() {
    const { note } = this.props

    if (isNil(note)) {
      return null
    }

    return (
      <div className="review-note">
        <TextAreaField
          name="review"
          handleChange={this.handleChange}
          value={note}
          rows={1}
        />
      </div>
    )
  }

  get reviewCheckbox() {
    return (
      <CheckboxField
        handleChange={this.handleCheckboxChange}
        name="review-enabled"
        value={!isNil(this.props.note)}
      />
    )
  }

  handleChange = (name, value) => {
    this.props.handleChange(this.props.name, value)
  }

  handleCheckboxChange = (name, checked) => {
    const value = (checked === true) ? '' : null
    this.props.handleChange(this.props.name, value)
  }

  /* eslint-disable react/prop-types */
  render() {
    return (
      <div>
        <div className="review-field-value">
          {this.props.children}
          {this.reviewCheckbox}
        </div>
        {this.review}
      </div>
    )
  }
  /* eslint-enable react/prop-types */
}
