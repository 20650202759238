import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isNil, cloneDeep, some } from 'lodash'
import Component from '../common/Component'
import actions from '../../actions/Embargoes'
import issuerActions from '../../actions/Issuers'
import { createFlashMessage } from '../../actions/FlashMessages'
import EmbargoForm from './EmbargoForm'
import EntitlementsProvider from '../common/EntitlementsProvider'
import alert from '../../alert'
import confirm from '../../confirm'

export class EmbargoEdit extends Component {
  static propTypes = {
    match: PropTypes.object,
    configuration: PropTypes.object,
    entitlements: PropTypes.object,
    update: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    destroyResourceErrors: PropTypes.object,
    attributes: PropTypes.object,
    updated: PropTypes.bool.isRequired,
    destroy: PropTypes.func.isRequired,
    destroyReset: PropTypes.func.isRequired,
    deleted: PropTypes.bool.isRequired,
    createFlashMessage: PropTypes.func.isRequired,
    issuers: PropTypes.array,
    indexIssuers: PropTypes.func.isRequired,
    resetIssuers: PropTypes.func.isRequired,
    issuersRequestInProgress: PropTypes.bool.isRequired
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
    this.props.destroyReset()
  }

  get id() {
    return this.props.match.params.id
  }

  get destroyError() {
    return get(this.props.destroyResourceErrors, 'base')
  }

  get initialAttributes() {
    const newAttributes = cloneDeep(this.props.attributes)

    if (!isNil(newAttributes.embargo_issuers)) {
      newAttributes.embargo_issuers = newAttributes.embargo_issuers.map((v) => ({
        label: v.issuer_label,
        value: v.issuer_id
      }))
    }

    newAttributes.embargo_date_range = {
      start: newAttributes.start_date,
      end: newAttributes.end_date
    }

    return newAttributes
  }

  handleSubmit = (values) => {
    this.props
      .update(this.id, this.translateValues(values))
      .then(({ id }) => {
        this.props.createFlashMessage('Updated embargo', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  translateValues = (values) => {
    const newValues = cloneDeep(values)

    if (!isNil(newValues.embargo_issuers)) {
      const existingEmbargoIssuers = get(this.props.attributes, 'embargo_issuers', [])

      newValues.embargo_issuers = existingEmbargoIssuers.map((v) => {
        if (!values.embargo_issuers.includes(v.issuer_id)) {
          return { id: v.id, _destroy: true }
        }

        return v
      })

      const newEmbargoIssuers = values.embargo_issuers
        .filter((v) => !some(existingEmbargoIssuers, { issuer_id: v }))
        .map((v) => ({ issuer_id: v }))

      newValues.embargo_issuers = newValues.embargo_issuers.concat(newEmbargoIssuers)
    }

    return newValues
  }

  handleDelete = () => {
    const confirmed = confirm('Are you sure you want to delete this embargo?')

    if (confirmed) {
      this.props
        .destroy(this.id)
        .then(() => {
          this.props.createFlashMessage(`Deleted embargo ‘${this.props.attributes.name}’`, { tag: 'index' })
        })
    }
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      entitlements,
      requestInProgress,
      attributes,
      updated,
      deleted
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (!isNil(this.destroyError)) {
      alert(this.destroyError)
    }

    if (isNil(attributes)) {
      return null
    }

    if (updated) {
      return this.redirect('/embargoes')
    }

    if (deleted) {
      return this.redirect('/embargoes')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    let issuers = cloneDeep(this.props.issuers)

    if (isNil(issuers) && !this.props.issuersRequestInProgress) {
      issuers = (attributes.embargo_issuers || []).map((o) => o.issuer)
    }

    return (
      <div className="content">
        <header className="form-header">
          <div>
            <div className="form-header-title">
              <h2>Edit Embargo</h2>
            </div>
            <nav>
              <button
                type="button"
                className="button destructive small"
                title="Delete"
                onClick={this.handleDelete}
              >
Delete
              </button>
            </nav>
          </div>
        </header>
        <div>
          <EntitlementsProvider entitlements={entitlements}>
            <EmbargoForm
              handleSubmit={this.handleSubmit}
              errors={errors}
              configuration={configuration}
              requestInProgress={requestInProgress}
              submitLabel="Update Embargo"
              issuers={issuers}
              resetIssuers={this.props.resetIssuers}
              issuersRequestInProgress={this.props.issuersRequestInProgress}
              indexIssuers={this.props.indexIssuers}
              initialValues={this.initialAttributes}
            />
          </EntitlementsProvider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.embargoes.update
  const showSlice = state.embargoes.show
  const destroySlice = state.embargoes.destroy
  const issuersSlice = state.issuers.index

  const configuration = state.login.session.configuration.embargoes
  const entitlements = state.login.session.configuration.entitlements.embargoes.update

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,
    deleted: destroySlice.deleted,
    destroyResourceErrors: destroySlice.resourceErrors,
    issuers: issuersSlice.records,
    issuersRequestInProgress: issuersSlice.requestInProgress,

    configuration,
    entitlements
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  update: actions.update.main,
  show: actions.show.main,
  destroy: actions.destroy.main,
  reset: actions.update.reset,
  showReset: actions.show.reset,
  destroyReset: actions.destroy.reset,

  indexIssuers: issuerActions.index.main,
  resetIssuers: issuerActions.index.reset,

  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmbargoEdit)
