// Core Libraries
import React from 'react'

// Actions
import actions from '../../actions/Forms'

// Components
import Component from '../common/Component'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import SelectField from '../common/SelectField'
import PlanYearOptionsHelper from '../common/helpers/PlanYearOptionsHelper'

export class FormEdit extends Component {
  static propTypes = defaultPropTypes

  get id() {
    return this.props.match.params.id
  }

  render() {
    return (
      <EditForm {...this.props} id={this.id}>
        <LabeledTextField
          key="title"
          name="title"
          label="Form Title"
          type="text"
        />
        <IssuerRemoteOptionsSelectField
          key="issuer_ids"
          name="issuer_ids"
          label="Issuers"
          recordLabel={['issuer_id', 'name', 'licensed_state_code']}
          filterFields={{ carrier_id_type: 'NAIC Company Number' }}
          errorKey="form_issuers"
          multi
        />
        <SelectField
          key="plan_year"
          name="plan_year"
          label="Plan Year"
          options={PlanYearOptionsHelper.planYearOptionsFromCurrent(1)}
        />
        <SelectField
          key="form_actions"
          name="form_actions"
          label="Form Actions"
          options={this.props.configuration.actions}
          multi
        />
      </EditForm>
    )
  }
}

export default connectEditForm(FormEdit, 'Form', 'forms', actions)
