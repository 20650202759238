import React from 'react'
import publishStateDisplay from './publishStateDisplay'
import PublishErrorModal from '../Medical/PublishErrorModal'

const publishStateDisplayWithModal = (record) => {
  const publishError = record.publish_error
  const publishState = record.publish_state

  if (publishError !== undefined && publishState === 'failed_to_publish') {
    return <PublishErrorModal record={record} />
  }

  return publishStateDisplay(record)
}

export default publishStateDisplayWithModal
