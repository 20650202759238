import MethodReducerGenerator from './MethodReducerGenerator'

export default class IndexReducerGenerator extends MethodReducerGenerator {
  constructor(name, basePath) {
    super('INDEX', name, basePath)
  }

  static initialState = {
    requestInProgress: false,
    generalError: undefined,
    resourceErrors: undefined,
    params: undefined,
    records: undefined,
    pagination: undefined,
    responseHeaders: undefined
  }

  requestHandler(state, action) {
    return { ...state,
      requestInProgress: true,
      params: action.params }
  }

  responseHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      records: action.records,
      pagination: action.pagination,
      responseHeaders: action.responseHeaders }
  }

  errorHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors }
  }
}
