import React from 'react'

import actions from '../../actions/Issuers'

import Component from './Component'
import ConnectedRemoteOptionsSelectField,
{ connectRemoteOptionsSelectField, defaultPropTypes }
  from './ConnectedRemoteOptionsSelectField'

export class IssuerRemoteOptionsSelectField extends Component {
  static propTypes = defaultPropTypes

  static defaultProps = {
    label: 'Issuer',
    multi: false,
    recordLabel: ['carrier_brand.name', 'licensed_state_code', 'issuer_id'],
    title: 'issuer_id'
  }

  render() {
    return (
      <ConnectedRemoteOptionsSelectField {...this.props} />
    )
  }
}

export default connectRemoteOptionsSelectField(IssuerRemoteOptionsSelectField, 'issuers', actions)
