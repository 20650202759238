import { get, isNil } from 'lodash'
import parseLinkHeader from 'parse-link-header'

function parseIntIfPresent(value) {
  if (!isNil(value)) {
    return parseInt(value, 10)
  }

  return null
}

export default function parsePaginationHeaders(headers) {
  const linkHeader = headers.link

  const currentPage = parseIntIfPresent(get(headers, 'x-page', null))
  const perPage = parseIntIfPresent(get(headers, 'x-per-page', null))
  const total = parseIntIfPresent(get(headers, 'x-total', null))

  if (isNil(currentPage) || isNil(perPage) || isNil(total)) {
    return null
  }

  const parsedPagination = parseLinkHeader(linkHeader)

  const pageTotal = currentPage * perPage
  const rangeMin = pageTotal - (perPage - 1)
  const rangeMax = Math.min(pageTotal, total)

  return {
    first: get(parsedPagination, 'first', null),
    prev: get(parsedPagination, 'prev', null),
    last: get(parsedPagination, 'last', null),
    next: get(parsedPagination, 'next', null),
    currentPage,
    perPage,
    total,
    currentRange: [rangeMin, rangeMax],
    pages: Math.ceil(total / perPage)
  }
}
