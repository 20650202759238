import React from 'react'
import Component from '../common/Component'
import MedicalBenefitsSetComponent from './MedicalBenefitsSetComponent'
import { benefitNames } from './fields'
import BenefitsSetForm from '../BenefitsSet/BenefitsSetForm'

export default class MedicalBenefitsSetForm extends Component {
  render() {
    return (
      <BenefitsSetForm
        benefitNames={benefitNames}
        benefitsSetComponent={MedicalBenefitsSetComponent}
        productLine="Medical"
        {...this.props}
      />
    )
  }
}
