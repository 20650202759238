import React from 'react'
import { get } from 'lodash'

const IssuersCellDisplayRenderer = (record, column) => {
  const values = get(record, column.key, [])
  const issuerRows = values.map((issuer, i) => (
    <tr key={`issuer_index_row-${record.vericred_id}-${issuer.id}`}>
      <td className="issuer-id">{issuer.issuer.issuer_id}</td>
      <td className="issuer-name">{issuer.issuer.name}</td>
    </tr>
  ))

  return (
    <div className="document-issuer">
      <table className="document-issuer">
        <tbody>
          {issuerRows}
        </tbody>
      </table>
    </div>
  )
}

export default IssuersCellDisplayRenderer
