import uuidv4 from 'uuid/v4'
import { CREATE_FLASH_MESSAGE } from './consts'

export default function createFlashMessage(message, data = {}) {
  const id = uuidv4()

  return {
    type: CREATE_FLASH_MESSAGE,
    id,
    message,
    data
  }
}
