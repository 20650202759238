import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import actions from '../../actions/IssuerDisclaimers'

export class IssuerDisclaimerShow extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    issuer_disclaimer: PropTypes.object,
    match: PropTypes.object,
    configuration: PropTypes.object
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
  }

  get id() {
    return this.props.match.params.id
  }

  formattedStartDate = (value) => {
    return moment(value).utc().format('YYYY-MM-DD')
  }

  formattedEndDate = (value) => {
    return value ? this.formattedStartDate(value) : 'Active Indefinitely'
  }

  render() {
    const { configuration } = this.props
    const issuerDisclaimer = this.props.issuer_disclaimer // eslint-disable-line camelcase

    if (isNil(issuerDisclaimer) || isNil(configuration)) {
      return null
    }

    return (
      <div className="content">
        <header className="form-header">
          <div>
            <div className="form-header-title">
              <h2>Issuer Disclaimer Info</h2>
            </div>
          </div>
          <div className="form-header-actions">
            <Link
              title="Edit"
              className="button quiet-button small"
              to={`/issuer_disclaimers/${this.id}/edit`}
            >
              Edit
            </Link>
            <Link
              title="Index"
              className="button quiet-button small"
              to="/issuer_disclaimers"
            >
              All Disclaimers
            </Link>
          </div>
        </header>
        <div>
          <table className="pure-table">
            <tbody>
              <tr>
                <td><strong>Issuer</strong></td>
                <td>{issuerDisclaimer.issuer.name}</td>
              </tr>
              <tr>
                <td><strong>Market</strong></td>
                <td>{issuerDisclaimer.market}</td>
              </tr>
              <tr>
                <td><strong>Line of Coverage</strong></td>
                <td>{issuerDisclaimer.line_of_coverage}</td>
              </tr>
              <tr>
                <td><strong>Disclaimer Effective Date</strong></td>
                <td>{this.formattedStartDate(issuerDisclaimer.active_from)}</td>
              </tr>
              <tr>
                <td><strong>Disclaimer Expiration Date</strong></td>
                <td>{this.formattedEndDate(issuerDisclaimer.active_until)}</td>
              </tr>
              <tr>
                <td><strong>Disclaimer</strong></td>
                <td><p>{issuerDisclaimer.disclaimer}</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.issuerDisclaimers.show

  const configuration = state.login.session.configuration.issuer_disclaimers
  return {
    issuer_disclaimer: slice.attributes,
    configuration
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  show: actions.show.main,
  reset: actions.show.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(IssuerDisclaimerShow)
