import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { upperFirst, some, map } from 'lodash'
import qs from 'qs'

import { clearSession, resetPassword, validateToken } from '../../actions/Login'
import LabeledTextField from '../common/LabeledTextField'
import { createFlashMessage } from '../../actions/FlashMessages'

export class PasswordReset extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    clearSession: PropTypes.func,
    resetPassword: PropTypes.func,
    success: PropTypes.bool,
    errors: PropTypes.any,
    validateToken: PropTypes.func,
    expired: PropTypes.bool,
    createFlashMessage: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      password: null,
      password_confirmation: null,
      errors: []
    }
  }

  componentDidMount() {
    this.props.validateToken(this.resetPasswordToken)
    this.props.clearSession()
  }

  get resetPasswordToken() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (this.state.password !== this.state.password_confirmation) {
      this.setState({ errors: ['Password confirmation does not match'] })
    } else {
      this.props.resetPassword({ token: this.resetPasswordToken, password: this.state.password })
    }
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value })
  }

  renderErrors() {
    if (!some(this.state.errors) && !some(this.props.errors)) {
      return null
    }

    return (
      <fieldset>
        <div className="error">
          {map(this.state.errors, (error) => (<div key={error}>{upperFirst(error)}</div>))}
          {map(this.props.errors, (error) => (<div key={error}>{upperFirst(error)}</div>))}
        </div>
      </fieldset>
    )
  }

  render() {
    if (this.props.success) {
      return <Redirect push to="/" />
    }

    if (this.props.expired) {
      this.props.createFlashMessage('This link is expired. Request a new password.', { tag: 'general' })

      return <Redirect push to="/forgot_password" />
    }

    return (
      <div className="password-reset-container">
        <form id="password-reset-form" className="pure-u-1 pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
          <fieldset>
            <LabeledTextField
              key="password"
              name="password"
              label="New Password"
              type="password"
              handleChange={this.handleChange}
              value={this.state.password}
            />
            <LabeledTextField
              key="password_confirmation"
              name="password_confirmation"
              label="Confirm Password"
              type="password"
              handleChange={this.handleChange}
              value={this.state.password_confirmation}
            />
          </fieldset>
          {this.renderErrors()}
          <fieldset>
            <button className="pure-button pure-input-1 pure-button-primary" title="Change Password" type="submit">
              Change Password
            </button>
          </fieldset>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.login.resetPassword
  }
}

const mapDispatchToProps = { validateToken, resetPassword, clearSession, createFlashMessage }

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
