import {
  REQUEST_UPDATE_CENSUS,
  REQUEST_CENSUS,
  REQUEST_CREATE_CENSUS,
  RECEIVE_CENSUS,
  RECEIVE_CENSUS_ERROR
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  id: undefined,
  attributes: undefined,
  generalError: undefined,
  resourceErrors: undefined,
  updateInProgress: false,
  createInProgress: false,
  getInProgress: false
}

export default function CensusReducer(state = defaultState, action) {
  switch (action.type) {
  case REQUEST_UPDATE_CENSUS:
    return { ...state,
      updateInProgress: true,
      id: action.id,
      attributes: action.attributes,
      generalError: undefined,
      resourceErrors: undefined }
  case REQUEST_CENSUS:
    return { ...state,
      getInProgress: true,
      id: action.id,
      attributes: undefined,
      generalError: undefined,
      resourceErrors: undefined }
  case REQUEST_CREATE_CENSUS:
    return { ...state,
      createInProgress: true,
      id: undefined,
      attributes: undefined,
      generalError: undefined,
      resourceErrors: undefined }
  case RECEIVE_CENSUS:
    return { ...state,
      id: action.id,
      attributes: action.attributes,
      generalError: undefined,
      resourceErrors: undefined,
      updateInProgress: false,
      createInProgress: false,
      getInProgress: false }
  case RECEIVE_CENSUS_ERROR:
    return { ...state,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors,
      updateInProgress: false,
      createInProgress: false,
      getInProgress: false }
  default:
    return { ...state }
  }
}
