import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { isNil } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../actions/Censuses'
import alert from '../../../alert'
import CensusForm from './CensusForm'

export class CensusCreate extends Component {
  static propTypes = {
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    indexReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number
  }

  static initialValues = {
    name: '',
    families_attributes: []
  }

  componentWillUnmount() {
    this.props.indexReset()
    this.props.reset()
  }

  handleSubmit = (values) => {
    this.props.create(values)
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      created,
      id
    } = this.props

    const requestInProgress = this.props.requestInProgress === true

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (created && !isNil(id)) {
      return this.redirect(`/censuses/${id}/edit`)
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <CensusForm
        onSubmit={this.handleSubmit}
        errors={errors}
        initialValues={CensusCreate.initialValues}
        submitLabel="Create Census"
        requestInProgress={requestInProgress}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.censuses.create

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,
  indexReset: actions.index.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CensusCreate)
