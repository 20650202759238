import { get, has, merge } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

export default function connectIndex(
  component,
  key,
  actions,
  filters = [],
  additionalProps = {},
  otherActions = [],
  otherKeys = [],
  otherIndex = null,
  otherActionsToPropMaps = []
) {
  const mapStateToProps = (state) => {
    let slice
    if (otherIndex) {
      slice = state[key][otherIndex]
    } else {
      slice = state[key].index
    }

    const filtersSlice = { ...filters.reduce((acc, filter) => {
      if (has(filter, 'config')) {
        acc[filter.key] = {
          records: get(state, `login.session.configuration.${filter.config}`),
          config: true,
          type: 'standardSelectField'
        }
      } else if (has(filter, 'options')) {
        acc[filter.key] = {
          records: filter.options,
          config: true,
          type: 'standardSelectField'
        }
      } else if (get(filter, 'dateField', false)) {
        acc[filter.key] = {
          records: null,
          config: true,
          type: 'dateField'
        }
      } else {
        acc[filter.key] = get(state, filter.path)
      }
      return acc
    }, {}) }

    const otherKeysObject = otherKeys.reduce((acc, cur) => {
      const otherSlice = state[cur.reducer]
      acc[cur.key] = otherSlice[cur.action]
      return acc
    }, {})

    const otherActionsToPropsMapsObject = otherActionsToPropMaps.reduce((acc, cur) => {
      const otherSlice = state[key][cur.action]
      acc[cur.prop] = otherSlice[cur.prop]
      return acc
    }, {})

    return { configuration: state.login.session.configuration,
      generalError: slice.generalError,
      resourceErrors: slice.resourceErrors,
      headers: slice.responseHeaders,
      records: slice.records,
      pagination: slice.pagination,
      flashMessages: state.flashMessages.messages,
      filters: filtersSlice,
      ...additionalProps,
      ...otherKeysObject,
      ...otherActionsToPropsMapsObject }
  }

  const otherDispatchProps = (dispatch) => {
    return otherActions.reduce((acc, cur) => {
      acc[cur.key] = bindActionCreators(cur.action, dispatch)
      return acc
    }, {})
  }

  const mapDispatchToPropsObject = (dispatch) => {
    let action = 'index'
    if (otherIndex) {
      action = otherIndex
    }
    const actionObject = {
      index: bindActionCreators(actions[action].main, dispatch),
      reset: bindActionCreators(actions[action].reset, dispatch),
      filterActions: filters.reduce((acc, filter) => {
        if (has(filter, 'actions')) {
          acc[filter.key] = bindActionCreators(filter.actions, dispatch)
        }

        return acc
      }, {})
    }
    return merge(actionObject, otherDispatchProps(dispatch))
  }

  const mapDispatchToProps = (dispatch) => (
    mapDispatchToPropsObject(dispatch)
  )

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
