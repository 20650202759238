import { isNil, lowerCase, difference, keys } from 'lodash'
import ActionTypes from './ActionTypes'

export default class GroupGenerator {
  constructor(name, basePath, parameterName = null) {
    this.name = name
    this.basePath = basePath
    this.parameterName = parameterName || name
    this.methods = [
      ActionTypes.CREATE,
      ActionTypes.SHOW,
      ActionTypes.INDEX,
      ActionTypes.UPDATE,
      ActionTypes.DESTROY,
      ActionTypes.BATCHDESTROY
    ]
  }

  static generatorClasses = {}

  nameForMethod(method) {
    return lowerCase(method)
  }

  get generators() {
    if (!isNil(this._generators)) {
      return this._generators
    }

    const generators = {}
    const missingActionGeneratorClasses = difference(this.methods, keys(this.constructor.generatorClasses))

    if (missingActionGeneratorClasses.length > 0) {
      throw new Error(`Missing generator classes for actions: ${missingActionGeneratorClasses.join(', ')}`)
    }

    for (const method of this.methods) {
      generators[method] = new this.constructor.generatorClasses[method](this.name, this.basePath, this.parameterName)
    }

    this._generators = generators

    return this._generators
  }

  generate = () => {}
}
