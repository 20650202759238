import React from 'react'
import Component from '../common/Component'
import BenefitsSetBulkEditsComponent from '../BenefitsSet/BenefitsSetBulkEditsComponent'
import { benefitLabels, metadatumFieldsBulkEdit } from './fields'

const defaultFields = [
  'hios_ids',
  'name',
  'audience',
  'plan_year',
  'effective_date',
  'expiration_date',
  'drug_formulary_url',
  'sbc_name',
  'embedded_deductible',
  'gated',
  'individual_medical_deductible_in_network',
  'individual_medical_deductible_out_of_network',
  'family_medical_deductible_in_network',
  'family_medical_deductible_out_of_network',
  'individual_drug_deductible_in_network',
  'individual_drug_deductible_out_of_network',
  'family_drug_deductible_in_network',
  'family_drug_deductible_out_of_network',
  'individual_medical_moop_in_network',
  'individual_medical_moop_out_of_network',
  'family_medical_moop_in_network',
  'family_medical_moop_out_of_network',
  'individual_drug_moop_in_network',
  'individual_drug_moop_out_of_network',
  'family_drug_moop_in_network',
  'family_drug_moop_out_of_network',
  'plan_coinsurance_in_network',
  'plan_coinsurance_out_of_network',
  'primary_care_physician_out_of_network',
  'specialist_out_of_network',
  'preventative_care_out_of_network',
  'diagnostic_test_out_of_network',
  'lab_test_out_of_network',
  'imaging_center_out_of_network',
  'imaging_physician_out_of_network',
  'outpatient_hospital_out_of_network',
  'outpatient_ambulatory_care_center_out_of_network',
  'outpatient_physician_out_of_network',
  'emergency_room_limit',
  'inpatient_facility_out_of_network',
  'inpatient_physician_out_of_network',
  'outpatient_mental_health_out_of_network',
  'outpatient_substance_out_of_network',
  'inpatient_mental_health_out_of_network',
  'inpatient_substance_out_of_network',
  'prenatal_care_out_of_network',
  'postnatal_care_out_of_network',
  'inpatient_birth_out_of_network',
  'inpatient_birth_physician_out_of_network',
  'home_health_care_out_of_network',
  'home_health_care_limit',
  'rehabilitation_services_out_of_network',
  'rehabilitation_services_limit',
  'habilitation_services_out_of_network',
  'habilitation_services_limit',
  'skilled_nursing_out_of_network',
  'skilled_nursing_limit',
  'durable_medical_equipment_out_of_network',
  'hospice_service_out_of_network',
  'child_eye_exam_out_of_network',
  'child_eye_exam_limit',
  'child_eyewear_out_of_network',
  'child_eyewear_limit',
  'child_dental_out_of_network',
  'child_dental_limit'
]

export default class MedicalBenefitsSetBulkEditsComponent extends Component {
  render() {
    return (
      <BenefitsSetBulkEditsComponent
        benefitLabels={benefitLabels}
        metadatumFields={metadatumFieldsBulkEdit}
        defaultFields={defaultFields}
        benefitsSetUrl="/medical_benefits"
        productLine="medical"
        hideId
        includeSbcCheckbox
        {...this.props}
      />
    )
  }
}
