import actions from '../../actions/BenefitsSetIdentifiers'
import connectIndex from '../../lib/connectIndex'
import BulkIdentifierComponent from '../common/BulkIdentifierComponent'

export default connectIndex(
  BulkIdentifierComponent,
  'benefitsSetIdentifiers',
  actions,
  [],
  {
    indexParams: { product_line: 'life' },
    redirectTo: '/life_benefits/bulk'
  }
)
