// Core
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

// Helpers
import { isEmpty } from 'lodash'

// Components
import Component from './Component'
import Form from './Form'

export default class CreateReviewForm extends Component {
  static propTypes = {
    modelName: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
    createAction: PropTypes.func.isRequired,
    resetCreateAction: PropTypes.func.isRequired,
    resourceActionStatuses: PropTypes.object.isRequired,
    attributes: PropTypes.object
  }

  static defaultProps = {
    attributes: {}
  }

  constructor(props) {
    super(props)

    this.state = { rejection_reason: '' }
  }

  componentWillUnmount() {
    this.props.resetCreateAction()
  }

  get reviewType() {
    return isEmpty(this.state.rejection_reason) ? 'Approval' : 'Rejection'
  }

  get created() {
    return !!(this.props.resourceActionStatuses.create.created)
  }

  get submitLabel() {
    return `Submit ${this.reviewType}`
  }

  get submitButtonClassName() {
    const classNames = ['pure-button', 'pure-input-1']

    if (this.reviewType === 'Approval') {
      classNames.push('pure-button-primary')
    } else {
      classNames.push('pure-button-destructive')
    }

    return classNames.join(' ')
  }

  handleChange = (data) => {
    this.setState({ rejection_reason: data.rejection_reason })
  }

  handleSubmit = (data) => {
    return this.props.createAction(data)
  }

  render() {
    if (this.created) return (<Redirect push to={this.props.redirectTo} />)

    return (
      <div className="content">
        <header className="form-header">
          <div className="form-header-title">
            <h2>Create {this.props.modelName}</h2>
          </div>
        </header>
        <Form
          actionStatus={this.props.resourceActionStatuses.create}
          handleSubmit={this.handleSubmit}
          attributes={this.props.attributes}
          submitLabel={this.submitLabel}
          submitButtonClassName={this.submitButtonClassName}
          handleChange={this.handleChange}
        >

          {this.props.children}
        </Form>
      </div>
    )
  }
}

