import React from 'react'

// Actions
import actions from '../../actions/Documents'

import Component from '../common/Component'
import CreateForm, { defaultPropTypes, connectCreateForm } from '../common/CreateForm'
import LabeledTextField from '../common/LabeledTextField'
import LabeledTextAreaField from '../common/LabeledTextAreaField'
import ConnectedFileUploaderComponent from '../common/ConnectedFileUploaderComponent'
import CheckboxField from '../common/CheckboxField'
import CheckboxFieldSet from '../common/CheckboxFieldSet'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import PrivateExchangeRemoteOptionsSelectField from '../common/PrivateExchangeRemoteOptionsSelectField'

import SourceContentsSetComponent from './SourceContentsSetComponent'

import SelectField from '../common/SelectField'

import disableSubmit from './disableSubmit'

export class DocumentCreate extends Component {
  static propTypes = defaultPropTypes

  static defaultAttributes = { requires_manual_build: false }

  render() {
    return (
      <CreateForm {...this.props} attributes={DocumentCreate.defaultAttributes} disableSubmit={disableSubmit}>
        <ConnectedFileUploaderComponent
          name="blob_id"
          label="File"
          disabled={false}
        />

        <LabeledTextField
          name="name"
          label="Document Name"
        />

        <SelectField
          name="year_quarters"
          label="Quarter(s)"
          options={this.props.configuration.year_quarters}
          multi
        />

        <SelectField
          name="state_id"
          label="State"
          options={this.props.configuration.state_id}
        />

        <SelectField
          name="plan_audiences"
          label="Audience(s)"
          options={this.props.configuration.audience}
          multi
        />

        <SelectField
          name="product_line"
          label="Product Line"
          options={this.props.configuration.product_line}
        />

        <SelectField
          name="source"
          label="Source"
          options={this.props.configuration.source}
        />

        <IssuerRemoteOptionsSelectField
          name="issuers"
          multi
        />

        <SelectField
          name="source_file_type"
          label="File Type"
          options={this.props.configuration.source_file_types}
        />

        <PrivateExchangeRemoteOptionsSelectField
          name="private_exchange_id"
        />

        <CheckboxField
          inputCssClass="pure-checkbox"
          name="privileged"
          label="Privileged"
        />

        <LabeledTextAreaField
          name="notes"
          label="Notes"
          type="textarea"
        />

        <CheckboxField
          inputCssClass="pure-checkbox"
          name="requires_manual_build"
          label="Requires Manual Build"
        />

        <CheckboxFieldSet
          name="plan_types"
          label="Plan Types"
          options={this.props.configuration.plan_types}
        />

        <SourceContentsSetComponent
          name="source_contents"
          options={this.props.configuration.source_contents}
        />
      </CreateForm>
    )
  }
}

export default connectCreateForm(DocumentCreate, 'Document', 'documents', actions)
