import { has, isNil, castArray, cloneDeep } from 'lodash'
import planTypePrepopulator from './prepopulators/planTypePrepopulator'
import marketPrepopulator from './prepopulators/marketPrepopulator'
import planCalendarPrepopulator from './prepopulators/planCalendarPrepopulator'
import BenefitListPrepopulator from './prepopulators/BenefitListPrepopulator'

const prepopulators = {
  audience: [
    marketPrepopulator,
    planCalendarPrepopulator
  ],
  state_id: planCalendarPrepopulator,
  carrier_id: planCalendarPrepopulator,
  plan_type: planTypePrepopulator
}

const listPrepopulators = [
  new BenefitListPrepopulator([
    'inpatient_facility',
    'inpatient_mental_health',
    'inpatient_substance',
    'inpatient_birth',
    'skilled_nursing',
    'hospice_service'
  ], { ignoreLimit: true }),
  new BenefitListPrepopulator([
    'lab_test',
    'diagnostic_test'
  ]),
  new BenefitListPrepopulator([
    'prenatal_care',
    'postnatal_care'
  ]),
  new BenefitListPrepopulator([
    'outpatient_hospital',
    'outpatient_ambulatory_care_center'
  ]),
  new BenefitListPrepopulator([
    'outpatient_mental_health',
    'outpatient_substance'
  ]),
  new BenefitListPrepopulator([
    'habilitation_services',
    'rehabilitation_services'
  ]),
  new BenefitListPrepopulator([
    'ambulance',
    'emergency_room'
  ], { condition: (state) => ['EPO', 'HMO'].includes(state.metadatum.plan_type) })
]

export default function prepopulator(changedField, state, config) {
  if (has(prepopulators, changedField)) {
    let newState = cloneDeep(state)

    castArray(prepopulators[changedField]).forEach((fn) => {
      newState = fn(newState, config)
    })

    return newState
  }

  const [benefitId, tierId] = changedField.split('-', 2)
  const listPrepopulator = listPrepopulators.find((p) => p.includesBenefit(benefitId))

  if (!isNil(listPrepopulator)) {
    return listPrepopulator.prepopulate(benefitId, tierId, state)
  }

  return state
}
