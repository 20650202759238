import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class ActionButtonsComponent extends Component {
  static propTypes = {
    disableActionButtons: PropTypes.bool,
    hideActionButtons: PropTypes.bool,
    actions: PropTypes.array
  }

  render() {
    const hide = this.props.hideActionButtons ? 'hidden' : ''
    return (
      <div>
        <fieldset className={hide}>
          {this.props.actions.map((action, index, array) => (
            <button
              key={action.id}
              className={`pure-button pure-input-1 pure-button-primary ${action.cssClass}`}
              title={action.title}
              id={action.id}
              onClick={action.handleOnClick}
              disabled={this.props.disableActionButtons}
            >
              {action.title}
            </button>
          ))}
        </fieldset>
      </div>
    )
  }
}

export default ActionButtonsComponent
