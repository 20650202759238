import { isEmpty } from 'lodash'

export default (originalState) => {
  const state = originalState
  if (state.metadatum.audience === 'Medicare Advantage') {
    state.metadatum.market = 'On Market'
  }

  if (state.metadatum.audience === 'Large Group') {
    state.metadatum.market = 'Off Market'
  }

  if (state.metadatum.audience === 'Large Group Custom') {
    state.metadatum.market = 'Off Market'
  }

  if (!isEmpty(state.metadatum.market)) {
    return state
  }

  if (state.metadatum.audience === 'Individual') {
    state.metadatum.market = 'On Market'
  }

  if (state.metadatum.audience === 'Small Group') {
    state.metadatum.market = 'Off Market'
  }

  return state
}
