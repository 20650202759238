import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/VisionBenefitsSets'
import { createFlashMessage } from '../../actions/FlashMessages'
import VisionBenefitsSetForm from './VisionBenefitsSetForm'
import BenefitsSetCreate from '../BenefitsSet/BenefitsSetCreate'
import issuerActions from '../../actions/Issuers'

const defaultValues = {
  document: null,
  metadatum: {
    stand_alone: null
  },
  plan_external_ids: [],
  benefits: []
}

const mapStateToProps = (state) => {
  const slice = state.visionBenefitsSets.create
  const configuration = state.login.session.configuration.vision_benefits
  const remoteOptionsSelectStates = { issuers: state.issuers.index }

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestComplete: slice.requestComplete,
    id: slice.id,
    created: slice.created,
    configuration,
    remoteOptionsSelectStates,
    benefitsSetForm: VisionBenefitsSetForm,
    defaultValues,
    productLine: 'Vision',
    benefitsSetPath: 'vision_benefits'
  }
}

const mapDispatchToProps = (dispatch) => ({
  create: bindActionCreators(actions.create.main, dispatch),
  reset: bindActionCreators(actions.create.reset, dispatch),

  remoteOptionsSelectActions: {
    issuersIndex: { main: bindActionCreators(issuerActions.index.main, dispatch),
      reset: bindActionCreators(issuerActions.index.reset, dispatch) }
  },

  createFlashMessage
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetCreate)
