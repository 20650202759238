// TODO: 156486135 Remove this and all of it's usage when the server sends a better serialized error response

import { isNil, get, set, forOwn, isObject, isEmpty } from 'lodash'

function keyParentNestedErrorKey(key, nestedErrors) {
  let foundKey
  const keyparts = key.split('.')
  while (!isEmpty(keyparts)) {
    let newKey = keyparts.join('.')
    // remove [DIGITS] somekey.anotherkey[DIGITS]
    newKey = newKey.replace(/\[\d+\]$/g, '')

    const existingObject = get(nestedErrors, newKey)
    if (existingObject) {
      foundKey = newKey
      break
    }
    keyparts.splice(-1, 1)
  }

  return foundKey
}

function nestResourceErrors(errors) {
  const nestedErrors = {}

  forOwn(errors, (error, key) => {
    const keyObject = get(nestedErrors, key)
    if (keyObject && isObject(keyObject)) {
      set(nestedErrors, `${key}.base`, error)
    } else {
      const parentKey = keyParentNestedErrorKey(key, nestedErrors)

      if (parentKey) {
        const lastKeyObjectValue = get(nestedErrors, parentKey)
        if (!isObject(lastKeyObjectValue)) {
          set(nestedErrors, `${parentKey}.base`, lastKeyObjectValue)
        }
      }

      set(nestedErrors, key, error)
    }
  })

  return nestedErrors
}
export default function parseError(error, nestErrors = true) {
  let generalError
  let resourceErrors = get(error, 'response.data.errors')

  if (isNil(resourceErrors)) {
    generalError = get(error, 'response.data.error', 'An unknown error has occurred.')
  } else if (nestErrors) {
    resourceErrors = nestResourceErrors(resourceErrors)
  }

  return {
    generalError,
    resourceErrors: resourceErrors || error.response.data
  }
}
