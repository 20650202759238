import { isNil, max, uniq } from 'lodash'

export default function totalTiersCountForBenefits(benefits) {
  if (isNil(benefits) || benefits.length === 0) {
    return 2
  }

  const benefitLengths = benefits.map((b) => {
    if (isNil(b.tiers)) {
      return 2
    }

    const tierNumbers = b.tiers.map((t) => {
      if (t.option.match(/\d+/)) {
        return parseInt(t.option.replace(/[^\d]+/, ''), 0)
      }
      return 0
    })

    return Math.max(2, max(uniq(tierNumbers)) + 1)
  })

  return max(benefitLengths)
}
