import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from '../common/Component'
import documentActions from '../../actions/RemoveDocuments'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'

import ActionModal from '../PlanProducts/ActionModal'

class RemoveDocumentFromPlan extends Component {
  static defaultProps = {
    documentType: 'SBC'
  }

  static propTypes = {
    documentType: PropTypes.oneOf(['SBC', 'SOB']),
    benefitsSetId: PropTypes.number.isRequired,
    planName: PropTypes.string.isRequired,
    show: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    createFlashMessage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = { modal: false }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => { }
    this.props.reset()
    this.props.showReset()
  }

  onClick = (event) => {
    event.preventDefault()
    this.setState({ modal: true })
  }

  handleClose = () => {
    this.setState({ modal: false })
  }

  handleAction = () => {
    this.props.create({ benefits_set_id: this.props.benefitsSetId, document_type: this.props.documentType })
      .then(() => {
        this.props.showReset()
        this.props.show(this.props.benefitsSetId)
        this.setState({ modal: false })
        this.props.createFlashMessage(
          `${this.props.documentType} has been removed succesffuly from ${this.props.planName}`,
          { tag: 'general' }
        )
      })
  }

  render() {
    const primaryMsg = <h3>Remove {this.props.documentType} from <strong>{this.props.planName}</strong>?</h3>
    const secondaryMsg = <p>All associated layers will be deleted, please confirm to proceed.</p>

    return (
      <div className="action-nav-item--right">
        <button
          className="modal-button button quiet-button small"
          onClick={this.onClick}
        >
          {`Remove ${this.props.documentType} from plan`}
        </button>

        {this.state.modal
          && (
            <ActionModal
              actionType={`Remove ${this.props.documentType}`}
              savingText={`Removing ${this.props.documentType}...`}
              primaryConfirmationMessage={primaryMsg}
              secondaryConfirmationMessage={secondaryMsg}
              handleAction={this.handleAction}
              handleClose={this.handleClose}
            />
          )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: documentActions.create.main,
  reset: documentActions.create.reset,
  createFlashMessage
}, dispatch)

export default connect(null, mapDispatchToProps)(RemoveDocumentFromPlan)
