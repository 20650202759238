import { pick, isEqual, isNil } from 'lodash'

export default class StorePersistor {
  static whitelist = [
    'login.session.nonce'
  ]

  static stateKey = 'initialState'

  constructor(clientStorage = window.localStorage) {
    this.clientStorage = clientStorage
  }

  currentState() {
    return pick(this.store.getState(), StorePersistor.whitelist)
  }

  initialState() {
    if (isNil(this.clientStorage)) {
      return {}
    }

    const initialStateJSON = this.clientStorage.getItem(StorePersistor.stateKey)

    if (isNil(initialStateJSON)) {
      return {}
    }

    return pick(JSON.parse(initialStateJSON), StorePersistor.whitelist)
  }

  setState(newState) {
    this.clientStorage.setItem(StorePersistor.stateKey, JSON.stringify(newState))
  }

  subscribe(store) {
    this.store = store

    if (isNil(this.clientStorage)) {
      return
    }

    let previouseStoredState = this.currentState()

    this.store.subscribe(() => {
      const newState = this.currentState()

      if (!isEqual(previouseStoredState, newState)) {
        this.setState(newState)
        previouseStoredState = newState
      }
    })
  }
}
