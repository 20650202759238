import { combineReducers } from 'redux'
import GoogleReducer from './GoogleReducer'
import SessionReducer from './SessionReducer'
import ResetPasswordReducer from './ResetPasswordReducer'

export default combineReducers({
  google: GoogleReducer,
  session: SessionReducer,
  resetPassword: ResetPasswordReducer
})
