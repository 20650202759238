// Core Libraries
import React from 'react'

// Actions
import actions from '../../actions/IssuerStatuses'

// Components
import Component from '../common/Component'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'
import CheckboxField from '../common/CheckboxField'

import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import PrivateExchangeRemoteOptionsSelectField from '../common/PrivateExchangeRemoteOptionsSelectField'

export class IssuerStatusEdit extends Component {
  static propTypes = defaultPropTypes

  get id() {
    return this.props.match.params.id
  }

  render() {
    return (
      <EditForm {...this.props} id={this.id}>
        <IssuerRemoteOptionsSelectField
          key="issuer_id"
          name="issuer_id"
        />

        <LabeledTextField
          key="active_from"
          name="active_from"
          label="Active From"
          placeholder="YYYY-MM-DD"
          type="text"
        />

        <LabeledTextField
          key="active_until"
          name="active_until"
          label="Active Until"
          placeholder="YYYY-MM-DD"
          type="text"
        />

        <SelectField
          key="state_id"
          name="state_id"
          label="State"
          options={this.props.configuration.states}
        />

        <SelectField
          key="product_line"
          name="product_line"
          label="Line of Coverage"
          options={this.props.configuration.product_lines}
        />

        <SelectField
          key="plan_audience"
          name="plan_audience"
          label="Market"
          options={this.props.configuration.plan_audiences}
        />

        <PrivateExchangeRemoteOptionsSelectField
          name="private_exchange_id"
        />

        <CheckboxField
          name="privileged"
          label="Privileged"
        />

        <LabeledTextField
          key="pd_score"
          name="pd_score"
          label="PD Score"
          min={0}
          max={5}
        />

        <LabeledTextField
          key="pr_score"
          name="pr_score"
          label="PR Score"
          min={0}
          max={5}
        />

        <LabeledTextField
          key="sa_score"
          name="sa_score"
          label="SA Score"
          min={0}
          max={5}
        />

        <LabeledTextField
          key="sbc_score"
          name="sbc_score"
          label="SBC Score"
          min={0}
          max={5}
        />
      </EditForm>
    )
  }
}

export default connectEditForm(IssuerStatusEdit, 'Issuer Status', 'issuer_statuses', actions)
