import ReducerGenerator from '../../lib/ReducerGenerator'

const generator = new ReducerGenerator(
  'benefits_set_identifier',
  '/benefits_set_identifiers',
  'benefits_set'
)
const reducer = generator.generate()

export default reducer
