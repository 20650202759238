import React from 'react'
import PropTypes from 'prop-types'
import {
  cloneDeep, isNil, map, reduce, range, isEmpty
} from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import Component from '../common/Component'
import DiffCellRenderer from './DiffCellRenderer'
import FieldDefinition from './BenefitsSetFieldDefinition'
import DiffSetColumns from '../../lib/DiffSetColumns'

export default class DiffSet extends Component {
  static propTypes = {
    records: PropTypes.array.isRequired,
    defaultFields: PropTypes.array.isRequired,
    metadataFields: PropTypes.object.isRequired,
    benefitFields: PropTypes.object.isRequired,
    handleApprovedRecords: PropTypes.func,
    configuration: PropTypes.object.isRequired,
    alwaysShowFields: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    const records = this.shallowRecords(props.records.map((i) => i.approved_benefits_set))
    const approvedRecords = this.shallowRecords(props.records.map((i) => i.full_benefits_set))
    const initialRecords = records.reduce((memo, value) => {
      return Object.assign(memo, { [value.id]: value })
    }, {})

    this.state = {
      records: cloneDeep(approvedRecords),
      initialRecords
    }
  }

  componentWillUnmount() {
    this._columnsWithDiffs = null
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi

    this.autoSizeAll()
    this.props.handleApprovedRecords(this.approvedRecordIds)
  }

  onSelectionChanged = (params) => {
    this.props.handleApprovedRecords(this.approvedRecordIds)
  }

  get approvedRecordIds() {
    const approvedRecordIds = []

    this.gridApi.forEachNode((rowNode) => {
      if (!rowNode.isSelected()) {
        approvedRecordIds.push(rowNode.id)
      }
    })

    return approvedRecordIds
  }

  get allBenefitFields() {
    let fields = [
      { key: 'in_network', label: 'In Network' }
    ]

    fields = fields.concat(
      range(2, Math.max(2, this.maxTiers + 1))
        .map((v) => ({ key: `in_network_tier_${v}`, label: `In Network Tier ${v}` }))
    )

    fields.push({ key: 'out_of_network', label: 'Out Of Network' })
    fields.push({ key: 'limit', label: 'Limit' })

    return fields
  }

  get maxTiers() {
    if (isNil(this.props.records)) {
      return 0
    }

    const diffRowTiers = this.maxTierInRecord('full_benefits_set')
    const approvedRowTiers = this.maxTierInRecord('approved_benefits_set')

    return Math.max(...approvedRowTiers.concat(diffRowTiers))
  }

  get rowCount() {
    return this.props.records.length
  }

  get columnsWithDiffs() {
    const diffSet = new DiffSetColumns(
      this.props.records,
      this.props.metadataFields,
      this.props.benefitFields,
      this.allBenefitFields
    )

    return diffSet.columns()
  }

  get columns() {
    const diffColumns = this.columnsWithDiffs

    return [
      {
        field: 'reject',
        headerName: 'Reject?',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressToolPanel: true,
        lockPosition: true,
        lockVisible: true,
        pinned: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true
      },
      {
        field: 'id',
        headerName: 'ID',
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        lockPosition: true,
        lockVisible: true,
        pinned: true
      },
      {
        field: 'vericred_id',
        headerName: 'Vericred ID',
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        lockPosition: true,
        lockVisible: true,
        pinned: true
      },
      {
        headerName: 'Info',
        children: [
          {
            field: 'hios_ids',
            headerName: 'HIOS IDs',
            hide: false
          }
        ].concat(this.metadatumColumns(diffColumns))
      }
    ].concat(this.benefitColumns(diffColumns))
  }

  metadatumColumns = (diffColumns) => {
    return map(this.props.metadataFields, (field, key) => {
      return {
        field: key,
        headerName: field.label,
        hide: !this.columnVisible(diffColumns, key),
        editable: false,
        cellRendererFramework: DiffCellRenderer,
        cellClass: 'ag-grid-cell-diff',
        filter: 'agTextColumnFilter',
        valueFormatter: this.valueFormatter(field, key)
      }
    })
  }

  benefitColumns = (diffColumns) => {
    return map(this.props.benefitFields, (value, key) => {
      return {
        headerName: value,
        children: this.allBenefitFields.map((field) => ({
          field: `${key}_${field.key}`,
          headerName: field.label,
          hide: !this.columnVisible(diffColumns, `${key}_${field.key}`),
          editable: false,
          filter: 'agTextColumnFilter',
          cellRendererFramework: DiffCellRenderer,
          cellClass: 'ag-grid-cell-diff'
        }))
      }
    })
  }

  columnVisible = (diffColumns, key) => {
    return this.props.alwaysShowFields.includes(key) || diffColumns.includes(key)
      || (isEmpty(diffColumns) && (this.props.defaultFields.includes(key)))
  }

  valueFormatter = (field, key) => {
    return (cell) => {
      const fieldDefinition = new FieldDefinition(
        key,
        field,
        cell.value,
        null,
        cell.data,
        this.props.configuration,
        null,
        null,
        null
      )

      return fieldDefinition.displayValue
    }
  }

  maxTierInRecord = (recordKey) => {
    return this.props.records.map((i) => i[recordKey]).map((row) => {
      const benefits = Object.values(row.benefits)

      const firstNilTiers = benefits.map((benefit) => {
        return range(2, 11).find((i) => isNil(benefit[`in_network_tier_${i}`]))
      })

      const maxTier = Math.max(...firstNilTiers)

      if (isNil(maxTier) || maxTier === 2) {
        return 0
      }

      return maxTier - 1
    })
  }

  shallowRecords = (records) => {
    return records.map(this.shallowRecord)
  }

  shallowRecord = (record) => {
    const newRecord = {

      ...record,
      ...this.explodedBenefitFieldsForRow(record),
      hios_ids: this.hiosIDsForRow(record)
    }

    delete newRecord.benefits
    delete newRecord.external_ids

    return newRecord
  }

  mainMenuItems = (params) => {
    const items = cloneDeep(params.defaultItems)

    return items
  }

  rowMapper = (row) => {
    return {

      ...row,
      ...this.explodedBenefitFieldsForRow(row),
      hios_ids: this.hiosIDsForRow(row)
    }
  }

  hiosIDsForRow = (row) => {
    return row.external_ids.reduce((memo, value) => {
      if (value.type === 'hios_id') {
        memo.push(value.external_id)
      }

      return memo
    }, []).join(', ')
  }

  explodedBenefitFieldsForRow = (row) => {
    return reduce(row.benefits, (memo, value, key) => {
      return Object.assign(memo, {
        [`${key}_limit`]: value.limit,
        [`${key}_in_network`]: value.in_network,
        [`${key}_in_network_tier_2`]: value.in_network_tier_2,
        [`${key}_in_network_tier_3`]: value.in_network_tier_3,
        [`${key}_in_network_tier_4`]: value.in_network_tier_4,
        [`${key}_in_network_tier_5`]: value.in_network_tier_5,
        [`${key}_in_network_tier_6`]: value.in_network_tier_6,
        [`${key}_in_network_tier_7`]: value.in_network_tier_7,
        [`${key}_in_network_tier_8`]: value.in_network_tier_8,
        [`${key}_in_network_tier_9`]: value.in_network_tier_9,
        [`${key}_in_network_tier_10`]: value.in_network_tier_10,
        [`${key}_out_of_network`]: value.out_of_network
      })
    }, {})
  }

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit()
  }

  autoSizeAll = () => {
    const allColumnIds = []

    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId)
    })

    this.gridColumnApi.autoSizeColumns(allColumnIds)
  }

  rowNodeId = (data) => {
    return data.id
  }

  render() {
    return (
      <div className="ag-theme-balham data-grid">
        <AgGridReact
          columnDefs={this.columns}
          rowData={this.state.records}
          onGridReady={this.onGridReady}
          onCellValueChanged={this.cellValueChanged}
          getMainMenuItems={this.mainMenuItems}
          getRowNodeId={this.rowNodeId}
          initialData={this.state.initialRecords}
          rowSelection="multiple"
          onSelectionChanged={this.onSelectionChanged}
          animateRows
          enableSorting
          enableFilter
          suppressAggFuncInHeader
          enableColResize
          deltaRowDataMode
          toolPanelSuppressRowGroups
          toolPanelSuppressValues
          toolPanelSuppressPivots
          toolPanelSuppressPivotMode
        />
      </div>
    )
  }
}
