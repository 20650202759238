import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, first, keys, filter, startCase, get } from 'lodash'

export default class RatesBatchCreateErrorsComponent extends Component {
  static propTypes = {
    errors: PropTypes.array
  }

  get fields() {
    return filter(keys(first(this.props.errors)), (key) => key !== 'error_messages')
  }

  get headers() {
    return this.fields.map((key) => {
      return (
        <td key={`header-cell-${key}`} className="header-cell">
          <div className="header-label">{startCase(key)}</div>
        </td>
      )
    })
  }

  get errorRows() {
    return this.props.errors.map(this.errorRow)
  }

  errorMessageLines = (messages) => {
    return messages.map((message, index) => (
      <li className="error-line" key={`error-line-${index}`}>
        {message}
      </li>
    ))
  }

  errorCell = (errorObject, field) => {
    const messages = get(errorObject.error_messages, field)
    const value = get(errorObject, field)

    let errorContainer = null

    if (!isEmpty(messages)) {
      errorContainer = (
        <ul className="value-field-error-container">
          {this.errorMessageLines(messages)}
        </ul>
      )
    }

    return (
      <div className="value-field-container">
        <div>{value}</div>
        {errorContainer}
      </div>
    )
  }

  errorRowCells = (errorRowObject) => {
    return this.fields.map((field) => {
      return <td key={`row-cell-${field}`}>{this.errorCell(errorRowObject, field)}</td>
    })
  }

  errorRow = (errorRowObject) => {
    return (
      <tr key={`row-${errorRowObject.row_number}`}>
        {this.errorRowCells(errorRowObject)}
      </tr>
    )
  }

  render() {
    return (
      <div className="full-width-table-container">
        <table className="pure-table full-width-table index-table">
          <thead>
            <tr>
              {this.headers}
            </tr>
          </thead>
          <tbody>
            {this.errorRows}
          </tbody>
        </table>
      </div>
    )
  }
}
