import React from 'react'
import Component from '../common/Component'
import LifeBenefitsSetComponent from './LifeBenefitsSetComponent'
import { benefitNames } from './lifeFields'
import BenefitsSetForm from '../BenefitsSet/BenefitsSetForm'

export default class LifeBenefitsSetForm extends Component {
  render() {
    return (
      <BenefitsSetForm
        benefitNames={benefitNames}
        benefitsSetComponent={LifeBenefitsSetComponent}
        productLine="Life"
        {...this.props}
      />
    )
  }
}
