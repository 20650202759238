// Core Libraries
import React from 'react'

// Actions
import actions from '../../actions/IssuerSources'

// Components
import Component from '../common/Component'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'
import IssuerStatusRemoteOptionsSelectField from '../common/IssuerStatusRemoteOptionsSelectField'

export class IssuerSourceEdit extends Component {
  static propTypes = defaultPropTypes

  get id() {
    return this.props.match.params.id
  }

  render() {
    return (
      <EditForm {...this.props} id={this.id}>

        <IssuerStatusRemoteOptionsSelectField
          name="issuer_status_id"
        />

        <LabeledTextField
          name="active_from"
          label="Active From"
          placeholder="YYYY-MM-DD"
          type="text"
        />

        <LabeledTextField
          name="active_until"
          label="Active Until"
          placeholder="YYYY-MM-DD"
          type="text"
        />

        <SelectField
          name="source"
          label="Source"
          options={this.props.configuration.sources}
        />

      </EditForm>
    )
  }
}

export default connectEditForm(IssuerSourceEdit, 'Issuer Source', 'issuer_sources', actions)
