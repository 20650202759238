import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, upperFirst } from 'lodash'
import InputField from './InputField'

export default class TextField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    fieldName: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    errorMessage: PropTypes.string,
    inputCssClass: PropTypes.string,
    step: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    id: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    placeholder: PropTypes.string
  }

  render() {
    const {
      name, handleChange, handleBlur,
      errorMessage, value, inputCssClass, title,
      type, fieldName, disabled, min, max, step, id,
      placeholder
    } = this.props

    const classNames = ['field']

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    return (
      <div className={classNames.join(' ')}>
        <InputField
          id={id}
          name={name}
          title={title}
          type={type}
          fieldName={fieldName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errorMessage={errorMessage}
          value={value}
          disabled={disabled}
          inputCssClass={inputCssClass}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
        />
        {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
      </div>
    )
  }
}
