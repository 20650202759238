import React from 'react'
import Component from '../common/Component'
import BenefitsSetBulkEditsComponent from '../BenefitsSet/BenefitsSetBulkEditsComponent'
import { benefitLabels, metadatumFieldsDiffSet } from './visionFields'

const defaultFields = [
  'id',
  'vericred_id',
  'name',
  'audience',
  'plan_type',
  'issuer_name',
  'stand_alone',
  'voluntary',
  'effective_date',
  'expiration_date'
]

export default class MedicareAdvantageBenefitsSetBulkEditsComponent extends Component {
  render() {
    return (
      <BenefitsSetBulkEditsComponent
        benefitLabels={benefitLabels}
        metadatumFields={metadatumFieldsDiffSet}
        defaultFields={defaultFields}
        benefitsSetUrl="/vision_benefits"
        productLine="vision"
        {...this.props}
      />
    )
  }
}
