import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil, startCase } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/SmokeChecks'
import SmokeCheckDetailComponent from './SmokeCheckDetailComponent'

export class SmokeCheckShow extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    smokeCheck: PropTypes.object,
    match: PropTypes.object
  }

  componentDidMount() {
    this.props.show(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.reset()
  }

  formattedIssuer = (ipa) => {
    const issuer = ipa.issuer
    return `${issuer.issuer_id} | ${issuer.name}`
  }

  formattedQuarterPeriod = (ipa) => {
    if (ipa.calendar_period.quarter) {
      return `${ipa.calendar_period.quarter}`
    }

    return 'NA'
  }

  formattedApprovalStatus = (smokeCheck) => {
    if (smokeCheck.approval_status_user) {
      return (
        `${startCase(smokeCheck.approval_status)} by ${smokeCheck.approval_status_user.username}`
      )
    }

    return `${startCase(smokeCheck.approval_status)}`
  }

  displaySmokeCheckDetails = (smokeCheck) => {
    const details = smokeCheck.details

    return (
      details.map((detail) => (
        <SmokeCheckDetailComponent
          smokeCheckDetail={detail}
          key={`${detail.type}`}
        />
      ))
    )
  }

  render() {
    const smokeCheck = this.props.smokeCheck

    if (isNil(smokeCheck)) {
      return null
    }

    return (
      <div className="content">
        <header className="form-header">
          <div>
            <div className="form-header-title">
              <h2>Smoke Check</h2>
            </div>
          </div>
        </header>

        <div>
          <h4>Metadata</h4>
          <table className="pure-table">
            <tbody>
              <tr>
                <td><strong>Issuer</strong></td>
                <td>{this.formattedIssuer(smokeCheck.issuer_period_audience)}</td>
              </tr>
              <tr>
                <td><strong>Audience</strong></td>
                <td>{smokeCheck.issuer_period_audience.audience}</td>
              </tr>
              <tr>
                <td><strong>Quarter</strong></td>
                <td>{this.formattedQuarterPeriod(smokeCheck.issuer_period_audience)}</td>
              </tr>
              <tr>
                <td><strong>Year</strong></td>
                <td>{smokeCheck.issuer_period_audience.calendar_period.year}</td>
              </tr>
              <tr>
                <td><strong>Source</strong></td>
                <td>{smokeCheck.sources}</td>
              </tr>
              <tr>
                <td><strong>Scheduling Status</strong></td>
                <td>{`${startCase(smokeCheck.scheduling_status)}`}</td>
              </tr>
              <tr>
                <td><strong>Approval Status</strong></td>
                <td>{this.formattedApprovalStatus(smokeCheck)}</td>
              </tr>
              <tr>
                <td><strong>Notes</strong></td>
                <td>{smokeCheck.notes}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {this.displaySmokeCheckDetails(smokeCheck)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.smokeChecks.show
  return {
    smokeCheck: slice.attributes
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  show: actions.show.main,
  reset: actions.show.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SmokeCheckShow)
