import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import fieldGroups, { infoFields, externalIdField, benefitNames } from './fields'
import MedicalBenefitsSetDocuments from './MedicalBenefitsSetDocuments'
import BenefitsSetComponent from '../BenefitsSet/BenefitsSetComponent'

export default class MedicalBenefitsSetComponent extends Component {
  static propTypes = {
    benefitsSet: PropTypes.object.isRequired,
    show: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired
  }

  render() {
    return (
      <div>
        <MedicalBenefitsSetDocuments
          benefitsSet={this.props.benefitsSet}
          show={this.props.show}
          showReset={this.props.showReset}
        />

        <BenefitsSetComponent
          fieldGroups={fieldGroups}
          infoFields={infoFields}
          externalIdField={externalIdField}
          benefitNames={benefitNames}
          {...this.props}
        />
      </div>
    )
  }
}
