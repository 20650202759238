import axios from 'axios'
import { isEmpty, get, cloneDeep } from 'lodash'
import store from './store'

export const clientFactory = (window) => {
  const formatURL = (origin, pathname) => {
    if (origin === 'https://howie.vericred.com') { return 'https://production.howie-api.vericred.com' }
    if (origin.match(/localhost/)) { return origin.replace(/localhost:(\d)\d(.*)$/, 'localhost:$15$2') }

    if (origin.match(/pr.howie.vericred.com/) && !isEmpty(pathname)) {
      const matches = pathname.match(/\/(\d+)\//)
      if (matches.length > 1) {
        return `https://howie-acceptance-pr-${matches[1]}.herokuapp.com`
      }
    }

    return origin.replace(/howie/, 'howie-api')
  }

  const axiosClient = axios.create({
    baseURL: formatURL(window.location.origin, window.location.pathname)
  })

  axiosClient.setAuthorization = function setAuthorization(nonce) {
    const headers = cloneDeep(this.defaults.headers)

    headers.Authorization = `Bearer ${nonce}`

    this.defaults.headers = headers
  }

  return (clientStore) => {
    if (typeof clientStore === 'undefined') {
      throw new Error('`clientStore` must be the first parameter to the client')
    }

    let currentNonce = get(clientStore.getState(), 'login.session.nonce')

    if (currentNonce) {
      axiosClient.setAuthorization(currentNonce)
    }

    clientStore.subscribe(() => {
      const previousNonce = currentNonce
      currentNonce = get(clientStore.getState(), 'login.session.nonce')

      if (currentNonce !== previousNonce) {
        axiosClient.setAuthorization(currentNonce)
      }
    })

    return axiosClient
  }
}

const client = clientFactory(global.window)(store)

export default client

