import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'

export default class DiffSetActions extends Component {
  static propTypes = {
    handlePublish: PropTypes.func,
    handleApprove: PropTypes.func.isRequired,
    approvePrompt: PropTypes.string,
    exportPublish: PropTypes.object,
    export: PropTypes.object.isRequired
  }

  get generateError() { return this.props.exportPublish.generateError }

  get created() { return this.props.exportPublish.created }

  statusDescription() {
    if (this.generateError) { return this.generateError }
    if (this.created) { return 'Published' }
    return ''
  }

  statusClassName() {
    if (this.generateError) { return 'error' }
    if (this.created) { return 'success' }
    return ''
  }

  publishStatus() {
    return (
      <div>
        <strong title="PublishStatus" className={this.statusClassName()}>
          {this.statusDescription()}
        </strong>
      </div>
    )
  }

  render() {
    return (
      <fieldset>
        {this.props.handlePublish
          && (
            <button
              className="pure-button pure-input-1 pure-button-primary"
              title="Publish"
              id="publish"
              onClick={this.props.handlePublish}
              disabled={this.props.exportPublish.requestInProgress}
            >
Publish
            </button>
          )}

        <button
          className="pure-button pure-input-1 pure-button-primary"
          title="Approve"
          id="approve"
          onClick={this.props.handleApprove}
          disabled={this.props.export.requestInProgress}
        >
Approve
        </button>
        {this.props.approvePrompt}
        {this.props.exportPublish && this.publishStatus()}
      </fieldset>
    )
  }
}
