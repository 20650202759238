import {
  REQUEST_PLANS,
  RECEIVE_PLANS,
  RECEIVE_PLANS_ERROR
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  plans: undefined,
  generalError: undefined,
  resourceErrors: undefined,
  getInProgress: false
}

export default function PlansReducer(state = defaultState, action) {
  switch (action.type) {
  case REQUEST_PLANS:
    return { ...state,
      getInProgress: true,
      plans: undefined,
      generalError: undefined,
      resourceErrors: undefined }
  case RECEIVE_PLANS:
    return { ...state,
      getInProgress: false,
      plans: action.plans,
      generalError: undefined,
      resourceErrors: undefined }
  case RECEIVE_PLANS_ERROR:
    return { ...state,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors,
      getInProgress: false }
  default:
    return { ...state }
  }
}
