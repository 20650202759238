import React from 'react'

// Actions
import actions from '../../actions/IssuerSources'

import Component from '../common/Component'
import CreateForm, { defaultPropTypes, connectCreateForm } from '../common/CreateForm'
import LabeledTextField from '../common/LabeledTextField'
import IssuerStatusRemoteOptionsSelectField from '../common/IssuerStatusRemoteOptionsSelectField'
import SelectField from '../common/SelectField'

export class IssuerSourceCreate extends Component {
  static propTypes = defaultPropTypes

  render() {
    return (
      <CreateForm {...this.props}>
        <IssuerStatusRemoteOptionsSelectField
          name="issuer_status_id"
        />

        <LabeledTextField
          name="active_from"
          label="Active From"
          placeholder="YYYY-MM-DD"
          type="text"
        />

        <LabeledTextField
          name="active_until"
          label="Active Until"
          placeholder="YYYY-MM-DD"
          type="text"
        />

        <SelectField
          name="source"
          label="Source"
          options={this.props.configuration.sources}
        />
      </CreateForm>
    )
  }
}

export default connectCreateForm(IssuerSourceCreate, 'Issuer Source', 'issuer_sources', actions)
