import React from 'react'
import Component from '../common/Component'
import VisionBenefitsSetComponent from './VisionBenefitsSetComponent'
import { benefitNames } from './visionFields'
import BenefitsSetForm from '../BenefitsSet/BenefitsSetForm'

export default class VisionBenefitsSetForm extends Component {
  render() {
    return (
      <BenefitsSetForm
        benefitNames={benefitNames}
        benefitsSetComponent={VisionBenefitsSetComponent}
        productLine="Vision"
        {...this.props}
      />
    )
  }
}
