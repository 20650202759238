import React from 'react'
import { PortalWithState } from 'react-portal'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import Component from './Component'

export default class Modal extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    className: PropTypes.string
  }

  get className() {
    if (isNil(this.props.className)) {
      return 'modal'
    }

    return `modal ${this.props.className}`
  }

  handleClose = () => {
    this.props.handleClose()
  }

  handleIgnoreClose = (event) => {
    event.stopPropagation()
  }

  render() {
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
      <PortalWithState
        defaultOpen
        closeOnOutsideClick
        closeOnEsc
        onClose={this.handleClose}
      >
        {({ openPortal, closePortal, isOpen, portal }) => [
          null,
          portal(
            <div role="alertdialog" className={this.className} onClick={this.handleClose}>
              <div role="alertdialog" className="modal-inner" onClick={this.handleIgnoreClose}>
                {this.props.children}
              </div>
            </div>
          )
        ]}
      </PortalWithState>
    )
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  }
}
