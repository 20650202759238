import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isUndefined, upperFirst } from 'lodash'
import LabeledTextField from '../common/LabeledTextField'

class CredentialsLogin extends Component {
  static propTypes = {
    createCredentialsSession: PropTypes.func.isRequired,
    error: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { values: {} }
  }

  handleChange(field, value) {
    this.setState((prevState) => {
      const newState = prevState.values

      if (value) {
        newState[field] = value
      } else {
        delete newState[field]
      }

      return { values: newState }
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    this.props.createCredentialsSession(this.state.values.username, this.state.values.password)
  }

  errorComponent(error) {
    if (isUndefined(error)) {
      return null
    }

    return (
      <fieldset>
        <div className="error">{upperFirst(error)}</div>
      </fieldset>
    )
  }

  render() {
    const { error } = this.props

    return (
      <form id="credentials-form" className="pure-u-1 pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
        <fieldset>
          <LabeledTextField
            key="username"
            name="username"
            label="Username"
            handleChange={this.handleChange}
            value={this.state.values.username}
          />
          <LabeledTextField
            key="email"
            name="password"
            label="Password"
            type="password"
            handleChange={this.handleChange}
            value={this.state.values.password}
          />
        </fieldset>
        {this.errorComponent(error)}
        <fieldset>
          <button className="pure-button pure-input-1 pure-button-primary" title="Login" type="submit">Login</button>
        </fieldset>
      </form>
    )
  }
}

export default CredentialsLogin
