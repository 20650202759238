import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, every, times, isNil, isEmpty, compact, get, flatMap, includes } from 'lodash'
import Component from '../common/Component'
import UserContext from './BenefitsSetUserContext'
import FieldDefinition from './BenefitsSetFieldDefinition'
import HeaderRow from './BenefitsSetHeaderRow'
import BenefitRow from './BenefitsSetBenefitRow'
import TextField from '../common/TextField'

export default class BenefitsSetBenefitsComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    benefits: PropTypes.array.isRequired,
    benefitsSet: PropTypes.object.isRequired,
    reviews: PropTypes.array,
    rejections: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleReviewChange: PropTypes.func.isRequired,
    handleHistory: PropTypes.func,
    handleTiersCountChange: PropTypes.func,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    errors: PropTypes.array,
    configuration: PropTypes.object.isRequired,
    displayValidFields: PropTypes.bool,
    tiersCount: PropTypes.number.isRequired,
    benefitNames: PropTypes.array.isRequired,
    fieldGroups: PropTypes.object.isRequired,
    headerKey: PropTypes.string.isRequired,
    showHistory: PropTypes.bool
  }

  static renderedProps = [
    'name',
    'benefits',
    'reviews',
    'rejections',
    'userContext',
    'errors',
    'displayValidFields',
    'configuration',
    'benefitsSet',
    'tiersCount'
  ]

  get additionalTiersCount() {
    return this.props.tiersCount - 2
  }

  get totalColumns() {
    return 2 + this.props.tiersCount
  }

  get indexedBenefits() {
    const benefits = this.props.benefits

    return this.props.benefitNames.map((name) => {
      const benefit = benefits.find((b) => b.name === name)

      return benefit || this.initialBenefitForName(name)
    })
  }

  get tierHeaderLabels() {
    const tiers = times(this.additionalTiersCount).map((index) => `In Network Tier ${index + 2}`)

    return ['In Network'].concat(tiers, 'Out of Network', 'Limit')
  }

  get shouldDisplayBenefits() {
    if (this.props.userContext.isViewable) {
      return true
    }

    if (this.props.displayValidFields === true) {
      return true
    }

    const benefitsErrors = this.props.errors

    return !isNil(benefitsErrors) && !isEmpty(benefitsErrors)
  }

  get tiersCountField() {
    if (!this.props.userContext.isEditable) {
      return null
    }

    return (
      <div>
        <label htmlFor="tiers_count">Tiers</label>
        <TextField
          id="tiers_count"
          name="tiers_count"
          type="number"
          min={2}
          max={10}
          step={1}
          handleChange={this.handleTiersCountChange}
          value={this.props.tiersCount}
          inputCssClass=""
        />
      </div>
    )
  }

  get benefitsHeader() {
    return (
      <tr className="header-row" key={`${this.props.headerKey}`}>
        <td key="header-1" colSpan={1}>Benefits</td>
        <td key="header-2" colSpan={this.totalColumns - 1 || 1}>
          {this.tiersCountField}
        </td>
      </tr>
    )
  }

  get rows() {
    const benefitGroups = this.props.fieldGroups.Benefits.benefits.fields
    const reviews = this.props.reviews || []
    const showHistory = this.props.showHistory
    const keyBenefits = this.keyBenefits()

    const rows = []

    let accumulator = 0

    if (!this.shouldDisplayBenefits) {
      return []
    }

    rows.push(this.benefitsHeader)

    benefitGroups.forEach((group) => {
      const displayGroup = group.fields.map((f) => ({
        name: f.name,
        label: f.label,
        display: this.shouldDisplayBenefitRow(f.name)
      }))

      if (every(displayGroup, { display: false })) {
        accumulator += (group.fields.length)
        return
      }

      rows.push(this.benefitHeaderRow(group.name))

      displayGroup.forEach(({ name, label, display }, index) => {
        if (!display) {
          return
        }

        const required = includes(keyBenefits, name)

        rows.push(
          this.benefitRow(
            name,
            (index + accumulator).toString(),
            label,
            reviews[index + accumulator],
            this.handleChange,
            this.handleReviewChange,
            this.handleHistory,
            showHistory,
            required
          )
        )
      })

      accumulator += (group.fields.length)
    })

    return rows
  }

  initialBenefitForName = (name) => ({
    name,
    value: name,
    tiers: [
      { option: 'In Network', value: '' },
      { option: 'Out of Network', value: '' }
    ]
  })

  benefitValueForName = (name) => this.props.benefits.find((b) => b.name === name)

  errorForName = (name) => {
    const index = this.props.benefitNames.indexOf(name)
    return get(this.props.errors, index)
  }

  handleChange = (index, value) => {
    const benefits = this.indexedBenefits

    if (isNil(value)) {
      benefits[index] = this.initialBenefitForName(this.props.benefitNames[index])
    } else {
      benefits[index] = value
    }

    if (isEmpty(compact(benefits))) {
      this.props.handleChange(this.props.name, null)
      return
    }

    this.props.handleChange(this.props.name, benefits)
  }

  handleReviewChange = (index, value) => {
    const reviews = cloneDeep(this.props.reviews) || []

    if (isNil(value)) {
      reviews[index] = null
    } else {
      reviews[index] = value
    }

    if (isEmpty(compact(reviews))) {
      this.props.handleReviewChange('benefit_reviews', null)
      return
    }

    this.props.handleReviewChange('benefit_reviews', reviews)
  }

  handleTiersCountChange = (name, value) => {
    this.props.handleTiersCountChange(parseInt(value, 10))
  }

  handleHistory = (name, benefitName) => {
    this.props.handleHistory(name, benefitName)
  }

  benefitRow = (name, index, label, review, handleChange, handleReviewChange, handleHistory, showHistory, required) => {
    const definition = this.benefitFieldDefinition(name, label)

    const benefitName = get(definition.value, 'name')

    let rejections = []

    if (!isNil(benefitName)) {
      rejections = this.rejectionsForBenefit(benefitName)
    }

    return (
      <BenefitRow
        key={name}
        label={label}
        name={index}
        review={review}
        rejections={rejections}
        required={required}
        handleBlur={this.props.handleBlur}
        handleChange={handleChange}
        handleReviewChange={handleReviewChange}
        handleHistory={handleHistory}
        showHistory={showHistory}
        additionalTiersCount={this.additionalTiersCount}
        userContext={this.props.userContext}
        fieldDefinition={definition}
        benefitsSet={this.props.benefitsSet}
      />
    )
  }

  rejectionsForBenefit = (benefitName) => {
    const rejections = this.props.rejections || []

    return compact(rejections.map((r) => {
      const review = r.review.find((benefitReview) => (
        benefitReview.benefit_name === benefitName
      ))

      if (isNil(review)) {
        return null
      }

      return {
        review_id: r.review_id,
        created_at: r.created_at,
        review
      }
    }))
  }

  shouldDisplayBenefitRow = (name) => {
    if (isNil(this.props.displayValidFields) || this.props.displayValidFields === true) {
      return true
    }

    return !isNil(this.errorForName(name))
  }

  keyBenefits = () => {
    const { benefitsSet, configuration } = this.props
    const keyBenefitsConfigs = configuration.key_benefits_configs

    return compact(flatMap(keyBenefitsConfigs, (config) => {
      const where = config.where
      if (where == null || get(benefitsSet.metadatum, where.field) === where.value) {
        return config.benefits
      }
      return null
    }))
  }

  benefitFieldDefinition(name, label) {
    return new FieldDefinition(
      name,
      { label },
      this.benefitValueForName(name) || { name, value: name },
      this.errorForName(name),
      this.props.benefitsSet,
      this.props.configuration,
      this.props.userContext
    )
  }

  headerRow(labels) {
    return (
      <HeaderRow
        key={`header-${labels[0]}`}
        labels={labels}
        totalColumns={this.totalColumns}
      />
    )
  }

  benefitHeaderRow(label) {
    return this.headerRow([label].concat(this.tierHeaderLabels))
  }

  render() {
    return (
      <tbody id="benefit-rows">
        {this.rows}
      </tbody>
    )
  }
}

