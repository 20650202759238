import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isNil } from 'lodash'
import CensusEdit from './CensusEdit'
import CensusCreate from './CensusCreate'
import routerHistory from '../../../routerHistory'
import CensusesSelectFieldComponent from '../common/CensusesSelectFieldComponent'
import actions from '../../../actions/Censuses'

export class CensusesContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    index: PropTypes.func.isRequired,
    needsCensuses: PropTypes.bool,
    records: PropTypes.array
  }

  constructor(props) {
    super(props)

    let censusId = get(props.match, 'params.id')

    if (censusId) {
      censusId = global.parseInt(censusId)
    } else {
      censusId = 'NewCensus'
    }

    this.state = {
      censusId
    }
  }

  componentDidMount() {
    if (this.props.needsCensuses) {
      this.props.index()
    }
  }

  componentDidUpdate() {
    if (this.props.needsCensuses) {
      this.props.index()
    }
  }

  handleCensusChange = (name, value) => {
    if (value !== this.state.censusId) {
      this.setState({
        censusId: value
      })
      this.updateHistory(value)
    }
  }

  updateHistory(censusId) {
    if (censusId === 'NewCensus' || isNil(censusId)) {
      routerHistory.push('/censuses/new')
    } else {
      routerHistory.push(`/censuses/${censusId}/edit`)
    }
  }

  formComponent() {
    if (isNil(this.state.censusId) || (this.state.censusId === 'NewCensus')) {
      return <CensusCreate />
    }

    return <CensusEdit id={this.state.censusId} />
  }

  render() {
    const { needsCensuses } = this.props

    if (needsCensuses) {
      return null
    }
    return (
      <div className="content">
        <CensusesSelectFieldComponent
          value={this.state.censusId}
          handleChange={this.handleCensusChange}
          displayNewCensus
          censuses={this.props.records}
        />

        <div>
          {this.formComponent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.censuses.index

  return {
    needsCensuses: !slice.requestInProgress && isNil(slice.records),
    records: slice.records,
    requestInProgress: slice.requestInProgress
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  index: actions.index.main,
  reset: actions.index.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CensusesContainer)
