import React from 'react'

// Actions
import actions from '../../actions/PrivateExchanges'

import Component from '../common/Component'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'

export class PrivateExchangeEdit extends Component {
  static propTypes = defaultPropTypes

  get id() {
    return this.props.match.params.id
  }

  render() {
    return (
      <EditForm {...this.props} id={this.id}>
        <LabeledTextField
          name="name"
          label="Name"
          placeholder="Enter a name"
          type="text"
        />
      </EditForm>
    )
  }
}

export default connectEditForm(PrivateExchangeEdit, 'Private Exchange', 'private_exchanges', actions)
