import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil, cloneDeep, upperFirst } from 'lodash'
import { IoIosArrowForward } from 'react-icons/io'
import Component from './Component'
import InputField from './InputField'

export default class DateRangeComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    value: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    }).isRequired
  }

  static renderedProps = [
    'disabled',
    'name',
    'title',
    'value',
    'errorMessage'
  ]

  static defaultProps = {
    disabled: false
  }

  get errorMessage() {
    if (isNil(this.props.errorMessage)) {
      return null
    }

    return <div className="error">{upperFirst(this.props.errorMessage)}</div>
  }

  handleChange = (field, value) => {
    const newValue = cloneDeep(this.props.value)

    if (isNil(value) || value === '') {
      newValue[field] = null
    } else {
      newValue[field] = value
    }

    this.props.handleChange(this.props.name, newValue)
  }

  render() {
    const classNames = ['field']

    if (!isEmpty(this.props.errorMessage)) {
      classNames.push('field-with-error')
    }

    return (
      <div className="date-range-container">
        <div className={classNames.join(' ')}>
          <InputField
            name="start"
            type="date"
            disabled={this.props.disabled}
            title="Start Date"
            value={this.props.value.start}
            handleChange={this.handleChange}
          />
          <i><IoIosArrowForward /></i>
          <InputField
            name="end"
            type="date"
            disabled={this.props.disabled}
            title="End Date"
            value={this.props.value.end}
            handleChange={this.handleChange}
          />
        </div>
        {this.errorMessage}
      </div>
    )
  }
}
