import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cloneDeep, get, isNil, some } from 'lodash'
import Component from '../common/Component'
import actions from '../../actions/Users'
import issuerActions from '../../actions/Issuers'
import { createFlashMessage } from '../../actions/FlashMessages'
import CarrierUserForm from './CarrierUserForm'
import alert from '../../alert'

export class CarrierUserEdit extends Component {
  static propTypes = {
    match: PropTypes.object,
    update: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    attributes: PropTypes.object,
    updated: PropTypes.bool.isRequired,
    createFlashMessage: PropTypes.func.isRequired,
    issuers: PropTypes.array,
    indexIssuers: PropTypes.func.isRequired,
    resetIssuers: PropTypes.func.isRequired,
    issuersRequestInProgress: PropTypes.bool.isRequired
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
  }

  get id() {
    return this.props.match.params.id
  }

  get initialAttributes() {
    const newAttributes = cloneDeep(this.props.attributes)

    newAttributes.user_issuers = newAttributes.user_issuers.map((v) => ({
      label: v.issuer_label,
      value: v.issuer_id
    }))

    return newAttributes
  }

  handleSubmit = (values) => {
    this.props
      .update(this.id, this.translateValues(values))
      .then(({ id }) => {
        this.props.createFlashMessage('Updated user', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  translateValues = (values) => {
    const newValues = cloneDeep(values)

    if (!isNil(newValues.user_issuers)) {
      const existingUserIssuers = get(this.props.attributes, 'user_issuers', [])

      newValues.user_issuers = existingUserIssuers.map((v) => {
        const exists = values.user_issuers.includes(v.issuer_id)

        if (!exists) {
          return { id: v.id, _destroy: true }
        }

        return v
      })

      const newUserIssuers = values.user_issuers
        .filter((v) => !some(existingUserIssuers, { issuer_id: v }))
        .map((v) => ({ issuer_id: v }))

      newValues.user_issuers = newValues.user_issuers.concat(newUserIssuers)
    }

    return newValues
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      requestInProgress,
      attributes,
      updated
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (!isNil(this.destroyError)) {
      alert(this.destroyError)
    }

    if (isNil(attributes)) {
      return null
    }

    if (updated) {
      return this.redirect('/users')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    let issuers = cloneDeep(this.props.issuers)

    if (isNil(issuers) && !this.props.issuersRequestInProgress) {
      issuers = attributes.user_issuers
      issuers = issuers.map((o) => o.issuer)
    }

    return (
      <div className="content">
        <h2>Edit Carrier User</h2>
        <div>
          <CarrierUserForm
            handleSubmit={this.handleSubmit}
            errors={errors}
            requestInProgress={requestInProgress}
            submitLabel="Update User"
            issuers={issuers}
            resetIssuers={this.props.resetIssuers}
            issuersRequestInProgress={this.props.issuersRequestInProgress}
            indexIssuers={this.props.indexIssuers}
            initialValues={this.initialAttributes}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.users.update
  const showSlice = state.users.show
  const issuersSlice = state.issuers.index

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,

    issuers: issuersSlice.records,
    issuersRequestInProgress: issuersSlice.requestInProgress
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  update: actions.update.main,
  reset: actions.update.reset,

  show: actions.show.main,
  showReset: actions.show.reset,

  indexIssuers: issuerActions.index.main,
  resetIssuers: issuerActions.index.reset,

  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CarrierUserEdit)
