import { has, cloneDeep } from 'lodash'

import {
  CREATE_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGES
} from '../../actions/FlashMessages/consts'

const initialState = {
  messages: []
}

const handlers = {
  [CREATE_FLASH_MESSAGE]: (state, action) => {
    const newMessages = cloneDeep(state.messages)

    const { id, message, data } = action

    newMessages.push({ id, message, data })

    return { ...state, messages: newMessages }
  },

  [CLEAR_FLASH_MESSAGES]: (state, action) => {
    const newMessages = cloneDeep(state.messages).filter((m) => !action.ids.includes(m.id))

    return { ...state, messages: newMessages }
  }
}

export default function FlashMessagesReducer(state = initialState, action) {
  if (has(handlers, action.type)) {
    return handlers[action.type](state, action)
  }

  return state
}
