// react base classes
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Component from '../common/Component'

// our custom IndexComponent
import IndexComponent from '../common/IndexComponent'

// actions for our resource
import actions from '../../actions/HowieServiceAreas'

// a special extension to "connect" to wire up different sorting/filtering
// behavior
import connectIndex from '../../lib/connectIndex'
import approvalStateDisplay from '../BenefitsSet/approvalStateDisplay'
import publishStateDisplay from '../BenefitsSet/publishStateDisplay'

export class HowieServiceAreaIndex extends Component {
  static propTypes = {
    // location provides access to data in the query string
    location: PropTypes.object,
    // index action for our resource
    index: PropTypes.func.isRequired,
    // reset index action for our resource
    reset: PropTypes.func.isRequired,
    // reference to the state machine of results for the index action
    records: PropTypes.array,
    // pagination info from the router
    pagination: PropTypes.object,
    // reference to global flash messages
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    // filter configuration
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['name', 'vericred_id', 'external_id', 'issuer', 'issuer_id']
  }

  // Configure the columns to show as well as whether or not they are filterable or sortable
  static columns = [
    {
      key: 'name',
      label: 'Name',
      filterable: false,
      sortable: false
    },
    {
      key: 'external_key',
      label: 'External ID',
      filterable: false,
      sortable: false
    },
    {
      key: 'line_of_coverage',
      label: 'Line of Coverage',
      filterable: true,
      sortable: true
    },
    {
      key: 'audience',
      label: 'Audience',
      filterable: true,
      sortable: false
    },
    {
      key: 'issuer_id',
      label: 'Issuer',
      filterable: false,
      sortable: false
    },
    {
      key: 'year',
      label: 'Year',
      filterable: true,
      sortable: true
    },
    {
      key: 'document',
      label: 'Source Document',
      display: IndexComponent.documentDisplay,
      filterable: false,
      sortable: false
    },
    {
      key: 'updated_at',
      label: 'Updated At',
      display: IndexComponent.dateTimeDisplay,
      filterable: false,
      sortable: true
    },
    {
      key: 'last_updated_by_username',
      label: 'Updated By',
      filterable: false,
      sortable: true
    },
    {
      key: 'approval_state',
      label: 'Status',
      display: approvalStateDisplay,
      recordLabel: 'text',
      recordValue: 'value',
      filterable: true,
      sortable: true
    },
    {
      key: 'publish_state',
      label: 'Publishing Status',
      display: publishStateDisplay,
      recordLabel: 'text',
      recordValue: 'value',
      filterable: true,
      sortable: true
    }
  ]

  get actions() { // TODO: manage entitlements when building service area review
    return [
      { key: 'new_review', display: this.newReviewLinkDisplay }
    ]
  }

  newReviewLinkDisplay = (record) => {
    const location = {
      pathname: `/howie_service_areas/${record.id}/reviews/new`,
      state: { return_path: this.props.location }
    }

    return <Link title="New Review" to={location}>New Review</Link>
  }

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/howie_service_areas"
          name="Service Area"
          pagination={this.props.pagination}
          columns={HowieServiceAreaIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name, External ID, Issuer, or Vericred ID"
          filterable
          actions={this.actions}
          searchColumns={this.props.searchColumns}
        />
      </div>
    )
  }
}

// Our filterable columns must know the available values on which to filter.
// This is a hard-coded list from the config, but they can also use a remote select
// to search for available options
const filters = [
  {
    key: 'name',
    config: 'howie_service_areas.name'
  },
  {
    key: 'line_of_coverage',
    config: 'howie_service_areas.line_of_coverage'
  },
  {
    key: 'audience',
    config: 'howie_service_areas.audience'
  },
  {
    key: 'year',
    config: 'howie_service_areas.year'
  },
  {
    key: 'approval_state',
    options: [
      { value: 'new', text: 'New' },
      { value: 'approved', text: 'Approved' },
      { value: 'rejected', text: 'Rejected' },
      { value: 'corrected', text: 'Corrected' },
      { value: 'amended', text: 'Amended' }
    ]
  },
  {
    key: 'publish_state',
    options: [
      { value: 'needs_publishing', text: 'Needs Publishing' },
      { value: 'failed_to_publish', text: 'Failed to publish' },
      { value: 'published', text: 'Published' },
      { value: 'unpublished', text: 'Unpublished' },
      { value: 'publishing', text: 'Publishing' }
    ]
  }
]

// wire up our index component with the custom connectIndex that
// links up our filters and actions
export default connectIndex(
  HowieServiceAreaIndex,
  'howieServiceAreas',
  actions,
  filters
)
