import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { isNil } from 'lodash'
import { Redirect } from 'react-router-dom'
import Component from '../common/Component'
import actions from '../../actions/SheetRatesIdentifiers'
import connectIndex from '../../lib/connectIndex'

export class SheetRatesBulkIdentifierComponent extends Component {
  static propTypes = {
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.assignQueryParams()
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentWillUnmount() {
    this.props.reset()
  }

  fetchRecords() {
    this.props.index(this.queryParams)
  }

  assignQueryParams() {
    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    if (isNil(this.props.records)) {
      return null
    }

    const params = {
      ids: this.props.records.join(',')
    }

    return (
      <Redirect
        push
        to={{
          pathname: '/sheet_rates/review',
          search: qs.stringify(params)
        }}
      />
    )
  }
}

export default connectIndex(
  SheetRatesBulkIdentifierComponent,
  'sheetRatesIdentifiers',
  actions
)
