import React from 'react'
import PropTypes from 'prop-types'
import { compact, keys, isEmpty, times, isNil, get, merge, cloneDeep } from 'lodash'
import cx from 'classnames'
import Component from '../common/Component'
import ValueField from './BenefitsSetValueField'
import UserContext from './BenefitsSetUserContext'
import FieldDefinition from './BenefitsSetFieldDefinition'

export default class BenefitRow extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    review: PropTypes.object,
    rejections: PropTypes.array,
    required: PropTypes.bool,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleReviewChange: PropTypes.func.isRequired,
    handleHistory: PropTypes.func,
    additionalTiersCount: PropTypes.number.isRequired,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    fieldDefinition: PropTypes.instanceOf(FieldDefinition).isRequired,
    benefitsSet: PropTypes.object.isRequired,
    showHistory: PropTypes.bool
  }

  static renderedProps = [
    'name',
    'label',
    'review',
    'rejections',
    'additionalTiersCount',
    'userContext',
    'fieldDefinition',
    'required'
  ]

  get benefit() {
    return this.props.fieldDefinition.value
  }

  get benefitName() {
    return this.props.fieldDefinition.value.name
  }

  get tierFields() {
    return this.tierOptions.map((option, index) => {
      const tier = this.tierForOption(option)

      const definition = this.props.fieldDefinition.benefitTierFieldDefinition(
        option,
        index,
        get(tier, 'value'),
        this.props.benefitsSet
      )

      return (
        <ValueField
          key={option}
          name={option}
          text={get(tier, 'value')}
          note={this.tierNoteForOption(option)}
          rejections={this.tierRejectionsForOption(option)}
          handleChange={this.handleTierChange}
          handleBlur={this.props.handleBlur}
          handleReviewChange={this.handleTierReviewChange}
          handleHistory={this.handleHistory}
          showHistory={this.props.showHistory}
          userContext={this.props.userContext}
          fieldDefinition={definition}
        />
      )
    })
  }

  get limitField() {
    const definition = this.props.fieldDefinition.benefitLimitFieldDefinition(this.props.benefitsSet)

    return (
      <ValueField
        name="limit"
        text={this.benefit.limit}
        note={this.limitReview}
        rejections={this.limitRejections}
        handleChange={this.handleChange}
        handleBlur={this.props.handleBlur}
        handleReviewChange={this.handleReviewChange}
        handleHistory={this.handleHistory}
        showHistory={this.props.showHistory}
        userContext={this.props.userContext}
        fieldDefinition={definition}
      />
    )
  }

  get tierOptions() {
    const options = ['In Network']

    times(this.props.additionalTiersCount).forEach((index) => {
      options.push(`In Network Tier ${index + 2}`)
    })

    options.push('Out of Network')

    return options
  }

  get limitReview() {
    return get(this.props.review, 'limit')
  }

  get limitRejections() {
    const rejections = this.props.rejections

    if (isNil(rejections)) {
      return []
    }

    return compact(rejections.map((r) => {
      const note = r.review.limit

      if (isNil(note)) {
        return null
      }

      return {
        review_id: r.review_id,
        created_at: r.created_at,
        review: note
      }
    }))
  }

  handleHistory = (name) => {
    this.props.handleHistory(name, this.props.fieldDefinition.value.name)
  }

  handleChange = (name, value) => {
    const benefit = cloneDeep(this.benefit) || {
      name: this.props.name,
      value: this.props.name
    }

    if (isNil(value)) {
      delete benefit[name]
    } else {
      benefit[name] = value
    }

    if (isEmpty(benefit.tiers)) {
      delete benefit.tiers
    }

    if (keys(benefit).length === 0) {
      this.props.handleChange(this.props.name, null)
      return
    }

    this.props.handleChange(this.props.name, benefit)
  }

  handleReviewChange = (name, note) => {
    const value = cloneDeep(this.props.review) || {}

    value.benefit_name = this.benefitName

    if (isNil(note)) {
      delete value[name]
    } else {
      value[name] = note
    }

    if (isEmpty(value.tier_reviews)) {
      delete value.tier_reviews
    }

    if (keys(value).length === 1) {
      this.props.handleReviewChange(this.props.name, null)
      return
    }

    this.props.handleReviewChange(this.props.name, value)
  }

  handleTierChange = (option, value) => {
    let tiers = get(this.benefit, 'tiers', [])
    tiers = this.assignValueToTiers(tiers, option, value)

    this.handleChange('tiers', tiers)
  }

  handleTierReviewChange = (option, note) => {
    let tierReviews = get(this.props.review, 'tier_reviews', [])
    tierReviews = this.assignNoteToTier(tierReviews, option, note)

    this.handleReviewChange('tier_reviews', tierReviews)
  }

  assignValueToTiers = (oldTiers, option, oldValue) => {
    let tiers = cloneDeep(oldTiers)
    let value = cloneDeep(oldValue)

    const existingIndex = tiers.findIndex((tier) => tier.option === option)
    const tier = tiers[existingIndex]

    if (isNil(value) && existingIndex !== -1) {
      value = ''
    }

    if (existingIndex !== -1) {
      tiers[existingIndex] = merge(tiers[existingIndex], {
        option: tier.option,
        value
      })
    } else {
      tiers.push({
        option,
        value
      })
    }

    if (isEmpty(compact(tiers))) {
      tiers = null
    }

    return tiers
  }

  assignNoteToTier = (oldTierReviews, option, note) => {
    let tierReviews = cloneDeep(oldTierReviews)

    const tier = this.tierForOption(option)

    if (isNil(tier)) {
      return {}
    }

    const existingIndex = tierReviews.findIndex((review) => get(review, 'tier_option') === tier.option)

    if (isNil(note) && existingIndex !== -1) {
      delete tierReviews[existingIndex]
    } else if (!isNil(note) && existingIndex !== -1) {
      tierReviews[existingIndex] = merge(tierReviews[existingIndex], {
        note,
        tier_option: tier.option
      })
    } else if (!isNil(note) && existingIndex === -1) {
      tierReviews.push({
        note,
        tier_option: tier.option
      })
    }

    if (isEmpty(compact(tierReviews))) {
      tierReviews = null
    }

    return tierReviews
  }

  tierNoteForOption = (option) => {
    const tier = this.tierForOption(option)

    if (isNil(tier)) {
      return null
    }

    const tierReviews = get(this.props.review, 'tier_reviews', [])
    const review = tierReviews.find((r) => {
      if (isNil(r)) {
        return false
      }

      return r.tier_option === tier.option
    })

    if (isNil(review)) {
      return null
    }

    return review.note
  }

  tierRejectionsForOption = (option) => {
    const rejections = this.props.rejections
    const tier = this.tierForOption(option)

    if (isNil(rejections)) {
      return []
    }

    if (isNil(tier)) {
      return []
    }

    return compact(rejections.map((r) => {
      const review = get(r.review, 'tier_reviews', []).find((tierReview) => (
        tierReview.tier_option === tier.option
      ))

      if (isNil(review)) {
        return null
      }

      return {
        review_id: r.review_id,
        created_at: r.created_at,
        review: review.note
      }
    }))
  }

  tierForOption(option) {
    const tiers = this.benefit.tiers

    if (isEmpty(tiers)) {
      return null
    }

    return tiers.find((tier) => tier.option === option)
  }

  render() {
    const { label, required } = this.props
    return (
      <tr>
        <td className={cx({ required })}>{label}</td>
        {this.tierFields}
        {this.limitField}
      </tr>
    )
  }
}

