import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import connectIndex from '../../lib/connectIndex'
import Component from '../common/Component'
import BulkPublishModal from './BulkPublishModal'
import actions from '../../actions/AncillaryRateFactorSets'

export class RatesPublishLink extends Component {
  static propTypes = {
    rateCount: PropTypes.number.isRequired,
    queryParams: PropTypes.object.isRequired,
    reset: PropTypes.func,
    createRateFactorPublish: PropTypes.func,
    selectedRates: PropTypes.array,
    rateFactorNames: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      rates: []
    }
  }

  get bulkPublishModal() {
    return this.state.showModal && (
      <BulkPublishModal
        handleClose={this.handleModalClose}
        handlePublish={this.handlePublish}
        disabled={!this.validSearchTerm}
      >
        {this.modalMessage()}
      </BulkPublishModal>
    )
  }

  get validSearchTerm() {
    const { rateCount } = this.props
    const searchTerm = this.props.queryParams.search_term
    return !isNil(searchTerm) && /rate_factor_set_id:\s+\S+/.test(searchTerm) && rateCount === 1
  }

  reset = () => {
    this.props.reset()
  }

  modalMessage = () => {
    const { rateCount } = this.props

    if (rateCount > 1) {
      return 'Only one Rate Factor Set may be published'
    }

    if (!this.validSearchTerm) {
      return 'To Publish Rates, please search for a rate_factor_set_id'
    }

    return `Publish Rate Factor Set: ${this.props.rateFactorNames}?`
  }

  handleOnClick = () => {
    this.reset()
    this.setState({ showModal: true, rates: this.props.selectedRates })
  }

  handleModalClose = () => {
    this.reset()
    this.setState({ showModal: false })
  }

  handlePublish = () => {
    this.props.createRateFactorPublish(this.state.rates)
    this.setState({ showModal: false })
  }

  render() {
    const title = `Publish Rates (${this.props.rateCount})`

    return (
      <button
        className="button quiet-button small"
        title={title}
        onClick={this.handleOnClick}
        disabled={this.props.rateCount === 0}
      >
        {title}
        {this.bulkPublishModal}
      </button>
    )
  }
}

export default connectIndex(
  RatesPublishLink,
  'ancillaryRateFactorSets',
  actions,
  [],
  {},
  [
    { action: actions.show.main, key: 'createRateFactorPublish' }
  ]
)
