import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { isNil, isEqual, pick, get, keys, isEmpty, startCase } from 'lodash'
import quoteRateMemberRatesActions from '../../../actions/VericredQuoteRateMemberRates'

export class PlanRatesComponent extends Component {
  static propTypes = {
    census: PropTypes.object,
    planPremiumsByFamily: PropTypes.array.isRequired,
    plan: PropTypes.object.isRequired,
    quoteRateMemberRates: PropTypes.object,
    quoteRateMemberRatesCreate: PropTypes.func.isRequired,
    quoteRateMemberRatesReset: PropTypes.func.isRequired,
    quoteRateMemberRatesCreated: PropTypes.bool,
    quoteRates: PropTypes.array
  }

  componentWillMount(prevProps) {
    const { plan, planPremiumsByFamily, quoteRateMemberRates, quoteRateMemberRatesCreated } = this.props
    if (planPremiumsByFamily[0].rate_id) {
      if ((!quoteRateMemberRatesCreated
        && !quoteRateMemberRates.requestInProgress
        && !quoteRateMemberRates.requestComplete)
        || (planPremiumsByFamily[0].rate_id) !== quoteRateMemberRates.attributes.rate_id) {
        this.props.quoteRateMemberRatesReset()
        this.props.quoteRateMemberRatesCreate(
          {
            rate_id: planPremiumsByFamily[0].rate_id,
            plan_id: plan.id
          }
        )
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.planPremiumsByFamily[0].rate_id) {
      if (!isNil(nextProps.quoteRateMemberRates)
        && (nextProps.quoteRateMemberRates.created === true
          && nextProps.quoteRateMemberRates.attributes.plan_id === this.props.plan.id)) {
        return true
      }
      return false
    }

    return !isEqual(pick(nextProps, 'plan'), pick(this.props, 'plan'))
  }

  componentWillUnmount() {
    this.props.quoteRateMemberRatesReset()
  }

  get ratingMethods() {
    return ['age_banded', '4_tier_composite', '3_tier_composite', '2_tier_composite', 'adult_child_tier_composite']
  }

  get quoteRateMemberRatesComponents() {
    const { quoteRateMemberRates, plan } = this.props
    return quoteRateMemberRates.responseAttributes.map((fp, index) => (
      <div key={`${plan.id}-rates-${index}`} id={`${plan.id}-rates`} className="plan-benefits-info-group">
        <table className="pure-table" name={`${plan.name}-rates-table`}>
          <thead>
            <tr>
              <td style={{ width: '50%' }}><strong>Family Name</strong></td>
              <td><strong>Cost</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td name="benefit-rate-family">
                <span>{fp.member_external_id}</span>
              </td>
              <td name="benefit-rate-premium">
                <span>{!isNil(fp.total_premium) ? `$${parseFloat(fp.total_premium).toFixed(2)}` : 'n/a'}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>Rating methods</p>
                {this.ratingMethodsPremiums(fp)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ))
  }

  get familyComponents() {
    const { planPremiumsByFamily } = this.props
    return planPremiumsByFamily.map((fp) => (
      <tr key={`${fp.name}-${fp.premium}`}>
        <td name="benefit-rate-family">
          <span>{fp.name}</span>
        </td>
        <td name="benefit-rate-premium">
          <span>{fp.premium !== 0 && !isNil(fp.premium) ? `$${fp.premium.toFixed(2)}` : 'n/a'}</span>
        </td>
      </tr>
    ))
  }

  get premiumStyle() {
    return { className: 'pure-u-1', marginBottom: '10px' }
  }

  ratingMethodComponent = (ratingMethod, fp) => {
    const ratingMethodPremiums = get(fp.premiums, ratingMethod, {})
    if (isEmpty(ratingMethodPremiums)) {
      return (
        <tr>
          <td colSpan={2}>n/a</td>
        </tr>
      )
    }
    return keys(ratingMethodPremiums).map((key, index) => (
      <tr key={`rating_premium_${key}_${index}`}>
        <td>{this.formatKey(key, fp)}</td>
        <td>{get(ratingMethodPremiums, key, 0)}</td>
      </tr>
    ))
  }

  formatKey = (key, fp) => {
    if (key !== 'member') {
      return key
    }

    const family = this.props.census.families.find((fam) => fam.name === fp.member_external_id)
    const applicant = family.members.find((member) => member.member_type === 'applicant')

    return `${key} (Age ${applicant.age})`
  }

  ratingMethodsPremiums = (fp) => {
    return this.ratingMethods.map((rm) => (
      <div style={this.premiumStyle} key={`${fp.name}-${rm}-${fp.member_external_id}`}>
        <div style={this.premiumStyle}>
          <b>{startCase(rm)}</b>
        </div>
        <table className="pure-table buffer-bottom">
          <thead>
            <th style={{ width: '50%' }}>Key</th>
            <th>Value</th>
          </thead>
          <tbody>
            {this.ratingMethodComponent(rm, fp)}
          </tbody>
        </table>
      </div>
    ))
  }

  statusComponent = (text, className) => (
    <div className={className}>
      <span>{text || 'Loading'}</span>
    </div>
  )

  quoteRate = () => {
    return this.props.quoteRates.find((rate) => rate.id === this.props.planPremiumsByFamily[0].rate_id)
  }

  levelFundedFields = () => {
    return (
      <div>
        <p>Level Funded fields</p>
        <table className="pure-table" key="level-funded-plans-table">
          <thead>
            <tr>
              <th style={{ width: '50%' }}>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aggregate Stop Loss Amount</td>
              <td>{this.quoteRate().aggregate_stop_loss_amount}</td>
            </tr>
            <tr>
              <td>Claims Surplus Fraction</td>
              <td>{this.quoteRate().claims_surplus_fraction}</td>
            </tr>
            <tr>
              <td>Monthly Claims Fund</td>
              <td>{this.quoteRate().premiums['4_tier_composite'].claims_fund}</td>
            </tr>
            <tr>
              <td>Monthly Fixed Costs</td>
              <td>{this.quoteRate().premiums['4_tier_composite'].fixed_costs}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { plan, planPremiumsByFamily, quoteRateMemberRates } = this.props
    if (planPremiumsByFamily[0].rate_id
      && ((!quoteRateMemberRates.created) || (quoteRateMemberRates.attributes.plan_id !== plan.id))) {
      return this.statusComponent('Loading rates', 'howie_info')
    }

    if (planPremiumsByFamily[0].rate_id) {
      return (
        <>
          {this.quoteRateMemberRatesComponents}
          {this.props.census.audience === 'Level Funded' && this.levelFundedFields()}
        </>
      )
    }
    return (
      <div key={`${plan.id}-rates`} id={`${plan.id}-rates`} className="plan-benefits-info-group">
        <table className="pure-table" name={`${plan.name}-rates-table`} key="main-ratings-table">
          <thead>
            <tr>
              <td style={{ width: '50%' }}>Family Name</td>
              <td>Cost</td>
            </tr>
          </thead>
          <tbody>
            {this.familyComponents}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const quoteRateMemberRates = state.quoteRateMemberRates.create
  return {
    quoteRateMemberRates,
    quoteRateMemberRatesCreated: quoteRateMemberRates.created,
    quoteRates: state.quoteRates.create.responseAttributes
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  quoteRateMemberRatesCreate: quoteRateMemberRatesActions.create.main,
  quoteRateMemberRatesReset: quoteRateMemberRatesActions.create.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PlanRatesComponent)
