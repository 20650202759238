import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MenuLink from './MenuLink'

export default class MenuSection extends Component {
  static propTypes = {
    hasChildren: PropTypes.bool,
    title: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
      }).isRequired
    )
  }

  get links() {
    return this.props.routes.map(({ title, path }) => this.link(title, path))
  }

  link = (title, path) => {
    return (
      <li
        key={`menu-link-${title}`}
        className="pure-menu-item"
      >
        <MenuLink title={title} path={path} />
      </li>
    )
  }

  render() {
    const { title } = this.props
    const parentClassNames = ['pure-menu-link', 'menu-link']
    const childrenClassNames = ['pure-menu-children']

    if (this.props.hasChildren) {
      parentClassNames.push('menu-link-right-arrow')
      childrenClassNames.push('pure-menu-parent')
    }

    return (
      <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
        <span className={parentClassNames.join(' ')}>{title}</span>
        <ul className={childrenClassNames.join(' ')}>
          {this.links}
        </ul>
      </li>
    )
  }
}
