import React from 'react'
import { get, xor, isEmpty, isNil, isFunction, omit, keys, has, snakeCase } from 'lodash'

import SelectField from '../common/SelectField'
import TextField from '../common/TextField'
import JsonField from '../common/JsonField'
import CheckboxField from '../common/CheckboxField'
import RemoteOptionsSelectField from '../common/RemoteOptionsSelectField'

export default class FieldDefinition {
  constructor(name, field, value, error, values, configuration,
    userContext, remoteOptionsSelectStates, remoteOptionsSelectActions) {
    const additionalKeys = keys(omit(field, [
      'disabled',
      'display',
      'foreign_key',
      'hidden',
      'label',
      'cvt_label',
      'max',
      'min',
      'never_hide',
      'display_on_cvt',
      'options',
      'step',
      'type',
      'recordsRequest',
      'reset',
      'recordPath',
      'existingRecordPath',
      'recordLabel',
      'searchType',
      'filterOptions',
      'filterFields',
      'bulkEditKey',
      'vericred_name',
      'alternateKey',
      'valueParser',
      'display_only'
    ]))
    if (!isEmpty(additionalKeys)) {
      throw new Error(`Invalid keys passed to FieldDefinition: ${additionalKeys.join(', ')}`)
    }
    this.fieldOptions = field
    this.configuration = configuration
    this.userContext = userContext
    this.error = error

    this.name = name
    this.type = field.type || 'text'
    this.label = field.label
    this.value = value
    this.neverHide = field.never_hide
    this.foreignKey = field.foreign_key
    this.alternateKey = field.alternateKey
    this.displayOnly = field.display_only || false
    this.bulkEditKey = field.bulkEditKey

    let options = field.options

    if (isEmpty(options) && has(configuration, name)) {
      options = configuration[name]
    }

    this.options = FieldDefinition.assignFunctionValue(options, values, configuration, userContext)
    this.disabled = FieldDefinition.assignFunctionValue(field.disabled, values, configuration, userContext) || false
    this.hidden = FieldDefinition.assignFunctionValue(field.hidden, values, configuration, userContext) || false
    this.display = FieldDefinition.assignFunctionValue(field.display, values, configuration, userContext)

    this.min = field.min
    this.max = field.max
    this.step = field.step
    this.remoteOptionsSelectStates = remoteOptionsSelectStates
    this.remoteOptionsSelectActions = remoteOptionsSelectActions
    this.recordLabel = field.recordLabel
    this.searchType = field.searchType
    this.filterOptions = field.filterOptions
    this.recordsRequest = get(remoteOptionsSelectActions, field.recordsRequest)
    this.reset = get(remoteOptionsSelectActions, field.reset)
    if (field.type === 'remoteOptionsSelectField') {
      this.setRecordsForRemoteOptionsSelectField(remoteOptionsSelectStates, values, field)
      this.setFiltersForRemoteOptionsSelectField(values, field.filterFields)
    }
  }

  static assignFunctionValue(field, values, configuration, userContext) {
    if (isFunction(field)) {
      return field(values, configuration, userContext)
    }

    return field
  }

  setFiltersForRemoteOptionsSelectField(otherFields, filterFields) {
    if (!(isNil(filterFields))) {
      const filterKeysForValues = filterFields.map((field) => field.data_key)

      this.remoteFilter = Object.keys(otherFields)
        .filter((key) => filterKeysForValues.includes(key))
        .reduce((obj, key) => {
          const filterField = filterFields.find((fk) => fk.data_key === key)
          const filterKeyForQuery = filterField.filter_key

          let fieldValue = otherFields[key]
          if (!isEmpty(filterField.addition)) {
            fieldValue = Number(fieldValue) + Number(filterField.addition)
          }

          // eslint-disable-next-line no-param-reassign
          obj[filterKeyForQuery] = fieldValue

          return obj
        }, {})

      const remoteFilterKeys = Object.keys(this.remoteFilter)
      const requiredKeys = filterFields.filter((field) => field.required === true).map((field) => field.filter_key)

      if (requiredKeys.length > 0 && this.disabled !== true) {
        this.disabled = !isEmpty(xor(remoteFilterKeys, requiredKeys))
      }
    }
  }

  setRecordsForRemoteOptionsSelectField(remoteOptionsSelectStates, values, field) {
    this.records = []
    if (this.isRecordsFromRequest(remoteOptionsSelectStates, field)) {
      this.records = get(remoteOptionsSelectStates, field.recordPath).records
      this.requestInProgress = get(remoteOptionsSelectStates, field.recordPath).requestInProgress
    } else if (this.isRecordsFromExistingValue(values, field)) {
      this.records = [get(values, field.existingRecordPath)]
    }
  }

  isRecordsFromRequest(remoteOptionsSelectStates, field) {
    return (!(isNil(remoteOptionsSelectStates) || isNil(get(remoteOptionsSelectStates, field.recordPath).records)))
  }

  isRecordsFromExistingValue(values, field) {
    return (!isNil(get(values, field.existingRecordPath)))
  }

  field(handleChange, handleBlur) {
    switch (this.type) {
    case 'select':
      return this.selectField(handleChange, handleBlur, false)
    case 'checkbox':
      return this.checkboxField(handleChange, handleBlur)
    case 'remoteOptionsSelectField':
      return this.remoteOptionsSelectField(handleChange, handleBlur)
    case 'multiSelect':
      return this.selectField(handleChange, handleBlur, true)
    case 'jsonField':
      return this.jsonField(handleChange, handleBlur)
    default:
      return this.textField(handleChange, handleBlur)
    }
  }

  get displayValue() {
    if (!isNil(this.display)) {
      return this.display
    }

    if (!isEmpty(this.options)) {
      const displayOption = this.options.find((val) => val.value === this.value)

      if (!isNil(displayOption)) {
        return displayOption.text
      }
    }

    return this.value
  }

  remoteOptionsSelectField(handleChange, handleBlur) {
    return (
      <RemoteOptionsSelectField
        key={this.name}
        name={this.name}
        recordLabel={this.recordLabel}
        searchType={this.searchType}
        handleChange={handleChange}
        errorMessage={this.error}
        value={this.value}
        records={this.records}
        reset={this.reset}
        recordsRequest={this.recordsRequest}
        requestInProgress={this.requestInProgress}
        disabled={this.disabled}
        filterOptions={this.filterOptions}
        filterFields={this.remoteFilter}
      />
    )
  }

  selectField(handleChange, handleBlur, multi) {
    return (
      <SelectField
        key={this.name}
        name={this.name}
        title={this.label}
        options={this.options}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errorMessage={this.error}
        value={this.value}
        disabled={this.disabled}
        multi={multi}
      />
    )
  }

  checkboxField(handleChange, handleBlur) {
    return (
      <CheckboxField
        key={this.name}
        name={this.name}
        title={this.label}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errorMessage={this.error}
        value={this.value}
        disabled={this.disabled}
      />
    )
  }

  textField(handleChange, handleBlur) {
    return (
      <TextField
        key={this.name}
        name={this.name}
        type={this.type}
        title={this.label}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errorMessage={this.error}
        value={this.value}
        disabled={this.disabled}
        min={this.min}
        max={this.max}
        step={this.step}
      />
    )
  }

  jsonField(handleChange, handleBlur) {
    return (
      <JsonField
        key={this.name}
        name={this.name}
        title={this.label}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errorMessage={this.error}
        value={this.value}
        disabled={this.disabled}
      />
    )
  }

  benefitLimitFieldDefinition(values) {
    return new FieldDefinition(
      `${this.name}-limit`,
      { label: `${this.label} Limit` },
      this.value.limit,
      get(this.error, 'limit'),
      values,
      this.configuration,
      this.userContext
    )
  }

  benefitTierFieldDefinition(tier, index, value, values) {
    const tierError = get(this.error, 'tiers', {})

    return new FieldDefinition(
      `${this.name}-${snakeCase(tier)}`,
      { label: `${this.label} ${tier}` },
      value,
      get(tierError[index], 'value'),
      values,
      this.configuration,
      this.userContext
    )
  }

  copyWithValue(value, values) {
    return new FieldDefinition(
      this.name,
      this.fieldOptions,
      value,
      this.error,
      values,
      this.configuration,
      this.userContext
    )
  }
}
