import React from 'react'
import PropTypes from 'prop-types'
import { castArray, debounce, isEmpty, isNil, split } from 'lodash'
import SelectField from './SelectField'
import Component from './Component'

export default class RemoteOptionsSelectField extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func,
    filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    name: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    clearable: PropTypes.bool,

    recordsRequest: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    records: PropTypes.array,
    recordLabel: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ]),
    recordValue: PropTypes.string,
    searchType: PropTypes.string,
    autoFocus: PropTypes.bool,
    multi: PropTypes.bool,
    filterFields: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    requestInProgress: false,
    recordLabel: ['name'],
    recordValue: 'id',
    searchType: 'search_term'
  }

  recordsRequest = debounce((value) => {
    if (value.length >= 2) {
      this.setState({ selectedOptions: null }, () => {
        const recordRequestQuery = {}
        recordRequestQuery[this.props.searchType] = value

        if (this.props.filterFields) {
          Object.keys(this.props.filterFields).forEach((key) => {
            recordRequestQuery[key] = this.props.filterFields[key]
          })
        }

        this.props.recordsRequest(recordRequestQuery).then((res) => {
          this.setState({ records: res.records })
        })
      })
    } else if (!isEmpty(this.props.records)) {
      this.props.reset()
      this.setState({ records: [] })
    }

    this.setState({ value })
  }, 250)

  constructor(props) {
    super(props)

    this.state = {
      value: '',
      records: props.records,
      selected: this.savedSelected
    }
  }

  componentWillUnmount() {
    this.recordsRequest.cancel()
  }

  get savedSelected() {
    if (!isNil(this.props.records)) {
      return this.props.records.map((group) => ({
        label: this.labelText(group),
        value: group[this.props.recordValue]
      }))
    }
    return null
  }

  get options() {
    const {
      requestInProgress
    } = this.props

    const {
      selectedOptions,
      records
    } = this.state

    if (requestInProgress) {
      return []
    }

    if (!isNil(selectedOptions)) {
      return castArray(selectedOptions).map((o) => ({ value: o.value, text: o.label }))
    }

    if (isNil(records)) return []

    return records.map((group) => ({
      text: this.labelText(group),
      value: group[this.props.recordValue]
    }))
  }

  get noResultsText() {
    if (this.props.requestInProgress) {
      return false
    }

    if (this.state.value.length < 2) {
      return 'Enter at least 2 characters to search'
    }
    return 'No results'
  }

  labelText = (group) => {
    if (!Array.isArray(this.props.recordLabel)) {
      return group[this.props.recordLabel]
    }

    return this.props.recordLabel.map((recordLabel) => {
      if (recordLabel.includes('.')) {
        const keys = split(recordLabel, '.', 2)
        return group[keys[0]][keys[1]]
      }

      return group[recordLabel]
    }).join(' - ')
  }

  handleChange = (name, value, options) => {
    let returnValue

    if (this.props.multi && isNil(value)) {
      returnValue = []
    } else {
      returnValue = value
    }

    this.setState({ selectedOptions: options, selected: options }, () => {
      this.props.handleChange(name, returnValue, options)
    })
  }

  handleInputChange = (value) => {
    if (value !== this.state.value) {
      this.recordsRequest(value)
    }
  }

  render() {
    let value = this.props.value
    if (this.props.multi) {
      value = this.state.selected
    }

    return (
      <SelectField
        name={this.props.name}
        label={this.props.label}
        handleChange={this.handleChange}
        handleInputChange={this.handleInputChange}
        errorMessage={this.props.errorMessage}
        value={value}
        handleBlur={this.props.handleBlur}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        clearable={this.props.clearable}
        options={this.options}
        isLoading={this.props.requestInProgress}
        noResultsText={this.noResultsText}
        autoFocus={this.props.autoFocus}
        multi={this.props.multi}
        filterOption={this.props.filterOption}
        searchColumns={this.props.searchColumns}
      />
    )
  }
}
