import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, isNil } from 'lodash'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/IssuerPeriodAudiences'
import connectIndex from '../../lib/connectIndex'
import SmokeCheckRun from '../SmokeChecks/SmokeCheckRun'

export class IssuerPeriodAudienceIndex extends Component {
  static propTypes = {
    name: PropTypes.string,
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterable: PropTypes.bool,
    filters: PropTypes.object,
    filterActions: PropTypes.object,
    includeSearch: PropTypes.bool,
    configuration: PropTypes.object,
    includeNewRecordLink: PropTypes.bool,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    name: 'IPAs',
    filterable: true,
    includeNewRecordLink: false,
    searchColumns: ['issuer', 'issuer_id']
  }

  static issuerDisplay = (record, column, config) => {
    const issuer = get(record, column.key, [])
    return `${issuer.issuer_id} | ${issuer.name}`
  }

  static plansLinkDisplay = (record, column, config) => {
    if (record.published_plans_count + record.unpublished_plans_count === 0) {
      return 'No Plans Available'
    }

    const query = []
    query.push(`${encodeURIComponent('search_term')}=${encodeURIComponent(record.issuer.issuer_id)}`)
    query.push(`${encodeURIComponent('audience')}=${encodeURIComponent(record.audience)}`)
    query.push(`${encodeURIComponent('year')}=${encodeURIComponent(record.calendar_period.year)}`)
    query.push(`${encodeURIComponent('issuer_period_audience')}=${encodeURIComponent(record.id)}`)

    if (record.audience === 'Small Group') {
      query.push(`${encodeURIComponent('quarter')}=${encodeURIComponent(record.calendar_period.quarter)}`)
    }

    const href = `/plan_products?${query.join('&')}`
    return <Link to={href}>Plans</Link>
  }

  static sidekiqBatchLinkDisplay = (record, column, config) => {
    if (isNil(record.sidekiq_batch_job_url)) {
      return 'No Publishing Jobs Enqueued'
    }

    return (
      <a href={record.sidekiq_batch_job_url} target="_blank" rel="noopener noreferrer">Publishing Status URL</a>
    )
  }

  static smokeChecksLinkDisplay = (record) => {
    return <SmokeCheckRun ipaId={record.id} />
  }

  static needsPublishingDisplay = (record, column, config) => {
    return record.needs_publishing_plans_count
  }

  static columns = [
    { key: 'issuer', label: 'Issuer', display: IssuerPeriodAudienceIndex.issuerDisplay },
    { key: 'audience', label: 'Audience', filterable: true, sortable: false },
    {
      key: 'calendar_period.display_quarter',
      label: 'Quarter',
      sortable: true,
      filterable: true,
      remoteKey: 'quarter'
    },
    {
      key: 'calendar_period.year',
      label: 'Year',
      filterable: true,
      sortable: true,
      remoteKey: 'year'
    },
    { key: 'unpublished_plans_count', label: 'Unpublished' },
    { key: 'published_plans_count', label: 'Published' },
    {
      key: 'needs_publishing',
      label: 'Needs Publishing',
      filterable: true,
      display: IssuerPeriodAudienceIndex.needsPublishingDisplay
    },
    { key: 'rejection_reason', label: 'Rejection Reason' },
    {
      key: 'sidekiq_batch_job_url',
      label: 'Publishing Status URL',
      display: IssuerPeriodAudienceIndex.sidekiqBatchLinkDisplay
    },
    { key: 'plans', label: 'Plans', display: IssuerPeriodAudienceIndex.plansLinkDisplay },
    {
      key: 'smoke_check',
      label: 'Smoke Check',
      filterable: false,
      sortable: false,
      display: IssuerPeriodAudienceIndex.smokeChecksLinkDisplay
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/issuer_period_audiences"
          name={this.props.name}
          pagination={this.props.pagination}
          columns={IssuerPeriodAudienceIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterable={this.props.filterable}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          includeSearch={this.props.includeSearch}
          configuration={this.props.configuration}
          includeNewRecordLink={this.props.includeNewRecordLink}
          actions={[]}
          searchColumns={this.props.searchColumns}
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'audience',
    config: 'plan_products.audience'
  },
  {
    key: 'calendar_period.year',
    config: 'plan_products.year'
  },
  {
    key: 'calendar_period.display_quarter',
    config: 'plan_products.quarter'
  },
  {
    key: 'needs_publishing',
    options: [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' }
    ]
  }
]

export default connectIndex(IssuerPeriodAudienceIndex, 'issuerPeriodAudiences', actions, filters)
