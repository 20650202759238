import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, get, isEmpty, last, find, filter, compact, isNil, includes, startCase } from 'lodash'
import FamilyMemberComponent from './FamilyMemberComponent'

export class FamilyMembersComponent extends Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    errors: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  get applicantComponent() {
    return this.memberComponentByType('applicant')
  }

  get spouseComponent() {
    return this.memberComponentByType('spouse')
  }

  get childrenAttributes() {
    let childrenValues = filter(this.props.value, { member_type: 'child' })

    if (isEmpty(childrenValues)) {
      childrenValues = [this.defaultMember('child')]
    }

    const children = childrenValues.map((child, index) => ({
      index: index + this.firstChildIndex,
      value: child,
      label: (index === 0) ? 'Children' : ''
    }))

    if (isEmpty(children) || get(last(children), 'value.age', '') !== '') {
      children.push({
        index: children.length + this.firstChildIndex,
        value: this.defaultMember('child'),
        label: ''
      })
    }

    return children
  }

  get firstChildIndex() {
    return filter(this.props.value, (m) => m.member_type !== 'child').length
  }

  get childrenComponents() {
    return this.childrenAttributes.map((child) => this.memberComponent(child.index, child.value, child.label))
  }

  memberComponentByType = (type) => {
    const member = find(this.props.value, { member_type: type }) || this.defaultMember(type)
    return this.memberComponent(this.memberIndex(type), member, startCase(type))
  }

  memberIndex = (type) => {
    const nonChildren = filter(this.props.value, (m) => m.member_type !== 'child')
    if (nonChildren.length === 0) {
      return 0
    }

    if (nonChildren.length === 1 && nonChildren[0].member_type === type) {
      return 0
    }

    if (type === 'applicant') {
      return 0
    }

    return 1
  }

  handleChange(index, value) {
    const members = compact(cloneDeep(this.props.value))
    if (!isNil(value)) {
      if (includes(['spouse', 'applicant'], value.member_type)) {
        const member = filter(members, (m) => m.member_type === value.member_type)[0]
        if (isNil(member)) {
          const insertIndex = this.memberIndex(value.member_type)
          members.splice(insertIndex, 0, value)
        }
      }
    }

    if (get(value, 'age')) {
      members[index] = value
    } else {
      delete members[index]
    }

    this.props.handleChange(this.props.name, compact(members))
  }

  memberComponent(index, value, label = '') {
    return (
      <FamilyMemberComponent
        key={`member-${value.member_type}-${index}`}
        label={label}
        index={index}
        value={value}
        disabled={this.props.disabled}
        errors={get(this.props, `errors.${index}`, {})}
        handleChange={this.handleChange}
      />
    )
  }

  defaultMember(type) {
    return {
      member_type: type,
      tobacco: false
    }
  }

  render() {
    return (
      <table className="pure-table family-member-table borderless-table">
        <colgroup>
          <col className="label" />
          <col className="age" />
          <col className="tobacco" />
          <col className="gender" />
        </colgroup>
        <thead>
          <tr>
            <td />
            <td>Age</td>
            <td>Tobacco?</td>
            <td>Gender</td>
          </tr>
        </thead>
        <tbody>
          {this.applicantComponent}
          {this.spouseComponent}
          {this.childrenComponents}
        </tbody>
      </table>
    )
  }
}

export default FamilyMembersComponent
