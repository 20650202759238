import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_TOKEN_VALID,
  RESET_PASSWORD_TOKEN_EXPIRED
} from '../../actions/Login/consts'

const defaultState = {
  requestInProgess: false,
  errors: undefined,
  success: false,
  expired: false
}

export default function ResetPasswordReducer(state = defaultState, action) {
  switch (action.type) {
  case RESET_PASSWORD_REQUEST:
    return { ...state,
      requestInProgess: true,
      success: false,
      errors: undefined,
      expired: false }
  case RESET_PASSWORD_SUCCESS:
    return { ...state,
      requestInProgess: false,
      success: true,
      errors: undefined,
      expired: false }
  case RESET_PASSWORD_ERROR:
    return { ...state,
      requestInProgess: false,
      success: false,
      errors: action.errors,
      expired: false }
  case RESET_PASSWORD_TOKEN_VALID:
    return { ...state,
      requestInProgess: false,
      success: false,
      errors: undefined,
      expired: false }
  case RESET_PASSWORD_TOKEN_EXPIRED:
    return { ...state,
      requestInProgess: false,
      success: false,
      errors: action.errors,
      expired: true }
  default:
    return { ...state }
  }
}
