import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import Component from '../common/Component'
import UserContext from './BenefitsSetUserContext'
import FieldDefinition from './BenefitsSetFieldDefinition'
import ReviewValueField from './BenefitsSetReviewValueField'
import ReviewDisplayField from './BenefitsSetReviewDisplayField'

export default class ValueField extends Component {
  static propTypes = {
    colSpan: PropTypes.number,
    fieldDefinition: PropTypes.instanceOf(FieldDefinition).isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleHistory: PropTypes.func,
    handleReviewChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    note: PropTypes.string,
    rejections: PropTypes.array,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    showHistory: PropTypes.bool
  }

  static renderedProps = [
    'colSpan',
    'fieldDefinition',
    'name',
    'note',
    'text',
    'userContext',
    'error',
    'rejections'
  ]

  get editableField() {
    const field = this.props.fieldDefinition.field(this.handleChange, this.handleBlur)

    return (
      <div className="value-field-container">
        {field}
        {this.reviewDisplay}
      </div>
    )
  }

  get viewableField() {
    return (
      <div className="value-field-container">
        {this.textValue}
        {this.reviewDisplay}
      </div>
    )
  }

  get reviewableField() {
    const { name, note } = this.props

    return (
      <div className="value-field-container">
        <ReviewValueField
          name={name}
          note={note}
          handleChange={this.handleReviewChange}
        >
          {this.textValue}
        </ReviewValueField>
        {this.reviewDisplay}
      </div>
    )
  }

  get reviewDisplay() {
    if (isEmpty(this.props.rejections)) {
      return null
    }

    return (
      <ReviewDisplayField
        rejections={this.props.rejections}
      />
    )
  }

  get textValue() {
    if (!this.props.userContext.isViewable) {
      return null
    }

    return (
      <div>
        <div className="value-field-text">{this.props.fieldDefinition.displayValue}</div>
      </div>
    )
  }

  handleChange = (name, value) => {
    this.props.handleChange(this.props.name, value)
  }

  handleBlur = (name) => {
    this.props.handleBlur(this.props.name)
  }

  handleHistory = () => {
    this.props.handleHistory(this.props.fieldDefinition.alternateKey || this.props.name)
  }

  handleReviewChange = (name, value) => {
    this.props.handleReviewChange(this.props.name, value)
  }

  render() {
    const colSpan = this.props.colSpan || 1
    const userContext = this.props.userContext

    let field

    if (userContext.isEditable) {
      field = this.editableField
    } else if (userContext.isReviewable) {
      field = this.reviewableField
    } else {
      field = this.viewableField
    }

    return (
      <td className="value-field" colSpan={colSpan || 2}>
        <i title="History" className="history_icon">
          {field}
          {!(this.props.showHistory === false)
            && <IoIosInformationCircleOutline className="history_button" size={20} onClick={this.handleHistory} />}
        </i>
      </td>
    )
  }
}
