import React from 'react'
import PropTypes from 'prop-types'
import { isNil, isEmpty, intersection } from 'lodash'
import pluralize from 'pluralize'
import connectIndex from '../../lib/connectIndex'
import Component from '../common/Component'
import BulkPublishModal from './BulkPublishModal'
import exportActions from '../../actions/BenefitsSetsExports'
import publishingActions from '../../actions/BenefitsSetsExportPublishings'
import identifierActions from '../../actions/BenefitsSetIdentifiers'

export class BulkPublishLink extends Component {
  static propTypes = {
    planCount: PropTypes.number.isRequired,
    queryParams: PropTypes.object.isRequired,
    index: PropTypes.func,
    reset: PropTypes.func,
    createBenefitsSetExport: PropTypes.func,
    resetCreateBenefitsSetExport: PropTypes.func,
    benefitsSetExport: PropTypes.object,
    createExportPublish: PropTypes.func,
    resetCreateExportPublish: PropTypes.func,
    records: PropTypes.array,
    productLine: PropTypes.string.isRequired,
    approveAll: PropTypes.bool,
    selectedRecords: PropTypes.array,
    approvedRecords: PropTypes.array,
    includeSearchTermValidation: PropTypes.bool,
    termValidated: PropTypes.string,
    planType: PropTypes.string
  }

  static defaultProps = {
    approveAll: true,
    approvedRecords: [],
    includeSearchTermValidation: false,
    planType: 'Bulk'
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      export: null,
      ids: []
    }
  }

  get bulkPublishModal() {
    return this.state.showModal && (
      <BulkPublishModal
        handleClose={this.handleModalClose}
        handlePublish={this.handlePublish}
        disabled={isEmpty(this.state.export) || !this.validSearchTerm}
      >
        {this.modalMessage()}
      </BulkPublishModal>
    )
  }

  get validSearchTerm() {
    const { includeSearchTermValidation, queryParams, termValidated } = this.props

    if (!includeSearchTermValidation) { return true }

    const searchTerm = queryParams.search_term
    const regex = new RegExp(`${termValidated}:\\s+\\S+`)
    return !isNil(searchTerm) && regex.test(searchTerm)
  }

  setIdentifiers = async () => {
    if (this.props.selectedRecords) {
      this.setState({ ids: this.filterForApproval(this.props.selectedRecords) })
    } else {
      await this.props.index({ ...this.props.queryParams, product_line: this.props.productLine })

      this.setState({ ids: this.filterForApproval(this.props.records) })
    }
  }

  reset = () => {
    this.props.reset()
    this.props.resetCreateBenefitsSetExport()
    this.props.resetCreateExportPublish()
  }

  modalMessage = () => {
    const { planCount, termValidated } = this.props

    const publishableCount = this.state.ids.length
    const plural = pluralize('plans', publishableCount, true)

    if (!this.validSearchTerm) {
      return `To Publish Plans, please search for an ${termValidated}:`
    } if (publishableCount === 0) {
      return 'None of these plans are approved for publishing'
    } if (isEmpty(this.state.export)) {
      return 'Creating Export...'
    } if (publishableCount === planCount) {
      return `Publish ${plural}?`
    }

    return `${publishableCount} out of ${planCount} plans approved. Publish ${plural}?`
  }

  filterForApproval = (records) => {
    if (this.props.approveAll) return records

    return intersection(records, this.props.approvedRecords)
  }

  createExport = async () => {
    await this.setIdentifiers()

    if (isEmpty(this.state.ids)) {
      return
    }

    await this.props.createBenefitsSetExport({ benefits_sets: this.state.ids })

    this.setState({ export: this.props.benefitsSetExport.responseAttributes })
  }

  handleOnClick = () => {
    this.reset()
    this.setState({ showModal: true, export: null })
    this.createExport()
  }

  handleModalClose = () => {
    this.reset()
    this.setState({ showModal: false })
  }

  handlePublish = () => {
    this.props.createExportPublish({ approve_all: this.props.approveAll }, { id: this.state.export.id })
    this.setState({ showModal: false })
  }

  render() {
    const title = `Publish ${this.props.planType} Plans (${this.props.planCount})`

    return (
      <button
        className="button quiet-button small"
        title={title}
        onClick={this.handleOnClick}
        disabled={this.props.planCount === 0}
      >
        {title}
        {this.bulkPublishModal}
      </button>
    )
  }
}

export default connectIndex(
  BulkPublishLink,
  'benefitsSetIdentifiers',
  identifierActions,
  [],
  {},
  [
    { action: exportActions.create.main, key: 'createBenefitsSetExport' },
    { action: exportActions.create.reset, key: 'resetCreateBenefitsSetExport' },
    { action: publishingActions.create.main, key: 'createExportPublish' },
    { action: publishingActions.create.reset, key: 'resetCreateExportPublish' }
  ],
  [
    { key: 'benefitsSetExport', reducer: 'benefitsSetsExports', action: 'create' }
  ]
)
