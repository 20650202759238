import { combineReducers } from 'redux'
import MedicalBenefitsSetReducer from './MedicalBenefitsSets'
import DentalBenefitsSetReducer from './DentalBenefitsSets'
import LifeBenefitsSetReducer from './LifeBenefitsSets'
import DisabilityBenefitsSetReducer from './DisabilityBenefitsSets'
import VisionBenefitsSetReducer from './VisionBenefitsSets'
import MedicareAdvantageBenefitsSetReducer from './MedicareAdvantageBenefitsSets'
import MedicalBenefitsSetReviewReducer from './MedicalBenefitsSetReviews'
import BenefitsSetFieldHistoryReducer from './BenefitsSetFieldHistories'
import DentalBenefitsSetReviewReducer from './DentalBenefitsSetReviews'
import VisionBenefitsSetReviewReducer from './VisionBenefitsSetReviews'
import BenefitsSetsExportReducer from './BenefitsSetsExports'
import CarrierVerificationReducer from './CarrierVerification'
import LoginReducer from './Login'
import UsersReducer from './Users'
import CarrierGroupsReducer from './CarrierGroups'
import CarriersReducer from './Carriers'
import CarrierBrandsReducer from './CarrierBrands'
import IssuersReducer from './Issuers'
import IssuerDisclaimersReducer from './IssuerDisclaimers'
import IssuerStatusesReducer from './IssuerStatuses'
import IssuerSourcesReducer from './IssuerSources'
import PrivateExchangesReducer from './PrivateExchanges'
import BlobReducer from './Blobs'
import FlashMessagesReducer from './FlashMessages'
import RatesBatchesReducer from './RatesBatches'
import RatesBatchDraftRatesReducer from './RatesBatchDraftRates'
import RatesBatchPublishingsReducer from './RatesBatchPublishings'
import RateFactorSetsReducer from './RateFactorSets'
import CensusesReducer from './Censuses'
import DocumentsReducer from './Documents'
import RemoveDocuments from './RemoveDocuments'
import EmbargoesReducer from './Embargoes'
import SheetRatesReducer from './SheetRates'
import BulkBenefitsSetsReducer from './BulkBenefitsSets'
import BulkBenefitsSetUpdatesReducer from './BulkBenefitsSetUpdates'
import BulkPlanProductRestoresReducer from './BulkPlanProductRestores'
import BenefitsSetFieldSourceValidationsReducer from './BenefitsSetFieldSourceValidations'
import BenefitsSetExternalIdReducer from './BenefitsSetExternalIds'
import BenefitsSetIdentifiersReducer from './BenefitsSetIdentifiers'
import BenefitsSetsExportPublishingReducer from './BenefitsSetsExportPublishings'
import BenefitsSetsCopyReducer from './BenefitsSetsCopies'
import SheetRatesIdentifiersReducer from './SheetRatesIdentifiers'
import SheetRatesPublishingReducer from './SheetRatesPublishings'
import SheetRatesReviewReducer from './SheetRatesReviews'
import ServiceAreasReducer from './ServiceAreas'
import PlanProductsReducer from './PlanProducts'
import PlanProductHistoriesReducer from './PlanProductHistories'
import IssuerPeriodAudiencesReducer from './IssuerPeriodAudiences'
import IssuerPeriodAudiencePublishingsReducer from './IssuerPeriodAudiencePublishings'
import VericredImportsReducer from './VericredImports'
import HowieGeographiesReducer from './HowieGeographies'
import HowieServiceAreasReducer from './HowieServiceAreas'
import AncillaryServiceAreasReducer from './AncillaryServiceAreas'
import AncillaryRateFactorSetsReducer from './AncillaryRateFactorSets'
import HowieServiceAreasReviewReducer from './HowieServiceAreaReviews'
import FormsReducer from './Forms'
import VericredGroupsReducer from './VericredGroups'
import VericredGroupMemberssReducer from './VericredGroupMembers'
import VericredQuotesReducer from './VericredQuotes'
import VericredQuoteRatesReducer from './VericredQuoteRates'
import VericredQuoteRateMemberRatesReducer from './VericredQuoteRateMemberRates'
import SmokeChecksReducer from './SmokeChecks'

const rootReducer = combineReducers({
  bulkBenefitsSets: BulkBenefitsSetsReducer,
  bulkBenefitsSetUpdates: BulkBenefitsSetUpdatesReducer,
  BulkPlanProductRestores: BulkPlanProductRestoresReducer,
  benefitsSeFieldSourceValidations: BenefitsSetFieldSourceValidationsReducer,
  medicalBenefitsSets: MedicalBenefitsSetReducer,
  dentalBenefitsSets: DentalBenefitsSetReducer,
  lifeBenefitsSets: LifeBenefitsSetReducer,
  disabilityBenefitsSets: DisabilityBenefitsSetReducer,
  visionBenefitsSets: VisionBenefitsSetReducer,
  dentalBenefitsSetReviews: DentalBenefitsSetReviewReducer,
  visionBenefitsSetReviews: VisionBenefitsSetReviewReducer,
  medicalBenefitsSetReviews: MedicalBenefitsSetReviewReducer,
  benefitsSetsExports: BenefitsSetsExportReducer,
  benefitsSetsExportPublishings: BenefitsSetsExportPublishingReducer,
  BenefitsSetExternalIds: BenefitsSetExternalIdReducer,
  benefitsSetsCopies: BenefitsSetsCopyReducer,
  medicareAdvantageBenefitsSets: MedicareAdvantageBenefitsSetReducer,
  carrierVerification: CarrierVerificationReducer,
  login: LoginReducer,
  users: UsersReducer,
  carrierGroups: CarrierGroupsReducer,
  carriers: CarriersReducer,
  carrierBrands: CarrierBrandsReducer,
  flashMessages: FlashMessagesReducer,
  issuers: IssuersReducer,
  issuerDisclaimers: IssuerDisclaimersReducer,
  issuerStatuses: IssuerStatusesReducer,
  issuerSources: IssuerSourcesReducer,
  privateExchanges: PrivateExchangesReducer,
  blobs: BlobReducer,
  ratesBatches: RatesBatchesReducer,
  draftRates: RatesBatchDraftRatesReducer,
  ratesBatchPublishings: RatesBatchPublishingsReducer,
  rateFactorSets: RateFactorSetsReducer,
  censuses: CensusesReducer,
  documents: DocumentsReducer,
  removeDocuments: RemoveDocuments,
  embargoes: EmbargoesReducer,
  sheetRates: SheetRatesReducer,
  benefitsSetIdentifiers: BenefitsSetIdentifiersReducer,
  sheetRatesIdentifiers: SheetRatesIdentifiersReducer,
  sheetRatesPublishings: SheetRatesPublishingReducer,
  sheetRatesReviews: SheetRatesReviewReducer,
  serviceAreas: ServiceAreasReducer,
  planProducts: PlanProductsReducer,
  planProductHistories: PlanProductHistoriesReducer,
  issuerPeriodAudiences: IssuerPeriodAudiencesReducer,
  issuerPeriodAudiencePublishings: IssuerPeriodAudiencePublishingsReducer,
  benefitsSetFieldHistory: BenefitsSetFieldHistoryReducer,
  vericredImports: VericredImportsReducer,
  howieGeographies: HowieGeographiesReducer,
  howieServiceAreas: HowieServiceAreasReducer,
  ancillaryServiceAreas: AncillaryServiceAreasReducer,
  ancillaryRateFactorSets: AncillaryRateFactorSetsReducer,
  howieServiceAreaReviews: HowieServiceAreasReviewReducer,
  forms: FormsReducer,
  groups: VericredGroupsReducer,
  quotes: VericredQuotesReducer,
  quoteRates: VericredQuoteRatesReducer,
  groupMembers: VericredGroupMemberssReducer,
  quoteRateMemberRates: VericredQuoteRateMemberRatesReducer,
  smokeChecks: SmokeChecksReducer
})

export default rootReducer
