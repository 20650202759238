import React from 'react'
import PropTypes from 'prop-types'
import { IoIosClose } from 'react-icons/io'
import Component from '../common/Component'
import Modal from '../common/Modal'

export default class ActionModal extends Component {
  static propTypes = {
    actionType: PropTypes.string.isRequired,
    savingText: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    handleAction: PropTypes.func.isRequired,
    primaryConfirmationMessage: PropTypes.object.isRequired,
    secondaryConfirmationMessage: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      close: false,
      progress: false
    }
  }

  get buttonText() {
    if (this.state.progress && this.props.savingText) { return this.props.savingText }

    return this.props.actionType
  }

  handleActionButton = (event) => {
    this.setState({ progress: true })
    if (this.props.handleAction) { this.props.handleAction() }
  }

  handleCancelButton = (event) => {
    this.setState({ close: true })
    this.props.handleClose()
  }

  render() {
    if (this.state.close) {
      return null
    }

    return (
      <Modal
        handleClose={this.props.handleClose}
        className="ipa-action-modal"
      >
        {this.props.primaryConfirmationMessage}
        {this.props.secondaryConfirmationMessage}
        <button
          className="select-button pure-button pure-input-1 pure-button-primary action-ipa"
          type="button"
          onClick={this.handleActionButton}
        >
          {this.buttonText}
        </button>
        <button
          className="cancel-button"
          type="button"
          onClick={this.handleCancelButton}
        >
          <IoIosClose />
        </button>
      </Modal>
    )
  }
}
