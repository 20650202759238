import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { cloneDeep, isNil } from 'lodash'
import Component from '../common/Component'
import alert from '../../alert'

export default class BenefitsSetCreate extends Component {
  static propTypes = {
    configuration: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    requestInProgress: PropTypes.bool,
    requestComplete: PropTypes.bool,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    remoteOptionsSelectStates: PropTypes.object,
    remoteOptionsSelectActions: PropTypes.object,
    benefitsSetForm: PropTypes.func,
    defaultValues: PropTypes.object,
    productLine: PropTypes.string,
    benefitsSetPath: PropTypes.string
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values) => {
    this.props.create(this.translateValues(values))
  }

  translateValues = (values) => {
    const newValues = cloneDeep(values)

    if (!isNil(newValues.document)) {
      newValues.document_id = newValues.document
    }

    return newValues
  }

  redirect(id) {
    return <Redirect push to={`/${this.props.benefitsSetPath}/${id}`} />
  }

  render() {
    const {
      created,
      id,
      generalError,
      resourceErrors,
      configuration,
      requestInProgress,
      requestComplete,
      productLine
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    const BenefitsSetForm = this.props.benefitsSetForm

    if (created && !isNil(id)) {
      return this.redirect(id)
    }

    return (
      <div className="content">
        <h2>
          New {productLine} Benefits Set
        </h2>
        <div>
          <BenefitsSetForm
            handleSubmit={this.handleSubmit}
            errors={errors}
            configuration={configuration}
            initialValues={this.props.defaultValues}
            requestInProgress={requestInProgress}
            requestComplete={requestComplete}
            remoteOptionsSelectStates={this.props.remoteOptionsSelectStates}
            remoteOptionsSelectActions={this.props.remoteOptionsSelectActions}
            showHistory={false}
          />
        </div>
      </div>
    )
  }
}
