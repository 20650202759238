import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toLower } from 'lodash'
import LabeledTextField from '../common/LabeledTextField'
import alert from '../../alert'
import client from '../../client'
import FlashMessage from '../common/FlashMessage'

export class ForgotPassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    messages: PropTypes.array,
    expired: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      email: null
    }
  }

  get submitDisabled() {
    return this.state.email == null
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await client.post('/passwords/forgot', { email: this.state.email })

      alert(
        `An email with a link to reset your password will be sent to ${this.state.email} if it exists in the system.`
      )

      this.props.history.push('/')
    } catch (error) {
      alert(error.message)
    }
  }

  handleChange = (field, value) => {
    this.setState({ [field]: toLower(value) })
  }

  render() {
    return (
      <div className="password-reset-container">
        {this.props.expired && <FlashMessage messages={this.props.messages} tag="general" visible />}
        <form id="forgot-password-form" className="pure-u-1 pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
          <fieldset>
            <LabeledTextField
              key="email"
              name="email"
              label="Enter email"
              type="email"
              handleChange={this.handleChange}
              value={this.state.email}
            />
          </fieldset>
          <fieldset>
            <button
              id="submit"
              className="pure-button pure-input-1 pure-button-primary"
              title="Forgot Password"
              type="submit"
              disabled={this.submitDisabled}
            >
              Forgot Password
            </button>
          </fieldset>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { expired: state.login.resetPassword.expired, messages: state.flashMessages.messages }
}

export default connect(mapStateToProps)(ForgotPassword)
