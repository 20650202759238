import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { find, reduce, isArray, isNil } from 'lodash'
import AccordionItem from '../../common/AccordionItem'
import PlanInformationComponent from './PlanInformationComponent'
import PlanBenefitsComponent from './PlanBenefitsComponent'
import PlanMemberRatesComponent from './PlanMemberRatesComponent'
import PlanRateComponent from './PlanRateComponent'
import PlanIdentifiersComponent from './PlanIdentifiersComponent'
import Tabs from '../../common/TabsComponent'
import Panel from '../../common/PanelComponent'
import medicalFieldGroups from '../../Medical/fields'
import dentalFieldGroups from '../../Dental/dentalFields'
import visionFieldGroups from '../../Vision/visionFields'
import lifeFieldGroups from '../../Life/lifeFields'
import disabilityFieldGroups from '../../Disability/disabilityFields'

export class PlanComponent extends Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    census: PropTypes.object.isRequired,
    plansByFamily: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ])
  }

  get censusFamilyPlansPremiums() {
    const { plan, census, plansByFamily } = this.props
    if (isArray(plansByFamily)) {
      const rate = find(plansByFamily, (p) => p.plan_id === plan.id)
      const premium = !isNil(rate) && !isNil(rate.total_premium) ? rate.total_premium : 0
      return [{ premium: parseFloat(premium), rate_id: (!isNil(rate) ? rate.id : null) }]
    }
    return census.families.map((f) => {
      const plans = plansByFamily[f.id]
      const familyPlan = find(plans, (p) => p.id === plan.id)
      const premium = familyPlan ? familyPlan.premium : 0
      return { name: f.name, premium }
    })
  }

  get contractCodes() {
    return this.props.plan.identifiers.filter((id) => id.type === 'contract_code')
  }

  get contractCodesString() {
    return this.contractCodes.map((id) => id.value).join(',')
  }

  get fieldGroupsForType() {
    switch (this.props.plan.product_line) {
    case 'medical':
      return medicalFieldGroups
    case 'dental':
      return dentalFieldGroups
    case 'vision':
      return visionFieldGroups
    case 'life':
      return lifeFieldGroups
    case 'disability':
      return disabilityFieldGroups
    default:
      return null
    }
  }

  get planPremiums() {
    if (this._planPremiums) {
      return this._planPremiums
    }

    this._planPremiums = this.censusFamilyPlansPremiums

    return this._planPremiums
  }

  get totalString() {
    const total = reduce(this.planPremiums, (sum, p) => sum + p.premium, 0)
    return total !== 0 ? `$${total.toFixed(2)}` : 'n/a'
  }

  get isLifeOrDisability() {
    return this.props.plan.product_line === 'life' || this.props.plan.product_line === 'disability'
  }

  get planTitles() {
    const titles = [this.props.plan.name, this.props.plan.id]
    if (!this.isLifeOrDisability) { titles.push(this.totalString, this.contractCodesString) }

    return titles
  }

  get titleClasses() {
    const classes = ['pure-u-10-24', 'pure-u-4-24']
    if (!this.isLifeOrDisability) { classes.push('pure-u-4-24', 'pure-u-4-24') }

    return classes
  }

  renderInformationPanel = () => {
    return (
      <Panel label="Information">
        <PlanInformationComponent plan={this.props.plan} fieldGroups={this.fieldGroupsForType} />
      </Panel>
    )
  }

  renderBenefitsPanel = () => {
    if (this.isLifeOrDisability) return false

    return (
      <Panel label="Benefits">
        <PlanBenefitsComponent plan={this.props.plan} fieldGroups={this.fieldGroupsForType} />
      </Panel>
    )
  }

  renderRatesPanel = () => {
    if (this.isLifeOrDisability) return this.renderRatePanel()

    return (
      <Panel label="Rates">
        <PlanMemberRatesComponent
          planPremiumsByFamily={this.planPremiums}
          plan={this.props.plan}
          census={this.props.census}
        />
      </Panel>
    )
  }

  renderRatePanel = () => {
    return (
      <Panel label="Rates">
        <PlanRateComponent plan={this.props.plan} />
      </Panel>
    )
  }

  renderIdentifiersPanel = () => {
    return (
      <Panel label="Identifiers">
        <PlanIdentifiersComponent identifiers={this.props.plan.identifiers} />
      </Panel>
    )
  }

  render() {
    const { plan } = this.props

    return (
      <div key={plan.id}>
        <AccordionItem
          title={this.planTitles}
          titleClass={this.titleClasses}
          name={plan.name}
        >
          <Tabs selected={0}>
            {this.renderInformationPanel()}
            {!this.isLifeOrDisability && this.renderBenefitsPanel()}
            {this.renderRatesPanel()}
            {this.renderIdentifiersPanel()}
          </Tabs>
        </AccordionItem>
      </div>
    )
  }
}

export default PlanComponent
