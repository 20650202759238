import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isNil } from 'lodash'
import { createFlashMessage } from '../../actions/FlashMessages'
import Component from '../common/Component'
import actions from '../../actions/Carriers'
import carrierGroupActions from '../../actions/CarrierGroups'
import CarrierForm from './CarrierForm'
import EntitlementsProvider from '../common/EntitlementsProvider'
import alert from '../../alert'

export class CarrierCreate extends Component {
  static propTypes = {
    configuration: PropTypes.object,
    entitlements: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    createFlashMessage: PropTypes.func.isRequired,
    indexCarrierGroups: PropTypes.func.isRequired,
    resetCarrierGroups: PropTypes.func.isRequired,
    carrierGroups: PropTypes.array,
    carrierGroupsRequestInProgress: PropTypes.bool.isRequired
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values) => {
    this.props
      .create(values)
      .then(({ id }) => {
        this.props.createFlashMessage('Created carrier', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      entitlements,
      requestInProgress,
      created,
      id
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (created && !isNil(id)) {
      return this.redirect('/carriers')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <div className="content">
        <h2>New Carrier</h2>
        <div>
          <EntitlementsProvider entitlements={entitlements}>
            <CarrierForm
              handleSubmit={this.handleSubmit}
              errors={errors}
              configuration={configuration}
              requestInProgress={requestInProgress}
              initialValues={{
                name: null,
                carrier_id: null,
                carrier_id_type: null,
                carrier_group_id: null
              }}

              resetCarrierGroups={this.props.resetCarrierGroups}
              carrierGroups={this.props.carrierGroups}
              carrierGroupsRequestInProgress={this.props.carrierGroupsRequestInProgress}
              indexCarrierGroups={this.props.indexCarrierGroups}
            />
          </EntitlementsProvider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.carriers.create
  const carrierGroupsSlice = state.carrierGroups.index
  const configuration = state.login.session.configuration.carriers
  const entitlements = state.login.session.configuration.entitlements.carriers.create

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created,

    carrierGroups: carrierGroupsSlice.records,
    carrierGroupsRequestInProgress: carrierGroupsSlice.requestInProgress,

    configuration,
    entitlements
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,

  indexCarrierGroups: carrierGroupActions.index.main,
  resetCarrierGroups: carrierGroupActions.index.reset,

  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CarrierCreate)
