import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { IoIosDocument } from 'react-icons/io'
import Tree from 'react-ui-tree'
import 'react-ui-tree/dist/react-ui-tree.css'
import cx from 'classnames'
import moment from 'moment'
import { get } from 'lodash'

import Component from '../common/Component'

import howieGeographyActions from '../../actions/HowieGeographies'

export class HowieGeographiesShow extends Component {
  static propTypes = {
    showHowieGeographyAction: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    howieGeography: PropTypes.object,
    match: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      active: false
    }
  }

  componentDidMount() {
    const { showHowieGeographyAction } = this.props
    showHowieGeographyAction(this.id)
  }

  onClickNode = (node) => {
    this.setState({
      active: node
    })
  }

  get defaultAttributes() {
    return { howie_geography_id: this.id, rejection_reason: '' }
  }

  get id() {
    return this.props.match.params.id
  }

  get shouldRender() {
    return this.props.howieGeography
  }

  get documentUrl() {
    return this.props.howieGeography.document.public_url
  }

  get documentLink() {
    if (this.documentUrl) {
      return (
        <a href={this.documentUrl} target="_sbc">
          <IoIosDocument size={30} />
          Document
        </a>
      )
    }
    return 'No Document Attached'
  }

  get treeComponents() {
    return (
      this.props.howieGeography.zip_counties.map((tree) => (
        <Tree
          key={tree.module}
          tree={tree}
          paddingLeft={20}
          onChange={this.handleChange}
          renderNode={this.renderNode}
          isNodeCollapsed
        />
      ))
    )
  }

  get returnPath() {
    return get(this.props.location, '/howie_geographies', '/howie_geographies/')
  }

  formattedDateValue = (value) => {
    return moment(value).utc().format('YYYY/MM/DD hh:mm A')
  }

  renderNode = (node) => {
    return (
      <span
        className={cx('node', {
          'is-active': node === this.state.active
        })}
        role="presentation"
        onClick={() => this.onClickNode.bind(null, node)}
        onKeyDown={() => this.onClickNode.bind(null, node)}
      >
        {node.module}
      </span>
    )
  }

  render() {
    const { howieGeography } = this.props

    if (!this.shouldRender) return null

    return (
      <div className="content">
        <h1>Howie Geography</h1>
        <div>
          {this.documentLink}
        </div>
        <h5>Metadata</h5>
        <table className="pure-table">
          <tbody>
            <tr>
              <td><strong>Name</strong></td>
              <td name="name">{howieGeography.name}</td>
            </tr>
            <tr>
              <td><strong>Last Updated At</strong></td>
              <td name="updated_at">{this.formattedDateValue(howieGeography.updated_at)}</td>
            </tr>
            <tr>
              <td><strong>Number of Zip Codes</strong></td>
              <td name="name">{howieGeography.zip_code_count}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>Zip Codes</h5>
        <div className="tree">
          {this.treeComponents}
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const showSlice = state.howieGeographies.show
  const configuration = state.login.session.configuration.howie_geographies

  return {
    howieGeography: showSlice.attributes,
    configuration
  }
}

const mapDispatchToProps = {
  showHowieGeographyAction: howieGeographyActions.show.main
}

export default connect(mapStateToProps, mapDispatchToProps)(HowieGeographiesShow)
