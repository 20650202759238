import client from '../../client'
import parseError from '../../lib/parseError'
import {
  REQUEST_ZIP_COUNTIES,
  RECEIVE_ZIP_COUNTIES,
  RECEIVE_ZIP_COUNTIES_ERROR
} from './consts'

function requestZipCounties() {
  return { type: REQUEST_ZIP_COUNTIES }
}

function receiveZipCounties(zipCounties) {
  return { type: RECEIVE_ZIP_COUNTIES, zipCounties }
}

function receiveZipCountiesError(error) {
  const { generalError, resourceErrors } = parseError(error)

  return { type: RECEIVE_ZIP_COUNTIES_ERROR, generalError, resourceErrors }
}

export default function getZipCounties(zipPrefix) {
  return (dispatch) => {
    dispatch(requestZipCounties())

    client.get(`/zip_counties?zip_prefix=${zipPrefix}`)
      .then((response) => response.data)
      .then((json) => dispatch(receiveZipCounties(json)))
      .catch((error) => dispatch(receiveZipCountiesError(error.response.data.error)))
  }
}
