import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/Forms'
import connectIndex from '../../lib/connectIndex'
import IssuersDisplay from '../common/IssuersDisplay'
import PlanYearOptionsHelper from '../common/helpers/PlanYearOptionsHelper'

export class FormIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['title', 'issuer', 'issuer_id']
  }

  static S3UrlLinkDisplay = (record) => {
    return <a href={record.public_url} target="_blank" rel="noopener noreferrer">View</a>
  }

  static actionDisplay = (record, column) => {
    const formActions = record[column.key]
    return (formActions.join(', '))
  }

  static columns = [
    {
      key: 'title',
      label: 'Form Title',
      filterable: false,
      sortable: true
    },
    {
      key: 'form_issuers',
      label: 'Issuers',
      display: IssuersDisplay,
      filterable: false,
      sortable: false
    },
    {
      key: 'plan_year',
      label: 'Plan Year',
      filterable: true,
      sortable: true,
      recordLabel: 'text',
      recordValue: 'value'
    },
    {
      key: 'form_actions',
      label: 'Actions',
      filterable: true,
      sortable: false,
      display: FormIndex.actionDisplay,
      recordLabel: 'text',
      recordValue: 'value'
    },
    {
      key: 'view',
      label: '',
      sortable: false,
      display: FormIndex.S3UrlLinkDisplay
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/forms"
          name="Form"
          pagination={this.props.pagination}
          columns={FormIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Title or Issuer"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'plan_year',
    options: PlanYearOptionsHelper.planYearOptionsFromCurrent(1)
  },
  {
    key: 'form_actions',
    config: 'forms.actions'
  }
]

export default connectIndex(FormIndex, 'forms', actions, filters)
