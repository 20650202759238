import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isNil } from 'lodash'
import { createFlashMessage } from '../../actions/FlashMessages'
import Component from '../common/Component'
import actions from '../../actions/CarrierGroups'
import CarrierGroupForm from './CarrierGroupForm'
import EntitlementsProvider from '../common/EntitlementsProvider'
import alert from '../../alert'

export class CarrierGroupCreate extends Component {
  static propTypes = {
    configuration: PropTypes.object,
    entitlements: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    createFlashMessage: PropTypes.func.isRequired
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values) => {
    this.props.create(values)
      .then(({ id }) => {
        this.props.createFlashMessage('Created carrier group', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      entitlements,
      requestInProgress,
      created,
      id
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (created && !isNil(id)) {
      return this.redirect('/carrier_groups')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <div className="content">
        <h2>New Carrier Group</h2>
        <div>
          <EntitlementsProvider entitlements={entitlements}>
            <CarrierGroupForm
              handleSubmit={this.handleSubmit}
              errors={errors}
              configuration={configuration}
              requestInProgress={requestInProgress}
              initialValues={{
                name: null,
                group_id_type: null,
                group_id: null
              }}
            />
          </EntitlementsProvider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.carrierGroups.create
  const configuration = state.login.session.configuration.carrier_groups
  const entitlements = state.login.session.configuration.entitlements.carrier_groups.create

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created,
    configuration,
    entitlements
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CarrierGroupCreate)
