import React from 'react'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'
import Component from '../common/Component'
import actions from '../../actions/CarrierBrands'

export class CarrierBrandEdit extends Component {
  static propTypes = defaultPropTypes

  get id() {
    return this.props.match.params.id
  }

  render() {
    return (
      <EditForm {...this.props} id={this.id}>
        <LabeledTextField
          key="name"
          name="name"
          label="Name"
        />

        <LabeledTextField
          key="uuid"
          name="uuid"
          label="UUID"
          disabled
        />
      </EditForm>
    )
  }
}

export default connectEditForm(CarrierBrandEdit, 'Carrier Brand', 'carrier_brands', actions)
