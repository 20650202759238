import React, { Component } from 'react'

export class Home extends Component {
  render() {
    return (
      <div className="content" />
    )
  }
}

export default Home
