import React from 'react'
import PropTypes from 'prop-types'
import { isNil, cloneDeep, some, keys, clone, get } from 'lodash'
import { connect } from 'react-redux'
import Component from '../common/Component'
import DocumentSelectField from '../common/DocumentSelectField'
import SubmitButton from '../common/SubmitButton'
import actions from '../../actions/RateFactorSets'
import createFlashMessage from '../../actions/FlashMessages/createFlashMessage'
import CheckboxField from '../common/CheckboxField'
import LabeledTextField from '../common/LabeledTextField'
import RateFactorCreate from './RateFactorCreate'

export class RateFactorSetCreate extends Component {
  static propTypes = {
    create: PropTypes.func,
    reset: PropTypes.func,
    requestInProgress: PropTypes.bool,
    createFlashMessage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = { values: { rate_factors: {}, composite: false } }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newValues = clone(prevState.values)

      newValues[field] = value

      return { values: newValues }
    })
  }

  handleRateFactorChange = (rateFactorKey, value) => {
    this.setState((prevState) => {
      const newValues = cloneDeep(prevState.values)

      newValues.rate_factors[rateFactorKey] = value

      return { values: newValues }
    })
  }

  handleFormulaChange = (field, value) => {
    const rateFactorFormulaKeys = value.match(/[A-Za-z0-9_]+/g) || []
    this.setState((prevState) => {
      const newValues = cloneDeep(prevState.values)
      const rateFactors = this.state.values.rate_factors

      newValues.rate_factors = {}

      rateFactorFormulaKeys.forEach((formulaKey) => {
        newValues.rate_factors[formulaKey] = rateFactors[formulaKey] || {}
      })

      newValues[field] = value

      return { values: newValues }
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const response = await this.props.create(this.createParams())

    if (!isNil(response.generalError)) {
      alert(response.generalError) // eslint-disable-line no-alert
    } else if (!isNil(response.resourceErrors)) {
      alert(get(response.resourceErrors, 'base') || response.resourceErrors) // eslint-disable-line no-alert
    } else {
      this.props.createFlashMessage(
        `Import file created in: ${response.responseAttributes.save_path}`,
        { tag: 'general' }
      )
    }
  }

  createParams() {
    const params = clone(this.state.values)

    params.rate_factors = keys(params.rate_factors).map((key) => (
      {
        formula_key: key,
        blob_id: params.rate_factors[key].blob_id
      }
    ))

    return params
  }

  incompleteRateFactor() {
    return some(keys(this.state.values.rate_factors), (key) => (
      isNil(this.state.values.rate_factors[key].blob_id)
    ))
  }

  incompleteFields() {
    const requiredFields = [
      'document_id',
      'name',
      'composite',
      'formula',
      'rate_factors'
    ]

    return some(requiredFields, (field) => (isNil(this.state.values[field])))
  }

  submitDisabled() {
    return this.incompleteFields() || this.incompleteRateFactor()
  }

  rateFactors() {
    return (
      Object.keys(this.state.values.rate_factors).map((key) => (
        <RateFactorCreate
          key={key}
          name={key}
          value={this.state.values.rate_factors[key]}
          handleChange={this.handleRateFactorChange}
        />
      ))
    )
  }

  render() {
    return (
      <div className="content">
        <h2>Rate Factor Set</h2>
        <div>
          <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            <fieldset>
              <DocumentSelectField
                name="document_id"
                filterFields={{ document_source_contents: ['Rates'] }}
                handleChange={this.handleChange}
                value={this.state.values.document_id}
              />
              <LabeledTextField
                key="name"
                name="name"
                label="Name"
                handleChange={this.handleChange}
                value={this.state.values.name}
              />
              <CheckboxField
                name="composite"
                label="Composite?"
                handleChange={this.handleChange}
                value={this.state.values.composite}
              />
              <br />
              <LabeledTextField
                key="formula"
                name="formula"
                label="Formula"
                handleChange={this.handleFormulaChange}
                value={this.state.values.formula}
              />
              {this.rateFactors()}
            </fieldset>
            <fieldset>
              <SubmitButton
                title="Submit"
                text="Submit"
                loading={this.props.requestInProgress}
                disabled={this.submitDisabled()}
              />
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { requestInProgress: state.rateFactorSets.create.requestInProgress }
}

const mapDispatchToProps = {
  create: actions.create.main,
  reset: actions.create.reset,
  createFlashMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(RateFactorSetCreate)
