import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import 'react-ui-tree/dist/react-ui-tree.css'
import moment from 'moment'
import { get } from 'lodash'

import Component from '../common/Component'

import rateFactorSetActions from '../../actions/AncillaryRateFactorSets'

import RateFactorDocumentIndex from './RateFactorDocumentIndex'

export class AncillaryRateFactorSetShow extends Component {
  static propTypes = {
    showRateFactorSetAction: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    rateFactorSet: PropTypes.object,
    match: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { showRateFactorSetAction } = this.props
    showRateFactorSetAction(this.id)
  }

  get defaultAttributes() {
    return { ancillary_service_area_id: this.id, rejection_reason: '' }
  }

  get id() {
    return this.props.match.params.id
  }

  get shouldRender() {
    return this.props.rateFactorSet
  }

  get returnPath() {
    return get(this.props.location, 'state.return_path', '/ancillary_rate_factor_sets/')
  }

  get newRateFactorLink() {
    return (
      <Link
        key="new_rate_factor"
        id="new_rate_factor"
        title="Add Rate Factors"
        className="button quiet-button right"
        to={{
          pathname: `/ancillary_rate_factor_sets/${this.id}/edit`
        }}
      > New Rate Factor
      </Link>
    )
  }

  formattedDateValue = (value) => {
    return moment(value).utc().format('YYYY/MM/DD hh:mm A')
  }

  render() {
    const { rateFactorSet } = this.props

    if (!this.shouldRender) return null

    return (
      <div className="content">
        <h1>Ancillary Rate Factor Set</h1>
        <nav>
          {this.newRateFactorLink}
        </nav>
        <h5>Metadata</h5>
        <table className="pure-table rate_factor_set_info">
          <tbody>
            <tr>
              <td><strong>Rate Factor Set ID</strong></td>
              <td name="vericred_id">{rateFactorSet.vericred_id}</td>
            </tr>
            <tr>
              <td><strong>Name</strong></td>
              <td name="name">{rateFactorSet.name}</td>
            </tr>
            <tr>
              <td><strong>Line of Coverage</strong></td>
              <td name="line_of_coverage">{rateFactorSet.line_of_coverage}</td>
            </tr>
            <tr>
              <td><strong>Issuer</strong></td>
              <td name="issuer_id">{rateFactorSet.issuer_id}</td>
            </tr>
            <tr>
              <td><strong>Last Updated At</strong></td>
              <td name="updated_at">{this.formattedDateValue(rateFactorSet.updated_at)}</td>
            </tr>
            <tr>
              <td><strong>Formula</strong></td>
              <td name="formula">{rateFactorSet.formula}</td>
            </tr>
            <tr>
              <td><strong>Composite</strong></td>
              <td name="composite">{rateFactorSet.composite ? 'Yes' : 'No'}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <RateFactorDocumentIndex
          records={rateFactorSet.rate_factor_documents}
        />
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const showSlice = state.ancillaryRateFactorSets.show
  const configuration = state.login.session.configuration.ancillary_rate_factor_sets

  return {
    rateFactorSet: showSlice.attributes,
    configuration
  }
}

const mapDispatchToProps = {
  showRateFactorSetAction: rateFactorSetActions.show.main
}

export default connect(mapStateToProps, mapDispatchToProps)(AncillaryRateFactorSetShow)
