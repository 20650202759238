import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { cloneDeep, isNil } from 'lodash'
import Component from '../common/Component'
import actions from '../../actions/Embargoes'
import issuerActions from '../../actions/Issuers'
import EmbargoForm from './EmbargoForm'

export class EmbargoCreate extends Component {
  static propTypes = {
    configuration: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    issuers: PropTypes.array,
    indexIssuers: PropTypes.func.isRequired,
    resetIssuers: PropTypes.func.isRequired,
    issuersRequestInProgress: PropTypes.bool.isRequired
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values) => {
    this.props.create(this.translateValues(values))
  }

  translateValues = (values) => {
    const newValues = cloneDeep(values)

    if (!isNil(newValues.embargo_issuers)) {
      newValues.embargo_issuers = newValues.embargo_issuers.map((v) => ({
        issuer_id: v
      }))
    }

    return newValues
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      resourceErrors,
      configuration,
      requestInProgress,
      created,
      id
    } = this.props

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    if (created && !isNil(id)) {
      return this.redirect(`/embargoes/${id}`)
    }

    return (
      <div className="content">
        <h2>New Embargo</h2>
        <div>
          <EmbargoForm
            handleSubmit={this.handleSubmit}
            errors={errors}
            configuration={configuration}
            requestInProgress={requestInProgress}
            issuers={this.props.issuers}
            resetIssuers={this.props.resetIssuers}
            issuersRequestInProgress={this.props.issuersRequestInProgress}
            indexIssuers={this.props.indexIssuers}
            initialValues={{
              name: null,
              product_line: null,
              audience: null,
              embargo_issuers: [],
              embargo_date_range: {
                start: null,
                end: null
              },
              release_date: null
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.embargoes.create
  const issuersSlice = state.issuers.index
  const configuration = state.login.session.configuration.embargoes

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created,
    configuration,

    issuers: issuersSlice.records,
    issuersRequestInProgress: issuersSlice.requestInProgress
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,

  indexIssuers: issuerActions.index.main,
  resetIssuers: issuerActions.index.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmbargoCreate)
