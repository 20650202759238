import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, isNil } from 'lodash'
import Component from '../common/Component'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'
import RemoteOptionsSelectField from '../common/RemoteOptionsSelectField'
import SubmitButton from '../common/SubmitButton'

export default class CarrierForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    configuration: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    requestInProgress: PropTypes.bool,
    submitLabel: PropTypes.string,

    indexCarrierGroups: PropTypes.func.isRequired,
    resetCarrierGroups: PropTypes.func.isRequired,
    carrierGroupsRequestInProgress: PropTypes.bool.isRequired,
    carrierGroups: PropTypes.array
  }

  static defaultProps = {
    requestInProgress: false,
    configuration: {},
    errors: {},
    submitLabel: 'Create Carrier',
    carrierGroups: []
  }

  static contextTypes = {
    attributeInaccessible: PropTypes.func
  }

  constructor(props) {
    super(props)

    const initialValues = cloneDeep(props.initialValues)

    this.state = {
      values: initialValues
    }
  }

  get values() {
    return cloneDeep(this.state.values)
  }

  get submitButton() {
    return (
      <SubmitButton
        title={this.props.submitLabel}
        text={this.props.submitLabel}
        loading={this.props.requestInProgress}
      />
    )
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)

      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        newState[field] = value
      }

      return { values: newState }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.handleSubmit(this.values)
  }

  render() {
    const {
      errors,
      configuration
    } = this.props

    return (
      <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
        <fieldset>
          <LabeledTextField
            key="name"
            name="name"
            label="Name"
            handleChange={this.handleChange}
            errorMessage={errors.name}
            value={this.state.values.name}
            disabled={this.context.attributeInaccessible('name')}
          />
          <LabeledTextField
            key="carrier_id"
            name="carrier_id"
            label="Carrier ID"
            handleChange={this.handleChange}
            errorMessage={errors.carrier_id}
            value={this.state.values.carrier_id}
            disabled={this.context.attributeInaccessible('carrier_id')}
          />
          <SelectField
            key="carrier_id_type"
            name="carrier_id_type"
            label="Carrier ID Type"
            options={configuration.carrier_id_type}
            handleChange={this.handleChange}
            errorMessage={errors.carrier_id_type}
            value={this.state.values.carrier_id_type}
            disabled={this.context.attributeInaccessible('carrier_id_type')}
          />
          <RemoteOptionsSelectField
            key="carrier_group_id"
            name="carrier_group_id"
            label="Carrier Group"
            handleChange={this.handleChange}
            errorMessage={errors.carrier_group_id}
            value={this.state.values.carrier_group_id}
            records={this.props.carrierGroups}
            reset={this.props.resetCarrierGroups}
            recordsRequest={this.props.indexCarrierGroups}
            requestInProgress={this.props.carrierGroupsRequestInProgress}
            disabled={this.context.attributeInaccessible('carrier_group_id')}
          />
        </fieldset>
        <fieldset>
          {this.submitButton}
        </fieldset>
      </form>
    )
  }
}
