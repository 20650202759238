import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get, cloneDeep, isNil, keys, indexOf, size, isEmpty } from 'lodash'
import Component from '../common/Component'
import ReviewValueField from './BenefitsSetReviewValueField'
import ReviewDisplayField from './BenefitsSetReviewDisplayField'
import FieldDefinition from './BenefitsSetFieldDefinition'
import RadioButtonSet from '../common/RadioButtonSet'
import TextField from '../common/TextField'
import UserContext from './BenefitsSetUserContext'

export default class BenefitsSetExternalIdComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number,
    review: PropTypes.object,
    rejections: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleReviewChange: PropTypes.func.isRequired,
    totalColumns: PropTypes.number.isRequired,
    fieldDefinition: PropTypes.instanceOf(FieldDefinition).isRequired,
    userContext: PropTypes.instanceOf(UserContext).isRequired,
    errors: PropTypes.object,
    location: PropTypes.object
  }

  static renderedProps = [
    'name',
    'index',
    'review',
    'rejections',
    'totalColumns',
    'fieldDefinition',
    'userContext',
    'errors'
  ]

  get externalId() {
    return this.props.fieldDefinition.value
  }

  get externalIdId() {
    return this.externalId.id
  }

  get reviewField() {
    const { name, review } = this.props

    return (
      <ReviewValueField
        name={name}
        note={get(review, 'note')}
        handleChange={this.handleReviewChange}
      >
        {this.textValue}
      </ReviewValueField>
    )
  }

  get textValue() {
    if (!this.props.userContext.isViewable) {
      return null
    }

    const location = {
      pathname: `/plan_external_ids/${this.externalIdId}`,
      state: { return_path: this.props.location }
    }

    return (
      <Link title={this.externalId.external_id} to={location}>{this.externalId.external_id}</Link>
    )
  }

  get selectedExternalIdTypeIndex() {
    return indexOf(
      keys(this.props.fieldDefinition.options),
      get(this.externalId, 'external_id_type')
    )
  }

  get externalIdTypeDisabled() {
    return size(this.props.fieldDefinition.options) <= 1
  }

  get editableCell() {
    return (
      <tr>
        <td colSpan={1} />
        <td colSpan={this.props.totalColumns - 1 || 1}>
          <RadioButtonSet
            name={`external_id_type-${this.props.index}`}
            values={this.props.fieldDefinition.options}
            handleChange={this.handleExternalIdTypeChange}
            handleBlur={this.props.handleBlur}
            defaultIndex={this.selectedExternalIdTypeIndex}
            disabled={this.externalIdTypeDisabled}
            hidden={this.externalIdTypeDisabled}
            errorMessage={get(this.props.errors, 'external_id_type')}
          />
          <TextField
            name="external_id"
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            errorMessage={get(this.props.errors, 'external_id')}
            value={this.props.fieldDefinition.value.external_id}
          />
          {this.reviewDisplay}
        </td>
      </tr>
    )
  }

  get reviewableCell() {
    return (
      <td colSpan={this.props.totalColumns - 1 || 1}>
        {this.reviewField}
        {this.reviewDisplay}
      </td>
    )
  }

  get viewableCell() {
    return (
      <td colSpan={this.props.totalColumns - 1 || 1}>
        {this.textValue}
        {this.reviewDisplay}
      </td>
    )
  }

  get reviewDisplay() {
    if (isEmpty(this.props.rejections)) {
      return null
    }

    return (
      <ReviewDisplayField
        rejections={this.props.rejections}
      />
    )
  }

  get label() {
    return this.props.fieldDefinition.options[this.externalId.external_id_type]
  }

  handleChange = (field, value) => {
    const externalId = cloneDeep(this.externalId)

    if (isNil(value)) {
      delete externalId[field]
    } else {
      externalId[field] = value
    }

    this.props.handleChange(this.externalIdId, this.props.index, externalId)
  }

  handleExternalIdTypeChange = (field, value) => {
    this.handleChange('external_id_type', value)
  }

  handleReviewChange = (field, value) => {
    this.props.handleReviewChange(this.externalIdId, this.props.index, value)
  }

  render() {
    const { userContext } = this.props

    if (userContext.isEditable) {
      return this.editableCell
    }

    const cell = (userContext.isReviewable) ? this.reviewableCell : this.viewableCell

    return (
      <tr>
        <td>{this.label}</td>
        {cell}
      </tr>
    )
  }
}

