import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, get } from 'lodash'
import LabeledTextField from '../../common/LabeledTextField'
import FamilyMembersComponent from './FamilyMembersComponent'

export class FamilyComponent extends Component {
  static propTypes = {
    value: PropTypes.object,
    handleChange: PropTypes.func,
    index: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    removable: PropTypes.bool,
    audience: PropTypes.string.isRequired
  }

  get willDelete() {
    return get(this.props, 'value._destroy', false)
  }

  get removeButtonText() {
    return this.willDelete ? `+ ${this.removeButtonTitle}` : `× ${this.removeButtonTitle}`
  }

  get removeButtonTitle() {
    return this.willDelete ? 'Restore' : 'Remove'
  }

  get removeButton() {
    if (!this.props.removable) {
      return null
    }

    return (
      <button
        type="button"
        className="text-button remove-button"
        onClick={this.handleRemove}
        title={this.removeButtonTitle}
      >
        {this.removeButtonText}
      </button>
    )
  }

  get fieldsForAudeince() {
    if (this.props.audience === 'Individual') {
      return this.individual
    }
    return this.smallGroup
  }

  get members() {
    return (
      <div className="family-members">
        <FamilyMembersComponent
          name="members_attributes"
          disabled={this.willDelete}
          errors={this.props.errors.members || []}
          value={this.props.value.members_attributes || []}
          handleChange={this.handleChange}
        />
      </div>
    )
  }

  get individual() {
    return (
      <div>
        {this.members}
        <div className="inline-fields">
          <LabeledTextField
            name="household_income"
            title="Household Income"
            label="Household Income*"
            handleChange={this.handleChange}
            value={this.props.value.household_income}
          />
          <LabeledTextField
            name="household_size"
            title="household_size"
            label="Household Size*"
            type="number"
            step="1"
            max={100}
            min={1}
            handleChange={this.handleChange}
            value={this.props.value.household_size}
          />
        </div>
        <p className="note">* Optional</p>
      </div>
    )
  }

  get smallGroup() {
    return (
      <div>
        {this.removeButton}
        <LabeledTextField
          key="name"
          name={`family-${this.props.index}.name`}
          fieldName="name"
          label="Name"
          disabled={this.willDelete}
          handleChange={this.handleChange}
          errorMessage={this.props.errors.name}
          value={get(this.props, 'value.name')}
        />
        {this.members}
      </div>
    )
  }

  handleChange = (field, value) => {
    const family = cloneDeep(this.props.value)
    family[field] = value

    this.props.handleChange(this.props.index, family)
  }

  handleRemove = () => {
    const family = cloneDeep(this.props.value)

    if (family._destroy) {
      delete family._destroy
    } else {
      family._destroy = true
    }

    this.props.handleChange(this.props.index, family)
  }

  render() {
    return (
      <div className="family" id={`family-${this.props.index}`}>
        {this.fieldsForAudeince}
      </div>
    )
  }
}

export default FamilyComponent
