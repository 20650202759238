import { Component } from 'react'
import { isEqual, pick, isEmpty } from 'lodash'

export default class HowieComponent extends Component {
  static renderedProps = []

  shouldComponentUpdate(nextProps, nextState) {
    if (isEmpty(this.constructor.renderedProps)) {
      return true
    }

    return !isEqual(pick(nextProps, this.constructor.renderedProps), pick(this.props, this.constructor.renderedProps))
  }
}
