import ActionTypes from '../ActionTypes'
import GroupGenerator from '../GroupGenerator'

import CreateActionGenerator from './CreateActionGenerator'
import ShowActionGenerator from './ShowActionGenerator'
import IndexActionGenerator from './IndexActionGenerator'
import UpdateActionGenerator from './UpdateActionGenerator'
import DestroyActionGenerator from './DestroyActionGenerator'
import BatchDestroyActionGenerator from './BatchDestroyActionGenerator'

export default class ActionsGenerator extends GroupGenerator {
  static generatorClasses = {
    [ActionTypes.CREATE]: CreateActionGenerator,
    [ActionTypes.SHOW]: ShowActionGenerator,
    [ActionTypes.INDEX]: IndexActionGenerator,
    [ActionTypes.UPDATE]: UpdateActionGenerator,
    [ActionTypes.DESTROY]: DestroyActionGenerator,
    [ActionTypes.BATCHDESTROY]: BatchDestroyActionGenerator
  }

  generate = () => {
    const actions = {}

    for (const method of this.methods) {
      actions[this.nameForMethod(method)] = this.generators[method].generateActions()
    }

    return actions
  }
}
