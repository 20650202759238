import client from '../../client'
import { RECEIVE_SCENARIO_FEEDBACK, RECEIVE_SCENARIO_FEEDBACK_ERROR, REQUEST_CREATE_SCENARIO_FEEDBACK, CLEAR_SCENARIO } from './consts'
import parseError from '../../lib/parseError'

function receiveScenarioError(error) {
  const { generalError, resourceErrors } = parseError(error)

  return { type: RECEIVE_SCENARIO_FEEDBACK_ERROR, generalError, resourceErrors }
}

function receiveScenario(action) {
  return { type: RECEIVE_SCENARIO_FEEDBACK, action }
}

function requestCreateScenario() {
  return { type: REQUEST_CREATE_SCENARIO_FEEDBACK }
}

function clearScenario() {
  return { type: CLEAR_SCENARIO }
}

export default function createScenarioFeedback(fields) {
  if (fields) {
    const action = fields.approved === true ? 'Approved' : 'Rejected'
    return (dispatch) => {
      dispatch(requestCreateScenario())
      client.post('/scenarios/feedback', { scenario: fields })
        .then((response) => response.data)
        .then((json) => dispatch(receiveScenario(action)))
        .catch((error) => dispatch(receiveScenarioError(error)))
    }
  }
  return (dispatch) => {
    dispatch(clearScenario())
  }
}
