import React from 'react'
import PropTypes from 'prop-types'
import { lowerCase } from 'lodash'
import { IoIosDocument } from 'react-icons/io'
import Component from '../common/Component'

export default class MedicalBenefitsSetDocumentComponent extends Component {
  static defaultProps = {
    needsProcessing: false,
    iconSize: 30,
    documentType: 'SBC'
  }

  static propTypes = {
    documentType: PropTypes.oneOf(['SBC', 'SOB']),
    url: PropTypes.string,
    needsProcessing: PropTypes.bool,
    iconSize: PropTypes.number
  }

  static renderedProps = ['url', 'needsProcessing']

  get needsProcessingText() {
    if (!this.props.url) {
      return ''
    } if (this.props.needsProcessing) {
      return `${this.props.documentType} Needs Processing`
    }
    return `${this.props.documentType} Processed`
  }

  get documentLink() {
    if (this.props.url) {
      return (
        <a href={this.props.url} target={`_${lowerCase(this.props.documentType)}`}>
          <IoIosDocument size={this.props.iconSize} />
          {this.needsProcessingText}
        </a>
      )
    }
    return `No ${this.props.documentType} Attached`
  }

  url() {
    return this.props.url
  }

  render() {
    return (<div>{this.documentLink}</div>)
  }
}
