import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import LabeledTextField from '../common/LabeledTextField'

export class SourceContentComponent extends Component {
  static propTypes = {
    sourceContentType: PropTypes.string,
    value: PropTypes.object,
    errorMessage: PropTypes.string,
    handleChange: PropTypes.func
  }

  handleChange = (field, value) => {
    this.props.handleChange(this.props.sourceContentType, field, value)
  }

  render() {
    const { errorMessage, sourceContentType, value } = this.props
    return (
      <div className="source-contents-input inline-fields" id={`sourceContent-${sourceContentType}`}>
        <label className="source-content-type-label">{sourceContentType}</label>
        <div className="text-field">
          <LabeledTextField
            key="pages"
            name="pages"
            label="Pages"
            labelCssClass="label"
            handleChange={this.handleChange}
            value={get(value, 'pages')}
          />
        </div>
        <div className="text-field">
          <LabeledTextField
            key="affected_records"
            name="affected_records"
            label="Affected Records"
            labelCssClass="label"
            handleChange={this.handleChange}
            errorMessage={errorMessage}
            value={get(value, 'affected_records')}
          />
        </div>
      </div>
    )
  }
}

export default SourceContentComponent
