import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isUndefined, slice } from 'lodash'
import { Link } from 'react-router-dom'
import GoogleOAuthLogin from './GoogleOAuthLogin'
import TestOAuthLogin from './TestOAuthLogin'
import CredentialsLogin from './CredentialsLogin'
import { loadConfigs, createOAuthSession, createCredentialsSession } from '../../actions/Login'

class Login extends Component {
  static propTypes = {
    loadConfigs: PropTypes.func.isRequired,
    createCredentialsSession: PropTypes.func.isRequired,
    createOAuthSession: PropTypes.func.isRequired,
    googleClientId: PropTypes.string,
    sessionError: PropTypes.string,
    oauthError: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.createCredentialsSession = this.createCredentialsSession.bind(this)
    this.createOAuthSession = this.createOAuthSession.bind(this)
  }

  componentDidMount() {
    this.props.loadConfigs()
  }

  get redirectPathname() {
    if (window.location.origin.match(/pr.howie.vericred.com/)) {
      const pathname = slice(window.location.pathname.split('/'), 2).join('/')
      return `/${pathname}`
    }

    return window.location.pathname
  }

  createCredentialsSession(username, password) {
    this.props.createCredentialsSession(username, password, this.redirectPathname)
  }

  createOAuthSession(token) {
    this.props.createOAuthSession(token, this.redirectPathname)
  }

  loginComponents() {
    const loginComponents = []

    loginComponents.push(
      <CredentialsLogin
        key="credentials"
        createCredentialsSession={this.createCredentialsSession}
        error={this.props.sessionError}
      />
    )
    loginComponents.push(
      <Link key="forgotPassword" to="/forgot_password" title="Forgot Password">Forgot Password?</Link>
    )

    if (!isUndefined(this.props.googleClientId)) {
      const OAuthComponent = process.env.REACT_APP_ACCEPTANCE_TESTING === 'true' ? TestOAuthLogin : GoogleOAuthLogin

      loginComponents.push(
        <OAuthComponent
          key="oauth"
          createOAuthSession={this.createOAuthSession}
          clientId={this.props.googleClientId}
          error={this.props.oauthError}
        />
      )
    }

    return loginComponents
  }

  render() {
    return (
      <div className="login-container">
        {this.loginComponents()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  sessionError: state.login.session.credentialsError,
  oauthError: state.login.google.error || state.login.session.oauthError,
  googleClientId: state.login.google.googleClientId
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadConfigs,
  createCredentialsSession,
  createOAuthSession
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)
