import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleLogin from 'react-google-login'
import alert from '../../alert'

class GoogleOAuthLogin extends Component {
  static propTypes = {
    createOAuthSession: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    error: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.processResponse = this.processResponse.bind(this)
  }

  processResponse(response) {
    this.props.createOAuthSession(response.tokenId)
  }

  render() {
    const { clientId, error } = this.props

    if (typeof error !== 'undefined') {
      alert(error)
    }

    return (
      <div className="pure-u-1 google-login">
        <GoogleLogin
          className="pure-button google-login-button"
          clientId={clientId}
          buttonText="Login with Google"
          onSuccess={this.processResponse}
          onFailure={this.processResponse}
        />
      </div>
    )
  }
}

export default GoogleOAuthLogin
