import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { get } from 'lodash'
import { routes } from './RouteDefinitions'

import Home from './Home'
import NotFound from './common/NotFound'

export default class EntitledRoutes extends Component {
  static propTypes = {
    entitlements: PropTypes.object.isRequired
  }

  get routes() {
    return routes
      .filter(({ group, action }) => this.userEntitledTo(group, action))
      .map(({ path, component }, index) => this.route(path, component, index))
  }

  userEntitledTo = (group, action) => {
    const { entitlements } = this.props

    return get(entitlements, `${group}.${action}.access`, false)
  }

  route = (path, component, key) => {
    return <Route exact key={key} path={path} component={component} />
  }

  render() {
    return (
      <Switch>
        {this.routes}
        <Route exact key="home" path="/" component={Home} />
        <Route key="not_found" component={NotFound} />
      </Switch>
    )
  }
}
