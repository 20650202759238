import React from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isNil, isEmpty, cloneDeep } from 'lodash'
import { Redirect } from 'react-router-dom'
import Component from '../common/Component'
import alert from '../../alert'
import reviewActions from '../../actions/MedicalBenefitsSetReviews'
import benefitsSetActions from '../../actions/MedicalBenefitsSets'
import totalTiersCountForBenefits from '../BenefitsSet/Helpers/tiersCountForBenefits'
import MedicalBenefitsSetComponent from './MedicalBenefitsSetComponent'
import UserContext from '../BenefitsSet/BenefitsSetUserContext'
import historyActions from '../../actions/BenefitsSetFieldHistories'

export class MedicalBenefitsSetNewReview extends Component {
  static propTypes = {
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showBenefitsSet: PropTypes.func.isRequired,
    resetShowBenefitsSet: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    attributes: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    benefitsSets: PropTypes.array,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    configuration: PropTypes.object,
    indexRequestInProgress: PropTypes.bool,
    history: PropTypes.func.isRequired,
    resetHistory: PropTypes.func.isRequired,
    benefitsSetFieldHistory: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = { values: {} }
  }

  componentDidMount() {
    this.props.showBenefitsSet(this.props.match.params.id)

    this.props.resetHistory()
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.match.params.id
    const nextId = nextProps.match.params.id

    if (currentId !== nextId) {
      this.setState({ values: {} })

      const params = {}
      const token = this.queryStrings.result_token

      if (!isNil(token)) {
        params.result_token = token
      }

      this.props.reset()
      this.props.resetShowBenefitsSet()
      this.props.resetHistory()

      this.props.showBenefitsSet(nextId)
    }
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.resetShowBenefitsSet()
    this.props.resetHistory()
  }

  get queryStrings() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  get returnURL() {
    return get(this.queryStrings, 'return_path', '/medical_benefits')
  }

  get tiersCount() {
    if (isNil(this.props.attributes)) {
      return 0
    }

    return totalTiersCountForBenefits(this.props.attributes.benefits)
  }

  get sanitizedValues() {
    const newValues = cloneDeep(this.state.values)
    newValues.review_type = this.reviewType
    newValues.benefits_set_id = this.props.attributes.id
    if (!isNil(newValues.plan_external_id_reviews)) {
      newValues.plan_external_id_reviews_attributes = newValues.plan_external_id_reviews.filter((r) => !isNil(r))
      delete newValues.plan_external_id_reviews
    }

    if (!isNil(newValues.metadatum_review)) {
      newValues.metadatum_review_attributes = newValues.metadatum_review
      delete newValues.metadatum_review
    }

    if (!isNil(newValues.benefit_reviews)) {
      newValues.benefit_reviews_attributes = newValues.benefit_reviews
        .filter((r) => !isNil(r))
        .map((b) => {
          if (isNil(b) || isNil(b.tier_reviews)) {
            return b
          }

          const newBenefitReview = cloneDeep(b)

          newBenefitReview.tier_reviews_attributes = newBenefitReview.tier_reviews
          delete newBenefitReview.tier_reviews

          return newBenefitReview
        })

      delete newValues.benefit_reviews
    }

    return newValues
  }

  get reviewType() {
    return isEmpty(this.state.values) ? 'Approval' : 'Rejection'
  }

  get submissionText() {
    return `Submit ${this.reviewType}`
  }

  get submitButtonClassName() {
    const classNames = ['pure-button', 'pure-input-1']

    if (this.reviewType === 'Approval') {
      classNames.push('pure-button-primary')
    } else {
      classNames.push('pure-button-destructive')
    }

    return classNames.join(' ')
  }

  get submitButtonDisabled() {
    if (this.reviewType === 'Approval' && this.props.attributes.approval_state === 'approved') {
      return true
    }
    return false
  }

  get rejections() {
    return get(this.props.attributes, 'rejections', [])
  }

  get needsBenefitSets() {
    return isNil(this.props.benefitsSets)
  }

  get redirectIfNecessary() {
    const {
      created,
      id
    } = this.props

    if (!created) {
      return null
    }

    if (isNil(id)) {
      return null
    }

    return this.redirect()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.create(this.sanitizedValues)
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)

      if (isNil(value)) {
        delete newState[field]
      } else {
        newState[field] = value
      }

      return { values: newState }
    })
  }

  redirect() {
    const returnURL = this.returnURL

    if (returnURL.pathname === window.location.pathname) {
      return null
    }

    return <Redirect push to={returnURL} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      attributes,
      configuration,
      indexRequestInProgress
    } = this.props

    if (indexRequestInProgress) {
      return null
    }

    if (isNil(configuration)) {
      return null
    }
    if (isNil(attributes)) {
      return null
    }

    if (!isNil(generalError)) {
      alert(generalError)
    }

    const submissionText = this.submissionText

    return (
      <div className="content">
        <h2>Review Medical Benefits Set</h2>
        <div>
          <form className="benefits-set-form pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            <fieldset>
              <MedicalBenefitsSetComponent
                handleReviewChange={this.handleChange}
                configuration={configuration}
                errors={resourceErrors}
                benefitsSet={attributes}
                review={this.state.values}
                userContext={new UserContext({ reviewable: true, viewable: true })}
                tiersCount={this.tiersCount}
                rejections={this.rejections}
                benefitsSetFieldHistory={this.props.benefitsSetFieldHistory}
                resetHistory={this.props.resetHistory}
                history={this.props.history}
              />
            </fieldset>
            <fieldset>
              <button
                className={this.submitButtonClassName}
                disabled={this.submitButtonDisabled}
                title={submissionText}
                type="submit"
              >
                {submissionText}
              </button>
            </fieldset>
          </form>
        </div>
        {this.redirectIfNecessary}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.medicalBenefitsSetReviews.create
  const showSlice = state.medicalBenefitsSets.show
  const configuration = state.login.session.configuration.benefits

  const historySlice = state.benefitsSetFieldHistory.index
  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    id: slice.id,
    created: slice.created,
    requestComplete: slice.requestComplete,
    configuration,
    benefitsSetFieldHistory: historySlice.records
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: reviewActions.create.main,
  reset: reviewActions.create.reset,

  showBenefitsSet: benefitsSetActions.show.main,
  resetShowBenefitsSet: benefitsSetActions.show.reset,

  history: historyActions.index.main,
  resetHistory: historyActions.index.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MedicalBenefitsSetNewReview)
