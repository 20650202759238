import React, { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { isNil, isUndefined, isEmpty, get, cloneDeep, some, find, snakeCase } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ZipFipsComponent from './ZipFipsComponent'
import PlansComponent from './PlansComponent'
import CensusesSelectFieldComponent from '../common/CensusesSelectFieldComponent'
import getZipCounties from '../../../actions/CarrierVerification/getZipCounties'
import FeedbackComponent from './FeedbackComponent'
import routerHistory from '../../../routerHistory'
import SelectField from '../../common/SelectField'
import LabeledTextField from '../../common/LabeledTextField'
import {
  getAllAudienceCensuses,
  setAudience,
  setAudienceByCensusId,
  getPlanSearches,
  getCensusPlanSearches,
  createScenarioFeedback
} from '../../../actions/CarrierVerification'

import groupActions from '../../../actions/VericredGroups'
import quoteActions from '../../../actions/VericredQuotes'
import quoteRatesActions from '../../../actions/VericredQuoteRates'
import groupMembersActions from '../../../actions/VericredGroupMembers'

export class ScenariosContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
    getAllAudienceCensuses: PropTypes.func.isRequired,
    setAudience: PropTypes.func.isRequired,
    getCensusPlanSearches: PropTypes.func.isRequired,
    audience: PropTypes.string,
    getZipCounties: PropTypes.func.isRequired,
    zipCounties: PropTypes.array,
    plans: PropTypes.array,
    censuses: PropTypes.array,
    plansByFamily: PropTypes.object,
    needFamilies: PropTypes.bool,
    requestInProgress: PropTypes.bool,
    resourceErrors: PropTypes.string,
    generalError: PropTypes.string,
    setAudienceByCensusId: PropTypes.func,
    createScenarioFeedback: PropTypes.func,
    getPlanSearches: PropTypes.func,
    needsCensuses: PropTypes.bool,
    configuration: PropTypes.object,
    scenarioActionTaken: PropTypes.string,
    zipFipsRequestInProgress: PropTypes.bool,
    groupMembersCreate: PropTypes.func.isRequired,
    groupMembersReset: PropTypes.func.isRequired,
    groupCreate: PropTypes.func.isRequired,
    groupReset: PropTypes.func.isRequired,
    group: PropTypes.object,
    quoteCreate: PropTypes.func.isRequired,
    quoteCreateReset: PropTypes.func.isRequired,
    quoteShow: PropTypes.func.isRequired,
    quoteShowReset: PropTypes.func.isRequired,
    quoteRatesCreate: PropTypes.func.isRequired,
    quoteRatesReset: PropTypes.func.isRequired,
    quote: PropTypes.object,
    quoteAttributes: PropTypes.object,
    quoteRates: PropTypes.object,
    groupMembers: PropTypes.object
  }

  constructor(props) {
    super(props)

    const values = this.stateValuesFromQS

    if (values.census_id) {
      this.props.setAudienceByCensusId(values.census_id)
    }
    this.state = {
      values,
      pollTimeout: null
    }
  }

  componentDidMount() {
    this.updateCensusesIfNeeded()
  }

  componentDidUpdate(prevProps) {
    this.updateCensusesIfNeeded(prevProps)
  }

  componentWillUnmount() {
    this.props.setAudience(null)
    this.resetGroupQuoteRequests()
    clearTimeout(this.state.pollTimeout)
    this.setState({ pollTimeout: null })
  }

  get queryStrings() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  get stateValuesFromQS() {
    const parsedValues = this.parseStateFromQueryStrings(this.queryStrings)

    return parsedValues
  }

  get selectedCensus() {
    const censusId = this.state.values.census_id

    if (!censusId) {
      return null
    }

    return find(this.props.censuses, (p) => p.id === censusId)
  }

  get submitButton() {
    const submitLabel = 'Run Scenario'
    const anyRequestInProgress = this.allRequestInProgress
    const sanitizedValues = cloneDeep(this.sanitizedStateValues)
    delete sanitizedValues.message
    const disableSubmit = some(sanitizedValues, (value) => isNil(value) || isEmpty(value.toString()))

    const classNames = ['pure-button', 'pure-input-1', 'pure-button-primary', 'submit-btn']

    if (anyRequestInProgress) {
      classNames.push('loading')
    }

    return (
      <button
        className={classNames.join(' ')}
        disabled={anyRequestInProgress || disableSubmit}
        title={submitLabel}
        name={submitLabel}
        type="submit"
      >
        {submitLabel}
      </button>
    )
  }

  get smallGroupComponents() {
    if (this.sanitizedStateValues.audience !== 'Individual') {
      return (
        <div>
          <div className="inline-fields">
            <LabeledTextField
              name="sic_code"
              title="Sic Code"
              label="SIC Code"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              value={this.state.values.sic_code}
            />
            <LabeledTextField
              name="participation_percentage"
              title="participation_percentage"
              label="Participation Percentage"
              type="number"
              step="1"
              max={100}
              min={0}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              value={this.state.values.participation_percentage}
            />
            <LabeledTextField
              name="contribution_percentage"
              title="contribution_percentage"
              label="Contribution Percentage"
              type="number"
              step="1"
              max={100}
              min={0}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              value={this.state.values.contribution_percentage}
            />
            <LabeledTextField
              name="number_of_employees"
              title="eligible_members"
              label="Eligible Members"
              type="number"
              step="1"
              max={100}
              min={0}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              value={this.state.values.number_of_employees}
            />
          </div>
          <div className="inline-fields">
            <SelectField
              key="rating_method"
              name="rating_method"
              label="Rating Method"
              options={this.ratingMethodOptions}
              handleChange={this.handleChange}
              value={this.state.values.rating_method}
              disabled={this.isLifeAndDisability}
            />
            <SelectField
              key="voluntary"
              name="voluntary"
              label="Voluntary"
              options={this.voluntaryOptions}
              handleChange={this.handleChange}
              value={this.state.values.voluntary}
            />
          </div>
        </div>
      )
    }
    return null
  }

  get isLifeAndDisability() {
    return ['life', 'disability'].includes(this.sanitizedStateValues.product_line)
  }

  get ratingMethodOptions() {
    return [
      { text: 'Age Banded', value: 'age_banded' },
      { text: '4 Tier Composite', value: '4_tier_composite' },
      { text: '3 Tier Composite', value: '3_tier_composite' },
      { text: '2 Tier Composite', value: '2_tier_composite' }
    ]
  }

  get voluntaryOptions() {
    return [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }]
  }

  get sanitizedStateValues() {
    const values = cloneDeep(this.state.values)
    return this.sanitizedValues(values, this.props.audience, this.selectedCensus)
  }

  get allResourceErrors() {
    return find([this.props.resourceErrors,
      this.props.group.resourceErrors,
      this.props.quote.resourceErrors,
      this.props.quoteRates.resourceErrors,
      this.props.groupMembers.resourceErrors], (p) => !isUndefined(p))
  }

  get allGeneralErrors() {
    return find([this.props.generalError,
      this.props.group.generalError,
      this.props.quote.generalError,
      this.props.quoteRates.generalError,
      this.props.groupMembers.generalError], (p) => !isUndefined(p))
  }

  get allRequestInProgress() {
    return (get(this.props, 'requestInProgress', false)
      || get(this.props, 'plansRequestInProgress', false)
      || get(this.props, 'group.requestInProgress', false)
      || get(this.props, 'quote.requestInProgress', false)
      || get(this.props, 'quoteRates.requestInProgress', false)
      || get(this.props, 'groupMembers.requestInProgress', false)
      || this.state.pollTimeout != null)
  }

  get quoteComplete() {
    return get(this.props.quoteAttributes, 'status') === 'complete'
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = prevState.values

      if (isNil(value)) {
        delete newState[field]
      } else {
        newState[field] = value
      }

      return { values: newState }
    })

    if (field !== 'message') {
      this.updateHistory(field, value)
    }
  }

  handleAudienceChange = (field, value) => {
    this.handleChange('census_id', null)
    this.props.setAudience(value)
  }

  sanitizedValues = (values, audience, census) => {
    const zipFips = this.zipFipsValuesFromString(values.zip_fips)
    const market = audience ? audience.toLowerCase().split(' ').join('_') : ''
    const effectiveDateString = values.effective_date
    const censusName = census ? census.name : ''

    return {
      zip_code: zipFips.zip_code,
      fips_code: zipFips.fips_code,
      market,
      effective_date: values.effective_date,
      audience,
      census_name: censusName,
      census_id: values.census_id,
      effectiveDateString,
      message: values.message,
      product_line: snakeCase(values.product_line),
      sic_code: values.sic_code,
      number_of_employees: values.number_of_employees,
      voluntary: values.voluntary,
      contribution_percentage: values.contribution_percentage,
      participation_percentage: values.participation_percentage,
      rating_method: values.rating_method
    }
  }

  generateGroupQuote = async () => {
    this.resetGroupQuoteRequests()

    await this.createGroup()

    await this.createGroupMembers()

    await this.createQuote()

    if (this.props.quote.created) {
      this.waitQuoteComplete()
    }
  }

  createGroup = async () => {
    const values = this.sanitizedStateValues
    await this.props.groupCreate(
      {
        voluntary: values.voluntary,
        sic_code: values.sic_code,
        fips_code: values.fips_code,
        zip_code: values.zip_code,
        number_of_employees: values.number_of_employees
      }
    )
  }

  createGroupMembers = async () => {
    if (!this.props.group.created) return

    const values = this.sanitizedStateValues
    await this.props.groupMembersCreate(
      {
        group_id: this.props.group.id,
        location_id: this.props.group.responseAttributes.location_id,
        zip_code: values.zip_code,
        fips_code: values.fips_code,
        census_id: values.census_id,
        effective_date: values.effectiveDateString
      }
    )
  }

  createQuote = async () => {
    if (!this.props.group.created) return

    const values = this.sanitizedStateValues
    await this.props.quoteCreate(
      {
        group_id: this.props.group.id,
        contribution_percentage: values.contribution_percentage,
        effective_date: values.effectiveDateString,
        participation_percentage: values.participation_percentage,
        product_line: snakeCase(values.product_line),
        rating_method: values.rating_method,
        voluntary: values.voluntary,
        market: values.market
      }
    )
  }

  waitQuoteComplete = () => {
    if (this.quoteComplete) {
      this.fetchQuoteRates()
      this.setState({ pollTimeout: null })
    } else {
      const pollTimeout = setTimeout(
        async () => {
          await this.fetchQuote()
          this.waitQuoteComplete()
        }, 3000
      )
      this.setState({ pollTimeout })
    }
  }

  fetchQuote = async () => {
    await this.props.quoteShow(this.props.quote.id)
  }

  fetchQuoteRates = () => {
    this.props.quoteRatesCreate({ quote_id: this.props.quote.id })
  }

  resetGroupQuoteRequests = () => {
    this.props.quoteRatesReset()
    this.props.quoteCreateReset()
    this.props.quoteShowReset()
    this.props.groupMembersReset()
    this.props.groupReset()
  }

  submit = (e) => {
    e.preventDefault()
    const values = this.sanitizedStateValues
    this.props.createScenarioFeedback()
    this.props.getPlanSearches(
      values.product_line,
      values.zip_code,
      values.fips_code,
      values.market,
      values.effectiveDateString
    )

    if (this.props.audience === 'Individual') {
      const sanitizedFamilies = this.sanitizedFamilies(this.selectedCensus.families)
      this.props.getCensusPlanSearches(
        values.product_line,
        values.zip_code,
        values.fips_code,
        values.market,
        values.effectiveDateString,
        sanitizedFamilies
      )
    } else {
      this.resetGroupQuoteRequests()
      this.generateGroupQuote()
    }
  }

  handleScenarioFeedback = (approved) => {
    const values = this.sanitizedStateValues
    values.approved = approved
    this.props.createScenarioFeedback(values)
  }

  zipFipsFromQueryString(queryStrings) {
    const zipFips = get(queryStrings, 'zip_fips', '')
    const zipCode = this.zipFipsValuesFromString(zipFips).zip_code

    return !isUndefined(zipCode) && zipCode.length === 5 ? zipFips : undefined
  }

  parseStateFromQueryStrings(queryStrings) {
    return {
      zip_fips: this.zipFipsFromQueryString(queryStrings),
      census_id: global.parseInt(get(queryStrings, 'census_id', null)) || undefined,
      effective_date: get(queryStrings, 'effective_date', undefined),
      product_line: get(queryStrings, 'product_line', undefined),
      sic_code: get(queryStrings, 'sic_code', '0100'),
      number_of_employees: get(queryStrings, 'number_of_employees', '30'),
      voluntary: get(queryStrings, 'voluntary', 'true'),
      participation_percentage: get(queryStrings, 'participation_percentage', '100'),
      contribution_percentage: get(queryStrings, 'contribution_percentage', '100'),
      rating_method: get(queryStrings, 'rating_method', 'age_banded')
    }
  }

  updateCensusesIfNeeded(prevProps) {
    if ((isNil(prevProps) && this.state.values.census_id)
      || (!isNil(prevProps) && this.props.audience && prevProps.audience !== this.props.audience)) {
      this.props.getAllAudienceCensuses(this.props.audience)
    }
  }

  updateHistory(key, value) {
    const search = this.queryStrings
    if (isNil(value)) {
      delete search[key]
      if (isEmpty(search)) {
        routerHistory.push('/scenarios')
      } else {
        this.updateRouterHistory(search)
      }
    } else {
      search[key] = value
      this.updateRouterHistory(search)
    }
  }

  updateRouterHistory(search) {
    const queryStrings = qs.stringify(search)
    routerHistory.push(`/scenarios?${queryStrings}`)
  }

  audienceOptions() {
    const configuration = this.props.configuration
    const productLine = this.state.values.product_line
    const audiences = get(configuration.product_line_audiences, productLine, [])

    return audiences.map((audience) => ({
      text: audience,
      value: audience
    }))
  }

  productLineOptions(productLines) {
    return productLines.map((productLine) => ({
      text: productLine,
      value: productLine
    }))
  }

  zipFipsValuesFromString(originalZipfips) {
    let zipfips = originalZipfips
    zipfips = zipfips ? zipfips.split('-') : []
    const zipCode = zipfips.length > 1 ? zipfips[0] : undefined
    const fipsCode = zipfips.length > 1 ? zipfips[1] : undefined
    return { zip_code: zipCode, fips_code: fipsCode }
  }

  sanitizedFamilies(families) {
    return families.map((family) => {
      const applicants = family.members
        .map((m) => (
          {
            age: m.age,
            smoker: m.tobacco,
            child: m.member_type === 'child',
            gender: m.gender[0].toUpperCase()
          }
        ))

      return (
        {
          id: family.id,
          name: family.name,
          household_income: family.household_income,
          household_size: family.household_size,
          applicants
        }
      )
    })
  }

  render() {
    const {
      needsCensuses,
      configuration,
      scenarioActionTaken,
      zipCounties,
      zipFipsRequestInProgress,
      plans,
      plansByFamily,
      quoteRates
    } = this.props

    let needFamilies = this.props.needFamilies
    const plansPrices = this.props.audience === 'Individual' ? plansByFamily : quoteRates.responseAttributes
    if (this.props.audience !== 'Individual') {
      needFamilies = !plansPrices
    }

    const audienceOptions = this.audienceOptions()
    const productLineOptions = this.productLineOptions(configuration.product_lines)
    /* eslint-disable react/jsx-no-bind */
    return (
      <div className="content">
        <form className="pure-form pure-form-stacked" onSubmit={this.submit}>
          <fieldset>
            <div>
              <SelectField
                name="product_line"
                label="Line Of Coverage"
                value={this.state.values.product_line}
                handleChange={this.handleChange}
                options={productLineOptions}
              />
            </div>
            <div className="inline-fields">
              <ZipFipsComponent
                value={this.state.values.zip_fips}
                handleChange={this.handleChange}
                requestInProgress={zipFipsRequestInProgress}
                zipCounties={zipCounties}
                getZipCounties={this.props.getZipCounties}
              />
              <SelectField
                key="audience"
                name="audience"
                label="Market"
                options={audienceOptions}
                handleChange={this.handleAudienceChange}
                value={this.props.audience}
              />
              <CensusesSelectFieldComponent
                value={this.state.values.census_id}
                handleChange={this.handleChange}
                censuses={this.props.censuses}
                needsCensuses={needsCensuses}
              />
              <LabeledTextField
                label="Effective Date"
                key="effective_date"
                name="effective_date"
                type="date"
                title="Effective Date"
                value={this.state.values.effective_date}
                handleChange={this.handleChange}
              />
            </div>
            {this.smallGroupComponents}
            <div className="actions">
              {this.submitButton}
            </div>
          </fieldset>
        </form>

        <PlansComponent
          census={this.selectedCensus}
          needFamilies={needFamilies}
          plans={plans}
          audience={this.props.audience}
          plansByFamily={plansPrices}
          requestInProgress={this.props.requestInProgress}
          resourceErrors={this.allResourceErrors}
          generalError={this.allGeneralErrors}
        />

        <FeedbackComponent
          handleApproveScenario={this.handleScenarioFeedback.bind(this, true)}
          handleRejectScenario={this.handleScenarioFeedback.bind(this, false)}
          scenarioActionTaken={scenarioActionTaken}
          rejectionMessage={this.state.values.message}
          handleChange={this.handleChange}
          needFamilies={needFamilies}
        />
      </div>
    )
    /* eslint-enable react/jsx-no-bind */
  }
}

const mapStateToProps = (state) => {
  const audience = state.carrierVerification.scenariosAudience.audience
  const censuses = get(state.carrierVerification.allCensuses.censusesByAudience, audience)

  const group = state.groups.create
  const quote = state.quotes.create
  const quoteAttributes = state.quotes.show.attributes || state.quotes.create.responseAttributes
  const quoteRates = state.quoteRates.create
  const groupMembers = state.groupMembers.create
  return {
    group,
    quote,
    quoteAttributes,
    quoteRates,
    groupMembers,
    configuration: state.login.session.configuration.censuses,
    needsCensuses: isNil(censuses),
    censuses,
    audience,
    scenarioActionTaken: state.carrierVerification.scenarios.actionTaken,
    plansRequestInProgress: state.carrierVerification.plans.getInProgress,
    zipCounties: state.carrierVerification.zipCounties.zipCounties,
    zipFipsRequestInProgress: state.carrierVerification.zipCounties.getInProgress,
    plans: state.carrierVerification.plans.plans,
    plansByFamily: state.carrierVerification.censusPlans.plansByFamily,
    requestInProgress: state.carrierVerification.plans.getInProgress,
    resourceErrors: state.carrierVerification.censusPlans.resourceErrors,
    generalError: state.carrierVerification.censusPlans.generalError,
    needFamilies: (
      isUndefined(get(state, 'carrierVerification.censusPlans.outstandingFamilyIds'))
      || !isEmpty(get(state, 'carrierVerification.censusPlans.outstandingFamilyIds'))
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getPlanSearches,
  getAllAudienceCensuses,
  setAudience,
  setAudienceByCensusId,
  getCensusPlanSearches,
  createScenarioFeedback,
  getZipCounties,
  groupCreate: groupActions.create.main,
  groupMembersCreate: groupMembersActions.create.main,
  quoteCreate: quoteActions.create.main,
  quoteShow: quoteActions.show.main,
  quoteRatesCreate: quoteRatesActions.create.main,
  quoteRatesReset: quoteRatesActions.create.reset,
  quoteCreateReset: quoteActions.create.reset,
  quoteShowReset: quoteActions.show.reset,
  groupMembersReset: groupMembersActions.create.reset,
  groupReset: groupActions.create.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosContainer)
