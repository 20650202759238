import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { castArray, cloneDeep, get, isNil, startsWith, trimStart, isFunction } from 'lodash'
import { Link } from 'react-router-dom'
import { IoIosFunnel, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import Component from './Component'
import routerHistory from '../../routerHistory'
import IndexFilterModal from './IndexFilterModal'

export default class IndexHeaderCellComponent extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    queryParams: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]).isRequired,
    columnKey: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    remoteKey: PropTypes.string,
    filterValues: PropTypes.object,
    filterActions: PropTypes.object,
    recordLabel: PropTypes.string,
    recordValue: PropTypes.string,
    optionsEnum: PropTypes.bool
  }

  static defaultProps = {
    sortable: true,
    recordLabel: 'name',
    recordValue: 'id',
    optionsEnum: false
  }

  constructor(props) {
    super(props)

    this.state = { displayFilterSelection: false }
  }

  componentDidMount() {
    this.fetchFilterRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryParams[this.props.remoteKey] !== this.props.queryParams[this.props.remoteKey]) {
      this.fetchFilterRecords()
    }
  }

  get isFiltered() {
    return !isNil(this.filterValue)
  }

  get isRemoteFilter() {
    return !get(this.props.filterValues, 'config', false)
  }

  get filterValue() {
    return this.props.queryParams[this.props.remoteKey]
  }

  get filterValueIds() {
    return castArray(this.filterValue).map((v) => String(v))
  }

  get currentSortDirection() {
    const { queryParams } = this.props

    if (!isNil(queryParams.sort) && startsWith(queryParams.sort, '-')) {
      return '-'
    }

    return ''
  }

  get currentSortKey() {
    const { queryParams } = this.props

    if (isNil(queryParams.sort)) {
      return null
    }

    if (startsWith(queryParams.sort, '-')) {
      return queryParams.sort.slice(1)
    }

    return cloneDeep(queryParams.sort)
  }

  get sortDirection() {
    if (this.props.columnKey !== this.currentSortKey || this.currentSortDirection !== '') {
      return ''
    }

    return '-'
  }

  get sortText() {
    const { columnKey } = this.props

    if (columnKey !== this.currentSortKey || this.currentSortDirection === '') {
      return <IoIosArrowUp />
    }

    return <IoIosArrowDown />
  }

  get sortButton() {
    const { columnKey, queryParams, sortable } = this.props

    if (!sortable) {
      return null
    }

    if (!isNil(this.filterValue)) {
      const value = castArray(this.filterValue)

      if (value.length <= 1) {
        return null
      }
    }

    const current = trimStart(queryParams.sort, '-') === columnKey

    const query = cloneDeep(queryParams)
    delete query.page

    const search = Object.assign(query, { sort: `${this.sortDirection}${columnKey}` })

    let className = 'hidden-button sort-button'

    if (current) {
      className += ' current'
    }

    return (
      <Link
        className={className}
        to={{ pathname: this.props.path, search: qs.stringify(search) }}
      >
        Sort
        <i>{this.sortText}</i>
      </Link>
    )
  }

  get filterButton() {
    const { filterable } = this.props

    if (filterable !== true) {
      return null
    }

    if (this.isFiltered) {
      return this.updateFilterButton
    }

    return this.enableFilterButton
  }

  get enableFilterButton() {
    return (
      <button
        name={this.props.columnKey}
        type="button"
        onClick={this.handleFilterButton}
        className="hidden-button filter-button"
      >
        Filter
        <i><IoIosFunnel /></i>
      </button>
    )
  }

  get filterText() {
    const count = this.filterValueIds.length

    return `${count} Selected`
  }

  get updateFilterButton() {
    const { filterValues } = this.props

    if (filterValues.requestInProgress) {
      return null
    }

    if (isNil(filterValues.records)) {
      return null
    }

    return (
      <button
        type="button"
        onClick={this.handleUpdateFilterButton}
        className="current hidden-button remove-filter-button"
      >
        {this.filterText}
        <i><IoIosFunnel /></i>
      </button>
    )
  }

  get filterModal() {
    if (this.props.filterable !== true) {
      return null
    }

    if (this.state.displayFilterSelection !== true) {
      return null
    }

    const {
      filterValues,
      filterActions,
      remoteKey,
      queryParams,
      recordValue,
      recordLabel,
      optionsEnum
    } = this.props

    let valueOptions = []
    const values = queryParams[remoteKey]
    const records = filterValues.records

    if (!isNil(values) && !isNil(records)) {
      valueOptions = castArray(values).map((v) => {
        if (optionsEnum) {
          return {
            value: v,
            label: v
          }
        }

        const record = records.find((r) => String(get(r, recordValue)) === v)

        return {
          value: get(record, recordValue),
          label: get(record, recordLabel)
        }
      })
    }

    return (
      <IndexFilterModal
        label={this.props.label}
        filterValues={filterValues}
        filterActions={filterActions}
        handleClose={this.handleFilterClose}
        handleSelected={this.handleFilterSelected}
        remoteKey={remoteKey}
        initialValue={valueOptions}
        recordValue={recordValue}
        recordLabel={recordLabel}
      />
    )
  }

  handleUpdateFilterButton = (event) => {
    event.preventDefault()

    this.setState({ displayFilterSelection: true })
  }

  handleFilterClose = () => {
    this.setState({ displayFilterSelection: false })
  }

  handleFilterSelected = (name, value) => {
    this.setState({ displayFilterSelection: false })
    this.updateFilter(name, value)
  }

  handleFilterButton = (event) => {
    event.preventDefault()

    this.setState({ displayFilterSelection: true })
  }

  updateFilter = (name, value) => {
    const query = cloneDeep(this.props.queryParams)

    delete query.page

    const filter = {}

    if (!isNil(value)) {
      filter[name] = value
    } else {
      delete query[name]
    }

    const search = Object.assign(query, filter)
    const push = {
      pathname: this.props.path,
      search: qs.stringify(search)
    }

    routerHistory.push(push)
  }

  fetchFilterRecords = () => {
    if (this.isFiltered && this.isRemoteFilter) {
      const ids = this.filterValueIds
      this.props.filterActions.records({ ids: ids.join(',') })
    }
  }

  render() {
    const { columnKey } = this.props
    let { label } = this.props

    if (isFunction(label)) {
      label = label()
    }

    return (
      <td key={`header-cell-${columnKey}`} className="header-cell">
        <div>
          <div className="header-label">{label}</div>
          <div className="header-actions">
            {this.filterButton}
            {this.sortButton}
          </div>
        </div>
        {this.filterModal}
      </td>
    )
  }
}
