import { cloneDeep, remove } from 'lodash'
import {
  REQUEST_CENSUS_PLANS,
  RECEIVE_CENSUS_PLAN,
  RECEIVE_CENSUS_PLANS_ERROR
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  plansByFamily: {},
  generalError: undefined,
  resourceErrors: undefined,
  getInProgress: {},
  outstandingFamilyIds: undefined
}
function removeItemByValue(array, value) {
  remove(array, (item) => item === value)
  return array
}

export default function PlansCensusReducer(state = defaultState, action) {
  switch (action.type) {
  case REQUEST_CENSUS_PLANS:
    return { ...state,
      plansByFamily: undefined,
      generalError: undefined,
      resourceErrors: undefined,
      outstandingFamilyIds: action.familyIds }
  case RECEIVE_CENSUS_PLAN: {
    const newPlans = cloneDeep(state.plansByFamily) || {}
    newPlans[action.familyId] = action.plans
    const newOutStandingFamilyIds = removeItemByValue(cloneDeep(state.outstandingFamilyIds), action.familyId)

    return { ...state,
      plansByFamily: newPlans,
      generalError: undefined,
      resourceErrors: undefined,
      outstandingFamilyIds: newOutStandingFamilyIds }
  }

  case RECEIVE_CENSUS_PLANS_ERROR: {
    const familyIdsWithNoErrors = removeItemByValue(cloneDeep(state.outstandingFamilyIds), action.familyId)
    return { ...state,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors,
      outstandingFamilyIds: familyIdsWithNoErrors }
  }
  default:
    return { ...state }
  }
}
