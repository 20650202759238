import { toPairs, isEmpty, isUndefined, isNil, has, omit, get, cloneDeep } from 'lodash'

import benefitGroups from './benefitGroups.json'

const fields = {
  'Plan Info': {
    metadatum: {
      name: {
        label: 'Name',
        never_hide: true,
        display_on_cvt: true
      },
      display_name: {
        label: 'Display Name',
        never_hide: true
      },
      level: {
        label: 'Level',
        type: 'select',
        never_hide: true,
        options: [],
        display_on_cvt: true
      },
      audience: {
        label: 'Audience',
        type: 'select',
        never_hide: true,
        options: []
      },
      plan_type: {
        label: 'Plan Type',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: (values, configuration) => {
          if (!has(configuration, 'audience_plan_types')) {
            return []
          }

          if (has(configuration.audience_plan_types, values.audience)) {
            return configuration.audience_plan_types[values.audience]
          }

          return []
        },
        disabled: (values) => isNil(values.audience)
      },
      plan_calendar: {
        label: 'Plan Calendar',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: (values, configuration) => {
          if (!has(configuration, 'audience_plan_calendars')) {
            return []
          }

          if (has(configuration.audience_plan_calendars, values.audience)) {
            return configuration.audience_plan_calendars[values.audience]
          }

          return []
        },
        disabled: (values) => isNil(values.audience)
      },
      plan_year: {
        label: 'Plan Year',
        never_hide: true,
        display_on_cvt: false
      },
      effective_date: {
        label: 'Effective Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      expiration_date: {
        label: 'Expiration Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      carrier_id: {
        label: 'Carrier ID',
        never_hide: true,
        display_on_cvt: false
      },
      issuer_name: {
        label: 'Issuer Name',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Carrier Name',
        vericred_name: 'carrier_name',
        disabled: () => { return true }
      },
      private_exchange: {
        label: 'Private Exchange',
        type: 'select',
        never_hide: true,
        options: []
      },
      state_id: {
        label: 'State',
        type: 'select',
        never_hide: true,
        options: [

        ]
      },
      covered_ages: {
        label: 'Covered Ages',
        type: 'select',
        never_hide: true,
        options: []
      },
      drug_formulary_url: {
        label: 'Drug Formulary',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Drug Formulary URL'
      },
      provider_directory_url: {
        label: 'Provider Directory',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Provider Directory URL'
      },
      source: {
        label: 'Data Source',
        never_hide: true,
        disabled: () => { return true }
      },
      market: {
        label: 'Market',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        vericred_name: 'plan_market',
        options: (values) => {
          let marketOptions
          switch (values.audience) {
          case 'Medicare Advantage':
            marketOptions = ['On Market']
            break
          case 'Large Group':
          case 'Large Group Custom':
            marketOptions = ['Off Market']
            break
          default:
            marketOptions = ['Both', 'Off Market', 'On Market']
            break
          }
          return marketOptions
        },
        disabled: (values) => {
          switch (values.audience) {
          case 'Medicare Advantage':
          case 'Large Group':
          case 'Large Group Custom':
            return true
          default:
            return false
          }
        }
      },
      sbc_name: {
        label: 'SBC Name',
        never_hide: true,
        display_on_cvt: false
      },
      actuarial_value: {
        label: 'Actuarial Value (%)',
        never_hide: true,
        display_on_cvt: true,
        type: 'number',
        min: 0,
        max: 100,
        step: 'any'
      },
      estimated_actuarial_value: {
        label: 'EST. Actuarial Value (%)',
        never_hide: true,
        type: 'number',
        display_only: true,
        disabled: true
      },
      mail_order_rx: {
        label: 'Mail Order RX',
        never_hide: true,
        display_on_cvt: true,
        type: 'number',
        min: 0,
        step: 'any'
      },
      embedded_deductible: {
        label: 'Embedded Deductible',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: ['Embedded', 'Non-Embedded', 'Unknown']
      },
      gated: {
        label: 'Gated',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: [

        ]
      },
      skilled_nursing_facility_365: {
        label: 'Skilled Nursing Facility 365',
        type: 'select',
        never_hide: true,
        display_on_cvt: false,
        options: ['Unlimited', 'Unknown'],
        disabled: (value, configuration) => {
          if (!has(configuration, 'state_id')) {
            return true
          }
          if (isNil(configuration.state_id) || isEmpty(configuration.state_id)) {
            return true
          }
          if (!has(value, 'state_id')) {
            return true
          }
          return value.state_id !== configuration.state_id.find((s) => s.name === 'NY').value
        }
      },
      actively_marketed: {
        label: 'Actively Marketed',
        type: 'select',
        never_hide: true,
        options: ['Yes', 'No', 'Renewal Only', 'Unknown']
      },
      hsa_eligible: {
        label: 'HSA Eligible',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: []
      },
      dp_rider: {
        label: 'Domestic Partner Rider Excluded',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Domestic Partner Rider',
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      fp_rider: {
        label: 'Family Planning Rider Excluded',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Family Planning Rider',
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      age29_rider: {
        label: 'Age 29 Rider Included',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Dependent Age 29 Rider',
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      infertility_rider: {
        label: 'Infertility Treatment Rider Included',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Infertility Treatment Rider',
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      abortion_rider: {
        label: 'Abortion Rider Included',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        cvt_label: 'Abortion Rider',
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      essential_health_benefits_percentage: {
        label: 'Essential Health Benefits (%)',
        never_hide: true,
        type: 'number',
        min: 0,
        max: 100,
        step: 'any'
      },
      plan_ancestor_id: {
        label: 'Plan Ancestor',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'medicalBenefitsSetsIndex.main',
        reset: 'medicalBenefitsSetsIndex.reset',
        recordPath: 'medical_benefits',
        existingRecordPath: 'plan_ancestor',
        recordLabel: ['hios_ids', 'name', 'vericred_id', 'plan_year'],
        searchType: 'search_term',
        filterOptions: true,
        filterFields: [{ data_key: 'plan_year', filter_key: 'plan_year', addition: '-1', required: true }],
        display: (value) => {
          let display = get(value, 'plan_ancestor_hios_id', '')

          const planAncestor = get(value, 'plan_ancestor', undefined)

          if (!isUndefined(planAncestor) && !isEmpty(planAncestor)) {
            display = `${get(planAncestor, 'hios_ids', [])[0]} - ${get(planAncestor, 'name', '')} `
              + `- ${get(planAncestor, 'vericred_id', '')} - ${get(planAncestor, 'plan_year', '')}`
          }
          return display
        },
        alternateKey: 'plan_ancestor_identifier',
        bulkEditKey: 'plan_ancestor_hios_id'
      },
      howie_service_area_id: {
        label: 'Service Area',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'howieServiceAreasIndex.main',
        reset: 'howieServiceAreasIndex.reset',
        recordPath: 'howie_service_areas',
        existingRecordPath: 'howie_service_area',
        recordLabel: ['name', 'external_key', 'issuer_id', 'year'],
        searchType: 'search_term',
        filterOptions: false,
        filterFields: [
          { data_key: 'audience', filter_key: 'audience', required: false },
          { data_key: 'plan_year', filter_key: 'year', required: false },
          { data_key: 'carrier_id', filter_key: 'issuer_id', required: false }
        ],
        display: (value) => {
          return get(value, 'howie_service_area.external_key', '') || get(value, 'service_area_external_key', '')
        },
        alternateKey: 'service_area_external_key',
        bulkEditKey: 'service_area_external_key'
      },
      privileged_data: {
        label: 'Privileged Data',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      telemedicine: {
        label: 'Telemedicine',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      adult_dental: {
        label: 'Adult Dental',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      chiropractic_services: {
        label: 'Chiropractic Services',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      cms_quality_ratings_overall: {
        label: 'CMS Quality Ratings Overall',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: ['1', '2', '3', '4', '5', 'not_rated']
      },
      cms_quality_ratings_medical_care: {
        label: 'CMS Quality Ratings Medical Care',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: ['1', '2', '3', '4', '5', 'not_rated']
      },
      cms_quality_ratings_member_experience: {
        label: 'CMS Quality Ratings Member Experience',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: ['1', '2', '3', '4', '5', 'not_rated']
      },
      cms_quality_ratings_plan_administration: {
        label: 'CMS Quality Ratings Plan Administration',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: ['1', '2', '3', '4', '5', 'not_rated']
      }
    },
    plan_external_ids: {
      label: 'External IDs',
      foreign_key: 'plan_external_id_id',
      options: (values) => {
        switch (values.metadatum.audience) {
        case 'Medicare Advantage':
          return { medicare_plan_id: 'Medicare Plan ID', package_code: 'Package Code' }
        case 'Large Group':
        case 'Large Group Custom':
          return { contract_id: 'Contract ID', package_code: 'Package Code' }
        default:
          return {
            hios_id: 'HIOS ID', contract_id: 'Contract ID', package_code: 'Package Code'
          }
        }
      },
      never_hide: true,
      type: 'plan_external_ids',
      hidden: () => {
        return false
      },
      display: (value) => {
        if (isUndefined(value)) {
          return ''
        }

        let typeLabel
        switch (value.external_id_type) {
        case 'contract_id':
          typeLabel = 'Contract ID'
          break
        case 'hios_id':
          typeLabel = 'HIOS ID'
          break
        case 'medicare_plan_id':
          typeLabel = 'Medicare Plan ID'
          break
        case 'package_code':
          typeLabel = 'Package Code'
          break
        default:
          typeLabel = ''
          break
        }

        return `${typeLabel}: ${value.external_id}`
      }
    }
  },
  Benefits: {
    benefits: {
      name: 'benefits',
      foreign_key: 'benefit_id',
      never_hide: true,
      type: 'tiered_field',
      label: 'Benefits',
      fields: benefitGroups
    }
  }
}

export const fieldPairs = toPairs(fields)
export const benefitLabels = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  // eslint-disable-next-line no-param-reassign
  fieldset.fields.forEach((f) => { memo[f.name] = f.label })
  return memo
}, {})

export const benefitNames = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  fieldset.fields.forEach((f) => { memo.push(f.name) })
  return memo
}, [])

export const benefitGroupNames = fields.Benefits.benefits.fields.map((b) => b.name)

export const infoFields = omit(fields['Plan Info'], ['plan_external_ids'])

export const externalIdField = fields['Plan Info'].plan_external_ids

const metadatumFieldsBulkEditTemp = {
  contract_codes: {
    label: 'Contract IDs',
    never_hide: true
  },
  package_codes: {
    label: 'Package Codes',
    never_hide: true
  }
}

Object.assign(metadatumFieldsBulkEditTemp, cloneDeep(infoFields.metadatum))

export const metadatumFields = infoFields.metadatum
export const metadatumFieldsDiffSet = infoFields.metadatum
export const metadatumFieldsBulkEdit = metadatumFieldsBulkEditTemp

export default fields
