import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator('benefits_sets_export_publishing',
  baseUrl,
  'publish')
const actions = generator.generate()

function baseUrl(params) {
  return `/benefits_sets_exports/${params.id}/publishings`
}

export default actions
