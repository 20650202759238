import { isNil, trim } from 'lodash'

export default function benefitValueFormatter(value) {
  if (isNil(value)) return value

  const formattedValue = trim(value)
    .replace(/^i(ncluded.in.)?m(edical)?$/i, 'Included in Medical')
    .replace(/^n(ot.)?c(overed)?$/i, 'Not Covered')
    .replace(/ ad$/i, ' after deductible')
    .replace(/^na$/i, 'Not Applicable')
    .replace(/ pbp/i, ' per benefit period')
    .replace(/ ps$/i, ' per script')
    .replace(/ py$/i, ' per year')
    .replace(/see carrier doc/, 'see carrier documentation for more information')

  if (formattedValue.match(/\$?\d{3,}(?!\s(visit|item|exam|script|month|day|procedure|scan|stay))/i)) {
    const returnValue = formattedValue.split(' ').map((part) => formatDollarAmount(part))

    return returnValue.join(' ')
  }

  if (formattedValue.match(/^\d{2}$/i)) {
    return `${formattedValue}%`
  }

  if (formattedValue.match(/^\d{2}\s(?!visit|item|exam|script|month|day|procedure)/i)) {
    const splitString = formattedValue.split(' ')
    const digits = splitString[0]
    splitString.shift()

    return trim(`${digits}% ${splitString.join(' ')}`)
  }

  return formattedValue
}

function formatDollarAmount(value) {
  if (!value.match(/^\$?\d{3,}$/i)) {
    return value
  }

  const digits = Number(value.replace(/[^\d.]/g, ''))
  return digits.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
}
