import { combineReducers } from 'redux'

import CensusReducer from './CensusReducer'
import AllCensusesReducer from './AllCensusesReducer'
import ZipCountiesReducer from './ZipCountiesReducer'
import ScenariosAudienceReducer from './ScenariosAudienceReducer'
import PlansReducer from './PlansReducer'
import PlansCensusReducer from './PlansCensusReducer'
import ScenariosReducer from './ScenariosReducer'

export default combineReducers({
  census: CensusReducer,
  allCensuses: AllCensusesReducer,
  zipCounties: ZipCountiesReducer,
  scenariosAudience: ScenariosAudienceReducer,
  plans: PlansReducer,
  censusPlans: PlansCensusReducer,
  scenarios: ScenariosReducer
})
