import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/VisionBenefitsSets'
import { createFlashMessage } from '../../actions/FlashMessages'
import BenefitsSetEdit from '../BenefitsSet/BenefitsSetEdit'
import VisionBenefitsSetForm from './VisionBenefitsSetForm'
import issuerActions from '../../actions/Issuers'
import historyActions from '../../actions/BenefitsSetFieldHistories'

const mapStateToProps = (state) => {
  const slice = state.visionBenefitsSets.update
  const showSlice = state.visionBenefitsSets.show
  const remoteOptionsSelectStates = { issuers: state.issuers.index }
  const configuration = state.login.session.configuration.vision_benefits
  const historySlice = state.benefitsSetFieldHistory.index

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,
    requestComplete: slice.requestComplete,
    configuration,
    remoteOptionsSelectStates,
    benefitsSetFieldHistory: historySlice.records,
    benefitsSetForm: VisionBenefitsSetForm,
    benefitsSetUrl: 'vision_benefits',
    productLine: 'Vision'
  }
}

const mapDispatchToProps = (dispatch) => ({
  update: bindActionCreators(actions.update.main, dispatch),
  show: bindActionCreators(actions.show.main, dispatch),
  reset: bindActionCreators(actions.update.reset, dispatch),
  showReset: bindActionCreators(actions.show.reset, dispatch),
  history: bindActionCreators(historyActions.index.main, dispatch),
  resetHistory: bindActionCreators(historyActions.index.reset, dispatch),

  remoteOptionsSelectActions: {
    issuersIndex: { main: bindActionCreators(issuerActions.index.main, dispatch),
      reset: bindActionCreators(issuerActions.index.reset, dispatch) }
  },

  createFlashMessage
})

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetEdit)
