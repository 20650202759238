import { toPairs, isUndefined, omit, cloneDeep, get } from 'lodash'

import benefitGroups from './lifeBenefitGroups.json'

function displayJSON(value) {
  return value ? JSON.stringify(value, null, 2).replace(/\\/g, '') : ''
}

const fields = {
  'Plan Info': {
    metadatum: {
      name: {
        label: 'Name',
        never_hide: true,
        display_on_cvt: true
      },
      audience: {
        label: 'Audience',
        type: 'select',
        never_hide: true,
        options: []
      },
      effective_date: {
        label: 'Effective Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      expiration_date: {
        label: 'Expiration Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      issuer_id: {
        label: 'Issuer',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'issuersIndex.main',
        reset: 'issuersIndex.reset',
        recordPath: 'issuers',
        existingRecordPath: 'issuer',
        recordLabel: ['issuer_id', 'name', 'state_code'],
        searchType: 'search_term',
        filterOptions: false,
        display: (value) => {
          return get(value, 'issuer.name', '') || ''
        }
      },
      howie_service_area_id: {
        label: 'Service Area',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'howieServiceAreasIndex.main',
        reset: 'howieServiceAreasIndex.reset',
        recordPath: 'howie_service_areas',
        existingRecordPath: 'howie_service_area',
        recordLabel: ['name', 'external_key', 'issuer_id', 'year'],
        searchType: 'search_term',
        filterOptions: false,
        filterFields: [
          { data_key: 'audience', filter_key: 'audience', required: true }
        ],
        display: (value) => {
          return get(value, 'howie_service_area.external_key', '') || get(value, 'service_area_external_key', '')
        },
        alternateKey: 'service_area_external_key',
        bulkEditKey: 'service_area_external_key'
      },
      source: {
        label: 'Data Source',
        type: 'select',
        never_hide: true,
        options: [

        ]
      },
      privileged_data: {
        label: 'Privileged Data',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      voluntary: {
        label: 'Voluntary',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      insured_benefits: {
        label: 'Insured Benefits',
        type: 'jsonField',
        never_hide: true,
        display_on_cvt: true,
        display: (value) => {
          return displayJSON(value.insured_benefits)
        }
      },
      guaranteed_issue_bands: {
        label: 'Guaranteed Issue Bands',
        type: 'jsonField',
        never_hide: true,
        display: (value) => {
          return displayJSON(value.guaranteed_issue_bands)
        }
      },
      convertible_after_termination: {
        label: 'Convertible After Termination',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      disabled_premium_waiver: {
        label: 'Disabled Premium Waiver',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      accelerated_life_benefit: {
        label: 'Accelerated Life Benefit',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      premium_increase: {
        label: 'Premium Increase',
        never_hide: true
      },
      benefit_reduction_schedules: {
        label: 'Benefit Reduction Schedules',
        type: 'jsonField',
        never_hide: true,
        display: (value) => {
          return displayJSON(value.benefit_reduction_schedules)
        }
      },
      accidental_death_dismemberment_included: {
        label: 'Accidental Death Dismemberment Included',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      accidental_death_dismemberment_covered_members: {
        label: 'Accidental Death Dismemberment Covered Members',
        type: 'jsonField',
        never_hide: true,
        display: (value) => {
          return displayJSON(value.accidental_death_dismemberment_covered_members)
        }
      }
    },
    plan_external_ids: {
      label: 'External IDs',
      foreign_key: 'plan_external_id_id',
      options: () => {
        return {
          contract_id: 'Contract ID', issuer_internal_id: 'Issuer-Internal ID'
        }
      },
      never_hide: true,
      type: 'plan_external_ids',
      hidden: () => {
        return false
      },
      display: (value) => {
        if (isUndefined(value)) {
          return ''
        }

        let typeLabel
        switch (value.external_id_type) {
        case 'issuer_internal_id':
          typeLabel = 'Issuer-Internal ID'
          break
        case 'contract_id':
          typeLabel = 'Contract ID'
          break
        default:
          typeLabel = ''
          break
        }

        return `${typeLabel}: ${value.external_id}`
      }
    }
  },
  Benefits: {
    benefits: {
      name: 'benefits',
      foreign_key: 'benefit_id',
      never_hide: true,
      type: 'tiered_field',
      label: 'Benefits',
      fields: benefitGroups
    }
  }
}

export const fieldPairs = toPairs(fields)
export const benefitLabels = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  // eslint-disable-next-line no-param-reassign
  fieldset.fields.forEach((f) => { memo[f.name] = f.label })
  return memo
}, {})

export const benefitNames = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  fieldset.fields.forEach((f) => { memo.push(f.name) })
  return memo
}, [])

export const benefitGroupNames = fields.Benefits.benefits.fields.map((b) => b.name)
export const infoFields = omit(fields['Plan Info'], ['plan_external_ids'])

export const externalIdField = fields['Plan Info'].plan_external_ids

const metadatumFieldsDiffSetTemp = cloneDeep(infoFields.metadatum)

metadatumFieldsDiffSetTemp.issuer_name = {
  label: 'Issuer Name',
  never_hide: true
}

metadatumFieldsDiffSetTemp.issuer_identifier = {
  label: 'Issuer Id',
  never_hide: true
}

delete metadatumFieldsDiffSetTemp.issuer_id

export const metadatumFields = infoFields.metadatum
export const metadatumFieldsDiffSet = metadatumFieldsDiffSetTemp

export default fields
