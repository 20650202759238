import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, upperFirst, isUndefined } from 'lodash'

export default class TextAreaField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    cols: PropTypes.number,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    inputCssClass: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  get fieldName() {
    return this.props.fieldName || this.props.name
  }

  handleChange(event) {
    if (!this.props.handleChange) {
      return
    }

    this.props.handleChange(this.fieldName, event.target.value)
  }

  handleBlur(event) {
    if (!this.props.handleBlur) {
      return
    }

    this.props.handleBlur(this.fieldName)
  }

  render() {
    const {
      name,
      errorMessage,
      inputCssClass,
      disabled
    } = this.props

    const value = this.props.value || ''
    const placeholder = this.props.placeholder || ''
    const rows = this.props.rows || 4
    const cols = this.props.cols || 50
    const classNames = ['field']
    let inputCssClassValue = inputCssClass

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    if (isUndefined(inputCssClassValue)) {
      inputCssClassValue = 'pure-input-1'
    }

    return (
      <div className={classNames.join(' ')}>
        <textarea
          className={inputCssClassValue}
          title={name}
          name={name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={value}
          rows={rows}
          cols={cols}
          disabled={disabled}
          placeholder={placeholder}
        />
        {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
      </div>
    )
  }
}
