import React from 'react'
import PropTypes from 'prop-types'
import { IoIosClose, IoIosCloseCircle, IoMdOpen } from 'react-icons/io'
import Component from '../common/Component'
import Modal from '../common/Modal'

export default class PublishErrorModal extends Component {
  static propTypes = {
    record: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = { modal: false }
  }

  get publishError() {
    return this.props.record.publish_error
  }

  handleClick = (event) => {
    this.setState({ modal: true })
  }

  handleClose = () => {
    this.setState({ modal: false })
  }

  renderModal = () => {
    return (
      <Modal
        className="publish-error-modal"
        handleClose={this.handleClose}
      >
        <div className="publish-error-modal-msg">
          <p>{this.publishError}</p>
        </div>

        <button
          className="cancel-button"
          type="button"
          onClick={this.handleClose}
        >
          <IoIosClose />
        </button>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <div className="approval-state">
          <i title="FailedToPublish"><IoIosCloseCircle size={30} className="error" /></i>

          <strong title="failed_to_publish" className="error">
            <button
              key="publish-error"
              title="Failed to publish"
              className="modal-button"
              onClick={this.handleClick}
            >
              Failed to publish
              <IoMdOpen size={20} className="error" />
            </button>
          </strong>
        </div>

        {this.state.modal && this.renderModal()}
      </>
    )
  }
}
