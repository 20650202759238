import React from 'react'
import { clone } from 'lodash'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import ConnectedFileUploaderComponent from '../common/ConnectedFileUploaderComponent'

export default class RateFactorCreate extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  handleChange = (name, value) => {
    const newValue = clone(this.props.value)

    newValue[name] = value

    this.props.handleChange(this.props.name, newValue)
  }

  render() {
    return (
      <fieldset className="pure-group">
        <h3>{this.props.name}</h3>
        <ConnectedFileUploaderComponent
          name="blob_id"
          handleChange={this.handleChange}
          blobId={this.props.value.blob_id}
          acceptedMimeTypes="text/csv"
        />
      </fieldset>
    )
  }
}
