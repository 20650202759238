import React from 'react'
import { get, uniqBy } from 'lodash'

const BrandReportsDisplay = (record, column) => {
  const brands = uniqBy(get(record, column.key, []), (issuer) => { return issuer.issuer_label })
  const rows = brands.map((issuer, i) => (
    <div key={i}>{issuer.issuer_label}</div>
  ))

  return (
    <div>
      {rows}
    </div>
  )
}

export default BrandReportsDisplay
