import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/Users'
import connectIndex from '../../lib/connectIndex'
import BrandReportsDisplay from '../common/BrandReportsDisplay'

export class UserIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    records: PropTypes.array,
    flashMessages: PropTypes.array.isRequired
  }

  static columns = [
    { key: 'username', label: 'Username' },
    { key: 'email', label: 'Email' },
    { key: 'user_issuers', label: 'Carrier Brands', display: BrandReportsDisplay }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/users"
          name="User"
          pagination={this.props.pagination}
          columns={UserIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          includeSearch={false}
        />
      </div>
    )
  }
}

export default connectIndex(UserIndex, 'users', actions)
