import client from '../../client'
import routerHistory from '../../routerHistory'
import createFlashMessage from '../FlashMessages/createFlashMessage'

import {
  REQUEST_SESSION,
  RECEIVE_SESSION
} from './consts'

function requestSession(redirectPath) {
  return { type: REQUEST_SESSION, redirectPath }
}

function receiveSession(session) {
  return {
    type: RECEIVE_SESSION, nonce: session.nonce, configuration: session.configuration, user: session.user
  }
}

function redirectPathForUser(user, path) {
  switch (user.user_type) {
  case 'carrier':
    if (path === '' || path === '/') {
      return '/'
    }

    return path
  default:
    return path
  }
}

export default function createSession(params, errorAction, redirectPath) {
  return (dispatch) => {
    dispatch(requestSession(redirectPath))

    client.post('/sessions', params)
      .then((response) => response.data)
      .then((json) => {
        dispatch(receiveSession(json))
        dispatch(createFlashMessage('You have been successfully logged in.', { tag: 'general' }))
        routerHistory.push(redirectPathForUser(json.user, redirectPath))
      })
      .catch((error) => dispatch(errorAction(error.response.data.error)))
  }
}
