import React from 'react'
import actions from '../../actions/IssuerDisclaimers'
import Component from '../common/Component'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'

// eslint-disable jsx-props-no-spreading
export class IssuerDisclaimerEdit extends Component {
  get id() {
    return this.props.match.params.id
  }

  render() {
    const { configuration } = this.props

    return (
      <EditForm
        {...this.props}
        id={this.id}
        redirectTo={`/issuer_disclaimers/${this.id}`}
        deleteRedirect="/issuer_disclaimers"
      >
        <IssuerRemoteOptionsSelectField
          name="issuer_id"
          label="Issuer"
          errorKey="issuer"
        />
        <LabeledTextField
          name="active_from"
          label="Active From"
          type="date"
        />
        <LabeledTextField
          name="active_until"
          label="Active Until"
          type="date"
        />
        <SelectField
          name="line_of_coverage"
          label="Line of Coverage"
          options={configuration.line_of_coverage}
        />
        <SelectField
          name="market"
          label="Market"
          options={configuration.market}
        />
        <LabeledTextField name="disclaimer" label="Disclaimer" type="textarea" />

      </EditForm>
    )
  }
}

IssuerDisclaimerEdit.propTypes = defaultPropTypes
export default connectEditForm(IssuerDisclaimerEdit, 'Issuer Disclaimer', 'issuer_disclaimers', actions)
