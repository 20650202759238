import ReducerGenerator from '../../lib/ReducerGenerator'

const generator = new ReducerGenerator(
  'plan_product_histories',
  '/plan_product_histories',
  'histories'
)
const reducer = generator.generate()

export default reducer
