import ReducerGenerator from '../../lib/ReducerGenerator'

const generator = new ReducerGenerator(
  'benefits_set_field_source_validation',
  '/benefits_set_field_source_validations',
  'validations'
)

const reducer = generator.generate()

export default reducer
