import React from 'react'
import PropTypes from 'prop-types'
import { isNil, isEmpty } from 'lodash'
import Component from './Component'

export default class SimpleDiffCellRenderer extends Component {
  static propTypes = {
    value: PropTypes.any,
    formatValue: PropTypes.func,
    valueFormatted: PropTypes.any
  }

  get hasChangedValue() {
    return this.sanitizeValue(this.value) !== this.sanitizeValue(this.initialValue)
  }

  get initialValue() {
    return this.props.value.old
  }

  get value() {
    return this.props.value.new
  }

  get initialValueFormatted() {
    if (isNil(this.initialValue)) {
      return this.initialValue
    }

    return this.formatValue(this.initialValue)
  }

  get valueFormatted() {
    if (isNil(this.value)) {
      return this.value
    }

    return this.formatValue(this.value)
  }

  get diffView() {
    return (
      <div className="grid-cell-diff">
        {this.initialValueDiv()}
        {this.currentValueDiv()}
      </div>
    )
  }

  get standardView() {
    return (
      <div className="grid-cell-no-diff">
        {this.valueFormatted}
      </div>
    )
  }

  initialValueDiv = () => {
    if (this.initialValueFormatted) {
      let className = 'initial'
      if (isEmpty(this.valueFormatted)) {
        className += ' nochange'
      }
      return (
        <div className={className}>
          {this.initialValueFormatted}
        </div>
      )
    }
    return null
  }

  currentValueDiv = () => {
    if (this.valueFormatted) {
      const className = 'current'
      return (
        <div className={className}>
          {this.valueFormatted}
        </div>
      )
    }
    return null
  }

  formatValue = (value) => {
    return isNil(this.props.valueFormatted)
      ? value.toString()
      : this.props.formatValue(value)
  }

  sanitizeValue(value) {
    return isNil(value) ? '' : value.toString()
  }

  render() {
    if (this.hasChangedValue) {
      return this.diffView
    }

    return this.standardView
  }
}

