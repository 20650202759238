import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Component from './Component'
import RemoteOptionsSelectField from './RemoteOptionsSelectField'
import documentActions from '../../actions/Documents'

const mapStateToProps = (state) => {
  return {
    records: state.documents.index.records,
    requestInProgress: state.documents.index.requestInProgress
  }
}

const mapDispatchToProps = {
  recordsRequest: documentActions.index.main,
  reset: documentActions.index.reset
}

const DocumentRemoteOptionsSelectField = connect(mapStateToProps, mapDispatchToProps)(RemoteOptionsSelectField)

export default class DocumentSelectField extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    recordLabel: PropTypes.array,
    handleChange: PropTypes.func,
    filterFields: PropTypes.object,
    errorMessage: PropTypes.string,
    recordValue: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ])
  }

  static defaultProps = {
    placeholder: 'Type at least 2 characters to search',
    name: 'document_id',
    recordValue: 'id',
    label: 'Document',
    recordLabel: ['vericred_id', 'name', 'state_code', 'source']
  }

  render() {
    return (
      <DocumentRemoteOptionsSelectField
        placeholder={this.props.placeholder}
        name={this.props.name}
        label={this.props.label}
        recordLabel={this.props.recordLabel}
        handleChange={this.props.handleChange}
        filterFields={this.props.filterFields}
        recordValue={this.props.recordValue}
        searchType="search_term"
        errorMessage={this.props.errorMessage}
        value={this.props.value}
      />
    )
  }
}
