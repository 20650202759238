import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { IoIosClose } from 'react-icons/io'
import Component from '../common/Component'
import actions from '../../actions/PlanProductHistories'
import ipaActions from '../../actions/IssuerPeriodAudiences'
import Modal from '../common/Modal'

export class GeneratePlanHistoryButton extends Component {
  static propTypes = {
    issuerPeriodAudience: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    ipaShow: PropTypes.func.isRequired,
    ipaReset: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      pollTimeout: null,
      generalError: null,
      attributes: { csv_generated_at: false },
      generatingCSV: false
    }
  }

  componentDidMount() {
    this.props.ipaShow(this.props.issuerPeriodAudience).then((response) => {
      this.setState({ attributes: response.attributes })
    })
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.ipaReset()
    clearTimeout(this.state.pollTimeout)
    this.setState({ pollTimeout: null })
  }

  handleClick = (event) => {
    this.props.reset()
    this.setState({ modal: true, generalError: null })
  }

  handleClose = (event) => {
    this.setState({ modal: false, generalError: null })
  }

  handlePlanHistory = async () => {
    this.setState({ generatingCSV: true })
    const response = await this.props.create({ issuer_period_audience_id: this.props.issuerPeriodAudience })

    if (!response.generalError) {
      const pollTimeout = setTimeout(
        async () => {
          await this.fetchIPA()
          if (this.state.attributes.csv_pending === 'No') {
            this.setState({ pollTimeout: null, generatingCSV: false })
          }
        }, 3000
      )
      this.setState({ pollTimeout })
    } else {
      this.setState({ generalError: response.generalError })
    }
  }

  fetchIPA = () => {
    this.props.ipaShow(this.props.issuerPeriodAudience).then((response) => {
      this.setState({ attributes: response.attributes })
    })
  }

  displayError = () => {
    return <div className="howie_error">{this.state.generalError}</div>
  }

  buttonText = () => {
    if (this.state.generatingCSV) { return 'Generating Plan History Report...' }

    return 'Generate New Plan History Report'
  }

  renderFile = () => {
    let text

    if (this.state.attributes.csv_generated_at) {
      text = <p><strong>Latest generated report:</strong></p>
    } else {
      text = <p><strong>No report has been generated yet.</strong></p>
    }

    return (
      <div>
        {text}
        {this.state.attributes.csv_generated_at
          && (
            <div>
              <p>
                <a href={this.state.attributes.csv_url} target="_blank" rel="noopener noreferrer">
                  {this.state.attributes.csv_filename}
                </a>
              </p>
              <p>{moment(this.state.attributes.csv_generated_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
            </div>
          )}
      </div>
    )
  }

  renderModal = () => {
    return (
      <Modal
        className="history-modal"
        handleClose={this.handleClose}
      >
        {this.state.generalError && this.displayError()}

        {this.renderFile()}

        <div className="buffer-top">
          <p>
            <strong>Click below to generate a new report for the {this.props.total} plans.</strong>
          </p>
        </div>

        <button
          id="generate-plan-product-history"
          className="select-button pure-button pure-input-1 pure-button-primary action-ipa"
          style={{ marginTop: '1rem' }}
          type="button"
          onClick={this.handlePlanHistory}
          disabled={this.state.generatingCSV}
        >
          {this.buttonText()}
        </button>

        <button
          className="cancel-button"
          type="button"
          onClick={this.handleClose}
        >
          <IoIosClose />
        </button>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <button
          key="plan-product-history"
          title="Generate Plan History"
          className="button quiet-button small"
          onClick={this.handleClick}
        >
          Plan History Changes
        </button>

        {this.state.modal && this.renderModal()}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,
  ipaShow: ipaActions.show.main,
  ipaReset: ipaActions.show.reset
}, dispatch)

export default connect(null, mapDispatchToProps)(GeneratePlanHistoryButton)
