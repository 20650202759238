import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import Component from '../common/Component'
import SimpleDiffCellRenderer from '../common/SimpleDiffCellRenderer'

export default class DiffCellRenderer extends Component {
  static propTypes = {
    value: PropTypes.any,
    colDef: PropTypes.object.isRequired,
    agGridReact: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
    formatValue: PropTypes.func,
    valueFormatted: PropTypes.any
  }

  get initialValue() {
    const initialData = this.props.agGridReact.props.initialData

    const row = initialData[this.props.node.id]

    if (isNil(row)) {
      return null
    }

    return row[this.props.colDef.field]
  }

  get value() {
    return { new: this.props.value, old: this.initialValue }
  }

  render() {
    return (
      <SimpleDiffCellRenderer
        value={this.value}
        formatValue={this.props.formatValue}
        valueFormatted={this.props.valueFormatted}
      />
    )
  }
}

