import React from 'react'
import PropTypes from 'prop-types'
import { IoIosClock } from 'react-icons/io'
import { isEmpty, orderBy } from 'lodash'
import Component from '../common/Component'
import DateTimeComponent from '../common/DateTimeComponent'

export default class ReviewDisplayField extends Component {
  static propTypes = {
    rejections: PropTypes.array.isRequired
  }

  static renderedProps = [
    'rejections'
  ]

  get rejections() {
    if (isEmpty(this.props.rejections)) {
      return []
    }

    return orderBy(this.props.rejections, ['created_at'])
  }

  get rejectionLines() {
    return this.rejections.map(this.rejectionLine)
  }

  get moreRejectionsButton() {
    if (this.props.rejections.length <= 1) {
      return null
    }

    return (
      <button type="button" className="text-button" onClick={this.toggleMoreRejections}>
        <IoIosClock title="All Rejections" size={20} />
      </button>
    )
  }

  rejectionLine(rejection) {
    return (
      <div className="rejection-line" key={`rejection-${rejection.review_id}`}>
        <div>
          <DateTimeComponent value={rejection.created_at} />
        </div>
        <div>
          {rejection.review || 'Rejected'}
        </div>
      </div>
    )
  }

  render() {
    if (isEmpty(this.props.rejections)) {
      return null
    }

    return (
      <div className="value-field-review-display">
        {this.rejectionLines}
      </div>
    )
  }
}
