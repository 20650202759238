import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { cloneDeep, isEmpty, isNil, get, pick, some, times } from 'lodash'
import { Link } from 'react-router-dom'
import { IoIosDocument } from 'react-icons/io'
import pluralize from 'pluralize'
import IndexHeaderCellComponent from './IndexHeaderCellComponent'
import DateTimeComponent from './DateTimeComponent'
import SearchField from './SearchField'
import FlashMessage from './FlashMessage'
import Component from './Component'
import Pagination from './Pagination'
import routerHistory from '../../routerHistory'

export default class IndexComponent extends Component {
  static propTypes = {
    fetchRecords: PropTypes.func.isRequired,
    resetRecords: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    pagination: PropTypes.object,
    path: PropTypes.string,
    records: PropTypes.array,
    columns: PropTypes.array.isRequired,
    flashMessages: PropTypes.array,
    name: PropTypes.string.isRequired,
    filterValues: PropTypes.object,
    filterActions: PropTypes.object,
    searchPlaceholder: PropTypes.string,
    actions: PropTypes.array,
    includeNewRecordLink: PropTypes.bool,
    newRecordLinkTitle: PropTypes.string,
    includeBulkActionLink: PropTypes.bool,
    includeSearch: PropTypes.bool,
    searchColumns: PropTypes.array,
    includeHeader: PropTypes.bool,
    additionalQueryParams: PropTypes.array,
    configuration: PropTypes.object,
    filterable: PropTypes.bool,
    bulkActionName: PropTypes.string,
    actionLinks: PropTypes.array,
    viewSelection: PropTypes.object
  }

  static defaultEditLinkDisplay = (record, path) => {
    return <Link title="Edit" to={`${path}/${record.id}/edit`}>Edit</Link>
  }

  static dateTimeDisplay = (record, column) => {
    const value = get(record, column.key)

    if (isNil(value)) {
      return null
    }

    return <DateTimeComponent value={value} />
  }

  static documentDisplay = (record, column) => {
    const value = get(record, column.key)

    if (isNil(value)) {
      return null
    }

    return (
      <a
        href={value.public_url}
        key="document"
        id={`${record.id}-document`}
        title={`${value.source}`}
        target="_blank"
        rel="noopener noreferrer"
      > <IoIosDocument /> {`${value.source} | ID: ${value.vericred_id}`}
      </a>
    )
  }

  static booleanDisplay = (record, column) => {
    if (get(record, column.key) === true) return 'Yes'
    return 'No'
  }

  static defaultProps = {
    additionalQueryParams: [],
    includeNewRecordLink: true,
    includeSearch: true,
    includeHeader: true,
    includeBulkActionLink: false,
    bulkActionName: 'Edit',
    pagination: {
      currentPage: 1,
      perPage: 25,
      count: 1
    },
    searchPlaceholder: '',
    actions: [
      { key: 'edit', display: IndexComponent.defaultEditLinkDisplay }
    ],
    flashMessages: [],
    path: '',
    searchColumns: []
  }

  constructor(props) {
    super(props)

    this.assignQueryParams()

    this.state = {
      searchTerm: this.queryParams.search_term
    }
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.assignQueryParams()
      this.props.resetRecords()
      this.fetchRecords()
    }
  }

  componentWillUnmount() {
    this.props.resetRecords()
  }

  get rows() {
    const {
      records,
      flashMessages
    } = this.props

    const idsToHighlight = flashMessages.map((m) => get(m, 'data.id'))

    return records.map((record) => {
      let className = ''

      if (idsToHighlight.includes(record.id)) {
        className = 'highlight'
      }

      if (get(record, 'deleted?')) {
        className = 'deleted-record'
      }

      return (
        <tr key={`row-${record.id}`} className={className}>
          {this.entitledColumns.map((column) => this.valueCell(record, column))}
          {this.actionsForRecord(record)}
        </tr>
      )
    })
  }

  get entitledColumns() {
    const { records } = this.props

    return this.props.columns.filter((a) => {
      if (isNil(a.entitlementKey)) {
        return true
      }

      return some(records, (record) => get(record, `meta.${a.entitlementKey}.access`, false))
    })
  }

  get actionHeaderColumns() {
    return times(this.entitledActions.length).map((index) => (
      <td key={`header-cell-action-${index}`} className="actions-header-cell" />
    ))
  }

  get tableHeader() {
    return (
      <thead>
        <tr>
          {this.entitledColumns.map((column) => this.headerCell(column))}
          {this.actionHeaderColumns}
        </tr>
      </thead>
    )
  }

  get navLinks() {
    if (!this.includeNavLinks) {
      return null
    }

    const navLinks = [
      this.bulkActionLink,
      this.newRecordLink
    ]

    navLinks.push(this.props.actionLinks)

    return navLinks
  }

  get includeNavLinks() {
    const {
      includeNewRecordLink,
      includeBulkActionLink,
      actionLinks
    } = this.props

    return includeNewRecordLink || includeBulkActionLink || !isNil(actionLinks)
  }

  get includeViewSelection() {
    const { viewSelection } = this.props
    return !isNil(viewSelection)
  }

  get bulkActionLink() {
    if (!this.props.includeBulkActionLink) {
      return null
    }

    const title = `Bulk ${this.props.bulkActionName} (${this.props.pagination.total})`

    const query = cloneDeep(this.queryParams)
    delete query.page

    return (
      <Link
        key="bulk_edit"
        id="bulk_edit"
        title={title}
        className="button quiet-button small"
        to={{
          pathname: `${this.props.path}/bulk/identifiers`,
          search: qs.stringify(query)
        }}
      > {title}
      </Link>
    )
  }

  get newRecordLink() {
    if (!this.props.includeNewRecordLink) {
      return null
    }

    const newRecordLinkTitle = this.props.newRecordLinkTitle || `New ${this.props.name}`

    return (
      <Link
        key="new_record"
        title={`${newRecordLinkTitle}`}
        className="button quiet-button small"
        to={`${this.props.path}/new`}
      > {newRecordLinkTitle}
      </Link>
    )
  }

  get resetFiltersButton() {
    if (!this.props.filterable) {
      return null
    }

    if (isEmpty(get(this.props, 'location.search'))) {
      return null
    }

    return (
      <Link
        title="Reset Filters"
        className="button small"
        to={this.props.path}
      > Reset Filters
      </Link>
    )
  }

  get header() {
    const {
      includeHeader,
      includeSearch
    } = this.props

    if (!includeHeader) {
      return null
    }

    return (
      <header className="form-header">
        <div>
          <div className="form-header-title">
            <h2>{pluralize(this.props.name)}</h2>
          </div>
          {!includeSearch && this.includeNavLinks
            && (
              <nav>
                {this.navLinks}
              </nav>
            )}
        </div>
        {includeSearch
          && (
            <div className="form-header-actions">
              {includeSearch
              && (
                <form className="search" onSubmit={this.handleSearchSubmit}>
                  <SearchField
                    name="search_term"
                    handleChange={this.handleSearchChange}
                    handleClear={this.handleSearchClear}
                    title="Search"
                    value={this.state.searchTerm}
                    label="Search"
                    placeholder={this.props.searchPlaceholder}
                    buttonDisabled={this.queryParams.search_term === this.state.searchTerm}
                    searchColumns={this.props.searchColumns}
                  />
                </form>
              )}
              {this.includeViewSelection
                   && (

                     this.props.viewSelection

                   )}
              <nav>
                {this.navLinks}
              </nav>
            </div>
          )}
        {this.props.children}
        <div>{this.resetFiltersButton}</div>
      </header>
    )
  }

  get content() {
    const {
      records,
      flashMessages
    } = this.props

    if (isNil(records)) {
      return null
    }

    return (
      <div>
        {this.header}
        <FlashMessage messages={flashMessages} tag="index" />
        <div className="full-width-table-container">
          <table className="pure-table full-width-table index-table">
            {this.tableHeader}
            <tbody>
              {this.rows}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  get filterableParams() {
    return this.props.columns.filter((c) => c.filterable).map((c) => c.remoteKey || c.key)
  }

  get entitledActions() {
    const { records } = this.props

    return this.props.actions.filter((a) => {
      if (isNil(a.entitlementKey)) {
        return true
      }

      return some(records, (record) => get(record, `meta.${a.entitlementKey}.access`, false))
    })
  }

  actionsForRecord = (record) => {
    return this.entitledActions.map((action) => (
      <td key={`row-cell-action-${action.key}`} className="actions-cell">
        {action.display(record, this.props.path)}
      </td>
    ))
  }

  actionDisplay = (action, record) => {
    let entitled

    if (isNil(action.entitlementKey)) {
      entitled = true
    } else {
      entitled = get(record, `meta.${action.entitlementKey}.access`, false)
    }

    if (!entitled) {
      return null
    }

    return action.display(record, this.props.path)
  }

  defaultDisplay = (record, column) => {
    return get(record, column.key)
  }

  valueCell = (record, column) => {
    let displayValue
    if (!isNil(column.display)) {
      displayValue = column.display(record, column, this.props.configuration)
    } else {
      displayValue = this.defaultDisplay(record, column)
    }

    const safeKey = `${column.key}`.toLowerCase().replace(/[^a-z]+/g, '-')

    return <td key={`row-cell-${safeKey}`} className={`cell-${safeKey}`}>{displayValue}</td>
  }

  handleSearchClear = (name) => {
    this.setState({ searchTerm: null }, this.submitSearch)
  }

  handleSearchChange = (name, value) => {
    this.setState({ searchTerm: value })
  }

  handleSearchSubmit = (event) => {
    event.preventDefault()

    this.submitSearch()
  }

  submitSearch = () => {
    const query = cloneDeep(this.queryParams)

    delete query.page

    if (isEmpty(this.state.searchTerm)) {
      delete query.search_term
    } else {
      query.search_term = this.state.searchTerm
    }

    const search = { ...query }

    const push = {
      pathname: this.props.path,
      search: qs.stringify(search)
    }

    routerHistory.push(push)
  }

  headerCell = (column) => {
    return (
      <IndexHeaderCellComponent
        key={column.key}
        label={column.label}
        path={this.props.path}
        queryParams={this.queryParams}
        columnKey={column.key}
        sortable={column.sortable}
        filterable={column.filterable}
        remoteKey={column.remoteKey || column.key}
        filterValues={get(this.props.filterValues, column.key)}
        filterActions={get(this.props.filterActions, column.key)}
        recordValue={column.recordValue}
        recordLabel={column.recordLabel}
        optionsEnum={column.optionsEnum}
      />
    )
  }

  fetchRecords = () => {
    const allowedParams = ['page', 'sort', 'search_term']
      .concat(this.filterableParams)
      .concat(this.props.additionalQueryParams)

    const params = pick(this.queryParams, allowedParams)
    this.props.fetchRecords(params)
  }

  assignQueryParams() {
    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    return (
      <Pagination
        pagination={this.props.pagination}
        path={this.props.path}
        queryParams={cloneDeep(this.queryParams)}
      >
        {this.content}
      </Pagination>
    )
  }
}
