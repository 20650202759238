import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { Link, Redirect } from 'react-router-dom'
import { get, isNil, includes, remove, isEmpty, map, compact, intersection, uniqBy } from 'lodash'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import exportActions from '../../actions/BenefitsSetsExports'
import CheckboxField from '../common/CheckboxField'
import BulkPublishLink from './BulkPublishLink'
import RatesPublishLink from './RatesPublishLink'

import connectIndex from '../../lib/connectIndex'

const BULK_PUBLISHABLE_AUDIENCES = ['Large Group Custom', 'Level Funded']

export class BenefitsSetIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    benefitsSetPath: PropTypes.string.isRequired,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    columnsList: PropTypes.array,
    ipaViewColumnsList: PropTypes.array,
    pagination: PropTypes.object,
    records: PropTypes.array,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    reviewReturnPath: PropTypes.string,
    name: PropTypes.string,
    includeDiffSetLink: PropTypes.bool,
    includeNewRecordLink: PropTypes.bool,
    includeBulkEditLink: PropTypes.bool,
    includeBulkPublishLink: PropTypes.bool,
    includeSearch: PropTypes.bool,
    searchColumns: PropTypes.array,
    includeSelectAll: PropTypes.bool,
    headers: PropTypes.object,
    sortable: PropTypes.bool,
    configuration: PropTypes.object,
    filterable: PropTypes.bool,
    createBenefitsSetExport: PropTypes.func,
    benefitsSetExport: PropTypes.object,
    resetCreateBenefitsSetExport: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    actionLinks: PropTypes.array,
    productLine: PropTypes.string.isRequired
  }

  static defaultProps = {
    name: 'Benefits Set',
    sortable: true,
    filterable: true,
    ipaViewColumnsList: [],
    includeBulkEditLink: true,
    includeDiffSetLink: false,
    includeBulkPublishLink: false,
    searchPlaceholder: 'Search by Name, HIOS ID, Vericred ID or Issuer ID',
    actionLinks: []
  }

  static showLinkDisplay = (path) => {
    return (record) => { return <Link title={record.name} to={`${path}/${record.id}`}>{record.name}</Link> }
  }

  static showRateFactorSetLinkDisplay = (record) => {
    return (
      <Link
        title={record.ancillary_rate_factor_set_name}
        to={`/ancillary_rate_factor_sets/${record.ancillary_rate_factor_set_id}`}
      >{record.ancillary_rate_factor_set_name}
      </Link>
    )
  }

  static showServiceAreaLinkDisplay = (record) => {
    return (
      <Link
        title={record.ancillary_service_area_name}
        to={`/ancillary_service_areas/${record.ancillary_service_area_id}`}
      >{record.ancillary_service_area_name}
      </Link>
    )
  }

  static stateDisplay = (record, column, config) => {
    const stateId = record[column.key]
    const state = config.states.find((s) => s.value === stateId)

    return get(state, 'name')
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedRecords: [],
      selectAllRecords: false,
      creatingDiffSet: false,
      ipaViewSelect: false
    }
  }

  componentWillUnmount() {
    this.props.benefitsSetExport && this.props.resetCreateBenefitsSetExport()
  }

  get actions() {
    return [
      { key: 'edit', display: this.editLinkDisplay, entitlementKey: 'entitlements.update' },
      { key: 'new_review', display: this.newReviewLinkDisplay, entitlementKey: 'review_entitlements.create' }
    ]
  }

  get actionLinks() {
    let list = this.props.actionLinks
    if (!isEmpty(this.props.ipaViewColumnsList)) {
      if (this.state.ipaViewSelect) {
        list = list.concat(this.publishPlansButton)
        list = list.concat(this.publishRateButton)
      }
    }

    if (this.props.includeBulkPublishLink) {
      list = list.concat(this.bulkPublishButton)
    }
    return list
  }

  get viewSelection() {
    if (isEmpty(this.props.ipaViewColumnsList)) {
      return null
    }

    return this.ipaCheckBox
  }

  get diffSetButton() {
    if (!this.props.includeDiffSetLink) {
      return null
    }

    if (isEmpty(this.state.selectedRecords)) {
      return null
    }
    return (
      <button
        className="pure-button pure-input-1 pure-button-primary"
        title="Diff Set"
        id="diff_set"
        onClick={this.handleDiffSetButton}
      >
Diff Set
      </button>
    )
  }

  get ipaCheckBox() {
    return (
      <CheckboxField
        key="ipaViewSelect"
        name="ipaViewSelect"
        label="IPA View"
        handleChange={this.handleCheckbox}
        value={this.state.ipaViewSelect}
      />
    )
  }

  get bulkPublishButton() {
    const planCount = this.selectedRecordIds.length
    const audiences = (this.queryParams.audience || [])

    if (!BULK_PUBLISHABLE_AUDIENCES.some((a) => audiences.includes(a))) {
      return null
    }

    return (
      <BulkPublishLink
        key="bulk-publish"
        planCount={planCount}
        queryParams={this.queryParams}
        selectedRecords={this.selectedRecordIds}
        productLine={this.props.productLine}
        approveAll={false}
        approvedRecords={this.approvedRecordIds}
      />
    )
  }

  get publishRateButton() {
    const allRateFactorSetIds = (this.props.records || []).filter((r) => {
      return !isNil(r.ancillary_rate_factor_set_id)
    }).map((r) => ({ id: r.ancillary_rate_factor_set_id, name: r.ancillary_rate_factor_set_name }))
    const uniqRateFactorSets = uniqBy(allRateFactorSetIds, 'id')

    return (
      <RatesPublishLink
        key="rates-publish"
        rateCount={uniqRateFactorSets.length}
        queryParams={this.queryParams}
        selectedRates={uniqRateFactorSets.map((r) => r.id)}
        rateFactorNames={uniqRateFactorSets.map((r) => r.name)}
      />
    )
  }

  get publishPlansButton() {
    const recordIds = (this.props.records || []).map((r) => r.id)
    const planCount = recordIds.length
    return (
      <BulkPublishLink
        key="bulk-publish"
        planCount={planCount}
        queryParams={this.queryParams}
        selectedRecords={recordIds}
        productLine={this.props.productLine}
        approveAll
        includeSearchTermValidation
        termValidated="issuer_id"
        planType=""
      />
    )
  }

  get approvedRecordIds() {
    return (this.props.records || []).filter((r) => r.approval_state === 'approved').map((r) => r.id)
  }

  get selectedRecordIds() {
    const publishableRecords = (this.props.records || [])
      .filter((r) => BULK_PUBLISHABLE_AUDIENCES.includes(r.audience))
      .map((r) => r.id)
    const selectedIds = (this.state.selectedRecords || []).map(Number)

    return intersection(publishableRecords, selectedIds)
  }

  get queryParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  get redirect() {
    const {
      location,
      headers
    } = this.props

    if (isNil(headers)) {
      return null
    }

    const token = headers['x-result-token']

    if (this.queryParams.result_token !== token) {
      const newQueryParams = { ...this.queryParams, result_token: token }

      const newLocation = {
        pathname: location.pathname,
        search: qs.stringify(newQueryParams)
      }

      return <Redirect replace to={newLocation} />
    }

    return null
  }

  get reviewReturnPath() {
    const {
      headers,
      reviewReturnPath
    } = this.props

    if (isNil(headers) || isNil(reviewReturnPath)) {
      return this.props.benefitsSetPath
    }

    const token = headers['x-result-token']
    return `${this.props.reviewReturnPath}?result_token=${token}`
  }

  get columns() {
    if (this.props.sortable) {
      return this.columnsList
    }

    return this.columnsList.map((c) => ({ ...c, sortable: false }))
  }

  get columnsList() {
    const columnListInUse = this.state.ipaViewSelect ? this.props.ipaViewColumnsList : this.props.columnsList
    return [
      {
        key: 'selected',
        label: this.selectedCheckboxAll,
        display: this.selectedCheckbox,
        handleChange: this.checkHandleChange,
        entitlementKey: 'export_entitlements.create'
      }
    ].concat(columnListInUse)
  }

  handleSelectAll = (_, checked) => {
    let selectedRecords = []
    if (checked) {
      selectedRecords = map(this.props.records, (record) => {
        if (record.approval_state === 'rejected' || !record.meta.export_entitlements.create.access) {
          return null
        }

        return record.id.toString()
      })
    }
    this.setState({ selectAllRecords: checked, selectedRecords: compact(selectedRecords) })
  }

  handleCheckbox = (key, value) => {
    this.setState({ [key]: value })
  }

  selectedCheckboxAll = () => {
    if (!this.props.includeSelectAll) {
      return null
    }

    return (
      <CheckboxField
        key="select-all-records"
        name="select-all-records"
        title="select"
        handleChange={this.handleSelectAll}
        value={this.state.selectAllRecords}
      />
    )
  }

  selectedCheckbox = (record, column) => {
    if (record.approval_state === 'rejected' || !record.meta.export_entitlements.create.access) {
      return null
    }

    return (
      <CheckboxField
        key={record.id.toString()}
        name={record.id.toString()}
        title="select"
        handleChange={column.handleChange}
        errorMessage=""
        value={this.displayCheckBox(record)}
      />
    )
  }

  displayCheckBox = (record) => {
    return includes(this.state.selectedRecords, record.id.toString())
  }

  checkHandleChange = (id, checked) => {
    this.setState((prevState) => {
      const newState = prevState.selectedRecords

      if (checked) {
        if (!includes(newState, id)) {
          newState.push(id)
        }
      } else {
        remove(newState, (item) => {
          return item === id
        })
      }
      return { selectedRecords: newState }
    })
  }

  editLinkDisplay = (record) => {
    return <Link title="Edit" to={`${this.props.benefitsSetPath}/${record.id}/edit`}>Edit</Link>
  }

  newReviewLinkDisplay = (record) => {
    const location = {
      pathname: `${this.props.benefitsSetPath}/${record.id}/reviews/new`
    }

    location.search = qs.stringify({
      return_path: this.reviewReturnPath
    })

    return <Link title="New Review" to={location}>New Review</Link>
  }

  handleDiffSetButton = () => {
    this.setState({ creatingDiffSet: true })
    this.props.createBenefitsSetExport({ benefits_sets: this.state.selectedRecords })
  }

  render() {
    const { benefitsSetExport, benefitsSetPath } = this.props

    if (benefitsSetExport && benefitsSetExport.created && this.state.creatingDiffSet) {
      return (
        <Redirect
          push
          to={{ pathname: `${benefitsSetPath}_exports/${benefitsSetExport.id}`,
            state: { from: this.props.location } }}
        />
      )
    }

    return (
      <div className="content">
        {this.redirect}
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path={this.props.benefitsSetPath}
          name={this.props.name}
          pagination={this.props.pagination}
          columns={this.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder={this.props.searchPlaceholder}
          actions={this.actions}
          includeNewRecordLink={this.props.includeNewRecordLink && !this.state.ipaViewSelect}
          includeBulkActionLink={this.props.includeBulkEditLink && !this.state.ipaViewSelect}
          includeSearch={this.props.includeSearch}
          searchColumns={this.props.searchColumns}
          additionalQueryParams={['result_token', 'ids']}
          configuration={this.props.configuration}
          filterable={this.props.filterable}
          actionLinks={this.actionLinks}
          viewSelection={this.viewSelection}
        >
          <strong>{this.diffSetButton}</strong>
        </IndexComponent>
      </div>
    )
  }
}

export function connectedBenefitsSetIndex(
  indexReducerKey,
  indexActions,
  filters,
  additionalProps
) {
  return connectIndex(
    BenefitsSetIndex,
    indexReducerKey,
    indexActions,
    filters,
    additionalProps,
    [
      { action: exportActions.create.main, key: 'createBenefitsSetExport' },
      { action: exportActions.create.reset, key: 'resetCreateBenefitsSetExport' }
    ],
    [{ key: 'benefitsSetExport', reducer: 'benefitsSetsExports', action: 'create' }]
  )
}
