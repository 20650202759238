import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, isNil, isEmpty } from 'lodash'
import Component from '../common/Component'
import LabeledTextField from '../common/LabeledTextField'
import SelectField from '../common/SelectField'
import RemoteOptionsSelectField from '../common/RemoteOptionsSelectField'
import SubmitButton from '../common/SubmitButton'
import DateRangeComponent from '../common/DateRangeComponent'

export default class EmbargoForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    configuration: PropTypes.object,
    issuers: PropTypes.array,
    indexIssuers: PropTypes.func.isRequired,
    resetIssuers: PropTypes.func.isRequired,
    issuersRequestInProgress: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    requestInProgress: PropTypes.bool,
    submitLabel: PropTypes.string
  }

  static defaultProps = {
    requestInProgress: false,
    configuration: {},
    errors: {},
    submitLabel: 'Create Embargo'
  }

  constructor(props) {
    super(props)

    const initialValues = cloneDeep(props.initialValues)

    this.state = {
      values: initialValues
    }
  }

  get values() {
    const newState = cloneDeep(this.state.values)
    newState.start_date = newState.embargo_date_range.start
    newState.end_date = newState.embargo_date_range.end
    return newState
  }

  get submitButton() {
    return (
      <SubmitButton
        title={this.props.submitLabel}
        text={this.props.submitLabel}
        loading={this.props.requestInProgress}
      />
    )
  }

  get audiencesOptions() {
    const options = this.props.configuration.audience[this.state.values.product_line]
    if (isEmpty(options)) {
      return []
    }
    return options
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.handleSubmit(this.values)
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)

      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        newState[field] = value
      }

      return { values: newState }
    })
  }

  render() {
    const {
      errors,
      configuration
    } = this.props

    let dateRangeErrors = null
    if (errors.start_date || errors.end_date) {
      dateRangeErrors = []
      if (errors.start_date) {
        dateRangeErrors.push(`${errors.start_date}`)
      }
      if (errors.end_date) {
        dateRangeErrors.push(`${errors.end_date}`)
      }
      dateRangeErrors = dateRangeErrors.join(' -> ')
    }

    return (
      <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
        <fieldset>
          <LabeledTextField
            key="name"
            name="name"
            label="Name"
            handleChange={this.handleChange}
            errorMessage={errors.name}
            value={this.state.values.name}
          />
          <SelectField
            key="product_line"
            name="product_line"
            label="Product Line"
            options={configuration.product_line}
            handleChange={this.handleChange}
            errorMessage={errors.product_line}
            value={this.state.values.product_line}
          />
          <SelectField
            key="audience"
            name="audience"
            label="Audience"
            options={this.audiencesOptions}
            handleChange={this.handleChange}
            errorMessage={errors.audience}
            value={this.state.values.audience}
            disabled={isEmpty(this.audiencesOptions)}
          />
          <RemoteOptionsSelectField
            placeholder="Type at least 2 characters to search"
            key="embargo_issuers"
            name="embargo_issuers"
            label="Issuers (optional)"
            recordLabel={['issuer_id', 'name', 'state_code']}
            handleChange={this.handleChange}
            records={this.props.issuers}
            requestInProgress={this.props.issuersRequestInProgress}
            reset={this.props.resetIssuers}
            recordsRequest={this.props.indexIssuers}
            value={this.state.values.embargo_issuers}
            multi
          />
          <LabeledTextField
            label="Release Date"
            key="release_date"
            name="release_date"
            type="date"
            title="Release Date"
            errorMessage={errors.release_date}
            value={this.state.values.release_date}
            handleChange={this.handleChange}
          />
          <label>
            <div>Plan Effective and Expiration Dates</div>
            <DateRangeComponent
              name="embargo_date_range"
              handleChange={this.handleChange}
              errorMessage={dateRangeErrors}
              value={this.state.values.embargo_date_range}
            />
          </label>

        </fieldset>
        <fieldset>
          {this.submitButton}
        </fieldset>
      </form>

    )
  }
}
