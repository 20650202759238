// react base classes
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Component from '../common/Component'

// our custom IndexComponent
import IndexComponent from '../common/IndexComponent'

// actions for our resource
import actions from '../../actions/AncillaryServiceAreas'

// a special extension to "connect" to wire up different sorting/filtering
// behavior
import connectIndex from '../../lib/connectIndex'

export class AncillaryServiceAreaIndex extends Component {
  static propTypes = {
    // location provides access to data in the query string
    location: PropTypes.object,
    // index action for our resource
    index: PropTypes.func.isRequired,
    // reset index action for our resource
    reset: PropTypes.func.isRequired,
    // reference to the state machine of results for the index action
    records: PropTypes.array,
    // pagination info from the router
    pagination: PropTypes.object,
    // reference to global flash messages
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    // filter configuration
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['external_id', 'document_id', 'issuer_id']
  }

  static showLinkDisplay = (record) => {
    return <Link title={record.external_id} to={`ancillary_service_areas/${record.id}`}>{record.external_id}</Link>
  }

  // Configure the columns to show as well as whether or not they are filterable or sortable
  static columns = [

    {
      key: 'external_id',
      label: 'External ID',
      display: AncillaryServiceAreaIndex.showLinkDisplay,
      filterable: false,
      sortable: false
    },
    {
      key: 'issuer_id',
      label: 'Issuer',
      filterable: false,
      sortable: false
    },
    {
      key: 'line_of_coverage',
      label: 'Line of Coverage',
      filterable: false,
      sortable: true
    },
    {
      key: 'document',
      label: 'Source Document',
      display: IndexComponent.documentDisplay,
      filterable: false,
      sortable: false
    },
    {
      key: 'audience',
      label: 'Audience',
      filterable: true,
      sortable: false
    },
    {
      key: 'updated_at',
      label: 'Last Updated At',
      display: IndexComponent.dateTimeDisplay,
      filterable: false,
      sortable: true
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/ancillary_service_areas"
          name="Ancillary Service Area"
          pagination={this.props.pagination}
          columns={AncillaryServiceAreaIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by External ID, Issuer, or Document ID"
          filterable
          actions={[]}
          searchColumns={this.props.searchColumns}
          newRecordLinkTitle="Import Ancillary Service Area"
        />
      </div>
    )
  }
}

// Our filterable columns must know the available values on which to filter.
// This is a hard-coded list from the config, but they can also use a remote select
// to search for available options
const filters = [
  {
    key: 'audience',
    config: 'ancillary_service_areas.audience'
  }
]

// wire up our index component with the custom connectIndex that
// links up our filters and actions
export default connectIndex(
  AncillaryServiceAreaIndex,
  'ancillaryServiceAreas',
  actions,
  filters
)
