import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isUndefined, isEmpty, filter, sortBy } from 'lodash'
import Component from '../../common/Component'
import PlanComponent from './PlanComponent'

export class PlansComponent extends Component {
  static propTypes = {
    plans: PropTypes.array,
    census: PropTypes.object,
    plansByFamily: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    needFamilies: PropTypes.bool,
    requestInProgress: PropTypes.bool,
    resourceErrors: PropTypes.string,
    generalError: PropTypes.string,
    audience: PropTypes.string,
    quoteRates: PropTypes.array
  }

  static renderedProps = [
    'plans',
    'census',
    'plansByFamily',
    'generalError',
    'resourceErrors',
    'requestInProgress'
  ]

  get plans() {
    if (!this.isLifeOrDisability) { return sortBy(this.props.plans, ['name'], ['asc']) }

    const plans = filter(this.props.plans, (plan) => {
      return this.props.quoteRates.find((rate) => rate.plan_id === plan.id)
    })

    return sortBy(plans, ['name'], ['asc'])
  }

  get planComponents() {
    return this.plans.map((plan) => (
      <PlanComponent
        plan={plan}
        key={`${plan.id}`}
        census={this.props.census}
        plansByFamily={this.props.plansByFamily}
      />
    ))
  }

  get productLine() {
    return this.props.plans[0].product_line
  }

  get isLifeOrDisability() {
    const productLine = this.productLine

    return productLine === 'life' || productLine === 'disability'
  }

  statusComponent = (text, className) => (
    <div className={className}>
      <span>{text || 'Loading'}</span>
    </div>
  )

  render() {
    const {
      plans,
      requestInProgress,
      census,
      generalError,
      resourceErrors,
      needFamilies,
      audience,
      plansByFamily
    } = this.props

    if (!isUndefined(generalError)) {
      return this.statusComponent(generalError, 'howie_error')
    }

    if (!isUndefined(resourceErrors)) {
      return this.statusComponent(resourceErrors, 'howie_error')
    }

    if (requestInProgress) {
      return this.statusComponent('Loading plans', 'howie_info')
    }

    if (!plans || !census) {
      return null
    }

    if (isEmpty(plans) || (plansByFamily && isEmpty(plansByFamily))) {
      return this.statusComponent('No Plans Found', 'howie_info')
    }

    if (audience === 'Individual' && needFamilies) {
      return this.statusComponent('Loading rates', 'howie_info')
    }

    if (audience !== 'Individual' && !plansByFamily) {
      return this.statusComponent('Loading rates', 'howie_info')
    }

    return (
      <fieldset>
        <div className="pure-g">
          <div className="pure-u-1 section-header-title">
            <div className="pure-u-10-24 section-header-title"><p>Plan Name</p></div>
            <div className="pure-u-4-24 section-header-title"><p>ID</p></div>
            {!this.isLifeOrDisability
              && (
                <>
                  <div className="pure-u-4-24 section-header-title"><p>Monthly Premium</p></div>
                  <div className="pure-u-4-24 section-header-title"><p>Contract Codes</p></div>
                </>
              )}
          </div>
          <div className="pure-u-1" name="plan-components">
            {this.planComponents}
          </div>
        </div>
      </fieldset>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    quoteRates: state.quoteRates.create.responseAttributes
  }
}

export default connect(mapStateToProps, null)(PlansComponent)
