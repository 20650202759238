import {
  SET_AUDIENCE,
  CLEAR_AUDIENCE
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  audience: undefined
}

export default function ScenariosAudienceReducer(state = defaultState, action) {
  switch (action.type) {
  case SET_AUDIENCE:
    return { ...state, audience: action.audience }
  case CLEAR_AUDIENCE:
    return { ...state, audience: undefined }
  default:
    return { ...state }
  }
}
