import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'benefits_set_identifier',
  '/benefits_set_identifiers',
  'benefits_set'
)

const actions = generator.generate()

export default actions
