import React from 'react'
import Component from '../common/Component'
import DentalBenefitsSetComponent from './DentalBenefitsSetComponent'
import { benefitNames } from './dentalFields'
import BenefitsSetForm from '../BenefitsSet/BenefitsSetForm'

export default class DentalBenefitsSetForm extends Component {
  render() {
    return (
      <BenefitsSetForm
        benefitNames={benefitNames}
        benefitsSetComponent={DentalBenefitsSetComponent}
        productLine="Dental"
        {...this.props}
      />
    )
  }
}
