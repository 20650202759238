import React from 'react'

// Actions
import actions from '../../actions/PrivateExchanges'

import Component from '../common/Component'
import CreateForm, { defaultPropTypes, connectCreateForm } from '../common/CreateForm'
import LabeledTextField from '../common/LabeledTextField'

export class PrivateExchangeCreate extends Component {
  static propTypes = defaultPropTypes

  render() {
    return (
      <CreateForm {...this.props}>
        <LabeledTextField
          name="name"
          label="Name"
          placeholder="Enter a name"
          type="text"
        />
      </CreateForm>
    )
  }
}

export default connectCreateForm(PrivateExchangeCreate, 'Private Exchange', 'private_exchanges', actions)
