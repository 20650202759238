import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { IoIosDocument } from 'react-icons/io'
import Tree from 'react-ui-tree'
import 'react-ui-tree/dist/react-ui-tree.css'
import cx from 'classnames'
import moment from 'moment'
import { get } from 'lodash'

import Component from '../common/Component'

import serviceAreaActions from '../../actions/AncillaryServiceAreas'

export class AncillaryServiceAreaShow extends Component {
  static propTypes = {
    showServiceAreaAction: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    serviceArea: PropTypes.object,
    match: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      active: false
    }
  }

  componentDidMount() {
    const { showServiceAreaAction } = this.props
    showServiceAreaAction(this.id)
  }

  onClickNode = (node) => {
    this.setState({
      active: node
    })
  }

  get defaultAttributes() {
    return { ancillary_service_area_id: this.id, rejection_reason: '' }
  }

  get id() {
    return this.props.match.params.id
  }

  get shouldRender() {
    return this.props.serviceArea
  }

  get documentUrl() {
    return this.props.serviceArea.document.public_url
  }

  get documentLink() {
    if (this.documentUrl) {
      return (
        <a href={this.documentUrl} target="_sbc">
          <IoIosDocument size={30} />
          Document
        </a>
      )
    }
    return 'No Document Attached'
  }

  get treeComponents() {
    return (
      this.props.serviceArea.howie_geographies.map((tree) => (
        <Tree
          key={tree.module}
          tree={tree}
          paddingLeft={20}
          onChange={this.handleChange}
          renderNode={this.renderNode}
          isNodeCollapsed
        />
      ))
    )
  }

  get returnPath() {
    return get(this.props.location, 'state.return_path', '/ancillary_service_areas/')
  }

  formattedDateValue = (value) => {
    return moment(value).utc().format('YYYY/MM/DD hh:mm A')
  }

  renderNode = (node) => {
    return (
      <span
        className={cx('node', {
          'is-active': node === this.state.active
        })}
        role="presentation"
        onClick={() => this.onClickNode.bind(null, node)}
        onKeyDown={() => this.onClickNode.bind(null, node)}
      >
        {node.module}
      </span>
    )
  }

  render() {
    const { serviceArea } = this.props

    if (!this.shouldRender) return null

    return (
      <div className="content">
        <h1>Ancillary Service Area</h1>
        <div>
          {this.documentLink}
        </div>
        <h5>Metadata</h5>
        <table className="pure-table service_area_info">
          <tbody>
            <tr>
              <td><strong>External ID</strong></td>
              <td name="external_id">{serviceArea.external_id}</td>
            </tr>
            <tr>
              <td><strong>Issuer</strong></td>
              <td name="issuer_id">{serviceArea.issuer_id}</td>
            </tr>
            <tr>
              <td><strong>Line of Coverage</strong></td>
              <td name="line_of_coverage">{serviceArea.line_of_coverage}</td>
            </tr>
            <tr>
              <td><strong>Audience</strong></td>
              <td name="audience">{serviceArea.audience}</td>
            </tr>
            <tr>
              <td><strong>Last Updated At</strong></td>
              <td name="updated_at">{this.formattedDateValue(serviceArea.updated_at)}</td>
            </tr>
            <tr>
              <td><strong>Number of Geographies</strong></td>
              <td name="geography_count">{serviceArea.geography_count}</td>
            </tr>
            <tr>
              <td><strong>Number of Zip Codes</strong></td>
              <td name="zip_code_count">{serviceArea.zip_code_count}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>Geographies</h5>
        <div className="tree">
          {this.treeComponents}
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const showSlice = state.ancillaryServiceAreas.show
  const configuration = state.login.session.configuration.ancillary_service_areas

  return {
    serviceArea: showSlice.attributes,
    configuration
  }
}

const mapDispatchToProps = {
  showServiceAreaAction: serviceAreaActions.show.main
}

export default connect(mapStateToProps, mapDispatchToProps)(AncillaryServiceAreaShow)
