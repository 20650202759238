import React, { Component } from 'react'
import PropTypes from 'prop-types'
import alert from '../../alert'

class TestOAuthLogin extends Component {
  static propTypes = {
    createOAuthSession: PropTypes.func.isRequired,
    error: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.processResponse = this.processResponse.bind(this)
  }

  componentDidMount() {
    if (process.env.REACT_APP_AUTO_LOGIN === 'true') {
      this.buttonElement.click()
    }
  }

  processResponse(e) {
    e.preventDefault()

    this.props.createOAuthSession(this.inputElement.value)
  }

  render() {
    const { error } = this.props

    if (typeof error !== 'undefined') {
      alert(error)
    }

    return (
      <div className="pure-u-1 google-login">
        <form
          id="oauth-form"
          className="pure-form pure-form-stacked"
          onSubmit={this.processResponse}
          ref={(form) => { this.formElement = form }}
        >
          <fieldset>
            <input
              className="pure-input-1 field"
              name="email"
              title="Email"
              ref={(input) => { this.inputElement = input }}
              defaultValue="ceo@vericred.com"
            />
          </fieldset>
          <fieldset>
            <button
              className="google-login-button pure-button pure-input-1 pure-button-primary"
              title="Login"
              type="submit"
              ref={(button) => { this.buttonElement = button }}
            >OAuth Login
            </button>
          </fieldset>
        </form>
      </div>
    )
  }
}

export default TestOAuthLogin
