import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'lodash'
import { IoIosCloseCircle } from 'react-icons/io'
import InputField from './InputField'
import SearchTooltip from './SearchTooltip'

export default class SearchField extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleClear: PropTypes.func,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    title: PropTypes.string,
    id: PropTypes.string,
    searchText: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchText: 'Search',
    buttonDisabled: false,
    searchColumns: []
  }

  get clearButton() {
    if (isEmpty(this.props.value)) {
      return null
    }

    return (
      <div className="clear-button-container">
        <button
          type="button"
          onClick={this.handleClearButton}
        >
          <IoIosCloseCircle />
        </button>
      </div>
    )
  }

  handleClearButton = (event) => {
    this.handleClear()
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      event.preventDefault()

      this.handleClear()
    }
  }

  handleClear = () => {
    if (!isNil(this.props.handleClear)) {
      this.props.handleClear(this.props.name)
    }
  }

  render() {
    const {
      name,
      handleChange,
      handleBlur,
      value,
      title,
      disabled,
      buttonDisabled,
      id,
      searchText,
      placeholder
    } = this.props

    return (
      <div className="compact">
        <div className="search-input-wrapper">
          <InputField
            inputCssClass="connected-search-input"
            id={id}
            name={name}
            title={title}
            type="search"
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleKeyDown={this.handleKeyDown}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
          />
          {this.clearButton}
        </div>
        <button
          className="search-button button primary pure-button connected-search-button"
          type="submit"
          disabled={buttonDisabled}
        >
          {searchText}
        </button>
        {this.props.searchColumns.length > 0 && <SearchTooltip columns={this.props.searchColumns} />}
      </div>
    )
  }
}
