import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MenuSection from './MenuSection'
import MenuSubSection from './MenuSubSection'

export default class Menu extends Component {
  static propTypes = {
    sectionRoutes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        routes: PropTypes.array.isRequired
      }).isRequired
    )
  }

  constructor(props) {
    super(props)

    this.state = { isActive: false }
  }

  get sections() {
    return this.props.sectionRoutes.map(({ title, routes }) => this.loadMenuSection(title, routes))
  }

  loadMenuSection = (title, routes) => {
    if (this.hasSubSections(routes)) {
      return this.subSection(title, routes)
    }

    return this.section(title, routes)
  }

  hasSubSections = (routes) => {
    return routes.some((route) => route.label)
  }

  section = (title, routes) => {
    return (
      <MenuSection
        key={`menu-section-${title}`}
        title={title}
        routes={routes}
        active={this.state.isActive}
      />
    )
  }

  subSection = (title, routes) => {
    return (
      <MenuSubSection
        key={`menu-sub-section-${title}`}
        title={title}
        routes={routes}
        active={this.state.isActive}
      />
    )
  }

  render() {
    return (
      <nav className="pure-menu pure-menu-horizontal">
        <ul className="pure-menu-list">
          {this.sections}
        </ul>
      </nav>
    )
  }
}
