import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { get, cloneDeep, isNil } from 'lodash'
import Component from '../common/Component'
import alert from '../../alert'

export default class BenefitsSetEdit extends Component {
  static propTypes = {
    match: PropTypes.object,
    configuration: PropTypes.object,
    update: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    requestComplete: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    attributes: PropTypes.object,
    updated: PropTypes.bool.isRequired,
    remoteOptionsSelectStates: PropTypes.object,
    remoteOptionsSelectActions: PropTypes.object,
    history: PropTypes.func.isRequired,
    resetHistory: PropTypes.func.isRequired,
    benefitsSetFieldHistory: PropTypes.object,
    benefitsSetForm: PropTypes.func,
    benefitsSetUrl: PropTypes.string,
    productLine: PropTypes.string
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
    this.props.resetHistory()
  }

  get id() {
    return this.props.match.params.id
  }

  get initialValues() {
    const newValues = cloneDeep(this.props.attributes)

    newValues.document = null

    return newValues
  }

  get rejections() {
    return get(this.props.attributes, 'rejections', [])
  }

  handleSubmit = (values) => {
    this.props.update(this.id, this.translateValues(values))
  }

  redirect = (id) => {
    return <Redirect push to={`/${this.props.benefitsSetUrl}/${id}`} />
  }

  translateValues = (values) => {
    const newValues = cloneDeep(values)

    if (!isNil(newValues.document)) {
      newValues.document_id = newValues.document
    }

    return newValues
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      requestInProgress,
      requestComplete,
      attributes,
      updated,
      productLine
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (!isNil(this.destroyError)) {
      alert(this.destroyError)
    }

    if (isNil(attributes)) {
      return null
    }

    if (updated) {
      return this.redirect(this.id)
    }

    const BenefitsSetForm = this.props.benefitsSetForm

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <div className="content">
        <h2>
          Update {productLine} Benefits Set
        </h2>
        <div>
          <BenefitsSetForm
            benefitsSetFieldHistory={this.props.benefitsSetFieldHistory}
            resetHistory={this.props.resetHistory}
            history={this.props.history}
            handleSubmit={this.handleSubmit}
            handleHistory={this.handleHistory}
            errors={errors}
            configuration={configuration}
            initialValues={this.initialValues}
            requestInProgress={requestInProgress}
            requestComplete={requestComplete}
            rejections={this.rejections}
            remoteOptionsSelectStates={this.props.remoteOptionsSelectStates}
            remoteOptionsSelectActions={this.props.remoteOptionsSelectActions}
            show={this.props.show}
            showReset={this.props.showReset}
          />
        </div>
      </div>
    )
  }
}
