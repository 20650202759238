import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isNil } from 'lodash'
import { createFlashMessage } from '../../actions/FlashMessages'
import Component from '../common/Component'
import actions from '../../actions/Issuers'
import carrierActions from '../../actions/Carriers'
import carrierBrandActions from '../../actions/CarrierBrands'
import IssuerForm from './IssuerForm'
import EntitlementsProvider from '../common/EntitlementsProvider'
import alert from '../../alert'

export class IssuerCreate extends Component {
  static propTypes = {
    configuration: PropTypes.object,
    entitlements: PropTypes.object,
    states: PropTypes.array,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    createFlashMessage: PropTypes.func.isRequired,
    indexCarriers: PropTypes.func.isRequired,
    resetCarriers: PropTypes.func.isRequired,
    carriers: PropTypes.array,
    carriersRequestInProgress: PropTypes.bool.isRequired,
    indexCarrierBrands: PropTypes.func.isRequired,
    resetCarrierBrands: PropTypes.func.isRequired,
    carrierBrands: PropTypes.array,
    carrierBrandsRequestInProgress: PropTypes.bool.isRequired
  }

  componentWillUnmount() {
    this.props.reset()
  }

  handleSubmit = (values) => {
    this.props
      .create(values)
      .then(({ id }) => {
        this.props.createFlashMessage('Created issuer', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      entitlements,
      states,
      requestInProgress,
      created,
      id
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (created && !isNil(id)) {
      return this.redirect('/issuers')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <div className="content">
        <h2>New Issuer</h2>
        <div>
          <EntitlementsProvider entitlements={entitlements}>
            <IssuerForm
              handleSubmit={this.handleSubmit}
              errors={errors}
              configuration={configuration}
              states={states}
              requestInProgress={requestInProgress}
              initialValues={{
                name: null,
                issuer_id: null,
                issuer_id_type: null,
                brand_name: null,
                logo_blob_id: null,
                address_line_1: null,
                address_line_2: null,
                city: null,
                state_id: null,
                licensed_state_id: null,
                zip_code: null,
                alternate_carrier_brand_audience: null
              }}
              resetCarriers={this.props.resetCarriers}
              carriers={this.props.carriers}
              carriersRequestInProgress={this.props.carriersRequestInProgress}
              indexCarriers={this.props.indexCarriers}
              resetCarrierBrands={this.props.resetCarrierBrands}
              carrierBrands={this.props.carrierBrands}
              carrierBrandsRequestInProgress={this.props.carrierBrandsRequestInProgress}
              indexCarrierBrands={this.props.indexCarrierBrands}
            />
          </EntitlementsProvider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.issuers.create
  const carriersSlice = state.carriers.index
  const carrierBrandsSlice = state.carrierBrands.index
  const configuration = state.login.session.configuration.issuers
  const entitlements = state.login.session.configuration.entitlements.issuers.create
  const states = state.login.session.configuration.states

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created,
    carriers: carriersSlice.records,
    carriersRequestInProgress: carriersSlice.requestInProgress,
    carrierBrands: carrierBrandsSlice.records,
    carrierBrandsRequestInProgress: carrierBrandsSlice.requestInProgress,
    states,
    configuration,
    entitlements
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset,
  indexCarriers: carrierActions.index.main,
  resetCarriers: carrierActions.index.reset,
  indexCarrierBrands: carrierBrandActions.index.main,
  resetCarrierBrands: carrierBrandActions.index.reset,

  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(IssuerCreate)
