import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../../css/Tooltip.scss'

export default class SearchTooltip extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = { visible: false }
  }

  get content() {
    return (
      <div className="tooltip-content">
        <ol>
          <li>
            Search by specifics columns using a search operator.
            <pre>column: search term</pre>
            <p>Available columns:</p>
            <p>
              {this.props.columns.map((column, index) => {
                return <strong key={`search_column_${index}`}>{column}</strong>
              })}
            </p>
          </li>
          <li>Search for exact matches using double quotes.
            <pre>column: &quot;search term&quot;</pre>
          </li>
          <li>Join multiple columns using &apos;|&apos;.
            <pre>column1: search term1 | column2: search term2</pre>
          </li>
        </ol>
      </div>
    )
  }

  handleMouseOver = (event) => {
    this.setState((prevState) => ({ visible: !prevState.visible }))
  }

  render() {
    return (
      <div className="tooltip" ref={this.wrapperRef}>
        <span
          className="tooltip-title"
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOver}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          Advanced Search
        </span>
        {this.state.visible && this.content}
      </div>
    )
  }
}
