import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isNil } from 'lodash'
import UserContext from './BenefitsSetUserContext'
import totalTiersCountForBenefits from './Helpers/tiersCountForBenefits'

import '../../css/BenefitsSet.scss'

export default class BenefitsSetShow extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    benefitsSet: PropTypes.object,
    match: PropTypes.object,
    configuration: PropTypes.object,
    history: PropTypes.func.isRequired,
    resetHistory: PropTypes.func.isRequired,
    benefitsSetFieldHistory: PropTypes.object,
    benefitsSetComponent: PropTypes.func,
    productLine: PropTypes.string
  }

  componentDidMount() {
    this.props.show(this.props.match.params.id)
    this.props.resetHistory()
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.resetHistory()
  }

  get needsBenefitsSet() {
    if (isNil(this.props.benefitsSet)) {
      return true
    }

    return get(this.props, 'benefitsSet.id') !== parseInt(this.props.match.params.id, 10)
  }

  get tiersCount() {
    if (isNil(this.props.benefitsSet)) {
      return 0
    }

    return totalTiersCountForBenefits(this.props.benefitsSet.benefits)
  }

  get rejections() {
    return get(this.props.benefitsSet, 'rejections', [])
  }

  render() {
    const { benefitsSet, configuration, productLine } = this.props

    const BenefitSetComponent = this.props.benefitsSetComponent

    if (isNil(configuration)) {
      return null
    }

    if (this.needsBenefitsSet) {
      return null
    }

    return (
      <div className="content">
        <h2>
          {productLine} Benefits Set Info
        </h2>
        <div>
          <BenefitSetComponent
            benefitsSetFieldHistory={this.props.benefitsSetFieldHistory}
            resetHistory={this.props.resetHistory}
            history={this.props.history}
            configuration={configuration}
            benefitsSet={benefitsSet}
            userContext={new UserContext({ viewable: true })}
            tiersCount={this.tiersCount}
            rejections={this.rejections}
            show={this.props.show}
            showReset={this.props.reset}
          />
        </div>
      </div>
    )
  }
}
