import { get, last } from 'lodash'
import { connect } from 'react-redux'
import actions from '../../actions/MedicareAdvantageBenefitsSets'
import historyActions from '../../actions/BenefitsSetFieldHistories'
import BenefitsSetShow from '../BenefitsSet/BenefitsSetShow'
import MedicareAdvantageBenefitsSetComponent from './MedicareAdvantageBenefitsSetComponent'

const mapStateToProps = (state) => {
  const slice = state.medicareAdvantageBenefitsSets.show

  const configuration = state.login.session.configuration.medicare_advantage_benefits
  const historySlice = state.benefitsSetFieldHistory.index
  return {
    benefitsSet: slice.attributes,
    rejection: last(get(slice.attributes, 'rejections', [])),
    configuration,
    benefitsSetFieldHistory: historySlice.records,
    productLine: 'Medicare Advantage',
    benefitsSetComponent: MedicareAdvantageBenefitsSetComponent
  }
}

const mapDispatchToProps = {
  show: actions.show.main,
  reset: actions.show.reset,
  history: historyActions.index.main,
  resetHistory: historyActions.index.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetShow)
