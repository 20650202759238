import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import Component from '../common/Component'

export default class HeaderRow extends Component {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string),
    totalColumns: PropTypes.number.isRequired,
    className: PropTypes.string,
    label: PropTypes.string
  }

  static renderedProps = [
    'labels',
    'totalColumns',
    'className'
  ]

  get labels() {
    return this.props.labels.map((label, index, array) => {
      let colSpan = 1

      if (index === array.length - 1) {
        colSpan = this.props.totalColumns - index
      }

      return <td key={`header-${index + 1}`} colSpan={colSpan || 2}>{label}</td>
    })
  }

  render() {
    const { label, className } = this.props

    const classNames = ['header-row']

    if (!isNil(className)) {
      classNames.push(className)
    }

    return (
      <tr className={classNames.join(' ')} key={`header-${label}`}>
        {this.labels}
      </tr>
    )
  }
}

