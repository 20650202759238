import React from 'react'
import { get } from 'lodash'

const IssuersDisplay = (record, column) => {
  const value = get(record, column.key, [])
  const issuerRows = value.map((issuer, i) => (
    <div key={i}>{issuer.issuer_label}</div>
  ))

  return (
    <div>
      {issuerRows}
    </div>
  )
}

export default IssuersDisplay
