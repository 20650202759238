import React, { Component } from 'react'
import CreateForm, { defaultPropTypes, connectCreateForm } from '../common/CreateForm'
import actions from '../../actions/Forms'
import ConnectedFileUploaderComponent from '../common/ConnectedFileUploaderComponent'
import LabeledTextField from '../common/LabeledTextField'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import SelectField from '../common/SelectField'
import PlanYearOptionsHelper from '../common/helpers/PlanYearOptionsHelper'

export class FormCreate extends Component {
  static propTypes = defaultPropTypes

  render() {
    return (
      <CreateForm {...this.props}>
        <ConnectedFileUploaderComponent
          key="blob_id"
          name="blob_id"
          label="Form (PDF Only)"
          acceptedMimeTypes="application/pdf"
        />
        <LabeledTextField
          key="title"
          name="title"
          label="Form Title"
          type="text"
        />
        <IssuerRemoteOptionsSelectField
          key="issuer_ids"
          name="issuer_ids"
          label="Issuers"
          recordLabel={['issuer_id', 'name', 'licensed_state_code']}
          filterFields={{ carrier_id_type: 'NAIC Company Number' }}
          errorKey="form_issuers"
          multi
        />
        <SelectField
          key="plan_year"
          name="plan_year"
          label="Plan Year"
          options={PlanYearOptionsHelper.planYearOptionsFromCurrent(1)}
        />
        <SelectField
          key="form_actions"
          name="form_actions"
          label="Form Actions"
          options={this.props.configuration.actions}
          multi
        />
      </CreateForm>
    )
  }
}

export default connectCreateForm(FormCreate, 'Form', 'forms', actions)
