import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { isNil } from 'lodash'
import { Redirect } from 'react-router-dom'
import Component from './Component'

export default class BulkIdentifierComponent extends Component {
  static propTypes = {
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    indexParams: PropTypes.object,
    location: PropTypes.object.isRequired,
    redirectTo: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.assignQueryParams()
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentWillUnmount() {
    this.props.reset()
  }

  fetchRecords() {
    this.props.index({ ...this.queryParams, ...this.props.indexParams })
  }

  assignQueryParams() {
    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    if (isNil(this.props.records)) {
      return null
    }

    const params = {
      ids: this.props.records.join(','),
      query: this.queryParams
    }

    return (
      <Redirect
        to={{
          pathname: this.props.redirectTo,
          search: qs.stringify(params)
        }}
      />
    )
  }
}
