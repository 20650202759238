import MethodActionGenerator from './MethodActionGenerator'

export default class BatchDestroyActionGenerator extends MethodActionGenerator {
  constructor(name, basePath, parameterName) {
    super('BATCHDESTROY', name, basePath, parameterName)
  }

  mainActionGenerator = () => {
    return (attributes) => (dispatch) => {
      dispatch({ type: this.types.request, attributes })

      return this.client.put(`${this.basePath}/batch_destroy`, { [this.parameterName]: attributes })
        .then((response) => {
          return dispatch({
            type: this.types.response,
            responseHeaders: response.headers
          })
        })
        .catch((error) => dispatch({ type: this.types.error, ...this.parseError(error) }))
    }
  }
}
