import React from 'react'
import qs from 'qs'
import { get, isEmpty, omit, isUndefined } from 'lodash'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import indexActions from '../../actions/DisabilityBenefitsSets'
import connectIndex from '../../lib/connectIndex'
import { BenefitsSetIndex } from '../BenefitsSet/BenefitsSetIndex'
import BenefitsSetsHelper from '../BenefitsSet/Helpers/BenefitsSetsHelper'
import approvalStateDisplay from '../BenefitsSet/approvalStateDisplay'
import publishStateDisplayWithModal from '../BenefitsSet/publishStateDisplayWithModal'
import BulkPublishLink from '../BenefitsSet/BulkPublishLink'
import exportActions from '../../actions/BenefitsSetsExports'

export class DisabilityBenefitsSetIndex extends Component {
  static propTypes = {
    ...omit(BenefitsSetIndex.propTypes, 'productLine')
  }

  get bulkPublishButton() {
    const planCount = get(this.props.pagination, 'total', 0)

    if (planCount === 0 || isEmpty(this.queryParams)) {
      return null
    }

    return (
      <BulkPublishLink
        key="bulk-publish-link"
        planCount={planCount}
        queryParams={this.queryParams}
        productLine="disability"
      />
    )
  }

  get queryParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    return (
      <div>
        <BenefitsSetIndex
          {...this.props}
          name="Disability Benefits Set"
          columnsList={columnsList}
          includeBulkEditLink
          includeDiffSetLink
          includeSelectAll
          includeNewRecordLink
          searchPlaceholder="Search by Name, Issuer, Issuer ID, or Vericred ID"
          actionLinks={[this.bulkPublishButton]}
          productLine="disability"
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'audience',
    config: 'benefits.audience'
  },
  {
    key: 'approval_state',
    options: [
      { value: 'new', text: 'New' },
      { value: 'approved', text: 'Approved' },
      { value: 'rejected', text: 'Rejected' },
      { value: 'corrected', text: 'Corrected' },
      { value: 'amended', text: 'Amended' }
    ]
  },
  {
    key: 'publish_state',
    options: [
      { value: 'needs_publishing', text: 'Needs Publishing' },
      { value: 'failed_to_publish', text: 'Failed to publish' },
      { value: 'published', text: 'Published' },
      { value: 'unpublished', text: 'Unpublished' },
      { value: 'publishing', text: 'Publishing' }
    ]
  },
  {
    key: 'term',
    options: [
      { value: 'short_term', text: 'Short' },
      { value: 'long_term', text: 'Long' }
    ]
  },
  {
    key: 'plan_years',
    options: BenefitsSetsHelper.planYearOptions(2015, (new Date()).getFullYear() + 1)
  }
]

const columnsList = [
  { key: 'name', label: 'Name', display: BenefitsSetIndex.showLinkDisplay('/disability_benefits') },
  { key: 'issuer_name', label: 'Issuer' },
  { key: 'issuer_id', label: 'Issuer ID' },
  { key: 'vericred_id', label: 'Vericred ID', sortable: false },
  {
    key: 'audience',
    label: 'Audience',
    filterable: true,
    optionsEnum: true
  },
  {
    key: 'term',
    label: 'Term',
    filterable: true,
    sortable: false,
    recordLabel: 'text',
    recordValue: 'value',
    display: (value) => {
      if (isUndefined(value)) {
        return ''
      }
      if (value.term === 'short_term') { return 'Short' }
      if (value.term === 'long_term') { return 'Long' }
      return ''
    }
  },
  {
    key: 'plan_years',
    label: 'Years',
    filterable: true,
    sortable: false,
    recordLabel: 'text',
    recordValue: 'value'
  },
  { key: 'updated_at', label: 'Last Updated At', display: IndexComponent.dateTimeDisplay },
  { key: 'last_updated_by_username', label: 'Last Updated By' },
  {
    key: 'approval_state',
    label: 'Status',
    display: approvalStateDisplay,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'publish_state',
    label: 'Publish Status',
    display: publishStateDisplayWithModal,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  }
]

export default connectIndex(
  DisabilityBenefitsSetIndex,
  'disabilityBenefitsSets',
  indexActions,
  filters,
  {
    benefitsSetPath: '/disability_benefits'
  },
  [
    { action: exportActions.create.main, key: 'createBenefitsSetExport' },
    { action: exportActions.create.reset, key: 'resetCreateBenefitsSetExport' }
  ],
  [
    { key: 'benefitsSetExport', reducer: 'benefitsSetsExports', action: 'create' }
  ]
)

