import React from 'react'

import actions from '../../actions/Carriers'

import Component from '../common/Component'
import ConnectedRemoteOptionsSelectField,
{ connectRemoteOptionsSelectField, defaultPropTypes }
  from '../common/ConnectedRemoteOptionsSelectField'

export class CarrierRemoteOptionsSelectField extends Component {
  static propTypes = defaultPropTypes

  static defaultProps = {
    label: 'Carrier',
    multi: false,
    title: 'carrier_id'
  }

  render() {
    return (
      <ConnectedRemoteOptionsSelectField {...this.props} />
    )
  }
}

export default connectRemoteOptionsSelectField(CarrierRemoteOptionsSelectField, 'carriers', actions)
