import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import SourceContentComponent from './SourceContentComponent'

export class SourceContentsSetComponent extends Component {
  static propTypes = {
    value: PropTypes.array,
    options: PropTypes.array,
    handleChange: PropTypes.func,
    errorMessage: PropTypes.string
  }

  static defaultProps = {
    value: []
  }

  get errorMessage() {
    if (this.props.errorMessage) {
      return (
        <div>{this.props.errorMessage}</div>
      )
    }
    return null
  }

  handleChange = (type, field, value) => {
    const sourceContents = cloneDeep(this.props.value) || []

    const contents = sourceContents.find((el) => { return el.source_content === type })

    if (contents) {
      contents[field] = value
    } else {
      const data = { source_content: type }
      data[field] = value

      sourceContents.push(data)
    }

    this.props.handleChange('source_contents', sourceContents)
  }

  render() {
    return (
      <fieldset>
        <label>Source Contents</label>
        {this.errorMessage}
        {this.props.options.map((sourceContent, index, array) => (
          <SourceContentComponent
            name="source_contents"
            key={sourceContent}
            sourceContentType={sourceContent}
            value={this.props.value.find((el) => { return el.source_content === sourceContent })}
            handleChange={this.handleChange}
          />
        ))}
      </fieldset>
    )
  }
}

export default SourceContentsSetComponent
