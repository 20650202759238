import {
  REQUEST_CENSUSES,
  RECEIVE_CENSUSES,
  CLEAR_CENSUSES,
  REQUEST_AUDIENCE_CENSUSES,
  RECEIVE_AUDIENCE_CENSUSES
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  censuses: undefined,
  getInProgress: false,
  censusesByAudience: {}
}

export default function CensusReducer(state = defaultState, action) {
  switch (action.type) {
  case CLEAR_CENSUSES:
    return { ...state,
      censuses: undefined,
      censusesByAudience: {} }
  case REQUEST_CENSUSES:
    return { ...state,
      getInProgress: true,
      censuses: undefined }
  case REQUEST_AUDIENCE_CENSUSES:
    return { ...state, getInProgress: true }
  case RECEIVE_CENSUSES:
    return { ...state,
      getInProgress: false,
      censuses: action.censuses }
  case RECEIVE_AUDIENCE_CENSUSES: {
    const censusesByAudience = state.censusesByAudience
    censusesByAudience[action.audience] = action.censuses

    return { ...state,
      getInProgress: false,
      censusesByAudience }
  }
  default:
    return { ...state }
  }
}
