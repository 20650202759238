
import getCensusBase from './getCensusBase'
import { SET_AUDIENCE } from './consts'

function setAudienceByCensus(census) {
  return { type: SET_AUDIENCE, audience: census.audience }
}

export default function getCensus(id) {
  return (dispatch) => {
    dispatch(getCensusBase(id, setAudienceByCensus))
  }
}
