import { get, last } from 'lodash'
import { connect } from 'react-redux'
import actions from '../../actions/DisabilityBenefitsSets'
import historyActions from '../../actions/BenefitsSetFieldHistories'
import BenefitsSetShow from '../BenefitsSet/BenefitsSetShow'
import DisabilityBenefitsSetComponent from './DisabilityBenefitsSetComponent'

const mapStateToProps = (state) => {
  const slice = state.disabilityBenefitsSets.show

  const configuration = state.login.session.configuration.benefits
  const historySlice = state.benefitsSetFieldHistory.index
  return {
    benefitsSet: slice.attributes,
    rejection: last(get(slice.attributes, 'rejections', [])),
    configuration,
    benefitsSetFieldHistory: historySlice.records,
    productLine: 'Disability',
    benefitsSetComponent: DisabilityBenefitsSetComponent
  }
}

const mapDispatchToProps = {
  show: actions.show.main,
  reset: actions.show.reset,
  history: historyActions.index.main,
  resetHistory: historyActions.index.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSetShow)
