import IndexComponent from '../common/IndexComponent'
import indexActions from '../../actions/VisionBenefitsSets'
import { BenefitsSetIndex, connectedBenefitsSetIndex } from '../BenefitsSet/BenefitsSetIndex'
import BenefitsSetsHelper from '../BenefitsSet/Helpers/BenefitsSetsHelper'
import approvalStateDisplay from '../BenefitsSet/approvalStateDisplay'
import publishStateDisplayWithModal from '../BenefitsSet/publishStateDisplayWithModal'
import ratesStatusDisplay from '../BenefitsSet/ratesStatusDisplay'

const columnsList = [
  { key: 'name', label: 'Name', display: BenefitsSetIndex.showLinkDisplay('/vision_benefits') },
  { key: 'issuer_name', label: 'Issuer' },
  { key: 'issuer_id', label: 'Issuer ID' },
  { key: 'vericred_id', label: 'Vericred ID', sortable: false },
  { key: 'hios_ids', label: 'HIOS IDs', sortable: false },
  {
    key: 'audience',
    label: 'Audience',
    filterable: true,
    optionsEnum: true
  },
  {
    key: 'plan_years',
    label: 'Years',
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'rate_factor_set_name',
    label: 'Rate Factor Set',
    display: BenefitsSetIndex.showRateFactorSetLinkDisplay
  },
  {
    key: 'service_area_name',
    label: 'Service Area',
    display: BenefitsSetIndex.showServiceAreaLinkDisplay
  },
  { key: 'updated_at', label: 'Last Updated At', display: IndexComponent.dateTimeDisplay },
  { key: 'last_updated_by_username', label: 'Last Updated By' },
  {
    key: 'approval_state',
    label: 'Status',
    display: approvalStateDisplay,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'publish_state',
    label: 'Publish Status',
    display: publishStateDisplayWithModal,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  }
]

const ipaViewColumnsList = [
  { key: 'vericred_id', label: 'Vericred ID', sortable: false },
  { key: 'issuer_id', label: 'Issuer ID' },
  {
    key: 'audience',
    label: 'Audience',
    filterable: true,
    optionsEnum: true
  },
  {
    key: 'plan_years',
    label: 'Years',
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  { key: 'name',
    label: 'Name',
    display: BenefitsSetIndex.showLinkDisplay('/dental_benefits') },
  {
    key: 'publish_state',
    label: 'Publish Status',
    display: publishStateDisplayWithModal,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'service_area_name',
    label: 'Service Area',
    display: BenefitsSetIndex.showServiceAreaLinkDisplay
  },
  {
    key: 'rate_factor_set_name',
    label: 'Rate Factor Set',
    display: BenefitsSetIndex.showRateFactorSetLinkDisplay
  },
  {
    key: 'ancillary_rate_factor_set_status',
    label: 'Rates Status',
    display: ratesStatusDisplay,
    recordLabel: 'text',
    recordValue: 'value'
  }
]

const filters = [
  {
    key: 'audience',
    config: 'benefits.audience'
  },
  {
    key: 'approval_state',
    options: [
      { value: 'new', text: 'New' },
      { value: 'approved', text: 'Approved' },
      { value: 'rejected', text: 'Rejected' },
      { value: 'corrected', text: 'Corrected' },
      { value: 'amended', text: 'Amended' }
    ]
  },
  {
    key: 'publish_state',
    options: [
      { value: 'needs_publishing', text: 'Needs Publishing' },
      { value: 'failed_to_publish', text: 'Failed to publish' },
      { value: 'published', text: 'Published' },
      { value: 'unpublished', text: 'Unpublished' },
      { value: 'publishing', text: 'Publishing' }
    ]
  },
  {
    key: 'plan_years',
    options: BenefitsSetsHelper.planYearOptions(2015, (new Date()).getFullYear() + 1)
  }
]

export default connectedBenefitsSetIndex(
  'visionBenefitsSets',
  indexActions,
  filters,
  {
    name: 'Vision Benefits Set',
    columnsList,
    ipaViewColumnsList,
    benefitsSetPath: '/vision_benefits',
    includeBulkEditLink: true,
    includeDiffSetLink: true,
    includeNewRecordLink: true,
    includeSelectAll: true,
    productLine: 'vision',
    searchPlaceholder: 'Search by Name, HIOS ID, Vericred ID, Issuer ID, Service Area ID, or Rate Factor Set ID',
    searchColumns: ['name',
      'vericred_id',
      'hios_id',
      'issuer_id',
      'document_id',
      'service_area_id',
      'rate_factor_set_id']
  }
)
