import { toPairs, isUndefined, isNil, has, omit, cloneDeep, get, range, join, trim } from 'lodash'

import benefitGroups from './dentalBenefitGroups.json'

const fields = {
  'Plan Info': {
    metadatum: {
      name: {
        label: 'Name',
        never_hide: true,
        display_on_cvt: true
      },
      audience: {
        label: 'Audience',
        type: 'select',
        never_hide: true,
        options: []
      },
      plan_type: {
        label: 'Plan Type',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: (values, configuration) => {
          if (!has(configuration, 'audience_plan_types')) {
            return []
          }

          if (has(configuration.audience_plan_types, values.audience)) {
            return configuration.audience_plan_types[values.audience]
          }

          return []
        },
        disabled: (values) => isNil(values.audience)
      },
      plan_years: {
        label: 'Plan Years',
        type: 'multiSelect',
        never_hide: true,
        options: range(2015, 2030).map(String),
        display: (value) => {
          return join(get(value, 'plan_years'), ', ')
        },
        valueParser: (value) => {
          return value.split(',').map(trim)
        }
      },
      effective_date: {
        label: 'Effective Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      expiration_date: {
        label: 'Expiration Date',
        type: 'date',
        never_hide: true,
        display_on_cvt: false
      },
      issuer_id: {
        label: 'Issuer',
        type: 'remoteOptionsSelectField',
        never_hide: true,
        recordsRequest: 'issuersIndex.main',
        reset: 'issuersIndex.reset',
        recordPath: 'issuers',
        existingRecordPath: 'issuer',
        recordLabel: ['issuer_id', 'name', 'state_code'],
        searchType: 'search_term',
        filterOptions: false,
        display: (value) => {
          return get(value, 'issuer.name', '') || ''
        }
      },
      source: {
        label: 'Data Source',
        type: 'select',
        never_hide: true,
        options: [

        ]
      },
      stand_alone: {
        label: 'Stand Alone',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      voluntary: {
        label: 'Voluntary',
        type: 'select',
        never_hide: true,
        display_on_cvt: true,
        options: ['Yes', 'No', 'Not Applicable']
      },
      ortho: {
        label: 'Ortho',
        type: 'checkbox',
        never_hide: true,
        display_on_cvt: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      },
      privileged_data: {
        label: 'Privileged Data',
        type: 'checkbox',
        never_hide: true,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      }
    },
    plan_external_ids: {
      label: 'External IDs',
      foreign_key: 'plan_external_id_id',
      options: () => {
        return {
          hios_id: 'HIOS ID', issuer_internal_id: 'Issuer-Internal ID'
        }
      },
      never_hide: true,
      type: 'plan_external_ids',
      hidden: () => {
        return false
      },
      display: (value) => {
        if (isUndefined(value)) {
          return ''
        }

        let typeLabel
        switch (value.external_id_type) {
        case 'issuer_internal_id':
          typeLabel = 'Issuer-Internal ID'
          break
        case 'hios_id':
          typeLabel = 'HIOS ID'
          break
        default:
          typeLabel = ''
          break
        }

        return `${typeLabel}: ${value.external_id}`
      }
    }
  },
  Benefits: {
    benefits: {
      name: 'benefits',
      foreign_key: 'benefit_id',
      never_hide: true,
      type: 'tiered_field',
      label: 'Benefits',
      fields: benefitGroups
    }
  }
}

export const fieldPairs = toPairs(fields)
export const benefitLabels = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  // eslint-disable-next-line no-param-reassign
  fieldset.fields.forEach((f) => { memo[f.name] = f.label })
  return memo
}, {})

export const benefitNames = fields.Benefits.benefits.fields.reduce((memo, fieldset) => {
  fieldset.fields.forEach((f) => { memo.push(f.name) })
  return memo
}, [])

export const benefitGroupNames = fields.Benefits.benefits.fields.map((b) => b.name)
export const infoFields = omit(fields['Plan Info'], ['plan_external_ids'])

export const externalIdField = fields['Plan Info'].plan_external_ids

const metadatumFieldsDiffSetTemp = cloneDeep(infoFields.metadatum)

metadatumFieldsDiffSetTemp.issuer_name = {
  label: 'Issuer Name',
  never_hide: true
}

metadatumFieldsDiffSetTemp.issuer_identifier = {
  label: 'Issuer Id',
  never_hide: true
}

delete metadatumFieldsDiffSetTemp.issuer_id

export const metadatumFields = infoFields.metadatum
export const metadatumFieldsDiffSet = metadatumFieldsDiffSetTemp

export default fields
