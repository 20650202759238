import React from 'react'
import Component from '../common/Component'
import BenefitsSetBulkEditsComponent from '../BenefitsSet/BenefitsSetBulkEditsComponent'
import { benefitLabels, metadatumFieldsDiffSet } from './medAdvFields'

const defaultFields = [
  'id',
  'vericred_id',
  'medicare_plan_id',
  'name',
  'audience',
  'plan_type',
  'premium_health',
  'premium_drug',
  'effective_date',
  'expiration_date'
]

export default class MedicareAdvantageBenefitsSetBulkEditsComponent extends Component {
  render() {
    return (
      <BenefitsSetBulkEditsComponent
        benefitLabels={benefitLabels}
        metadatumFields={metadatumFieldsDiffSet}
        defaultFields={defaultFields}
        benefitsSetUrl="/med_adv_benefits"
        productLine="medicare_advantage"
        {...this.props}
      />
    )
  }
}
