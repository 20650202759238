import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce, isNil } from 'lodash'
import SelectField from '../../common/SelectField'

export class ZipFipsComponent extends Component {
  static propTypes = {
    getZipCounties: PropTypes.func.isRequired,
    value: PropTypes.string,
    requestInProgress: PropTypes.bool,
    zipCounties: PropTypes.array,
    handleChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.debouncedGetZipCounties = debounce(this.props.getZipCounties, 250)
  }

  componentDidMount() {
    this.updateZipCountiesIfNeeded()
  }

  componentDidUpdate() {
    this.updateZipCountiesIfNeeded()
  }

  get zipCountyOptions() {
    const { requestInProgress, zipCounties } = this.props

    if (requestInProgress || isNil(zipCounties)) {
      return []
    }

    return zipCounties.map((zc) => ({
      text: `${zc.zip_code} - ${zc.county_name}`,
      value: `${zc.zip_code}-${zc.fips_code}`
    }))
  }

  handleChange = (name, value) => {
    this.props.handleChange(name, value)
  }

  handleInputChange = (value) => {
    if (value.length >= 3) {
      this.debouncedGetZipCounties(value)
    }
  }

  updateZipCountiesIfNeeded() {
    if (this.props.value && isNil(this.props.zipCounties) && !this.props.requestInProgress) {
      this.props.getZipCounties(this.props.value.split('-')[0])
    }
  }

  render() {
    return (
      <div>
        <SelectField
          key="zip_fips"
          name="zip_fips"
          label="Zip Counties"
          handleChange={this.handleChange}
          handleInputChange={this.handleInputChange}
          value={this.props.value}
          isLoading={this.props.requestInProgress}
          options={this.zipCountyOptions}
        />
      </div>
    )
  }
}

export default ZipFipsComponent
