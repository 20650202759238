import React from 'react'
import PropTypes from 'prop-types'
import { isArray, zipWith } from 'lodash'
import Component from './Component'
import '../../css/Accordion.scss'

export default class AccordionItem extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]),
    titleClass: PropTypes.array,
    name: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      class: 'section'
    }
  }

  get sectionHeadTitleComponents() {
    if (isArray(this.props.title)) {
      return zipWith(this.props.title, this.props.titleClass, (titleText, titleClass) => (
        <div
          className={`accordion-title ${titleClass}`}
          key={`section-head-title_${titleText}`}
        >
          {titleText}
        </div>
      ))
    }
    return <div className={this.props.titleClass}>{this.props.title}</div>
  }

  handleClick = () => {
    this.setState((prevState) => {
      return { open: !prevState.open, class: prevState.open ? 'section' : 'section open' }
    })
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  render() {
    const sectionHeadTitles = this.sectionHeadTitleComponents
    return (
      <div className={this.state.class} name={this.props.name}>
        <button title="Toggle" />
        <div className="pure-g sectionhead" name={`${this.props.name}-toggle`} onClick={this.handleClick}>
          {sectionHeadTitles}
        </div>
        <div className="articlewrap">
          <div className="article">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
  /* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
}
