import React from 'react'
import PropTypes from 'prop-types'
import { IoIosClose } from 'react-icons/io'
import { get, filter, map } from 'lodash'
import Component from '../common/Component'
import Modal from '../common/Modal'

export default class PublishErrorsModal extends Component {
  static propTypes = {
    record: PropTypes.object,
    handleClose: PropTypes.func
  }

  displayBenefitsSetError = () => {
    const benefitsSetError = get(this.props.record, 'benefits_set.publish_error')

    return benefitsSetError && (
      <div>
        <b>Benefits Set Publish Errors:</b>
        <ul><li>{ this.displayError(benefitsSetError) }</li></ul>
      </div>
    )
  }

  displayServiceAreaError = () => {
    const serviceAreaError = get(this.props.record, 'service_area.publish_error')

    return serviceAreaError && (
      <div>
        <b>Service Area Publish Errors:</b>
        <ul><li>{ this.displayError(serviceAreaError) }</li></ul>
      </div>
    )
  }

  displaySheetRateErrors = () => {
    const sheetRateErrors = filter(get(this.props.record, 'sheet_rates.publish_errors'))

    return sheetRateErrors.length > 0 && (
      <div>
        <b>Rates Publish Errors:</b>
        <ul>{sheetRateErrors.map((error, idx) => <li key={idx}>{this.displayError(error)}</li>)}</ul>
      </div>
    )
  }

  displayError = (error) => {
    try {
      const parsedError = JSON.parse(error)

      return map(parsedError, (v, k) => {
        return <div><i>{k}:</i> {v}</div>
      })
    } catch (e) {
      return error
    }
  }

  render() {
    const { handleClose } = this.props

    return (
      <Modal
        className="ipa-publish-errors-modal"
        handleClose={handleClose}
      >
        {this.displayBenefitsSetError()}
        {this.displaySheetRateErrors()}
        {this.displayServiceAreaError()}
        <button
          className="cancel-button"
          type="button"
          onClick={handleClose}
        ><IoIosClose />
        </button>
      </Modal>
    )
  }
}
