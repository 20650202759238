import React from 'react'
import qs from 'qs'
import { get, isEmpty, omit } from 'lodash'
import IndexComponent from '../common/IndexComponent'
import indexActions from '../../actions/MedicareAdvantageBenefitsSets'
import { BenefitsSetIndex } from '../BenefitsSet/BenefitsSetIndex'
import approvalStateDisplay from '../BenefitsSet/approvalStateDisplay'
import publishStateDisplayWithModal from '../BenefitsSet/publishStateDisplayWithModal'
import Component from '../common/Component'
import connectIndex from '../../lib/connectIndex'
import exportActions from '../../actions/BenefitsSetsExports'
import BulkPublishLink from '../BenefitsSet/BulkPublishLink'

export class MedicareAdvantageBenefitsSetIndex extends Component {
  static propTypes = {
    ...omit(BenefitsSetIndex.propTypes, 'productLine')
  }

  get bulkPublishButton() {
    const planCount = get(this.props.pagination, 'total', 0)

    if (planCount === 0 || isEmpty(this.queryParams)) {
      return null
    }

    return (
      <BulkPublishLink
        key="bulk-publish-link"
        planCount={planCount}
        queryParams={this.queryParams}
        productLine="medicare_advantage"
      />
    )
  }

  get queryParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    return (
      <div>
        <BenefitsSetIndex
          {...this.props}
          name="Medicare Advantage Benefits Set"
          columnsList={columnsList}
          includeBulkEditLink
          includeDiffSetLink
          includeSelectAll
          includeNewRecordLink={false}
          searchPlaceholder="Search by Name, Contract ID, Vericred ID, or Issuer ID"
          actionLinks={[this.bulkPublishButton]}
          productLine="medicare_advantage"
        />
      </div>
    )
  }
}

const columnsList = [
  { key: 'name', label: 'Name', display: BenefitsSetIndex.showLinkDisplay('/med_adv_benefits') },
  { key: 'issuer_name', label: 'Issuer' },
  { key: 'issuer_id', label: 'Issuer ID' },
  { key: 'vericred_id', label: 'Vericred ID', sortable: false },
  { key: 'medicare_plan_id', label: 'Contract ID', sortable: false },
  { key: 'plan_year', label: 'Year', sortable: false },
  { key: 'updated_at', label: 'Last Updated At', display: IndexComponent.dateTimeDisplay },
  { key: 'last_updated_by_username', label: 'Last Updated By' },
  {
    key: 'approval_state',
    label: 'Status',
    display: approvalStateDisplay,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  },
  {
    key: 'publish_state',
    label: 'Publish Status',
    display: publishStateDisplayWithModal,
    filterable: true,
    recordLabel: 'text',
    recordValue: 'value'
  }
]

const filters = [
  {
    key: 'approval_state',
    options: [
      { value: 'new', text: 'New' },
      { value: 'approved', text: 'Approved' },
      { value: 'rejected', text: 'Rejected' },
      { value: 'corrected', text: 'Corrected' },
      { value: 'amended', text: 'Amended' }
    ]
  },
  {
    key: 'publish_state',
    options: [
      { value: 'needs_publishing', text: 'Needs Publishing' },
      { value: 'failed_to_publish', text: 'Failed to publish' },
      { value: 'published', text: 'Published' },
      { value: 'unpublished', text: 'Unpublished' },
      { value: 'publishing', text: 'Publishing' }
    ]
  }
]

export default connectIndex(
  MedicareAdvantageBenefitsSetIndex,
  'medicareAdvantageBenefitsSets',
  indexActions,
  filters,
  {
    benefitsSetPath: '/med_adv_benefits',
    searchColumns: ['name', 'vericred_id', 'hios_id', 'issuer_id', 'document_id']
  },
  [
    { action: exportActions.create.main, key: 'createBenefitsSetExport' },
    { action: exportActions.create.reset, key: 'resetCreateBenefitsSetExport' }
  ],
  [
    { key: 'benefitsSetExport', reducer: 'benefitsSetsExports', action: 'create' }
  ]
)
