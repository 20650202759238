import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/Embargoes'
import connectIndex from '../../lib/connectIndex'
import IssuersDisplay from '../common/IssuersDisplay'

export class EmbargoIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterable: PropTypes.bool,
    filters: PropTypes.object,
    filterActions: PropTypes.object,
    includeSearch: PropTypes.bool,
    configuration: PropTypes.object,
    includeNewRecordLink: PropTypes.bool,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    filterable: true,
    includeSearch: true,
    includeNewRecordLink: true,
    searchColumns: ['name']
  }

  static columns = [
    { key: 'name', label: 'Name', sortable: false },
    { key: 'product_line', label: 'Product Line', sortable: false, filterable: true },
    { key: 'audience', label: 'Audience', sortable: false, filterable: true },
    { key: 'release_date', label: 'Release Date' },
    { key: 'start_date', label: 'Plan Effective Date' },
    { key: 'end_date', label: 'Plan Expiration Date' },
    { key: 'embargo_issuers', label: 'Issuers', display: IssuersDisplay }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/embargoes"
          name="Embargo"
          pagination={this.props.pagination}
          columns={EmbargoIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterable={this.props.filterable}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          includeSearch={this.props.includeSearch}
          searchPlaceholder="Search by Name"
          configuration={this.props.configuration}
          includeNewRecordLink={this.props.includeNewRecordLink}
          searchColumns={this.props.searchColumns}
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'product_line',
    config: 'embargoes.product_line'
  },
  {
    key: 'audience',
    config: 'embargoes.audience["Medical"]'
  }
]

export default connectIndex(EmbargoIndex, 'embargoes', actions, filters)
