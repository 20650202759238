import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isNil } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import actions from '../../actions/Embargoes'

export class EmbargoShow extends Component {
  static propTypes = {
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    embargo: PropTypes.object,
    match: PropTypes.object,
    configuration: PropTypes.object
  }

  componentDidMount() {
    this.props.show(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.reset()
  }

  get needsEmbargo() {
    if (isNil(this.props.embargo)) {
      return true
    }

    return get(this.props, 'embargo.id') !== parseInt(this.props.match.params.id, 10)
  }

  get issuerRows() {
    const issuerLabels = this.props.embargo.embargo_issuers.map((u) => u.issuer_label)
    const rows = []

    rows.push(
      <tr key="issuers-0">
        <td><strong>Issuer 1.</strong></td>
        <td>{issuerLabels[0]}</td>
      </tr>
    )

    return rows.concat(issuerLabels.slice(1).map((label, index) => (
      <tr key={`issuers-${index + 1}`}>
        <td><strong>{`Issuer ${index + 2}.`}</strong></td>
        <td>{label}</td>
      </tr>
    )))
  }

  formattedDateValue = (value) => {
    return moment(value).utc().format('YYYY-MM-DD')
  }

  render() {
    const { embargo, configuration } = this.props

    if (isNil(configuration)) {
      return null
    }

    if (this.needsEmbargo) {
      return null
    }

    return (

      <div className="content">
        <h2>Embargo Info</h2>
        <div>
          <table className="pure-table">
            <tbody>
              <tr>
                <td><strong>Name</strong></td>
                <td>{embargo.name}</td>
              </tr>
              <tr>
                <td><strong>Audience</strong></td>
                <td>{embargo.audience}</td>
              </tr>
              <tr>
                <td><strong>Product Line</strong></td>
                <td>{embargo.product_line}</td>
              </tr>
              {this.issuerRows}
              <tr>
                <td><strong>Release Date</strong></td>
                <td>{this.formattedDateValue(embargo.release_date)}</td>
              </tr>
              <tr>
                <td><strong>Plan Effective Date</strong></td>
                <td>{this.formattedDateValue(embargo.start_date)}</td>
              </tr>
              <tr>
                <td><strong>Plan Expiration Date</strong></td>
                <td>{this.formattedDateValue(embargo.end_date)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.embargoes.show

  const configuration = state.login.session.configuration.embargoes
  return {
    embargo: slice.attributes,
    configuration
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  show: actions.show.main,
  reset: actions.show.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmbargoShow)
