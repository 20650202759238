import { includes, filter, map } from 'lodash'

const requiresCalendarYear = (state, context) => {
  return state.metadatum.audience === 'Small Group'
    && (includes(context.small_group_calendar_year_only_issuers, state.metadatum.carrier_id)
      || includes(calendarYearOnlyStateIds(context), state.metadatum.state_id))
}

let calendarYearOnlyStateIds = (context) => {
  const calendarOnlyState = (state) => {
    return includes(context.small_group_calendar_year_only_states, state.name)
  }

  return map(filter(context.state_id, calendarOnlyState), (state) => {
    return state.value
  })
}

export default (originalState, context) => {
  const state = originalState

  if (requiresCalendarYear(state, context)) {
    state.metadatum.plan_calendar = 'Calendar Year'
  }

  return state
}
