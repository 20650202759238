import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil, startCase } from 'lodash'

export class SmokeCheckDetailComponent extends Component {
  static propTypes = {
    smokeCheckDetail: PropTypes.object
  }

  sectionTitle = (detailType) => ({
    BenefitsSetsSmokeCheckDetail: 'Benefits Sets',
    RatesSmokeCheckDetail: 'Rates',
    ServiceAreasSmokeCheckDetail: 'Service Areas'
  })[detailType]

  segmentRows = (detailData) => {
    return (
      Object.entries(detailData).map((segment) => {
        const segmentKey = `${startCase(segment[0])}`
        const segmentValues = Object.entries(segment[1]).map((val) => (
          <p key={val[0]}>{`${startCase(val[0])}: ${startCase(val[1])}`}</p>
        ))

        return (
          <tr>
            <td><strong>{segmentKey}</strong></td>
            <td>{segmentValues}</td>
          </tr>
        )
      })
    )
  }

  render() {
    const detail = this.props.smokeCheckDetail

    if (isNil(detail)) {
      return null
    }

    return (
      <div className="content">
        <div>
          <h5>{this.sectionTitle(detail.type)}</h5>
          <table className="pure-table">
            <tbody>
              {this.segmentRows(detail.data)}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default SmokeCheckDetailComponent
