import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/Carriers'
import carrierGroupActions from '../../actions/CarrierGroups'
import connectIndex from '../../lib/connectIndex'

export class CarrierIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    records: PropTypes.array,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['name', 'group_id']
  }

  static columns = [
    { key: 'name', label: 'Name' },
    { key: 'carrier_id', label: 'Carrier ID' },
    { key: 'carrier_id_type', label: 'Carrier ID Type' },
    { key: 'carrier_group.name', label: 'Carrier Group', filterable: true, remoteKey: 'carrier_group_id' }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/carriers"
          name="Carrier"
          pagination={this.props.pagination}
          columns={CarrierIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name or Carrier ID"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'carrier_group.name',
    path: 'carrierGroups.index',
    actions: {
      records: carrierGroupActions.index.main,
      reset: carrierGroupActions.index.reset
    }
  }
]

export default connectIndex(CarrierIndex, 'carriers', actions, filters)
