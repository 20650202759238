import ActionsGenerator from '../../lib/ActionsGenerator'

const generator = new ActionsGenerator(
  'bulk_plan_product_restores',
  '/bulk_plan_product_restores',
  'restores'
)
const actions = generator.generate()

export default actions
