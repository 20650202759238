import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, upperFirst, toPairs, isArray } from 'lodash'

export default class RadioButtonSet extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    defaultIndex: PropTypes.number,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    errorMessage: PropTypes.string,
    styles: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  get options() {
    const { values } = this.props

    if (!isArray(values)) {
      return toPairs(values).map((value, index) => ({
        text: value[1],
        value: value[0]
      }))
    }

    return values
  }

  handleChange(event) {
    this.props.handleChange(event.target.name, event.target.value)
  }

  handleBlur(event) {
    if (!this.props.handleBlur) {
      return
    }

    this.props.handleBlur(event.target.name)
  }

  render() {
    const {
      name, defaultIndex, disabled, hidden, errorMessage
    } = this.props

    const classNames = ['field']

    if (!isEmpty(errorMessage)) {
      classNames.push('field-with-error')
    }

    const radioComponents = this.options.map((option, index) => {
      const checked = (index === defaultIndex)
      const type = hidden ? 'hidden' : 'radio'

      /* eslint-disable jsx-a11y/label-has-for */
      return (
        <div className="radio-set-value" key={`${name}-${index}`} style={this.props.styles}>
          <label className="pure-radio">
            <input
              name={name}
              type={type}
              disabled={disabled}
              checked={checked}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={option.value}
            />
            <span>{option.text}</span>
          </label>
        </div>
      )
      /* eslint-enable jsx-a11y/label-has-for */
    })

    return (
      <div className={classNames.join(' ')}>
        { radioComponents }
        {errorMessage && <div className="error">{upperFirst(errorMessage)}</div>}
      </div>
    )
  }
}
