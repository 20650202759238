import React from 'react'
import { IoMdCheckmarkCircleOutline, IoIosCloseCircle, IoMdRemoveCircle } from 'react-icons/io'
import { startCase } from 'lodash'

const iconForApprovalState = (approvalState) => {
  switch (approvalState) {
  case 'approved':
    return <i title="Approved"><IoMdCheckmarkCircleOutline size={30} className="success" /></i>
  case 'rejected':
    return <i title="Rejected"><IoIosCloseCircle size={30} className="error" /></i>
  default:
    return <i title="Not Reviewed"><IoMdRemoveCircle size={30} /></i>
  }
}

const classNameForApprovalState = (approvalState) => {
  switch (approvalState) {
  case 'approved':
    return 'success'
  case 'corrected':
  case 'amended':
    return 'warning'
  case 'rejected':
    return 'error'
  case 'new':
    return ''
  default:
    return null
  }
}

const approvalStateDisplay = (record) => {
  const approvalState = record.approval_state

  const icon = iconForApprovalState(approvalState)
  const className = classNameForApprovalState(approvalState)

  return (
    <div className="approval-state">
      {icon}
      <strong title={approvalState} className={`${className}`}>
        <div>{startCase(approvalState)}</div>
      </strong>
    </div>
  )
}

export default approvalStateDisplay
