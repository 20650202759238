import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Component from '../common/Component'
import PublishErrorsModal from './PublishErrorsModal'

export default class PlanProductStatus extends Component {
  static propTypes = {
    record: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: false
    }
  }

  displayStatus = () => {
    const status = get(this.props.record, 'status')

    if (status === 'failed to publish') {
      return (
        <button type="button" className="text-button" onClick={this.handleStatusClick}>
          {status}
        </button>
      )
    }

    return status
  }

  handleModalClose = () => {
    this.setState({ showModal: false })
  }

  handleStatusClick = () => {
    this.setState({ showModal: true })
  }

  render() {
    const showModal = this.state.showModal

    return (
      <div>
        {this.displayStatus()}
        {showModal && <PublishErrorsModal record={this.props.record} handleClose={this.handleModalClose} />}
      </div>
    )
  }
}
