import React from 'react'
import { isNil, get, omit, reduce, map, flatMap, upperFirst, words } from 'lodash'
import PropTypes from 'prop-types'
import Component from '../common/Component'

export default class BulkEditsErrorsComponent extends Component {
  static propTypes = {
    bulkUpdate: PropTypes.object,
    explodedBenefitFieldsForRow: PropTypes.func.isRequired
  }

  titleCaseField = (fieldName) => {
    return words(fieldName).map(upperFirst).join(' ')
  }

  translatedResourceError = (resourceError) => {
    const metadata = omit(resourceError, ['id', 'benefits'])
    const benefits = reduce(this.props.explodedBenefitFieldsForRow(resourceError), (memo, value, key) => {
      if (!isNil(value)) {
        Object.assign(memo, { [key]: value })
      }

      return memo
    }, {})

    return map({ ...metadata, ...benefits }, (value, key) => ({
      id: resourceError.id,
      field: key,
      message: value
    }))
  }

  errorRows = (errors) => {
    return errors.map(this.errorRow)
  }

  errorRow = (error) => {
    return (
      <tr key={`${error.id}-${error.field}`}>
        <td>{error.id}</td>
        <td>{this.titleCaseField(error.field)}</td>
        <td>{upperFirst(error.message)}</td>
      </tr>
    )
  }

  render() {
    const resourceErrors = get(this.props.bulkUpdate, 'resourceErrors')

    if (isNil(resourceErrors)) {
      return null
    }

    return (
      <table id="errors">
        <thead>
          <tr>
            <th>ID</th>
            <th>Field</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {this.errorRows(flatMap(resourceErrors, this.translatedResourceError))}
        </tbody>
      </table>
    )
  }
}
