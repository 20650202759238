import axios from 'axios'
import { isNil, get } from 'lodash'
import client from '../../client'

import {
  CREATE_COPY,
  RECEIVE_COPY,
  RECEIVE_ERROR,
  RESET_REQUEST
} from './consts'

const cancelToken = axios.CancelToken.source()

function parseErrors(errors) {
  let generalError
  const resourceErrors = get(errors, 'response.data.errors')

  if (isNil(resourceErrors)) { generalError = get(errors, 'response.data.error') }

  return { generalError, resourceErrors: resourceErrors || errors.response.data }
}

function createCopy(attributes) {
  return (dispatch) => {
    dispatch({ type: CREATE_COPY, ...attributes })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      cancelToken: cancelToken.token
    }

    client.post('/benefits_set_copies', attributes, config)
      .then((response) => {
        return dispatch({
          type: RECEIVE_COPY,
          responseAttributes: response.data,
          responseHeaders: response.headers
        })
      })
      .catch((errors) => dispatch({ type: RECEIVE_ERROR, ...parseErrors(errors) }))
      .finally(() => axios.isCancel() && cancelToken.cancel())
  }
}

function resetCopy() {
  return (dispatch) => {
    dispatch({ type: RESET_REQUEST })
  }
}

export { createCopy, resetCopy }
