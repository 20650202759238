import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import SelectField from '../../common/SelectField'

export class CensusesSelectFieldComponent extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    getInProgress: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    displayNewCensus: PropTypes.bool,
    censuses: PropTypes.array,
    className: PropTypes.string
  }

  handleChange = (name, value) => {
    this.props.handleChange(name, value)
  }

  censusOptions(censuses) {
    if (!censuses) {
      return []
    }

    const censusOptions = censuses.map((census) => ({
      text: census.name,
      value: census.id
    }))

    let formattedOptions = [...censusOptions]

    if (this.props.displayNewCensus) {
      const createOption = {
        text: 'New Census',
        value: 'NewCensus'
      }

      formattedOptions = [createOption, ...censusOptions]
    }

    return formattedOptions
  }

  render() {
    const { censuses, className } = this.props

    const censusOptions = this.censusOptions(censuses)
    const disabled = isNil(censuses)
    return (
      <div className={className || 'census-selection'}>
        <SelectField
          key="census_id"
          name="census_id"
          label="Censuses"
          handleChange={this.handleChange}
          value={this.props.value}
          isLoading={this.props.getInProgress}
          options={censusOptions}
          disabled={disabled}
        />
      </div>
    )
  }
}

export default CensusesSelectFieldComponent
