import { isFunction, toUpper } from 'lodash'
import client from '../../client'
import parseError from '../parseError'

export default class MethodActionGenerator {
  constructor(method, name, basePath, parameterName) {
    this.method = method
    this.name = name
    this.basePath = basePath
    this.typeName = toUpper(name)
    this.parameterName = parameterName

    this.types = {
      reset: `${this.typeName}_${this.method}_RESET`,
      request: `${this.typeName}_${this.method}_REQUEST`,
      response: `${this.typeName}_${this.method}_RESPONSE`,
      error: `${this.typeName}_${this.method}_ERROR`
    }
  }

  get client() {
    return client
  }

  urlWithParams = (params) => {
    if (isFunction(this.basePath)) {
      return this.basePath(params)
    }

    return this.basePath
  }

  parseError(error) {
    return parseError(error)
  }

  generateActions() {
    return {
      reset: this.resetActionGenerator(),
      main: this.mainActionGenerator()
    }
  }

  resetActionGenerator() {
    return () => (dispatch) => {
      return dispatch({ type: this.types.reset })
    }
  }
}
