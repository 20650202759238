// react base classes
import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil, get } from 'lodash'
import { IoIosFunnel } from 'react-icons/io'

import IndexComponent from '../common/IndexComponent'
import IndexFilterModal from '../common/IndexFilterModal'
import publishStateDisplayWithModal from '../BenefitsSet/publishStateDisplayWithModal'

import Component from '../common/Component'
import BenefitsSetsHelper from '../BenefitsSet/Helpers/BenefitsSetsHelper'

class RateFactorDocumentIndex extends Component {
  static propTypes = {
    records: PropTypes.array
  }

  // Configure the columns to show as well as whether or not they are filterable or sortable
  static columns = [
    {
      key: 'effective_date',
      label: 'EffectiveDate',
      filterable: true,
      sortable: false
    },
    {
      key: 'expiration_date',
      label: 'ExpirationDate',
      filterable: true,
      sortable: false
    },
    {
      key: 'document',
      label: 'Source Document',
      display: IndexComponent.documentDisplay,
      filterable: false,
      sortable: false
    },
    {
      key: 'total_benefit_sets',
      label: 'Total Benefits Sets',
      filterable: false,
      sortable: false
    },
    {
      key: 'publish_status',
      label: 'Publish Status',
      display: publishStateDisplayWithModal,
      filterable: false,
      sortable: false
    },
    {
      key: 'publish_date',
      label: 'Publish Date',
      filterable: false,
      sortable: false
    }
  ]

  constructor(props) {
    super(props)

    this.state = {
      enrollmentDate: '',
      displayFilterSelection: false,
      columnFilters: {}
    }
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  get filterModal() {
    if (isEmpty(this.state.column) || this.state.column.filterable !== true) {
      return null
    }

    if (this.state.displayFilterSelection !== true) {
      return null
    }

    const values = BenefitsSetsHelper.planYearOptions(2015, (new Date()).getFullYear() + 1)
    const options = { records: values, type: 'standardSelectField' }

    return (
      <IndexFilterModal
        label={this.state.column.label}
        filterValues={options}
        handleClose={this.handleFilterClose}
        handleSelected={this.handleFilterSelected}
        initialValue={this.state.columnFilters[this.state.column.key]}
        remoteKey={this.state.column.key}
      />
    )
  }

  get tableHeader() {
    return (
      <thead>
        <tr>
          {RateFactorDocumentIndex.columns.map((column) => this.headerCell(column))}
        </tr>
      </thead>
    )
  }

  get content() {
    const {
      records
    } = this.props

    if (isNil(records)) {
      return null
    }

    let filteredRecords = records
    if (this.state.enrollmentDate.trim() !== '') {
      filteredRecords = records.filter((record) => this.filterRecord(record))
    } else {
      filteredRecords = records.filter((record) => isEmpty(this.state.columnFilters)
                                                    || this.columnFiltered('effective_date', record)
                                                    || this.columnFiltered('expiration_date', record))
    }

    let sortedRecords = filteredRecords.reduce((sorted, record) => {
      const innerSorted = { ...sorted }
      innerSorted[record.formula_key] = innerSorted[record.formula_key] || []
      if (this.state.enrollmentDate.trim() === '') {
        innerSorted[record.formula_key].push(record)
      } else if (isNil(innerSorted[record.formula_key][0])
                 || innerSorted[record.formula_key][0].publish_date < record.publish_date) {
        innerSorted[record.formula_key][0] = record
      }

      return innerSorted
    }, {})

    if (isEmpty(sortedRecords)) {
      sortedRecords = records.reduce((sorted, record) => {
        const innerSorted = { ...sorted }
        innerSorted[record.formula_key] = innerSorted[record.formula_key] || []
        return innerSorted
      }, {})
    }

    return (
      <div> {Object.keys(sortedRecords).map((key) => this.rateFactorDisplay(key, sortedRecords[key]))}
        {this.filterModal}
      </div>
    )
  }

  headerCell = (column) => {
    return (
      <td key={`header-cell-${column.key}`} className="header-cell">
        <div>
          <div className="header-label">{column.label}</div>
          <div className="header-actions">

            {this.filterButton(column)}
          </div>
        </div>
      </td>
    )
  }

  defaultDisplay = (record, column) => {
    return get(record, column.key)
  }

  valueCell = (record, column) => {
    let displayValue
    if (!isNil(column.display)) {
      displayValue = column.display(record, column)
    } else {
      displayValue = this.defaultDisplay(record, column)
    }

    const safeKey = `${column.key}`.toLowerCase().replace(/[^a-z]+/g, '-')
    return <td key={`row-cell-${safeKey}`} className={`cell-${safeKey}`}>{displayValue}</td>
  }

  handleFilterClose = () => {
    this.setState({ displayFilterSelection: false })
  }

  handleFilterSelected = (name, value) => {
    this.setState((prevState) => {
      let newFilter = {}
      if (!isNil(value) && !isEmpty(value)) {
        newFilter = { ...prevState.columnFilters, [name]: value }
      } else {
        newFilter = { ...prevState.columnFilters }
        delete newFilter[name]
      }
      return { columnFilters: newFilter }
    })
    this.setState({ displayFilterSelection: false })
  }

  handleUpdateFilterButton(event, column) {
    event.preventDefault()

    this.setState({ displayFilterSelection: true,
      column })
  }

  handleFilterButton(event, column) {
    event.preventDefault()

    this.setState({ displayFilterSelection: true, column })
  }

  handleDateChange(event) {
    this.setState({
      enrollmentDate: event.target.value
    })
  }

  rows(record) {
    let className = ''
    if (get(record, 'deleted?')) {
      className = 'deleted-record'
    }
    return (
      <tr key={`row-${record.id}`} className={className}>
        {RateFactorDocumentIndex.columns.map((column) => this.valueCell(record, column))}
      </tr>
    )
  }

  header(name) {
    return (
      <header className="form-header">
        <div>
          <div className="form-header-title">
            <h2><strong>{(name)}</strong></h2>
          </div>
        </div>
      </header>
    )
  }

  filterButton(column) {
    if (column.filterable !== true) {
      return null
    }

    if (this.isFiltered(column)) {
      return this.updateFilterButton(column)
    }
    return this.enableFilterButton(column)
  }

  filterRecord(record) {
    return (new Date(record.effective_date) <= new Date(this.state.enrollmentDate)
            && new Date(record.expiration_date) >= new Date(this.state.enrollmentDate)
            && record.publish_state === 'published')
  }

  isFiltered(column) {
    return !isEmpty(this.state.columnFilters) && !isNil(this.state.columnFilters[column.key])
  }

  columnFiltered(columnName, record) {
    return (columnName in this.state.columnFilters
            && this.state.columnFilters[columnName].includes((new Date(record[columnName])).getUTCFullYear()))
  }

  enableFilterButton(column) {
    return (
      <button
        name={column.key}
        type="button"
        onClick={(event) => this.handleFilterButton(event, column)}
        className="hidden-button filter-button"
      >
        Filter
        <i><IoIosFunnel /></i>
      </button>
    )
  }

  filterText(column) {
    const count = this.state.columnFilters[column.key].length

    return `${count} Selected`
  }

  updateFilterButton(column) {
    return (
      <button
        type="button"
        onClick={(event) => this.handleUpdateFilterButton(event, column)}
        className="current hidden-button remove-filter-button"
      >
        {this.filterText(column)}
        <i><IoIosFunnel /></i>
      </button>
    )
  }

  rateFactorDisplay(name, records) {
    return (
      <div key={`ratefactordisplay-${name}`}>
        {this.header(name)}

        <div className="full-width-table-container">
          <table className="pure-table full-width-table index-table">
            {this.tableHeader}
            <tbody>
              {records.map((record) => this.rows(record))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="content">
        <h5>Active Rate Factors by enrollment date</h5>
        <div className="field">
          <input
            className="pure-input-1"
            name="enrollmentDate"
            type="date"
            onChange={this.handleDateChange}
            value={this.state.enrollmentDate}
          />
        </div>
        {this.content}
      </div>
    )
  }
}

export default RateFactorDocumentIndex
