import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Router, Route, Switch } from 'react-router-dom'
import { isUndefined, isNil, isEmpty, get } from 'lodash'
import pluralize from 'pluralize'
import { LicenseManager } from 'ag-grid-enterprise/main'
import qs from 'qs'

import 'node-normalize-scss/_normalize.scss'
import 'purecss/build/pure.css'
import 'purecss/build/grids.css'
import 'purecss/build/grids-responsive.css'
import 'react-select/dist/react-select.css'
import 'ag-grid/dist/styles/ag-grid.css'
import 'ag-grid/dist/styles/ag-theme-balham.css'

import Login from './Login'
import Menu from './common/Menu'
import MenuButton from './common/MenuButton'
import EntitledRoutes from './EntitledRoutes'
import routerHistory from '../routerHistory'
import PasswordReset from './PasswordReset'
import ForgotPassword from './ForgotPassword'
import FlashMessage from './common/FlashMessage'
import { getCurrentSession, clearSession } from '../actions/Login'
import { menuSections } from './RouteDefinitions'

import '../css/Howie.scss'

pluralize.addPluralRule('embargo', 'embargoes')

LicenseManager.setLicenseKey(
  'Evaluation_License_Valid_Until__25_August_2018__MTUzNTE1MTYwMDAwMA==53aedc27a9df43c4e4bd6c97d9e6f413'
)

class Howie extends Component {
  static propTypes = {
    needsSession: PropTypes.bool.isRequired,
    needsSessionUser: PropTypes.bool.isRequired,
    getCurrentSession: PropTypes.func.isRequired,
    clearSession: PropTypes.func.isRequired,
    entitlements: PropTypes.object,
    flashMessages: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount() {
    if (this.props.needsSessionUser && !this.props.needsSession) {
      this.props.getCurrentSession()
    }

    const redirectTo = qs.parse(window.location.search, { ignoreQueryPrefix: true }).redirect_to
    if (redirectTo) {
      routerHistory.push(redirectTo)
    }
  }

  get entitledSectionRoutes() {
    if (isNil(this.props.entitlements)) {
      return []
    }

    return menuSections.reduce((memo, sectionRoute) => {
      const routes = sectionRoute.routes.filter(({ group, action }) => {
        return this.userEntitledTo(group, action)
      })

      if (!isEmpty(routes)) {
        memo.push({
          title: sectionRoute.title,
          routes: routes.map(({ title, path, label }) => ({ title, path, label }))
        })
      }

      return memo
    }, [])
  }

  get router() {
    return (
      <Router history={routerHistory}>
        <div>
          <header className="main">
            <div>
              <a href="/">
                <h1>Howie</h1>
              </a>
            </div>
            <Menu sectionRoutes={this.entitledSectionRoutes} />
            <div className="actions">
              {this.logoutButton()}
            </div>
          </header>
          <div className="general-flash-message">
            <FlashMessage messages={this.props.flashMessages} tag="general" />
          </div>
          <EntitledRoutes entitlements={this.props.entitlements} />
          <Route exact path="/password_reset" component={PasswordReset} />
        </div>
      </Router>
    )
  }

  userEntitledTo = (group, action) => {
    const { entitlements } = this.props

    return get(entitlements, `${group}.${action}.access`, false)
  }

  login() {
    return (
      <Router history={routerHistory}>
        <div className="MainContainer">
          <Switch>
            <Route exact key="passwordReset" path="/password_reset" component={PasswordReset} />
            <Route exact key="forgotPassword" path="/forgot_password" component={ForgotPassword} />
            <Route key="login" component={Login} />
          </Switch>
        </div>
      </Router>
    )
  }

  handleLogout() {
    this.props.clearSession()
  }

  logoutButton() {
    return <MenuButton title="Logout" handleClick={this.handleLogout} />
  }

  render() {
    const { entitlements, needsSession, needsSessionUser } = this.props

    if (needsSession) {
      return this.login()
    }

    if (needsSessionUser) {
      return null
    }

    if (isNil(entitlements)) {
      return null
    }

    return this.router
  }
}

const mapStateToProps = (state) => ({
  needsSession: !isUndefined(state.login.session.generalError) || isUndefined(state.login.session.nonce),
  needsSessionUser: isUndefined(state.login.session.user),
  userType: get(state, 'login.session.user.user_type'),
  sessionError: get(state, 'login.session.generalError'),
  entitlements: get(state, 'login.session.configuration.entitlements'),
  flashMessages: state.flashMessages.messages
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ getCurrentSession, clearSession }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Howie)
