import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class MenuLink extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }

  render() {
    const currentPathname = global.window.location.pathname
    const { title, path } = this.props

    if (currentPathname === path) {
      return (
        <span className="pure-menu-disabled pure-menu-link menu-current">{title}</span>
      )
    }

    return (
      <Link className="pure-menu-link menu-link" title={title} to={path}>{title}</Link>
    )
  }
}

export default MenuLink
