import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cloneDeep, get, isNil, isEmpty } from 'lodash'
import Component from '../common/Component'
import SubmitButton from '../common/SubmitButton'
import ConnectedFileUploaderComponent from '../common/ConnectedFileUploaderComponent'
import DocumentSelectField from '../common/DocumentSelectField'
import actions from '../../actions/RatesBatches'
import RatesBatchCreateErrorsComponent from './RatesBatchCreateErrorsComponent'
import CheckboxField from '../common/CheckboxField'

export class RatesBatchCreate extends Component {
  static propTypes = {
    resourceErrors: PropTypes.array,
    requestInProgress: PropTypes.bool,
    created: PropTypes.bool.isRequired,
    id: PropTypes.number,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  }

  static defaultProps = {
    requestInProgress: false
  }

  constructor(props) {
    super(props)

    this.state = {
      values: {},
      unchangedSinceLastSubmission: false
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  get submitButton() {
    const submitDisabled = isNil(this.state.values.import_file_blob_id)
      || isNil(this.state.values.document_id)
      || this.state.unchangedSinceLastSubmission

    return (
      <SubmitButton
        title="Submit"
        text="Submit"
        loading={this.props.requestInProgress}
        disabled={submitDisabled}
      />
    )
  }

  get draftRates() {
    if (isEmpty(this.props.resourceErrors)) {
      return null
    }

    return (
      <RatesBatchCreateErrorsComponent
        errors={this.props.resourceErrors}
      />
    )
  }

  handleChange = (field, value) => {
    this.props.reset()

    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)

      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        newState[field] = value
      }

      return { values: newState, unchangedSinceLastSubmission: false }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.create(this.state.values)

    this.setState({ unchangedSinceLastSubmission: true })
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const { resourceErrors, created, id } = this.props

    if (created && !isNil(id)) {
      return this.redirect(`/rates_batches_export/${id}`)
    }

    return (
      <div className="content">
        <h2>Upload Rates</h2>
        <div>
          <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
            <fieldset>
              <DocumentSelectField
                key="document_id"
                name="document_id"
                filterFields={{ document_source_contents: 'Rates' }}
                handleChange={this.handleChange}
                value={this.state.values.document_id}
              />
              <ConnectedFileUploaderComponent
                key="import_file_blob_id"
                name="import_file_blob_id"
                label="Import File"
                handleChange={this.handleChange}
                errorMessage={get(resourceErrors, 'import_file_blob_id')}
                acceptedMimeTypes="text/csv"
                value={get(this.state.values, 'import_file_blob_id')}
              />
              <CheckboxField
                key="monthly_rates"
                name="monthly_rates"
                label="Monthly Rates?"
                handleChange={this.handleChange}
                value={this.state.values.monthly_rates}
              />
            </fieldset>
            <a
              id="age_banded_rates_template"
              href="/rates_templates/age_banded_rates_template.csv"
              className="button quiet-button small"
            >
Age Banded Rates Template
            </a>
            <a
              id="family_tiered_rates_template"
              href="/rates_templates/family_tiered_rates_template.csv"
              className="button quiet-button small"
            >
Family Tiered Rates Template
            </a>
            <fieldset>
              {this.submitButton}
            </fieldset>
            <fieldset>
              {this.draftRates}
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.ratesBatches.create

  return {
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    id: slice.id,
    created: slice.created
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  create: actions.create.main,
  reset: actions.create.reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RatesBatchCreate)
