import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isNil } from 'lodash'
import Component from '../common/Component'
import actions from '../../actions/CarrierGroups'
import { createFlashMessage } from '../../actions/FlashMessages'
import CarrierGroupForm from './CarrierGroupForm'
import EntitlementsProvider from '../common/EntitlementsProvider'
import alert from '../../alert'
import confirm from '../../confirm'

export class CarrierGroupEdit extends Component {
  static propTypes = {
    match: PropTypes.object,
    configuration: PropTypes.object,
    entitlements: PropTypes.object,
    update: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showReset: PropTypes.func.isRequired,
    requestInProgress: PropTypes.bool,
    generalError: PropTypes.string,
    resourceErrors: PropTypes.object,
    destroyResourceErrors: PropTypes.object,
    attributes: PropTypes.object,
    updated: PropTypes.bool.isRequired,
    destroy: PropTypes.func.isRequired,
    destroyReset: PropTypes.func.isRequired,
    deleted: PropTypes.bool.isRequired,
    createFlashMessage: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.show(this.id)
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.showReset()
    this.props.destroyReset()
  }

  get id() {
    return this.props.match.params.id
  }

  get destroyError() {
    return get(this.props.destroyResourceErrors, 'base')
  }

  handleSubmit = (values) => {
    this.props
      .update(this.id, values)
      .then(({ id }) => {
        this.props.createFlashMessage('Updated carrier group', { id: parseInt(id, 10), tag: 'index' })
      })
  }

  handleDelete = () => {
    const confirmed = confirm('Are you sure you want to delete this group?')

    if (confirmed) {
      this.props.destroy(this.id)
        .then(() => {
          this.props.createFlashMessage(`Deleted carrier group ‘${this.props.attributes.name}’`, { tag: 'index' })
        })
    }
  }

  redirect(path) {
    return <Redirect push to={path} />
  }

  render() {
    const {
      generalError,
      resourceErrors,
      configuration,
      entitlements,
      requestInProgress,
      attributes,
      updated,
      deleted
    } = this.props

    if (!isNil(generalError)) {
      alert(generalError)
    }

    if (!isNil(this.destroyError)) {
      alert(this.destroyError)
    }

    if (isNil(attributes)) {
      return null
    }

    if (updated) {
      return this.redirect('/carrier_groups')
    }

    if (deleted) {
      return this.redirect('/carrier_groups')
    }

    const errors = isNil(resourceErrors) ? {} : resourceErrors

    return (
      <div className="content">
        <header className="form-header">
          <div>
            <div className="form-header-title">
              <h2>Edit Carrier Group</h2>
            </div>
            <nav>
              <button
                type="button"
                className="button destructive small"
                title="Delete"
                onClick={this.handleDelete}
              >
Delete
              </button>
            </nav>
          </div>
        </header>
        <div>
          <EntitlementsProvider entitlements={entitlements}>
            <CarrierGroupForm
              handleSubmit={this.handleSubmit}
              errors={errors}
              configuration={configuration}
              requestInProgress={requestInProgress}
              submitLabel="Update Carrier Group"
              initialValues={attributes}
            />
          </EntitlementsProvider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const slice = state.carrierGroups.update
  const showSlice = state.carrierGroups.show
  const destroySlice = state.carrierGroups.destroy

  const configuration = state.login.session.configuration.carrier_groups
  const entitlements = state.login.session.configuration.entitlements.carrier_groups.update

  return {
    generalError: slice.generalError,
    resourceErrors: slice.resourceErrors,
    requestInProgress: slice.requestInProgress,
    attributes: showSlice.attributes,
    id: slice.id,
    updated: slice.updated,
    deleted: destroySlice.deleted,
    destroyResourceErrors: destroySlice.resourceErrors,
    configuration,
    entitlements
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  update: actions.update.main,
  show: actions.show.main,
  destroy: actions.destroy.main,
  reset: actions.update.reset,
  showReset: actions.show.reset,
  destroyReset: actions.destroy.reset,
  createFlashMessage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CarrierGroupEdit)
