import MethodReducerGenerator from './MethodReducerGenerator'

export default class ShowReducerGenerator extends MethodReducerGenerator {
  constructor(name, basePath) {
    super('SHOW', name, basePath)
  }

  static initialState = {
    requestInProgress: false,
    generalError: undefined,
    resourceErrors: undefined,
    attributes: undefined,
    id: undefined,
    responseHeaders: undefined
  }

  requestHandler(state, action) {
    return { ...state,
      requestInProgress: true,
      id: action.id }
  }

  responseHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      id: action.id,
      attributes: action.attributes,
      responseHeaders: action.responseHeaders }
  }

  errorHandler(state, action) {
    return { ...state,
      requestInProgress: false,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors }
  }
}
