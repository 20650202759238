import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/Documents'
import connectIndex from '../../lib/connectIndex'
import SourceContentCellDisplayRenderer from './SourceContentCellDisplayRenderer'
import IssuersCellDisplayRenderer from './IssuersCellDisplayRenderer'
import BooleanCellDisplayRenderer from '../common/BooleanCellDisplayRenderer'
import StateCellDisplayRenderer from '../common/StateCellDisplayRenderer'

export class DocumentIndex extends Component {
  static propTypes = {
    name: PropTypes.string,
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterable: PropTypes.bool,
    filters: PropTypes.object,
    filterActions: PropTypes.object,
    includeSearch: PropTypes.bool,
    configuration: PropTypes.object,
    includeNewRecordLink: PropTypes.bool,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    name: 'Document',
    filterable: true,
    includeSearch: true,
    includeNewRecordLink: true,
    searchColumns: ['name', 'vericred_id', 'adapter_name', 'issuer', 'issuer_id', 'private_exchange']
  }

  static planTypeDisplay = (record, column, config) => {
    const documentPlanTypes = record[column.key]
    const planTypes = documentPlanTypes.map((documentPlanType) => (documentPlanType.plan_type))
    return (planTypes.join(', '))
  }

  static yearQuartersDisplay = (record, column, config) => {
    const documentYearQuarters = record[column.key]
    const yearQuarters = documentYearQuarters.map((documentYearQuarter) => (documentYearQuarter.year_quarter))
    return (yearQuarters.join(', '))
  }

  static editLinkDisplay = (record) => {
    return <Link title={record.vericred_id} to={`documents/${record.vericred_id}/edit`}>{record.vericred_id}</Link>
  }

  static S3UrlLinkDisplay = (record) => {
    return <a href={record.public_url} target="_blank" rel="noopener noreferrer">Download</a>
  }

  static planAudiencesDisplay = (record, column, config) => {
    const documentAudiences = record[column.key]
    const audiences = documentAudiences.map((documentAudience) => (documentAudience.plan_audience))
    return (audiences.join(', '))
  }

  static dateDisplay = (record, column, config) => {
    const value = get(record, column.key, [])

    return value ? moment(value).utc().format('YYYY-MM-DD') : null
  }

  static columns = [
    { key: 'vericred_id', label: 'Document ID', display: DocumentIndex.editLinkDisplay },
    { key: 'download', label: 'Download', display: DocumentIndex.S3UrlLinkDisplay },
    { key: 'name', label: 'Name' },
    {
      key: 'document_issuers',
      label: 'Issuers',
      display: IssuersCellDisplayRenderer,
      filterable: false,
      sortable: false
    },
    {
      key: 'document_year_quarters',
      label: 'Year Quarters',
      display: DocumentIndex.yearQuartersDisplay,
      filterable: true,
      sortable: false
    },
    {
      key: 'state_id',
      label: 'State',
      display: StateCellDisplayRenderer,
      filterable: true,
      recordLabel: 'text',
      recordValue: 'value'
    },
    {
      key: 'document_plan_audiences',
      label: 'Audiences',
      display: DocumentIndex.planAudiencesDisplay,
      filterable: true
    },

    { key: 'source', label: 'Source', filterable: true },
    { key: 'product_line', label: 'Product Line', filterable: true, sortable: true },
    {
      key: 'document_plan_types',
      label: 'Plan Types',
      display: DocumentIndex.planTypeDisplay,
      filterable: true,
      sortable: false
    },
    {
      key: 'requires_manual_build',
      label: 'Requires Manual Build',
      display: BooleanCellDisplayRenderer,
      filterable: true,
      sortable: false
    },
    {
      key: 'created_at',
      label: 'Upload Date',
      display: DocumentIndex.dateDisplay,
      filterable: false,
      sortable: true
    },
    {
      key: 'last_processed_at',
      label: 'Last Processed',
      display: DocumentIndex.dateDisplay,
      filterable: false,
      sortable: true
    },
    {
      key: 'adapter_name',
      label: 'Adapter Name',
      filterable: false,
      sortable: true
    },
    {
      key: 'document_source_contents',
      label: 'Source Contents',
      display: SourceContentCellDisplayRenderer,
      filterable: true,
      sortable: false
    },
    {
      key: 'privileged',
      label: 'Privileged',
      filterable: true,
      sortable: false,
      display: IndexComponent.booleanDisplay
    },
    {
      key: 'private_exchange_name',
      label: 'Private Exchange',
      filterable: false,
      sortable: true,
      recordLabel: 'text',
      recordValue: 'value'
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/documents"
          name={this.props.name}
          pagination={this.props.pagination}
          columns={DocumentIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterable={this.props.filterable}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          includeSearch={this.props.includeSearch}
          configuration={this.props.configuration}
          includeNewRecordLink={this.props.includeNewRecordLink}
          searchColumns={this.props.searchColumns}
          actions={[]}
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'document_year_quarters',
    config: 'documents.year_quarters'
  },
  {
    key: 'state_id',
    config: 'states'
  },
  {
    key: 'document_plan_audiences',
    config: 'documents.audience'
  },
  {
    key: 'source',
    config: 'documents.source'
  },
  {
    key: 'document_source_contents',
    config: 'documents.source_contents'
  },
  {
    key: 'product_line',
    config: 'documents.product_line'
  },
  {
    key: 'requires_manual_build',
    options: [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' }
    ]
  },
  {
    key: 'document_plan_types',
    config: 'documents.plan_types'
  },
  {
    key: 'privileged',
    options: [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' }
    ]
  },
  {
    key: 'private_exchange_name',
    config: 'private_exchanges'
  }
]

export default connectIndex(DocumentIndex, 'documents', actions, filters)
