import ReducerGenerator from '../../lib/ReducerGenerator'

const generator = new ReducerGenerator(
  'bulk_benefits_set_updates',
  '/bulk_benefits_set_updates',
  'updates'
)
const reducer = generator.generate()

export default reducer
