import React from 'react'
import PropTypes from 'prop-types'
import { IoIosDocument } from 'react-icons/io'
import Component from '../common/Component'

export default class SourceDocumentCellRenderer extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired
  }

  render() {
    return (
      <a href={this.props.value.public_url} target="_blank" rel="noopener noreferrer">
        <IoIosDocument size={20} />
        {this.props.value.source}
      </a>
    )
  }
}
