import { uniq, forEach, has } from 'lodash'

export default class DiffSetColumns {
  constructor(records, metadataFields, benefitFields, networkTiers) {
    this.records = records
    this.metadataFields = metadataFields
    this.benefitFields = benefitFields
    this.networkTiers = networkTiers
  }

  columns() {
    let ret = []
    ret = ret.concat(this.metadataFieldsWithDiffs())
    ret = ret.concat(this.benefitFieldsWithDiffs())
    return uniq(ret)
  }

  metadataFieldsWithDiffs() {
    let ret = []
    forEach(this.records, (record) => {
      forEach(this.metadataFields, (definition, key) => {
        if (record.approved_benefits_set[key] !== record.full_benefits_set[key]) {
          ret.push(key)
        }
        ret = uniq(ret)
      })
    })
    return ret
  }

  benefitFieldsWithDiffs() {
    let ret = []
    forEach(this.records, (record) => {
      forEach(this.benefitFields, (name, key) => {
        forEach(this.networkTiers, (tier) => {
          if (this.hasBenefitChange(record, key, tier.key)) {
            ret.push(`${key}_${tier.key}`)
          }
        })
      })
      ret = uniq(ret)
    })
    return ret
  }

  hasBenefitChange(record, key, tier) {
    if (!has(record.full_benefits_set.benefits, key)) { return false }

    return record.approved_benefits_set.benefits[key][tier] !== record.full_benefits_set.benefits[key][tier]
  }
}
