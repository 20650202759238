import React from 'react'
import { clone } from 'lodash'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import DocumentSelectField from '../common/DocumentSelectField'

export default class RateFactorDocumentCreate extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { value: this.props.value }
  }

  handleChange = (name, value) => {
    const newValue = clone(this.props.value)
    newValue[name] = value
    this.setState({ value })
    this.props.handleChange(this.props.name, newValue)
  }

  render() {
    return (
      <fieldset className="pure-group">
        <h3>{this.props.name}</h3>
        <DocumentSelectField
          name="blob_id"
          recordValue="blob_id"
          filterFields={{ document_source_contents: ['Service Areas', 'Rates'] }}
          handleChange={this.handleChange}
          value={this.state.value}
        />
      </fieldset>
    )
  }
}
