import {
  RECEIVE_SCENARIO_FEEDBACK,
  RECEIVE_SCENARIO_FEEDBACK_ERROR,
  REQUEST_CREATE_SCENARIO_FEEDBACK,
  CLEAR_SCENARIO
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  createInProgress: undefined,
  action: undefined
}

export default function ScenariosAudienceReducer(state = defaultState, action) {
  switch (action.type) {
  case RECEIVE_SCENARIO_FEEDBACK:
    return { ...state,
      createInProgress: false,
      actionTaken: action.action }
  case RECEIVE_SCENARIO_FEEDBACK_ERROR:
    return { ...state,
      createInProgress: true,
      actionTaken: undefined }
  case REQUEST_CREATE_SCENARIO_FEEDBACK:
    return { ...state,
      createInProgress: true,
      actionTaken: undefined }
  case CLEAR_SCENARIO:
    return { ...state,
      createInProgress: undefined,
      actionTaken: undefined }
  default:
    return { ...state }
  }
}
