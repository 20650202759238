import React from 'react'
import PropTypes from 'prop-types'
import Component from '../common/Component'
import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/IssuerStatuses'
import carrierBrandActions from '../../actions/CarrierBrands'
import connectIndex from '../../lib/connectIndex'

export class IssuerStatusIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static searchColumns= ['name', 'issuer_id', 'state', 'market']

  static defaultProps = {
    searchColumns: this.searchColumns
  }

  static columns = [
    {
      key: 'carrier_brand_name',
      label: 'Issuer Carrier Brand Name',
      filterable: true,
      sortable: true
    },
    {
      key: 'issuer.name',
      label: 'Issuer Name',
      filterable: false,
      sortable: false
    },
    {
      key: 'issuer.issuer_id',
      label: 'Issuer ID',
      sortable: false,
      filterable: false
    },
    {
      key: 'state_code',
      label: 'State',
      sortable: false,
      filterable: false
    },
    {
      key: 'active_from',
      label: 'Active From'
    },
    {
      key: 'active_until',
      label: 'Active Until'
    },
    {
      key: 'product_line',
      label: 'Line of Coverage',
      filterable: true,
      sortable: false
    },
    {
      key: 'plan_audience',
      label: 'Market',
      filterable: true,
      sortable: false
    },
    {
      key: 'privileged',
      label: 'Privileged',
      filterable: false,
      sortable: false,
      display: IndexComponent.booleanDisplay
    },
    {
      key: 'private_exchange.name',
      label: 'Private Exchange',
      filterable: false,
      sortable: false
    },
    {
      key: 'pd_score',
      label: 'PD Score'
    },
    {
      key: 'pr_score',
      label: 'PR Score'
    },
    {
      key: 'sa_score',
      label: 'SA Score'
    },
    {
      key: 'sbc_score',
      label: 'SBC Score'
    }
  ]

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/issuer_statuses"
          name="Issuer Status"
          pagination={this.props.pagination}
          columns={IssuerStatusIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name or Issuer ID"
          searchColumns={this.props.searchColumns}
          filterable
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'issuer.carrier_brand_name',
    path: 'carrier_brands.index',
    actions: {
      records: carrierBrandActions.index.main,
      reset: carrierBrandActions.index.reset
    }
  },
  {
    key: 'product_line',
    config: 'issuer_statuses.product_lines'
  },
  {
    key: 'plan_audience',
    config: 'issuer_statuses.plan_audiences'
  }
]

export default connectIndex(IssuerStatusIndex, 'issuerStatuses', actions, filters)
