import { createBrowserHistory } from 'history'
import { take } from 'lodash'

let basename = ''

if (window.location.origin.match(/pr.howie.vericred.com/)) {
  const pathname = window.location.pathname
  basename = take(pathname.split('/'), 2).join('/')
}

export default createBrowserHistory({
  basename
})
