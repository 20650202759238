import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, get } from 'lodash'
import { IoIosClose } from 'react-icons/io'
import Component from '../common/Component'
import Modal from '../common/Modal'
import TextAreaField from '../common/TextAreaField'

export default class RejectionModal extends Component {
  static propTypes = {
    planCount: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleRejection: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      values: {},
      close: false
    }
  }

  get rejectionMessagePresent() {
    const values = cloneDeep(this.state.values)
    const rejectionMessage = get(values, 'rejectionMessage', '')
    return rejectionMessage.length >= 5
  }

  handleCancelButton = (event) => {
    this.setState({ close: true })
    this.props.handleClose()
  }

  handleRejection = () => {
    this.props.handleRejection(this.state.values.rejectionMessage)
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = cloneDeep(prevState.values)
      newState[field] = value
      return { values: newState }
    })
  }

  render() {
    if (this.state.close) {
      return null
    }

    return (
      <Modal
        handleClose={this.props.handleClose}
        className="ipa-rejection-modal"
      >
        <p>Please confirm that you would like to REJECT {this.props.planCount} plans</p>
        <div className="rejection-message">
          <TextAreaField
            name="rejectionMessage"
            handleChange={this.handleChange}
            value={this.state.values.rejectionMessage}
            rows={3}
            inputCssClass="rejection-message"
          />
        </div>
        <button
          className="select-button pure-button pure-input-1 pure-button-primary reject-ipa"
          type="button"
          onClick={this.handleRejection}
          disabled={!this.rejectionMessagePresent}
        >Reject
        </button>
        <button
          className="cancel-button"
          type="button"
          onClick={this.handleCancelButton}
        ><IoIosClose />
        </button>
      </Modal>
    )
  }
}
