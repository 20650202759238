import { reject, some } from 'lodash'
import PlanYearOptionsHelper from '../../common/helpers/PlanYearOptionsHelper'

export default class BenefitsSetsHelper {
  constructor(benefitsSets) {
    this.benefitsSets = benefitsSets
  }

  firstUnreviewedBenefitsSet(currentBenefitsSetId) {
    const unapprovedBenefitsSets = reject(this.benefitsSets, (bs) => (
      bs.approval_state === 'approved' || bs.approval_state === 'rejected' || bs.id === currentBenefitsSetId
    ))

    return unapprovedBenefitsSets[0]
  }

  hasUnreviewedBenefitsSet = () => (
    some(this.benefitsSets, (bs) => bs.approval_state !== 'approved' && bs.approval_state !== 'rejected')
  )

  static planYearOptions(fromYear, toYear) {
    return PlanYearOptionsHelper.planYearOptions(fromYear, toYear)
  }
}

