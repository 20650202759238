import {
  REQUEST_ZIP_COUNTIES,
  RECEIVE_ZIP_COUNTIES,
  RECEIVE_ZIP_COUNTIES_ERROR
} from '../../actions/CarrierVerification/consts'

const defaultState = {
  zipCounties: undefined,
  generalError: undefined,
  resourceErrors: undefined,
  getInProgress: false
}

export default function ZipCountiesReducer(state = defaultState, action) {
  switch (action.type) {
  case REQUEST_ZIP_COUNTIES:
    return { ...state,
      getInProgress: true,
      zipCounties: undefined,
      generalError: undefined,
      resourceErrors: undefined }
  case RECEIVE_ZIP_COUNTIES:
    return { ...state,
      getInProgress: false,
      zipCounties: action.zipCounties,
      generalError: undefined,
      resourceErrors: undefined }
  case RECEIVE_ZIP_COUNTIES_ERROR:
    return { ...state,
      generalError: action.generalError,
      resourceErrors: action.resourceErrors,
      getInProgress: false }
  default:
    return { ...state }
  }
}
