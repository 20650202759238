import client from '../../client'

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_TOKEN_EXPIRED
} from './consts'

export default function resetPassword(params) {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST })

    client.post('/passwords/reset', params)
      .then((response) => response.data)
      .then(() => dispatch({ type: RESET_PASSWORD_SUCCESS }))
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({ type: RESET_PASSWORD_TOKEN_EXPIRED, errors: error.response.data.errors })
        } else {
          dispatch({ type: RESET_PASSWORD_ERROR, errors: error.response.data.errors })
        }
      })
  }
}
