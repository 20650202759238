import React from 'react'
import PropTypes from 'prop-types'
import { IoIosDocument } from 'react-icons/io'
import Component from '../common/Component'

export default class BenefitsSummaryDocument extends Component {
  static propTypes = {
    sbcUrl: PropTypes.string,
    iconSize: PropTypes.number
  }

  static renderedProps = ['sbcUrl']

  get sbcUrl() {
    return this.props.sbcUrl
  }

  get sbcLink() {
    if (this.sbcUrl) {
      return (
        <a href={this.sbcUrl} target="_sbc">
          <IoIosDocument size={this.props.iconSize} />
          Benefits Summary Document
        </a>
      )
    }
    return (<div>No Benefits Summary Attached</div>)
  }

  render() {
    return (
      <div>
        {this.sbcLink}
      </div>
    )
  }
}
