import React, { Component } from 'react'
import { isObject } from 'lodash'
import TextAreaField from './TextAreaField'

export default class JsonField extends Component {
  static propTypes = {
    ...TextAreaField.props
  }

  constructor(props) {
    super(props)

    this.state = {
      jsonValue: isObject(props.value) ? JSON.stringify(props.value, null, 2).replace(/\\/g, '') : props.value,
      errorMessage: null
    }
  }

  get errorMessage() {
    return this.state.errorMessage || this.props.errorMessage
  }

  handleChange = (name, jsonValue) => {
    this.setState({ jsonValue })
    let value

    try {
      value = JSON.parse(jsonValue)
      this.props.handleChange(name, value)
      this.setState({ errorMessage: null })
    } catch {
      this.setState({ errorMessage: 'Invalid JSON format' })
    }
  }

  handleBlur = (name) => {
    if (!this.state.errorMessage) {
      this.setState((prevState) => {
        try {
          return { jsonValue: JSON.stringify(JSON.parse(prevState.jsonValue), null, 2) }
        } catch {
          return { errorMessage: 'Invalid JSON format' }
        }
      })
    }

    if (this.props.handleBlur) {
      this.props.handleBlur(name)
    }
  }

  render() {
    return (
      <div>
        <TextAreaField
          {...this.props}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          value={this.state.jsonValue}
          errorMessage={this.errorMessage}
        />
      </div>
    )
  }
}
