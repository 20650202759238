import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Component from '../common/Component'

import IndexComponent from '../common/IndexComponent'
import actions from '../../actions/HowieGeographies'
import connectIndex from '../../lib/connectIndex'

export class HowieGeographiesIndex extends Component {
  static propTypes = {
    location: PropTypes.object,
    index: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    records: PropTypes.array,
    pagination: PropTypes.object,
    flashMessages: PropTypes.array.isRequired,
    filterActions: PropTypes.object,
    filters: PropTypes.object,
    searchColumns: PropTypes.array
  }

  static defaultProps = {
    searchColumns: ['name', 'document_id']
  }

  static showLinkDisplay = (record) => {
    return <Link title={record.name} to={`howie_geographies/${record.id}`}>{record.name}</Link>
  }

  static columns = [
    {
      key: 'name',
      label: 'Name',
      filterable: false,
      sortable: false,
      display: HowieGeographiesIndex.showLinkDisplay
    },
    {
      key: 'document',
      label: 'Source Document',
      display: IndexComponent.documentDisplay,
      filterable: false,
      sortable: false
    },
    {
      key: 'updated_at',
      label: 'Updated At',
      display: IndexComponent.dateTimeDisplay,
      filterable: false,
      sortable: true
    }
  ]

  get actions() {
    return []
  }

  render() {
    return (
      <div className="content">
        <IndexComponent
          fetchRecords={this.props.index}
          resetRecords={this.props.reset}
          location={this.props.location}
          path="/howie_geographies"
          name="Geography"
          pagination={this.props.pagination}
          columns={HowieGeographiesIndex.columns}
          flashMessages={this.props.flashMessages}
          records={this.props.records}
          filterValues={this.props.filters}
          filterActions={this.props.filterActions}
          searchPlaceholder="Search by Name or Document ID"
          filterable
          actions={this.actions}
          searchColumns={this.props.searchColumns}
          newRecordLinkTitle="Import Geographies"
        />
      </div>
    )
  }
}

const filters = [
  {
    key: 'name',
    config: 'howie_geographies.name'
  }
]

export default connectIndex(HowieGeographiesIndex, 'howieGeographies', actions, filters)
