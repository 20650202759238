import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { last, isNil, get, cloneDeep, pick, some, isEmpty, forEach, compact } from 'lodash'
import LabeledTextField from '../../common/LabeledTextField'
import SelectField from '../../common/SelectField'
import FamilyComponent from './FamilyComponent'

export class CensusForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    submitLabel: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    requestInProgress: PropTypes.bool,
    configuration: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleAudienceChange = this.handleAudienceChange.bind(this)
    this.handleFamilyChange = this.handleFamilyChange.bind(this)

    const values = props.initialValues
    values.families_attributes = this.resolveFamilies(values.families_attributes, values.audience)

    this.state = { values }
  }

  get submitButton() {
    const { submitLabel } = this.props
    const requestInProgress = this.props.requestInProgress === true
    const classNames = ['pure-button', 'pure-input-1', 'pure-button-primary']

    if (requestInProgress) {
      classNames.push('loading')
    }

    return (
      <button
        className={classNames.join(' ')}
        disabled={requestInProgress}
        title={submitLabel}
        type="submit"
      >
        {submitLabel}
      </button>
    )
  }

  get sanitizedValues() {
    const values = cloneDeep(this.state.values)

    if (!this.hasAnyFamilyData(last(values.families_attributes) || {})) {
      values.families_attributes.pop()
    }

    values.families_attributes = this.sanitizedFamiliesAttributes(values.families_attributes)

    return values
  }

  get families() {
    if (isNil(this.state.values.audience)) {
      return undefined
    }

    const familyErrors = get(this.props.errors, 'families', {})

    return (
      this.state.values.families_attributes.map((family, index, array) => (
        <FamilyComponent
          name="family"
          key={index}
          index={index}
          handleChange={this.handleFamilyChange}
          value={family}
          audience={this.state.values.audience}
          errors={familyErrors[index] || {}}
          removable={index < (array.length - 1)}
        />
      ))
    )
  }

  get familyLegend() {
    if (isNil(this.state.values.audience)) {
      return ''
    }

    return this.state.values.audience === 'Individual' ? 'Family' : 'Families'
  }

  sanitizedMembersAttributes = (attributes) => {
    const newAttributes = cloneDeep(attributes)
    newAttributes.members_attributes = compact(attributes.members_attributes)
    const familyAttributes = compact(this.props.initialValues.families_attributes)
    const family = familyAttributes.filter((c) => c.id === attributes.id)[0]

    if (isEmpty(family)) {
      return newAttributes
    }

    forEach(family.members_attributes, (member) => {
      if (isNil(member.id)) {
        return
      }

      const newMember = newAttributes.members_attributes.filter((c) => c.id === member.id)[0]
      if (isNil(newMember)) {
        const newMemberValue = cloneDeep(member)
        newMemberValue._destroy = true
        newAttributes.members_attributes.push(newMemberValue)
      }
    })
    return newAttributes
  }

  resolveFamilies(families, audience) {
    if (isNil(audience) || isEmpty(families)) {
      return [{ members_attributes: [] }]
    }

    if (audience === 'Individual') {
      return this.resolveFamiliesIndivdual(families)
    }
    return this.resolveFamiliesSmallGroup(families)
  }

  resolveFamiliesIndivdual(families) {
    const newFamilies = families

    newFamilies[0].name = newFamilies[0].name || 'Family 1'
    return [newFamilies[0]]
  }

  resolveFamiliesSmallGroup(families) {
    if (!families.find((f) => isNil(f.name))) {
      families.push({ members_attributes: [] })
    }

    return families
  }

  handleFamilyChange(index, value) {
    this.setState((prevState) => {
      const newValues = prevState.values

      newValues.families_attributes[index] = value
      newValues.families_attributes = this.resolveFamilies(newValues.families_attributes, prevState.values.audience)

      return { values: newValues }
    })
  }

  handleAudienceChange(_field, value) {
    this.setState((prevState) => {
      const newValues = prevState.values

      newValues.audience = value
      newValues.families_attributes = this.resolveFamilies(newValues.families_attributes, value)

      return { values: newValues }
    })
  }

  handleChange(field, value) {
    this.setState((prevState) => {
      const newState = prevState.values

      if (isNil(value)) {
        delete newState[field]
      } else {
        newState[field] = value
      }

      return { values: newState }
    })
  }

  sanitizedFamiliesAttributes(attributes) {
    return attributes
      .filter((f) => isNil(f._destroy) || (!isNil(f.id) && f._destroy))
      .map((f) => {
        if (f._destroy) {
          return pick(f, ['id', '_destroy'])
        }

        return this.sanitizedMembersAttributes(f)
      })
  }

  handleSubmit(e) {
    e.preventDefault()

    this.props.onSubmit(this.sanitizedValues)
  }

  hasAnyFamilyData(family) {
    if (!isEmpty(family.name)) {
      return true
    }

    return some(family.members_attributes, (m) => !isEmpty(m.age))
  }

  valuesAddingFamilyIfNecessary(originalValues) {
    const values = originalValues
    const families = values.families_attributes

    if (!families.find((f) => isNil(f.name))) {
      families.push({ members_attributes: [] })
      values.families_attributes = families
    }

    return values
  }

  audienceOptions(audiences) {
    return audiences.map((audience) => ({
      text: audience,
      value: audience
    }))
  }

  render() {
    const {
      errors,
      configuration
    } = this.props

    const audienceOptions = this.audienceOptions(configuration.audience)
    const familiesBaseError = get(errors, 'families.base')

    return (
      <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
        <fieldset>
          <legend>Census Info</legend>
          <LabeledTextField
            key="name"
            name="name"
            label="Name"
            handleChange={this.handleChange}
            errorMessage={errors.name}
            value={this.state.values.name}
          />
          <SelectField
            key="audience"
            name="audience"
            label="Market"
            options={audienceOptions}
            handleChange={this.handleAudienceChange}
            handleBlur={() => {}}
            errorMessage={errors.audience}
            value={this.state.values.audience}
          />
        </fieldset>
        <fieldset>
          <legend>{this.familyLegend}</legend>
          {familiesBaseError && <div className="error-header">{familiesBaseError}</div>}
          {this.families}
        </fieldset>
        <fieldset>
          {this.submitButton}
        </fieldset>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  configuration: state.login.session.configuration.censuses,
  generalError: state.carrierVerification.census.generalError,
  resourceErrors: state.carrierVerification.census.resourceErrors
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CensusForm)
