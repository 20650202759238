import React from 'react'
import { cloneDeep } from 'lodash'

// Actions
import actions from '../../actions/Documents'

import Component from '../common/Component'
import EditForm, { defaultPropTypes, connectEditForm } from '../common/EditForm'
import LabeledTextField from '../common/LabeledTextField'
import LabeledTextAreaField from '../common/LabeledTextAreaField'
import CheckboxField from '../common/CheckboxField'
import CheckboxFieldSet from '../common/CheckboxFieldSet'
import IssuerRemoteOptionsSelectField from '../common/IssuerRemoteOptionsSelectField'
import PrivateExchangeRemoteOptionsSelectField from '../common/PrivateExchangeRemoteOptionsSelectField'

import SourceContentsSetComponent from './SourceContentsSetComponent'

import SelectField from '../common/SelectField'

import disableSubmit from './disableSubmit'

export class DocumentEdit extends Component {
  static propTypes = defaultPropTypes

  get id() {
    return this.props.match.params.id
  }

  transformAttributes = (attrs) => {
    const clonedAttrs = cloneDeep(attrs)

    clonedAttrs.issuers = clonedAttrs.document_issuers.map((di) => { return di.issuer_id })
    clonedAttrs.plan_audiences = clonedAttrs.document_plan_audiences.map((pa) => { return pa.plan_audience })
    clonedAttrs.plan_types = clonedAttrs.document_plan_types.map((pt) => { return pt.plan_type })
    clonedAttrs.source_contents = clonedAttrs.document_source_contents
    clonedAttrs.year_quarters = clonedAttrs.document_year_quarters.map((yq) => { return yq.year_quarter })

    return clonedAttrs
  }

  render() {
    return (
      <EditForm
        {...this.props}
        disableSubmit={disableSubmit}
        showDelete={false}
        transformAttributes={this.transformAttributes}
        id={this.id}
      >

        <LabeledTextField
          name="name"
          label="Document Name"
        />

        <SelectField
          name="year_quarters"
          label="Quarter(s)"
          options={this.props.configuration.year_quarters}
          multi
        />

        <SelectField
          name="state_id"
          label="State"
          options={this.props.configuration.state_id}
        />

        <SelectField
          name="plan_audiences"
          label="Audience(s)"
          options={this.props.configuration.audience}
          multi
        />

        <SelectField
          name="product_line"
          label="Product Line"
          options={this.props.configuration.product_line}
        />

        <SelectField
          name="source"
          label="Source"
          options={this.props.configuration.source}
        />

        <IssuerRemoteOptionsSelectField
          name="issuers"
          multi
        />

        <SelectField
          name="source_file_type"
          label="File Type"
          options={this.props.configuration.source_file_types}
        />

        <PrivateExchangeRemoteOptionsSelectField
          name="private_exchange_id"
        />

        <CheckboxField
          inputCssClass="pure-checkbox"
          name="privileged"
          label="Privileged"
        />

        <LabeledTextAreaField
          name="notes"
          label="Notes"
          type="textarea"
        />

        <CheckboxField
          inputCssClass="pure-checkbox"
          name="requires_manual_build"
          label="Requires Manual Build"
        />

        <CheckboxFieldSet
          name="plan_types"
          label="Plan Types"
          options={this.props.configuration.plan_types}
        />

        <SourceContentsSetComponent
          name="source_contents"
          options={this.props.configuration.source_contents}
        />
      </EditForm>
    )
  }
}

export default connectEditForm(DocumentEdit, 'Document', 'documents', actions)
