import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, isNil, uniqBy } from 'lodash'
import LabeledTextField from '../common/LabeledTextField'
import RemoteOptionsSelectField from '../common/RemoteOptionsSelectField'
import CarrierRemoteOptionsSelectField from '../Carriers/CarrierRemoteOptionsSelectField'
import SelectField from '../common/SelectField'
import SubmitButton from '../common/SubmitButton'

class CarrierUserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    issuers: PropTypes.array,
    indexIssuers: PropTypes.func.isRequired,
    resetIssuers: PropTypes.func.isRequired,
    issuersRequestInProgress: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    requestInProgress: PropTypes.bool.isRequired,
    submitLabel: PropTypes.string
  }

  static defaultProps = {
    submitLabel: 'Create User'
  }

  constructor(props) {
    super(props)

    const initialValues = cloneDeep(props.initialValues)

    this.state = {
      values: initialValues
    }
  }

  handleBlur = (field) => {
  }

  handleChange = (field, value) => {
    this.setState((prevState) => {
      const newState = prevState.values

      if (isNil(value) || value === '') {
        newState[field] = null
      } else {
        newState[field] = value
      }

      return { values: newState }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.handleSubmit(this.state.values)
  }

  filterOption = (option, inputValue) => {
    if (!this.props.issuers) { return false }

    const issuers = uniqBy(this.props.issuers, (issuer) => {
      return `${issuer.brand_name} - ${issuer.licensed_state_code}`
    })

    const brandIssuer = issuers.filter(
      (issuer) => `${issuer.brand_name} - ${issuer.licensed_state_code}` === option.label
    )[0]

    return isNil(brandIssuer) ? true : brandIssuer.id === option.value
  }

  carrierOptions(carriers) {
    return carriers.map((carrier) => ({
      text: carrier.name,
      value: carrier.carrier_id
    }))
  }

  render() {
    const { errors } = this.props

    return (
      <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
        <fieldset>
          <LabeledTextField
            key="username"
            name="username"
            label="Username"
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            errorMessage={errors.username}
            value={this.state.values.username}
          />
          <LabeledTextField
            key="email"
            name="email"
            label="Email"
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            errorMessage={errors.email}
            value={this.state.values.email}
          />
          <CarrierRemoteOptionsSelectField
            name="carrier_id"
            handleChange={this.handleChange}
            value={this.state.values.carrier_id}
            errorMessage={errors.carrier_id}
          />
          <RemoteOptionsSelectField
            placeholder="Type at least 2 characters to search"
            key="user_issuers"
            name="user_issuers"
            label="Carrier Brands"
            recordLabel={['brand_name', 'licensed_state_code']}
            handleChange={this.handleChange}
            records={this.props.issuers}
            requestInProgress={this.props.issuersRequestInProgress}
            reset={this.props.resetIssuers}
            recordsRequest={this.props.indexIssuers}
            value={this.state.values.user_issuers}
            filterOption={this.filterOption}
            multi
          />
        </fieldset>
        <SelectField
          key="carrier_verification_tool"
          name="carrier_verification_tool"
          label="Carrier Verification Tool"
          options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
          handleChange={this.handleChange}
          errorMessage={errors.carrier_verification_tool}
          value={this.state.values.carrier_verification_tool}
          disabled={false}
        />
        <SelectField
          key="quote_reporting"
          name="quote_reporting"
          label="Quote Reporting"
          options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
          handleChange={this.handleChange}
          errorMessage={errors.quote_reporting}
          value={this.state.values.quote_reporting}
          disabled={false}
        />
        <SelectField
          key="analytics_reporting"
          name="analytics_reporting"
          label="analytics_reporting"
          options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
          handleChange={this.handleChange}
          errorMessage={errors.analytics_reporting}
          value={this.state.values.analytics_reporting}
          disabled={false}
        />
        <fieldset>
          <SubmitButton
            title={this.props.submitLabel}
            text={this.props.submitLabel}
            loading={this.props.requestInProgress}
          />
        </fieldset>
      </form>
    )
  }
}

export default CarrierUserForm
