import MedicalBenefitsSetIndex from './Medical/MedicalBenefitsSetIndex'
import MedicalBenefitsSetShow from './Medical/MedicalBenefitsSetShow'
import MedicalBenefitsSetCreate from './Medical/MedicalBenefitsSetCreate'
import DentalBenefitsSetIndex from './Dental/DentalBenefitsSetIndex'
import DentalBenefitsSetCreate from './Dental/DentalBenefitsSetCreate'
import DentalBenefitsSetShow from './Dental/DentalBenefitsSetShow'
import DentalBenefitsSetExportShow from './Dental/DentalBenefitsSetExportShow'
import VisionBenefitsSetExportShow from './Vision/VisionBenefitsSetExportShow'
import MedicalBenefitsSetExportShow from './Medical/MedicalBenefitsSetExportShow'
import MedicareAdvantageBenefitsSetExportShow from './MedicareAdvantage/MedicareAdvantageBenefitsSetExportShow'
import DentalBenefitsSetEdit from './Dental/DentalBenefitsSetEdit'
import DentalBenefitsSetReview from './Dental/DentalBenefitsSetNewReview'
import VisionBenefitsSetIndex from './Vision/VisionBenefitsSetIndex'
import VisionBenefitsSetCreate from './Vision/VisionBenefitsSetCreate'
import VisionBenefitsSetShow from './Vision/VisionBenefitsSetShow'
import VisionBenefitsSetEdit from './Vision/VisionBenefitsSetEdit'
import VisionBenefitsSetReview from './Vision/VisionBenefitsSetNewReview'
import BenefitsSetEdit from './Medical/MedicalBenefitsSetEdit'
import BenefitsSetReview from './Medical/MedicalBenefitsSetNewReview'
import BenefitsSetExternalIdShow from './BenefitsSet/BenefitsSetExternalIdShow'
import MedicalBenefitsSetCopy from './Medical/MedicalBenefitsSetCopy'
import CarrierUserCreate from './Users/CarrierUserCreate'
import CarrierUserEdit from './Users/CarrierUserEdit'
import ScenariosContainer from './CarrierVerification/Scenarios'
import CensusesContainer from './CarrierVerification/Censuses'
import DocumentCreate from './Documents/DocumentCreate'
import DocumentEdit from './Documents/DocumentEdit'
import DocumentsIndex from './Documents/DocumentIndex'
import CarrierGroupsIndex from './CarrierGroups/CarrierGroupIndex'
import CarrierGroupsCreate from './CarrierGroups/CarrierGroupCreate'
import CarrierGroupsEdit from './CarrierGroups/CarrierGroupEdit'
import CarriersIndex from './Carriers/CarrierIndex'
import CarriersCreate from './Carriers/CarrierCreate'
import CarriersEdit from './Carriers/CarrierEdit'
import CarrierBrandsIndex from './CarrierBrands/CarrierBrandIndex'
import CarrierBrandsCreate from './CarrierBrands/CarrierBrandCreate'
import CarrierBrandsEdit from './CarrierBrands/CarrierBrandEdit'
import IssuerIndex from './Issuers/IssuerIndex'
import IssuerCreate from './Issuers/IssuerCreate'
import IssuerEdit from './Issuers/IssuerEdit'
import IssuerDisclaimerCreate from './IssuerDisclaimers/IssuerDisclaimerCreate'
import IssuerDisclaimerEdit from './IssuerDisclaimers/IssuerDisclaimerEdit'
import IssuerDisclaimerShow from './IssuerDisclaimers/IssuerDisclaimerShow'
import IssuerDisclaimerIndex from './IssuerDisclaimers/IssuerDisclaimerIndex'
import IssuerSourceCreate from './IssuerSources/IssuerSourceCreate'
import IssuerSourceEdit from './IssuerSources/IssuerSourceEdit'
import IssuerSourceIndex from './IssuerSources/IssuerSourceIndex'
import IssuerStatusCreate from './IssuerStatuses/IssuerStatusCreate'
import IssuerStatusEdit from './IssuerStatuses/IssuerStatusEdit'
import IssuerStatusIndex from './IssuerStatuses/IssuerStatusIndex'
import PrivateExchangeCreate from './PrivateExchanges/PrivateExchangeCreate'
import PrivateExchangeEdit from './PrivateExchanges/PrivateExchangeEdit'
import PrivateExchangeIndex from './PrivateExchanges/PrivateExchangeIndex'
import UserShow from './Users/UserShow'
import UserIndex from './Users/UserIndex'
import RatesBatchCreate from './RatesBatches/RatesBatchCreate'
import RatesBatchExport from './RatesBatches/RatesBatchExport'
import SheetRateIndex from './SheetRates/SheetRateIndex'
import SheetRatesReview from './SheetRates/SheetRatesReview'
import SheetRatesBulkIdentifier from './SheetRates/SheetRatesBulkIdentifierComponent'
import EmbargoCreate from './Embargoes/EmbargoCreate'
import EmbargoShow from './Embargoes/EmbargoShow'
import EmbargoIndex from './Embargoes/EmbargoIndex'
import EmbargoEdit from './Embargoes/EmbargoEdit'
import MedicalBenefitsSetBulkEdit from './Medical/MedicalBenefitsSetBulkEditsComponent'
import MedicalBenefitsSetBulkIdentifier from './Medical/MedicalBenefitsSetBulkIdentifierComponent'
import MedicareAdvantageBenefitsSetBulkEdit from './MedicareAdvantage/MedicareAdvantageBenefitsSetBulkEditsComponent'
import MedicareAdvantageBenefitsSetBulkIdentifier from './MedicareAdvantage/MedicareAdvantageBenefitsSetBulkIdentifierComponent'
import DentalBenefitsSetBulkEdit from './Dental/DentalBenefitsSetBulkEditsComponent'
import DentalBenefitsSetBulkIdentifier from './Dental/DentalBenefitsSetBulkIdentifierComponent'
import VisionBenefitsSetBulkEdit from './Vision/VisionBenefitsSetBulkEditsComponent'
import VisionBenefitsSetBulkIdentifier from './Vision/VisionBenefitsSetBulkIdentifierComponent'
import PlanProductIndex from './PlanProducts/PlanProductIndex'
import IssuerPeriodAudienceIndex from './IssuerPeriodAudiences/IssuerPeriodAudienceIndex'
import MedicareAdvantageBenefitsSetIndex from './MedicareAdvantage/MedicareAdvantageBenefitsSetIndex'
import MedicareAdvantageBenefitsSetShow from './MedicareAdvantage/MedicareAdvantageBenefitsSetShow'
import MedicareAdvantageBenefitsSetEdit from './MedicareAdvantage/MedicareAdvantageBenefitsSetEdit'
import ServiceAreasImport from './VericredImport/ServiceAreasImport'
import PlanServiceAreasImport from './VericredImport/PlanServiceAreasImport'
import GeographiesImport from './VericredImport/GeographiesImport'
import RateFactorSetCreate from './RateFactorSets/RateFactorSetCreate'
import HowieGeographiesIndex from './HowieGeography/HowieGeographiesIndex'
import HowieGeographiesCreate from './HowieGeography/HowieGeographiesCreate'
import HowieGeographiesShow from './HowieGeography/HowieGeographiesShow'
import HowieServiceAreaIndex from './HowieServiceAreas/HowieServiceAreaIndex'
import HowieServiceAreaReview from './HowieServiceAreas/HowieServiceAreaReview'
import AncillaryServiceAreaIndex from './AncillaryServiceAreas/AncillaryServiceAreaIndex'
import AncillaryServiceAreaShow from './AncillaryServiceAreas/AncillaryServiceAreaShow'
import AncillaryRateFactorSetIndex from './AncillaryRateFactorSets/AncillaryRateFactorSetIndex'
import AncillaryRateFactorSetShow from './AncillaryRateFactorSets/AncillaryRateFactorSetShow'
import AncillaryRateFactorSetCreate from './AncillaryRateFactorSets/AncillaryRateFactorSetCreate'
import AncillaryRateFactorSetEdit from './AncillaryRateFactorSets/AncillaryRateFactorSetEdit'
import FormIndex from './Forms/FormIndex'
import FormCreate from './Forms/FormCreate'
import FormEdit from './Forms/FormEdit'
import SmokeCheckIndex from './SmokeChecks/SmokeCheckIndex'
import SmokeCheckShow from './SmokeChecks/SmokeCheckShow'
import LifeBenefitsSetIndex from './Life/LifeBenefitsSetIndex'
import LifeBenefitsSetCreate from './Life/LifeBenefitsSetCreate'
import LifeBenefitsSetShow from './Life/LifeBenefitsSetShow'
import LifeBenefitsSetExportShow from './Life/LifeBenefitsSetExportShow'
import LifeBenefitsSetEdit from './Life/LifeBenefitsSetEdit'
import LifeBenefitsSetBulkEdit from './Life/LifeBenefitsSetBulkEditsComponent'
import LifeBenefitsSetBulkIdentifier from './Life/LifeBenefitsSetBulkIdentifierComponent'
import DisabilityBenefitsSetIndex from './Disability/DisabilityBenefitsSetIndex'
import DisabilityBenefitsSetCreate from './Disability/DisabilityBenefitsSetCreate'
import DisabilityBenefitsSetShow from './Disability/DisabilityBenefitsSetShow'
import DisabilityBenefitsSetExportShow from './Disability/DisabilityBenefitsSetExportShow'
import DisabilityBenefitsSetEdit from './Disability/DisabilityBenefitsSetEdit'
import DisabilityBenefitsSetBulkEdit from './Disability/DisabilityBenefitsSetBulkEditsComponent'
import DisabilityBenefitsSetBulkIdentifier from './Disability/DisabilityBenefitsSetBulkIdentifierComponent'

export const menuSections = [
  {
    title: 'Benefits Sets',
    routes: [
      {
        title: 'New Benefits Set',
        path: '/medical_benefits/new',
        group: 'medical_benefits_sets',
        action: 'create',
        label: 'Medical Benefits Sets'
      },
      {
        title: 'All Benefits Sets',
        path: '/medical_benefits',
        group: 'medical_benefits_sets',
        action: 'index',
        label: 'Medical Benefits Sets'
      },
      {
        title: 'Copy Benefits Sets',
        path: '/medical_benefits/copy',
        group: 'medical_benefits_sets',
        action: 'create',
        label: 'Medical Benefits Sets'
      },
      {
        title: 'New Benefits Set',
        path: '/dental_benefits/new',
        group: 'dental_benefits_sets',
        action: 'create',
        label: 'Dental Benefits Sets'
      },
      {
        title: 'All Benefits Sets',
        path: '/dental_benefits',
        group: 'dental_benefits_sets',
        action: 'index',
        label: 'Dental Benefits Sets'
      },
      {
        title: 'New Benefits Set',
        path: '/vision_benefits/new',
        group: 'vision_benefits_sets',
        action: 'create',
        label: 'Vision Benefits Sets'
      },
      {
        title: 'All Benefits Sets',
        path: '/vision_benefits',
        group: 'vision_benefits_sets',
        action: 'index',
        label: 'Vision Benefits Sets'
      },
      {
        title: 'All Benefits Sets',
        path: '/med_adv_benefits',
        group: 'medicare_advantage_benefits_sets',
        action: 'index',
        label: 'Medicare Advantage Benefits Sets'
      },
      {
        title: 'New Benefits Set',
        path: '/life_benefits/new',
        group: 'life_benefits_sets',
        action: 'create',
        label: 'Life Benefits Sets'
      },
      {
        title: 'All Benefits Sets',
        path: '/life_benefits',
        group: 'life_benefits_sets',
        action: 'index',
        label: 'Life Benefits Sets'
      },
      {
        title: 'New Benefits Set',
        path: '/disability_benefits/new',
        group: 'disability_benefits_sets',
        action: 'create',
        label: 'Disability Benefits Sets'
      },
      {
        title: 'All Benefits Sets',
        path: '/disability_benefits',
        group: 'disability_benefits_sets',
        action: 'index',
        label: 'Disability Benefits Sets'
      }
    ]
  },
  {
    title: 'Rates',
    routes: [
      { title: 'Upload', path: '/rates_batches/new', group: 'rates_batches', action: 'create' },
      { title: 'All Rates', path: '/sheet_rates', group: 'sheet_rates', action: 'index' },
      { title: 'Add Ancillary Rate Factor Sets',
        path: '/rate_factor_sets/new',
        group: 'rate_factor_sets',
        action: 'create' },
      { title: 'Ancillary Rate Factor Sets',
        path: '/ancillary_rate_factor_sets',
        group: 'ancillary_rate_factor_sets',
        action: 'index' },
      { title: 'Ancillary Service Areas',
        path: '/ancillary_service_areas/new',
        group: 'vericred_imports',
        action: 'create' },
      { title: 'Ancillary Plan Service Areas',
        path: '/ancillary_plan_service_areas/new',
        group: 'vericred_imports',
        action: 'create' },
      { title: 'Geographies',
        path: '/geographies/new',
        group: 'vericred_imports',
        action: 'create' }
    ]
  },
  {
    title: 'Service Areas',
    routes: [
      { title: 'All Service Areas', path: '/howie_service_areas', group: 'howie_service_areas', action: 'index' },
      { title: 'Geographies', path: '/howie_geographies', group: 'howie_geographies', action: 'index' },
      { title: 'Ancillary Service Areas',
        path: '/ancillary_service_areas',
        group: 'ancillary_service_areas',
        action: 'index' }
    ]
  },
  {
    title: 'Carrier Verification',
    routes: [
      { title: 'Censuses', path: '/censuses/new', group: 'censuses', action: 'create' },
      { title: 'Scenarios', path: '/scenarios', group: 'scenarios', action: 'create' },
      { title: 'Users', path: '/users', group: 'users', action: 'index' },
      { title: 'Add Carrier User', path: '/users/new', group: 'users', action: 'create' }
    ]
  },
  {
    title: 'Carrier Organization',
    routes: [
      { title: 'Carrier Groups', path: '/carrier_groups', group: 'carrier_groups', action: 'index' },
      { title: 'Carriers', path: '/carriers', group: 'carriers', action: 'index' },
      { title: 'Carrier Brands', path: '/carrier_brands', group: 'carrier_brands', action: 'index' },
      { title: 'Issuers', path: '/issuers', group: 'issuers', action: 'index' },
      { title: 'Issuer Statuses', path: '/issuer_statuses', group: 'issuer_statuses', action: 'index' },
      { title: 'Issuer Sources', path: '/issuer_sources', group: 'issuer_sources', action: 'index' },
      { title: 'Issuer Disclaimers', path: '/issuer_disclaimers', group: 'issuer_disclaimers', action: 'index' },
      { title: 'Private Exchanges', path: '/private_exchanges', group: 'private_exchanges', action: 'index' }
    ]
  },
  {
    title: 'Documents',
    routes: [
      { title: 'New Document', path: '/documents/new', group: 'documents', action: 'create' },
      { title: 'All Documents', path: '/documents', group: 'documents', action: 'index' }
    ]
  },
  {
    title: 'Embargoes',
    routes: [
      { title: 'Create Embargo', path: '/embargoes/new', group: 'embargoes', action: 'create' },
      { title: 'All Embargoes', path: '/embargoes', group: 'embargoes', action: 'index' }
    ]
  },
  {
    title: 'IPAs',
    routes: [
      { title: 'All IPAs', path: '/issuer_period_audiences', group: 'issuer_period_audiences', action: 'index' },
      { title: 'Smoke Checks', path: '/smoke_checks', group: 'smoke_checks', action: 'index' }
    ]
  },
  {
    title: 'Forms',
    routes: [
      { title: 'New Form', path: '/forms/new', group: 'forms', action: 'create' },
      { title: 'All Forms', path: '/forms', group: 'forms', action: 'index' }
    ]
  }
]

export const routes = [
  {
    path: '/medical_benefits',
    component: MedicalBenefitsSetIndex,
    group: 'medical_benefits_sets',
    action: 'index'
  },
  {
    path: '/rates_batches/new',
    component: RatesBatchCreate,
    group: 'rates_batches',
    action: 'create'
  },
  {
    path: '/rates_batches_export/:id',
    component: RatesBatchExport,
    group: 'rates_batches',
    action: 'show'
  },
  {
    path: '/dental_benefits',
    component: DentalBenefitsSetIndex,
    group: 'dental_benefits_sets',
    action: 'index'
  },
  {
    path: '/medical_benefits/new',
    component: MedicalBenefitsSetCreate,
    group: 'medical_benefits_sets',
    action: 'create'
  },
  {
    path: '/medical_benefits/bulk/identifiers',
    component: MedicalBenefitsSetBulkIdentifier,
    group: 'medical_benefits_sets',
    action: 'index'
  },
  {
    path: '/medical_benefits/bulk',
    component: MedicalBenefitsSetBulkEdit,
    group: 'medical_benefits_sets',
    action: 'update'
  },
  {
    path: '/medical_benefits/copy',
    component: MedicalBenefitsSetCopy,
    group: 'medical_benefits_sets',
    action: 'create'
  },
  {
    path: '/dental_benefits/new',
    component: DentalBenefitsSetCreate,
    group: 'dental_benefits_sets',
    action: 'create'
  },
  {
    path: '/dental_benefits/bulk/identifiers',
    component: DentalBenefitsSetBulkIdentifier,
    group: 'dental_benefits_sets',
    action: 'index'
  },
  {
    path: '/dental_benefits/bulk',
    component: DentalBenefitsSetBulkEdit,
    group: 'dental_benefits_sets',
    action: 'update'
  },
  {
    path: '/dental_benefits/:id',
    component: DentalBenefitsSetShow,
    group: 'dental_benefits_sets',
    action: 'show'
  },
  {
    path: '/dental_benefits/:id/edit',
    component: DentalBenefitsSetEdit,
    group: 'dental_benefits_sets',
    action: 'update'
  },
  {
    path: '/life_benefits',
    component: LifeBenefitsSetIndex,
    group: 'life_benefits_sets',
    action: 'index'
  },
  {
    path: '/life_benefits/new',
    component: LifeBenefitsSetCreate,
    group: 'life_benefits_sets',
    action: 'create'
  },
  {
    path: '/life_benefits/bulk/identifiers',
    component: LifeBenefitsSetBulkIdentifier,
    group: 'life_benefits_sets',
    action: 'index'
  },
  {
    path: '/life_benefits/bulk',
    component: LifeBenefitsSetBulkEdit,
    group: 'life_benefits_sets',
    action: 'update'
  },
  {
    path: '/life_benefits/:id',
    component: LifeBenefitsSetShow,
    group: 'life_benefits_sets',
    action: 'show'
  },
  {
    path: '/life_benefits/:id/edit',
    component: LifeBenefitsSetEdit,
    group: 'life_benefits_sets',
    action: 'update'
  },
  {
    path: '/life_benefits_exports/:id',
    component: LifeBenefitsSetExportShow,
    group: 'benefits_sets_exports',
    action: 'show'
  },
  {
    path: '/disability_benefits',
    component: DisabilityBenefitsSetIndex,
    group: 'disability_benefits_sets',
    action: 'index'
  },
  {
    path: '/disability_benefits/new',
    component: DisabilityBenefitsSetCreate,
    group: 'disability_benefits_sets',
    action: 'create'
  },
  {
    path: '/disability_benefits/bulk/identifiers',
    component: DisabilityBenefitsSetBulkIdentifier,
    group: 'disability_benefits_sets',
    action: 'index'
  },
  {
    path: '/disability_benefits/bulk',
    component: DisabilityBenefitsSetBulkEdit,
    group: 'disability_benefits_sets',
    action: 'update'
  },
  {
    path: '/disability_benefits/new',
    component: DisabilityBenefitsSetCreate,
    group: 'disability_benefits_sets',
    action: 'create'
  },
  {
    path: '/disability_benefits/:id',
    component: DisabilityBenefitsSetShow,
    group: 'disability_benefits_sets',
    action: 'show'
  },
  {
    path: '/disability_benefits/:id/edit',
    component: DisabilityBenefitsSetEdit,
    group: 'disability_benefits_sets',
    action: 'update'
  },
  {
    path: '/disability_benefits_exports/:id',
    component: DisabilityBenefitsSetExportShow,
    group: 'benefits_sets_exports',
    action: 'show'
  },
  {
    path: '/medical_benefits/:id',
    component: MedicalBenefitsSetShow,
    group: 'medical_benefits_sets',
    action: 'show'
  },
  {
    path: '/plan_external_ids/:id',
    component: BenefitsSetExternalIdShow,
    group: 'medical_benefits_sets',
    action: 'show'
  },
  {
    path: '/medical_benefits/:id/edit',
    component: BenefitsSetEdit,
    group: 'medical_benefits_sets',
    action: 'update'
  },
  {
    path: '/medical_benefits/:id/reviews/new',
    component: BenefitsSetReview,
    group: 'medical_benefits_set_reviews',
    action: 'create'
  },
  {
    path: '/dental_benefits/:id/reviews/new',
    component: DentalBenefitsSetReview,
    group: 'dental_benefits_set_reviews',
    action: 'create'
  },
  {
    path: '/vision_benefits/:id/reviews/new',
    component: VisionBenefitsSetReview,
    group: 'vision_benefits_set_reviews',
    action: 'create'
  },
  {
    path: '/vision_benefits',
    component: VisionBenefitsSetIndex,
    group: 'vision_benefits_sets',
    action: 'index'
  },
  {
    path: '/vision_benefits/new',
    component: VisionBenefitsSetCreate,
    group: 'vision_benefits_sets',
    action: 'create'
  },
  {
    path: '/dental_benefits_exports/:id',
    component: DentalBenefitsSetExportShow,
    group: 'benefits_sets_exports',
    action: 'show'
  },
  {
    path: '/vision_benefits/bulk/identifiers',
    component: VisionBenefitsSetBulkIdentifier,
    group: 'vision_benefits_sets',
    action: 'index'
  },
  {
    path: '/vision_benefits/bulk',
    component: VisionBenefitsSetBulkEdit,
    group: 'vision_benefits_sets',
    action: 'update'
  },
  {
    path: '/vision_benefits_exports/:id',
    component: VisionBenefitsSetExportShow,
    group: 'benefits_sets_exports',
    action: 'show'
  },
  {
    path: '/medical_benefits_exports/:id',
    component: MedicalBenefitsSetExportShow,
    group: 'benefits_sets_exports',
    action: 'show'
  },
  {
    path: '/vision_benefits/:id',
    component: VisionBenefitsSetShow,
    group: 'vision_benefits_sets',
    action: 'show'
  },
  {
    path: '/vision_benefits/:id/edit',
    component: VisionBenefitsSetEdit,
    group: 'vision_benefits_sets',
    action: 'update'
  },
  {
    path: '/med_adv_benefits/bulk/identifiers',
    component: MedicareAdvantageBenefitsSetBulkIdentifier,
    group: 'medicare_advantage_benefits_sets',
    action: 'index'
  },
  {
    path: '/med_adv_benefits/bulk',
    component: MedicareAdvantageBenefitsSetBulkEdit,
    group: 'medicare_advantage_benefits_sets',
    action: 'update'
  },
  {
    path: '/med_adv_benefits',
    component: MedicareAdvantageBenefitsSetIndex,
    group: 'medicare_advantage_benefits_sets',
    action: 'index'
  },
  {
    path: '/med_adv_benefits/:id',
    component: MedicareAdvantageBenefitsSetShow,
    group: 'medicare_advantage_benefits_sets',
    action: 'show'
  },
  {
    path: '/med_adv_benefits/:id/edit',
    component: MedicareAdvantageBenefitsSetEdit,
    group: 'medicare_advantage_benefits_sets',
    action: 'update'
  },
  {
    path: '/med_adv_benefits_exports/:id',
    component: MedicareAdvantageBenefitsSetExportShow,
    group: 'benefits_sets_exports',
    action: 'show'
  },
  {
    path: '/documents/new',
    component: DocumentCreate,
    group: 'documents',
    action: 'create'
  },
  {
    path: '/documents/:id/edit',
    component: DocumentEdit,
    group: 'documents',
    action: 'update'
  },
  {
    path: '/documents',
    component: DocumentsIndex,
    group: 'documents',
    action: 'index'
  },
  {
    path: '/users/new',
    component: CarrierUserCreate,
    group: 'users',
    action: 'create'
  },
  {
    path: '/users/:id/edit',
    component: CarrierUserEdit,
    group: 'users',
    action: 'update'
  },
  {
    path: '/users',
    component: UserIndex,
    group: 'users',
    action: 'index'
  },
  {
    path: '/users/:id',
    component: UserShow,
    group: 'users',
    action: 'show'
  },
  {
    path: '/carrier_groups/new',
    component: CarrierGroupsCreate,
    group: 'carrier_groups',
    action: 'create'
  },
  {
    path: '/carrier_groups/:id/edit',
    component: CarrierGroupsEdit,
    group: 'carrier_groups',
    action: 'update'
  },
  {
    path: '/carrier_groups',
    component: CarrierGroupsIndex,
    group: 'carrier_groups',
    action: 'index'
  },
  {
    path: '/carriers/new',
    component: CarriersCreate,
    group: 'carriers',
    action: 'create'
  },
  {
    path: '/carriers/:id/edit',
    component: CarriersEdit,
    group: 'carriers',
    action: 'update'
  },
  {
    path: '/carriers',
    component: CarriersIndex,
    group: 'carriers',
    action: 'index'
  },
  {
    path: '/carrier_brands/new',
    component: CarrierBrandsCreate,
    group: 'carrier_brands',
    action: 'create'
  },
  {
    path: '/carrier_brands/:id/edit',
    component: CarrierBrandsEdit,
    group: 'carrier_brands',
    action: 'update'
  },
  {
    path: '/carrier_brands',
    component: CarrierBrandsIndex,
    group: 'carrier_brands',
    action: 'index'
  },
  {
    path: '/issuers/new',
    component: IssuerCreate,
    group: 'issuers',
    action: 'create'
  },
  {
    path: '/issuers/:id/edit',
    component: IssuerEdit,
    group: 'issuers',
    action: 'update'
  },
  {
    path: '/issuers',
    component: IssuerIndex,
    group: 'issuers',
    action: 'index'
  },
  {
    path: '/issuer_disclaimers/new',
    component: IssuerDisclaimerCreate,
    group: 'issuer_disclaimers',
    action: 'create'
  },
  {
    path: '/issuer_disclaimers/:id/edit',
    component: IssuerDisclaimerEdit,
    group: 'issuer_disclaimers',
    action: 'update'
  },
  {
    path: '/issuer_disclaimers/:id',
    component: IssuerDisclaimerShow,
    group: 'issuer_disclaimers',
    action: 'show'
  },
  {
    path: '/issuer_disclaimers',
    component: IssuerDisclaimerIndex,
    group: 'issuer_disclaimers',
    action: 'index'
  },
  {
    path: '/issuer_sources/new',
    component: IssuerSourceCreate,
    group: 'issuer_sources',
    action: 'create'
  },
  {
    path: '/issuer_sources',
    component: IssuerSourceIndex,
    group: 'issuer_sources',
    action: 'index'
  },
  {
    path: '/issuer_sources/:id/edit',
    component: IssuerSourceEdit,
    group: 'issuer_sources',
    action: 'update'
  },
  {
    path: '/issuer_statuses/new',
    component: IssuerStatusCreate,
    group: 'issuer_statuses',
    action: 'create'
  },
  {
    path: '/issuer_statuses',
    component: IssuerStatusIndex,
    group: 'issuer_statuses',
    action: 'index'
  },
  {
    path: '/issuer_statuses/:id/edit',
    component: IssuerStatusEdit,
    group: 'issuer_statuses',
    action: 'update'
  },
  {
    path: '/private_exchanges/new',
    component: PrivateExchangeCreate,
    group: 'private_exchanges',
    action: 'create'
  },
  {
    path: '/private_exchanges',
    component: PrivateExchangeIndex,
    group: 'private_exchanges',
    action: 'index'
  },
  {
    path: '/private_exchanges/:id/edit',
    component: PrivateExchangeEdit,
    group: 'private_exchanges',
    action: 'update'
  },
  {
    path: '/censuses/:id/edit',
    component: CensusesContainer,
    group: 'censuses',
    action: 'update'
  },
  {
    path: '/censuses/new',
    component: CensusesContainer,
    group: 'censuses',
    action: 'create'
  },
  {
    path: '/scenarios',
    component: ScenariosContainer,
    group: 'scenarios',
    action: 'create'
  },
  {
    path: '/sheet_rates',
    component: SheetRateIndex,
    group: 'sheet_rates',
    action: 'index'
  },
  {
    path: '/sheet_rates/bulk/identifiers',
    component: SheetRatesBulkIdentifier,
    group: 'sheet_rates',
    action: 'index'
  },
  {
    path: '/sheet_rates/review',
    component: SheetRatesReview,
    group: 'sheet_rates',
    action: 'update'
  },
  {
    path: '/embargoes/new',
    component: EmbargoCreate,
    group: 'embargoes',
    action: 'create'
  },
  {
    path: '/embargoes/:id',
    component: EmbargoShow,
    group: 'embargoes',
    action: 'show'
  },
  {
    path: '/embargoes/:id/edit',
    component: EmbargoEdit,
    group: 'embargoes',
    action: 'update'
  },
  {
    path: '/embargoes',
    component: EmbargoIndex,
    group: 'embargoes',
    action: 'index'
  },
  {
    path: '/plan_products',
    component: PlanProductIndex,
    group: 'plan_products',
    action: 'index'
  },
  {
    path: '/issuer_period_audiences',
    component: IssuerPeriodAudienceIndex,
    group: 'issuer_period_audiences',
    action: 'index'
  },
  {
    path: '/ancillary_service_areas/new',
    component: ServiceAreasImport,
    group: 'vericred_imports',
    action: 'create'
  },
  {
    path: '/ancillary_plan_service_areas/new',
    component: PlanServiceAreasImport,
    group: 'vericred_imports',
    action: 'create'
  },
  {
    path: '/rate_factor_sets/new',
    component: RateFactorSetCreate,
    group: 'rate_factor_sets',
    action: 'create'
  },
  {
    path: '/geographies/new',
    component: GeographiesImport,
    group: 'vericred_imports',
    action: 'create'
  },
  {
    path: '/howie_geographies',
    component: HowieGeographiesIndex,
    group: 'howie_geographies',
    action: 'index'
  },
  {
    path: '/howie_geographies/new',
    component: HowieGeographiesCreate,
    group: 'howie_geographies',
    action: 'create'
  },
  {
    path: '/howie_geographies/:id',
    component: HowieGeographiesShow,
    group: 'howie_geographies',
    action: 'show'
  },
  {
    path: '/howie_service_areas',
    component: HowieServiceAreaIndex,
    group: 'howie_service_areas',
    action: 'index'
  },
  {
    path: '/howie_service_areas/:id/reviews/new',
    component: HowieServiceAreaReview,
    group: 'howie_service_area_reviews',
    action: 'create'
  },
  {
    path: '/ancillary_service_areas',
    component: AncillaryServiceAreaIndex,
    group: 'ancillary_service_areas',
    action: 'index'
  },
  {
    path: '/ancillary_service_areas/:id',
    component: AncillaryServiceAreaShow,
    group: 'ancillary_service_areas',
    action: 'show'
  },
  {
    path: '/ancillary_rate_factor_sets',
    component: AncillaryRateFactorSetIndex,
    group: 'ancillary_rate_factor_sets',
    action: 'index'
  },
  {
    path: '/ancillary_rate_factor_sets/new',
    component: AncillaryRateFactorSetCreate,
    group: 'ancillary_rate_factor_sets',
    action: 'create'
  },
  {
    path: '/ancillary_rate_factor_sets/:id',
    component: AncillaryRateFactorSetShow,
    group: 'ancillary_rate_factor_sets',
    action: 'show'
  },
  {
    path: '/ancillary_rate_factor_sets/:id/edit',
    component: AncillaryRateFactorSetEdit,
    group: 'ancillary_rate_factor_sets',
    action: 'update'
  },
  {
    path: '/forms/new',
    component: FormCreate,
    group: 'forms',
    action: 'create'
  },
  {
    path: '/forms',
    component: FormIndex,
    group: 'forms',
    action: 'index'
  },
  {
    path: '/forms/:id/edit',
    component: FormEdit,
    group: 'forms',
    action: 'update'
  },
  {
    path: '/smoke_checks',
    component: SmokeCheckIndex,
    group: 'smoke_checks',
    action: 'index'
  },
  {
    path: '/smoke_checks/:id',
    component: SmokeCheckShow,
    group: 'smoke_checks',
    action: 'show'
  }
]
