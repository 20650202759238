import React from 'react'
import { IoMdCheckmarkCircleOutline, IoIosCloseCircle, IoMdRemoveCircle } from 'react-icons/io'
import { startCase } from 'lodash'

const iconForpublishState = (publishState) => {
  switch (publishState) {
  case 'published':
    return <i title="Published"><IoMdCheckmarkCircleOutline size={30} className="success" /></i>
  case 'failed_to_publish':
    return <i title="FailedToPublish"><IoIosCloseCircle size={30} className="error" /></i>
  default:
    return <i title="Not Published"><IoMdRemoveCircle size={30} /></i>
  }
}

const classNameForpublishState = (publishState) => {
  switch (publishState) {
  case 'published':
    return 'success'
  case 'needs_publishing':
  case 'publishing':
    return 'warning'
  case 'failed_to_publish':
    return 'error'
  case 'unpublished':
    return ''
  default:
    return null
  }
}

const ratesStatusDisplay = (record) => {
  const publishState = record.ancillary_rate_factor_set_status
  const icon = iconForpublishState(publishState)
  const className = classNameForpublishState(publishState)

  return (
    <div className="approval-state">
      {icon}
      <strong title={publishState} className={`${className}`}>
        <div>{startCase(publishState)}</div>
      </strong>
    </div>
  )
}

export default ratesStatusDisplay
